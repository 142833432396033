import { DeprecatedModalSlideshow } from '@dev-spendesk/grapes';
import { type MouseEventHandler } from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import type { Language } from 'src/core/config/i18n';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

import './CostCenterActivationInitialModal.css';

type Props = {
  isOpen: boolean;
  onCloseModal: MouseEventHandler<HTMLElement>;
  onDone: MouseEventHandler<HTMLElement>;
};

export const CostCenterActivationInitialModal = ({
  isOpen,
  onCloseModal,
  onDone,
}: Props) => {
  const { t, activeLanguage } = useTranslation('global');

  return (
    <DeprecatedModalSlideshow
      slides={[
        {
          title: t('costCenters.activation.modal.initial.title'),
          content: t('costCenters.activation.modal.initial.content'),
          illustration: (
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <video
              controls
              playsInline
              disablePictureInPicture
              controlsList="nodownload"
              className="CostCenterActivationInitialModal__video"
            >
              <source
                src={getVideoFromLanguage(activeLanguage)}
                type="video/mp4"
              />
            </video>
          ),
          illustrationHeight: 'auto',
        },
      ]}
      isOpen={isOpen}
      translations={{
        cancel: t('costCenters.activation.modal.initial.cancel'),
        previous: '', // since there is only one slide, we don't need next and previous buttons
        next: '',
        done: t('costCenters.activation.modal.initial.done'),
      }}
      onClose={onCloseModal}
      onCancel={(event) => {
        track(AnalyticEventName.COST_CENTERS_MIGRATION_CANCEL_BUTTON_CLICKED);
        onCloseModal(event);
      }}
      onDone={(event) => {
        track(AnalyticEventName.COST_CENTERS_MIGRATION_USE_BUTTON_CLICKED);
        onDone(event);
      }}
    />
  );
};

const getVideoFromLanguage = (language: Language) => {
  switch (language) {
    case 'fr':
      return 'https://spx-production.s3.eu-west-1.amazonaws.com/cost-centers/onboarding-fr.mp4';
    default:
      return 'https://spx-production.s3.eu-west-1.amazonaws.com/cost-centers/onboarding-en.mp4';
  }
};
