import { Button, Modal } from '@dev-spendesk/grapes';
import React, { type Dispatch, type SetStateAction } from 'react';

import {
  type AccountingSoftware,
  hasIntegrationFileBasedExport,
} from 'modules/bookkeep/integration/status';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { type EmployeeAccount } from '../../../../../../../accounting/employeeAccount';
import { type ModalState } from '../ModalState';

interface Props {
  integration: AccountingSoftware;
  onAdd: (employeeAccount: EmployeeAccount) => Promise<void>;
  modalState: ModalState;
  setModalState: Dispatch<SetStateAction<ModalState>>;
}

export const EmployeeAccountCreateConfirmationModal = ({
  integration,
  onAdd,
  modalState,
  setModalState,
}: Props) => {
  const { t } = useTranslation('global');

  return (
    <Modal
      title={t(
        'bookkeep.integrations.settings.employeeAccountsTable.confirmAddTitle',
        {
          employee:
            modalState.kind === 'confirmAdd' &&
            `${modalState.employeeAccount.user?.firstName} ${modalState.employeeAccount.user?.lastName}`,
        },
      )}
      subtitle={
        hasIntegrationFileBasedExport(integration)
          ? t('bookkeep.integrations.fileBased.confirmDeleteDescription')
          : t(
              'bookkeep.integrations.settings.employeeAccountsTable.confirmAddSubtitle',
              {
                employee:
                  modalState.kind === 'confirmAdd' &&
                  `${modalState.employeeAccount.user?.firstName} ${modalState.employeeAccount.user?.lastName}`,
              },
            )
      }
      isOpen={modalState.kind === 'confirmAdd'}
      iconVariant="warning"
      iconName="pen"
      data-testid="employee-account-confirmation-modal"
      actions={[
        <Button
          key="cancel"
          onClick={() => setModalState({ kind: 'closed' })}
          text={t('misc.cancel')}
          variant="secondary"
        />,
        <Button
          data-testid="employee-account-confirm"
          key="yes"
          onClick={() => {
            if (
              modalState.kind === 'confirmAdd' &&
              modalState.employeeAccount.user &&
              modalState.employeeAccount.generalAccountCode
            ) {
              onAdd({
                user: modalState.employeeAccount.user,
                generalAccountCode:
                  modalState.employeeAccount.generalAccountCode,
                auxiliaryAccountCode:
                  modalState.employeeAccount.auxiliaryAccountCode,
              });
            }

            setModalState({ kind: 'closed' });
          }}
          text={t('misc.saveChanges')}
          variant="warning"
        />,
      ]}
    />
  );
};
