import { createContext, useContext as useReactContext } from 'react';

export type Context = {
  type: 'invoice' | 'expense';
} | null;

export const context = createContext<Context>(null);
export const useContext = () => {
  const value = useReactContext(context);
  if (value === null) {
    throw new Error(
      'useContext must be used within a Provider: TransferContext',
    );
  }
  return value;
};
