import { type ConfirmationError as ErrorModalConfirmationError } from 'common/components/WireTransferModal/WireTransferConfirmationErrorModal';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { isInvoiceBill } from '../../../models';
import { type ConfirmationError } from '../../hooks/api/useConfirmTransfers';
import { type Transfer } from '../../models';

export function useMapErrorsToPayments() {
  const { t } = useTranslation('global');

  return (errors: ConfirmationError[], transfers: Transfer[]) => {
    const indexedTransfersById = transfers.reduce<Record<string, Transfer>>(
      (accumulator, transfer) => {
        accumulator[transfer.id] = transfer;

        return accumulator;
      },
      {},
    );

    return errors.reduce<ErrorModalConfirmationError[]>(
      (accumulator, error) => {
        const transfer =
          indexedTransfersById[error.transferSchedulingPaymentId];
        if (!transfer) {
          return accumulator;
        }

        return [
          ...accumulator,
          {
            id: transfer.id,
            amount: transfer.amount,
            description: isInvoiceBill(transfer.bill)
              ? t('wiretransfer.confirmation.errorModal.invoiceDescription', {
                  invoiceNumber:
                    transfer.bill.documentaryEvidence.invoiceNumber,
                  supplierName: transfer.bill.counterparty.name,
                })
              : transfer.bill.counterparty.name,
            error: error.error,
          },
        ];
      },
      [],
    );
  };
}
