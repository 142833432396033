import { DeprecatedModalSlideshow } from '@dev-spendesk/grapes';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import Slide1En from './slide-1.en.svg';
import Slide1Fr from './slide-1.fr.svg';
import Slide2En from './slide-2.en.svg';
import Slide2Fr from './slide-2.fr.svg';
import Slide3En from './slide-3.en.svg';
import Slide3Fr from './slide-3.fr.svg';

const videoEnSource = '/static/img/cost-center/video.en.mp4';
const videoFrSource = '/static/img/cost-center/video.fr.mp4';

type Props = {
  isOpen: boolean;
  onCloseModal(): void;
  onDone(): void;
};
export const CostCenterSetupGuideModal = ({
  isOpen,
  onCloseModal,
  onDone,
}: Props) => {
  const { t, activeLanguage } = useTranslation('global');
  const isLanguageFr = activeLanguage === 'fr';
  return (
    <DeprecatedModalSlideshow
      slides={[
        {
          title: t('costCenters.onboarding.slide.one.title'),
          content: t('costCenters.onboarding.slide.one.content'),
          illustration: <img src={isLanguageFr ? Slide1Fr : Slide1En} alt="" />,
        },
        {
          title: t('costCenters.onboarding.slide.two.title'),
          content: t('costCenters.onboarding.slide.two.content'),
          illustration: (
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <video poster={isLanguageFr ? Slide2Fr : Slide2En} loop autoPlay>
              <source
                src={isLanguageFr ? videoFrSource : videoEnSource}
                type="video/mp4"
              />
            </video>
          ),
        },
        {
          title: t('costCenters.onboarding.slide.three.title'),
          content: t('costCenters.onboarding.slide.three.content'),
          illustration: <img src={isLanguageFr ? Slide3Fr : Slide3En} alt="" />,
        },
      ]}
      isOpen={isOpen}
      translations={{
        cancel: t('costCenters.onboarding.cancel'),
        previous: t('costCenters.onboarding.previous'),
        next: t('costCenters.onboarding.next'),
        done: t('costCenters.onboarding.done'),
      }}
      onCancel={onCloseModal}
      onClose={onCloseModal}
      onDone={onDone}
    />
  );
};
