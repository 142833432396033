import {
  useMutation,
  type MutationState,
} from 'src/core/api/hooks/useMutation';

import {
  type TaxAccount,
  type NewTaxAccount,
} from '../../accounting/taxAccount';

export type TaxAccountAddSuccess =
  | {
      outcome: 'set';
      setAccounts: TaxAccount[];
    }
  | {
      outcome: 'notSet';
      reason: 'nameAndRateAlreadyExists' | 'codeAlreadyExists';
    };

export const useSetTaxAccountMutation = <
  AccountType extends NewTaxAccount | TaxAccount,
>(): MutationState<AccountType, TaxAccountAddSuccess, unknown> => {
  return useMutation<
    AccountType,
    TaxAccountAddSuccess,
    TaxAccountAddSuccess,
    unknown
  >({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/accounting-integration/chart-of-accounts/tax-accounts',
      method: 'post',
    },
    reshapeData(data) {
      return data;
    },
    options: {
      onSuccess({ data, client }) {
        if (data.outcome === 'set') {
          client.invalidateQueries('getTaxAccounts');
          client.invalidateQueries('useTaxAccountsQuery'); // tax account list in Prepare
          client.invalidateQueries('useReverseChargeTaxAccountRatesQuery'); // reverse tax account list in Prepare
          client.invalidateQueries('useTaxAndExpenseAccountsCountsQuery'); // account tips in Prepare
        }
      },
    },
  });
};
