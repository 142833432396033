import { apiUrl } from 'src/core/utils/api';

type CancelRequestParams = {
  requestId: string;
  companyId: string;
};
export const cancelRequest = async ({
  requestId,
  companyId,
}: CancelRequestParams) => {
  const response = await fetch(
    apiUrl(`/requests/${requestId}/cancel`, companyId),
    {
      method: 'PUT',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
    },
  );
  if (response.status === 200) {
    return response.json();
  }
  throw new Error('An unknown error occured');
};
