import { SkeletonText } from '@dev-spendesk/grapes';

export const BreakdownGaugeSkeleton = ({
  legendMatrix,
}: {
  legendMatrix?: number[];
}) => {
  return (
    <div className="flex flex-col gap-s">
      <SkeletonText size="l" />
      {legendMatrix && (
        <div className="flex flex-row justify-between gap-s">
          {[...Array(legendMatrix.length).keys()].map((key) => (
            <div key={`legend-block-skeleton-${key}`} className="flex-1">
              {[...Array(legendMatrix[key]).keys()].map((index) => (
                <SkeletonText
                  key={`legend-block-item-${key}-skeleton-${index}`}
                />
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
