import { useUser } from 'modules/app/hooks/useUser';
import { type HubTask } from 'modules/onboarding/types/hubTask';
import { useQuery } from 'src/core/api/hooks/useQuery';

export const useSetupHubTasksQuery = () => {
  const user = useUser();

  const categories = [];
  if (user.is_admin || user.is_account_owner) {
    categories.push('control_settings');
  }
  if (user.is_controller || user.is_account_owner) {
    categories.push('accounting');
  }
  return useQuery<HubTask[], HubTask[]>({
    key: ['onboarding-hub', 'tasks', 'setup_hub'],
    isEnabled: user.is_admin || user.is_account_owner || user.is_controller,
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/onboarding-hub/tasks`,
      params: {
        category: categories,
      },
    },
    options: {
      cacheTime: 10 * 60 * 1000 /* 10min */,
      staleTime: 10 * 60 * 1000 /* 10min */,
    },
  });
};
