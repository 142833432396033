import { Icon, Tooltip, colors } from '@dev-spendesk/grapes';
import classNames from 'classnames';

import { type BreakdownGaugePreparedBar } from './BreakdownGauge';

type Props = {
  preparedBar: BreakdownGaugePreparedBar;
  isLastItem: boolean;
  color?: BreakdownGaugePreparedBar['color'];
  isCondensed?: boolean;
};

export const BreakdownGaugeLegendItem = ({
  preparedBar,
  isLastItem,
  color,
  isCondensed = false,
}: Props) => {
  return (
    <div
      key={`legend-${preparedBar.key}`}
      className="flex shrink grow-0 flex-col"
    >
      <div className="flex items-center gap-xxs">
        <div
          className="inline-block h-xs w-xs rounded-[50%]"
          style={{
            background: preparedBar.cssBackground,
            color: colors.neutralDarker,
          }}
        />
        <p className={classNames(isCondensed ? 'body-s' : 'body-m')}>
          {preparedBar.name}
        </p>
        {preparedBar.valueLabelTooltip && (
          <Tooltip
            maxWidth={180}
            content={
              <div className="leading-normal body-s">
                {preparedBar.valueLabelTooltip}
              </div>
            }
            triggerAsChild
          >
            <Icon size="s" color={colors.neutralDark} name="info" />
          </Tooltip>
        )}
      </div>
      <div
        className={classNames(
          isCondensed ? 'title-s' : 'title-m',
          isLastItem && 'md:text-end',
        )}
        style={{ color }}
      >
        {preparedBar.valueLabel}
      </div>
      {preparedBar.valueLabelSubtext && (
        <p
          className={classNames(
            isCondensed ? 'body-s' : 'body-m',
            isLastItem && 'md:text-end',
          )}
          style={{ color: colors.neutralDark }}
        >
          {preparedBar.valueLabelSubtext}
        </p>
      )}
    </div>
  );
};
