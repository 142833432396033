import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

type RequestsStatsResponse = {
  all: number;
  card_load: number;
  credit_notes: number;
  drafts: number;
  expenses: number;
  invoices: number;
  mine: number;
  purchase_order: number;
  purchases: number;
  single_purchases: number;
  subscription_increase: number;
  subscriptions: number;
  to_approve: number;
};

type RequestsStats = {
  all: number;
  toApprove: number;
};

export const useOrganisationEntityRequestStatsQuery = (
  companyId: string,
  options: {
    isEnabled: boolean;
  } = {
    isEnabled: true,
  },
): QueryState<RequestsStats> => {
  return useQuery<RequestsStats, RequestsStatsResponse>({
    key: ['organisation-reporting', companyId, 'requests-to-approve'],
    request: {
      type: 'rest',
      target: 'baseAPI',
      endpoint: `/${companyId}/requests/stats`,
    },
    isEnabled: options.isEnabled,
    reshapeData: (stats) => ({
      all: stats.all,
      toApprove: stats.to_approve,
    }),
  });
};
