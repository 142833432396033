import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useNotifications } from 'modules/app/notifications';
import {
  getDefaultTaxAccountName,
  useIntegrationStatusQuery,
} from 'modules/bookkeep';
import { isIntegrationStatusWithIntegration } from 'modules/bookkeep/integration/status';
import { useGetDefaultTaxAccountQuery } from 'modules/bookkeep/settings/integrations/hooks/useGetDefaultTaxAccountQuery';
import { useSetDefaultTaxAccountMutation } from 'modules/bookkeep/settings/integrations/hooks/useSetDefaultTaxAccountMutation';
import { TaskStepLayout } from 'modules/onboarding/components/TaskStepLayout';
import { unwrapQuery } from 'src/core/api/unwrapQuery';
import { routeFor } from 'src/core/constants/routes';

import { DefaultVatAccountForm } from './components/DefaultVatAccountForm';
import { VatAccountsTableAndModals } from './components/VatAccountsTableAndModals';
import { validate, type FormValues } from './form';
import { routes } from '../../routes';

export const AccountingVatAccounts = () => {
  const history = useHistory();

  const { t } = useTranslation('global');
  const { dangerNotif } = useNotifications();

  const companyId = useCompanyId();

  const integrationStatusQueryState = useIntegrationStatusQuery();
  const integrationStatus = unwrapQuery(integrationStatusQueryState);

  // If there is no default VAT account, defaultTaxAccount is undefined
  // If there is an archived default VAT account, defaultTaxAccount is also undefined
  const defaultTaxAccountQueryState = useGetDefaultTaxAccountQuery();
  const defaultTaxAccount =
    defaultTaxAccountQueryState.status === 'success' &&
    !defaultTaxAccountQueryState.data?.isArchived
      ? defaultTaxAccountQueryState.data
      : undefined;

  const [setDefaultTaxAccount] = useSetDefaultTaxAccountMutation();

  const { errors, handleSubmit, setFieldValue, submitCount, values } =
    useFormik<FormValues>({
      initialValues: {
        code: defaultTaxAccount?.code ?? '',
        rate: defaultTaxAccount?.rate ?? '',
      },
      enableReinitialize: true,
      validate: (newValues) => validate(newValues, t),
      onSubmit: async (newValues) => {
        if (
          !integrationStatus ||
          !isIntegrationStatusWithIntegration(integrationStatus)
        ) {
          return;
        }

        const code = newValues.code.trim();
        const rate = newValues.rate.trim();

        try {
          // If both code and rate are set, we set the default tax account
          if (code && rate) {
            const result = await setDefaultTaxAccount({
              ...defaultTaxAccount,
              name:
                defaultTaxAccount?.name ??
                getDefaultTaxAccountName(integrationStatus.accountingCountry),
              code,
              rate: rate.toString(),
              isArchived: false,
            });

            if (result.outcome === 'notSet') {
              dangerNotif(t('misc.errors.unknownError'));
              return;
            }

            goToNextStep();
            return;
          }

          // If the default VAT account was not archived
          // and we are not setting any value, then we archive it
          if (defaultTaxAccount && !defaultTaxAccount.isArchived) {
            const result = await setDefaultTaxAccount({
              ...defaultTaxAccount,
              isArchived: true,
            });
            if (result.outcome === 'notSet') {
              dangerNotif(t('misc.errors.unknownError'));
              return;
            }
            goToNextStep();
            return;
          }

          // If there was nothing to do, we go to the next step
          goToNextStep();
        } catch {
          dangerNotif(t('misc.errors.unknownError'));
        }
      },
    });

  const goToNextStep = () => {
    // TODO(GROW-1482): Go to reverse charge page
  };

  const goBack = () => {
    const setupHubPath = routeFor(routes.SETUP_HUB_TASK_LIST.path, {
      company: companyId,
    });
    history.push(setupHubPath);
  };

  return (
    <div className="page__container">
      <TaskStepLayout
        description={t('setupHub.vatAccounts.vat.description')}
        onBack={goBack}
        onNext={handleSubmit}
        title={t('setupHub.vatAccounts.vat.title')}
        video={{
          // TODO(GROW-1484): Replace with localized video URL
          url: '',
          title: t('setupHub.vatAccounts.vat.helpTitle'),
        }}
      >
        <div className="flex flex-col gap-s">
          <p className="title-m">{t('setupHub.expenseAccounts.listTitle')}</p>
          <VatAccountsTableAndModals />
        </div>
        <div className="my-s flex items-center gap-s">
          <div className="separator" />
          <p className="uppercase text-complementary body-l">{t('misc.or')}</p>
          <div className="separator" />
        </div>
        <form onSubmit={handleSubmit}>
          <DefaultVatAccountForm
            code={values.code}
            codeError={submitCount > 0 ? errors.code : undefined}
            rate={values.rate}
            rateError={submitCount > 0 ? errors.rate : undefined}
            setCode={(code: string) => setFieldValue('code', code)}
            setRate={(rate: string) => setFieldValue('rate', rate)}
          />
        </form>
      </TaskStepLayout>
    </div>
  );
};
