import { Callout } from '@dev-spendesk/grapes';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { validate } from 'modules/custom-fields/components/CustomFieldCreationModal/validate';
import { useUpdateCachedTaskStatus } from 'modules/onboarding/setup-hub/pages/SetupHubPage/hooks/useUpdateCachedTaskStatus';
import { routeFor } from 'src/core/constants/routes';
import {
  NotificationType,
  useNotifications,
} from 'src/core/modules/app/notifications';
import { useIsAnalyticalSplitActivated } from 'src/core/modules/bookkeep/apis/analytical-split';
import { useCreateCustomFieldMutation } from 'src/core/modules/custom-fields/containers/CustomFieldCreationModalContainer/useCreateCustomFieldMutation';
import { useDefaultCustomFieldPayload } from 'src/core/modules/custom-fields/containers/customField';
import { convertValues } from 'src/core/modules/custom-fields/utils/convertValues';

import { CustomFieldValuesFormField } from './components/CustomFieldValuesFormField';
import { SetupHubLayout } from '../../../components/SetupHubLayout';
import { VideoBlock } from '../../../components/VideoBlock';
import { routes } from '../../routes';

export const ControlSettingsCustomFieldValues = () => {
  const { t } = useTranslation('global');
  const { pushNotif } = useNotifications();

  const history = useHistory();
  const customField = {
    name: history.location.state?.name,
    isRequired: history.location.state?.isRequired,
  };

  const companyId = useCompanyId();

  const [customFieldValues, setCustomFieldValues] = useState<string>('');
  const convertedCustomFieldValues = convertValues(customFieldValues);

  const [hasSubmittedOnce, setHasSubmittedOnce] = useState<boolean>(false);

  const errors = useMemo(
    () => validate({ customFieldValues }),
    [customFieldValues],
  );

  const isAnalyticalSplitActivated = useIsAnalyticalSplitActivated();
  const defaultCustomFieldPayload = useDefaultCustomFieldPayload();
  const [createCustomField] = useCreateCustomFieldMutation();
  const updateCachedTaskStatus = useUpdateCachedTaskStatus();

  const onContinue = async () => {
    if (!hasSubmittedOnce) {
      setHasSubmittedOnce(true);
    }

    if (Object.keys(errors).length > 0) {
      return;
    }

    try {
      await createCustomField({
        ...defaultCustomFieldPayload,
        ...customField,
        values: convertedCustomFieldValues,
        isSplittable: isAnalyticalSplitActivated,
      });

      updateCachedTaskStatus('analytical_fields');

      const nextPagePath = routeFor(
        routes.CONTROL_SETTINGS_CUSTOM_FIELD_LIST.path,
        {
          company: companyId,
        },
      );
      history.push(nextPagePath);
    } catch {
      pushNotif({
        type: NotificationType.Danger,
        message: t('misc.errors.unknownError'),
      });
    }
  };

  const goBack = () => {
    const hubPath = routeFor(routes.CONTROL_SETTINGS_CUSTOM_FIELD_NAME.path, {
      company: companyId,
    });
    history.push(hubPath);
  };

  // Redirect to previous step if previous values are not set
  useEffect(() => {
    if (!customField.name || customField.isRequired === undefined) {
      goBack();
    }
  }, []);

  return (
    <SetupHubLayout
      onBack={goBack}
      onNext={onContinue}
      onNextLabel={t('misc.continue')}
      rightContent={
        <VideoBlock
          id="custom_fields"
          title={t('setupHub.customField.video.title')}
          description={t('setupHub.customField.video.description')}
        />
      }
      scope="custom-field-values"
      stepIndex={1}
      stepTotal={3}
      title={t('setupHub.customField.title')}
    >
      <Callout className="mb-m" title={t('setupHub.customField.callout.title')}>
        <p>{t('setupHub.customField.callout.description')}</p>
      </Callout>
      <form className="flex flex-col" onSubmit={onContinue}>
        <CustomFieldValuesFormField
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
            setCustomFieldValues(event.target.value)
          }
          showErrors={hasSubmittedOnce}
          values={customFieldValues}
        />
      </form>
    </SetupHubLayout>
  );
};
