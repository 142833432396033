import { Button, Modal } from '@dev-spendesk/grapes';
import { useMemo, type Dispatch, type SetStateAction } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { useNotifications } from 'modules/app/notifications';
import {
  type AccountingSoftware,
  hasIntegrationFileBasedExport,
} from 'modules/bookkeep/integration/status';
import { useSetTaxAccountMutation } from 'modules/bookkeep/settings/integrations/hooks/useSetTaxAccountMutation';

import { type ModalState } from '../ModalState';

interface Props {
  integration: AccountingSoftware;
  formState: ModalState;
  setFormState: Dispatch<SetStateAction<ModalState>>;
  hideSubtitle?: boolean;
}

export const VatDeleteModal = ({
  integration,
  formState,
  setFormState,
  hideSubtitle = false,
}: Props) => {
  const { t } = useTranslation('global');
  const { dangerNotif } = useNotifications();

  const [setTaxAccount] = useSetTaxAccountMutation();

  const subtitle = useMemo(() => {
    if (hideSubtitle) {
      return undefined;
    }
    return hasIntegrationFileBasedExport(integration)
      ? t('bookkeep.integrations.fileBased.confirmDeleteDescription')
      : t(
          'bookkeep.integrations.settings.vatAccountsTable.confirmSendBackToPrepareSubtitle',
        );
  }, [hideSubtitle, integration]);

  const onConfirm = async () => {
    try {
      if (formState.kind === 'confirmDeleteVat') {
        await setTaxAccount({
          id: formState.id,
          name: formState.name,
          code: formState.code,
          rate: formState.rate,
          type: 'vat',
          isArchived: true,
        });
      }
      setFormState({ kind: 'closed' });
    } catch {
      dangerNotif(t('misc.errors.unknownError'));
    }
  };

  return (
    <Modal
      title={
        formState.kind === 'confirmDeleteVat'
          ? t(
              'bookkeep.integrations.settings.vatAccountsTable.confirmDeleteTaxAccountTitle',
              { accountName: formState.name },
            )
          : ''
      }
      subtitle={subtitle}
      isOpen={formState.kind === 'confirmDeleteVat'}
      iconVariant="alert"
      iconName="trash"
      actions={[
        <Button
          key="cancel"
          onClick={() => setFormState({ kind: 'closed' })}
          text={t('misc.cancel')}
          variant="secondary"
        />,
        <Button
          key="yes"
          onClick={onConfirm}
          text={t(
            'bookkeep.integrations.settings.vatAccountsTable.confirmDeletion',
          )}
          variant="alert"
        />,
      ]}
    />
  );
};
