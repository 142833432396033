import { Button } from '@dev-spendesk/grapes';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';
import { apiUrl } from 'src/core/utils/api';

import { type Payable } from '../PayablePanelContainer';

type Props = {
  payable: Pick<Payable, 'id'>;
};

const generateDownloadLink = (companyId: string, payableId: string) => {
  return apiUrl(`/bookkeeping/payable/${payableId}/history.csv`, companyId);
};

export const PayableHistoryDownloadContainer = ({ payable }: Props) => {
  const { t } = useTranslation('global');
  const companyId = useCompanyId();

  return (
    <div>
      <Button
        fit="parent"
        component="a"
        href={generateDownloadLink(companyId, payable.id)}
        download
        target="_blank"
        iconName="download"
        iconPosition="left"
        text={t('payables.panel.payableHistoryDownloadSection.title')}
        variant="secondary"
      />
    </div>
  );
};
