import { FormField, TextInput } from '@dev-spendesk/grapes';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

type Props = {
  error?: string;
  onChange: (value: string) => void;
  value: string;
  visuallyHideLabel?: boolean;
};

export const DefaultTaxAccountCodeFormField = ({
  error,
  onChange,
  value,
  visuallyHideLabel = false,
}: Props) => {
  const { t } = useTranslation('global');
  return (
    <FormField
      className="flex-1"
      label={t('bookkeep.integrations.settings.defaultTaxAccountForm.codeName')}
      htmlFor="set-account-code-input"
      alertMessage={error}
      visuallyHideLabel={visuallyHideLabel}
    >
      <TextInput
        placeholder={t(
          'bookkeep.integrations.settings.defaultTaxAccountForm.codePlaceholder',
        )}
        value={value}
        onChange={(event) => onChange(event.target.value)}
        id="set-account-code-input"
      />
    </FormField>
  );
};
