import { PhoneInput } from '@dev-spendesk/grapes';
import {
  type CountryCode,
  getCountryCallingCode,
  isSupportedCountry,
  type CountryCallingCode,
} from 'libphonenumber-js';
import React, { forwardRef, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { COUNTRIES } from 'src/core/config/country';

import {
  formatPhoneNumber,
  getCountryFromNumber,
  getNationalNumberFromNumber,
} from './helpers';

// TODO: use localization to set country and calling code
const DEFAULT_COUNTRY = 'FR';
const DEFAULT_CALLING_CODE = '33';

// TODO: export Props from Grapes
export type Props = {
  className?: string;
  value: string | null;
  isDisabled?: boolean;
  name?: string;
  placeholder?: string;
  initialCountry?: string;
  fit?: 'content' | 'parent';
  isInvalid?: boolean;
  onChange?: (phoneNumber: string) => void;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
};

export const PhoneNumberInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      className,
      value,
      isDisabled = false,
      isInvalid = false,
      name,
      placeholder,
      initialCountry = DEFAULT_COUNTRY,
      fit,
      onChange = () => {},
      onFocus,
      onBlur,
    }: Props,
    ref,
  ) => {
    const { t } = useTranslation('global');

    const [country, setCountry] = useState<CountryCode | undefined>(
      () => getCountryFromNumber(value) ?? (initialCountry as CountryCode),
    );
    const [callingCode, setCallingCode] = useState<
      CountryCallingCode | undefined
    >();

    useEffect(() => {
      if (value !== '') {
        const newCountry = getCountryFromNumber(value);
        if (newCountry) {
          setCountry(newCountry);
        }
      }
    }, [value]);

    useEffect(() => {
      setCallingCode(
        country && isSupportedCountry(country)
          ? getCountryCallingCode(country)
          : undefined,
      );
    }, [country]);

    const countries = useMemo(
      () =>
        Object.entries(COUNTRIES).map(([key, value_]) => ({
          key,
          label: t(value_.translationKey),
        })),
      [],
    );

    return (
      <PhoneInput
        className={className}
        isDisabled={isDisabled}
        isInvalid={isInvalid}
        value={value}
        name={name}
        placeholder={placeholder}
        callingCode={callingCode ?? DEFAULT_CALLING_CODE}
        country={country ?? DEFAULT_COUNTRY}
        countries={countries}
        fit={fit}
        ref={ref}
        onChange={(newValue) => {
          // This gets the national number, which is useful if the user wrote
          // his number with a leading 0 for example.
          const nationalNumber = getNationalNumberFromNumber(newValue);
          onChange(
            nationalNumber ? `+${callingCode}${nationalNumber}` : newValue,
          );
        }}
        onSelectCountry={(selectedCountry) => {
          setCountry(selectedCountry.key as CountryCode);
          onChange('');
        }}
        formatPhoneNumber={formatPhoneNumber}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    );
  },
);
PhoneNumberInput.displayName = 'PhoneNumberInput';
