import * as R from '@dev-spendesk/general-type-helpers/Result';
import React from 'react';
import { useQueryClient } from 'react-query';

import { NotificationType, useNotifications } from 'modules/app/notifications';
import { type QueryState } from 'src/core/api/queryState';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { useCreateOrUpdateAccountPayable } from 'src/core/modules/accounting-integration/apis/useCreateOrUpdateAccountPayable';
import { logger } from 'src/utils/datadog-log-wrapper';

import {
  EmployeeAccountsLocalOnlySection,
  EmployeeAccountsLocalOnlyWithDefaultAccountsSection,
} from './EmployeeAccountsLocalOnlySection/EmployeeAccountsLocalOnlySection';
import { EmployeeAccountsPullWithDefaultAccountsSection } from './EmployeeAccountsPullSection/EmployeeAccountsPullWithDefaultAccountsSection';
import { rejectUnexpectedValue } from '../../../../../../../../utils/switchGuard';
import { GraphQLProvider } from '../../../../../../components/GraphQLProvider';
import {
  type IntegrationStatusWithIntegration,
  type EmployeeAccountsCapability,
  hasIntegrationFileBasedExport,
} from '../../../../../../integration/status';
import {
  type DefaultEmployeeAccount,
  type EmployeeAccount,
} from '../../../../../accounting';
import { useGetDefaultEmployeeAccountQuery } from '../../../../hooks/useGetDefaultEmployeeAccountQuery';
import { useGetDefaultEmployeeAccountQuery__deprecated } from '../../../../hooks/useGetDefaultEmployeeAccountQuery__deprecated';
import { usePaginatedEmployeeAccountsQuery } from '../../../../hooks/usePaginatedEmployeeAccountsQuery';
import { useSetDefaultEmployeeAccountMutation } from '../../../../hooks/useSetDefaultEmployeeAccountMutation';
import { useSetDefaultEmployeeAccountMutation__deprecated } from '../../../../hooks/useSetDefaultEmployeeAccountMutation__deprecated';

interface Props {
  integrationStatus: IntegrationStatusWithIntegration;
  employeeAccountsCapability: EmployeeAccountsCapability;
}

export const EmployeeAccountsSection = (props: Props) => {
  const { integrationStatus, employeeAccountsCapability } = props;
  const { t } = useTranslation('global');
  const { t: tErrors } = useTranslation('errors');
  const { pushNotif } = useNotifications();

  const capabilityHasDefault =
    integrationStatus.capabilities.employeeAccounts ===
      'localOnlyWithDefaultAccounts' ||
    integrationStatus.capabilities.employeeAccounts ===
      'pullWithDefaultAccounts';

  const defaultEmployeeAccountQueryState__deprecated =
    useGetDefaultEmployeeAccountQuery__deprecated(!capabilityHasDefault);
  const setDefaultEmployeeAccount__deprecated =
    useSetDefaultEmployeeAccountMutation__deprecated();

  const defaultEmployeeAccountQueryState = useGetDefaultEmployeeAccountQuery({
    isEnabled: capabilityHasDefault,
  });

  const employeeAccountsQueryState = usePaginatedEmployeeAccountsQuery({
    isMapped: true,
  });
  const [setDefaultEmployeeAccount, setDefaultEmployeeAccountQueryState] =
    useSetDefaultEmployeeAccountMutation();
  const [updateEmployeeAccount] =
    useCreateOrUpdateAccountPayable('employeeAccount');
  const queryClient = useQueryClient();

  async function handleAddEmployeeAccount(employeeAccount: EmployeeAccount) {
    const result = await updateEmployeeAccount({
      generalAccountCode: employeeAccount.generalAccountCode,
      auxiliaryAccountCode: employeeAccount.auxiliaryAccountCode,
      isArchived: false,
      name: employeeAccount.user
        ? `${employeeAccount.user.firstName} ${employeeAccount.user.lastName}`
        : t(
            'bookkeep.integrations.settings.employeeAccountsTable.deletedEmployeeAccount',
          ),
      memberIds: employeeAccount.user ? [employeeAccount.user.id] : [],
    });

    if (R.isFailure(result)) {
      pushNotif({
        type: NotificationType.Danger,
        message: tErrors('somethingWrong'),
      });

      logger.error("Couldn't add employee account", {
        scope: 'prepare',
        team: 'accounting-integration',
        result,
      });
    }
  }

  async function handleDeleteEmployeeAccount(employeeAccount: EmployeeAccount) {
    const result = await updateEmployeeAccount({
      id: employeeAccount.id,
      generalAccountCode: employeeAccount.generalAccountCode,
      auxiliaryAccountCode: employeeAccount.auxiliaryAccountCode,
      isArchived: true,
      name: employeeAccount.user
        ? `${employeeAccount.user.firstName} ${employeeAccount.user.lastName}`
        : t(
            'bookkeep.integrations.settings.employeeAccountsTable.deletedEmployeeAccount',
          ),
      memberIds: employeeAccount.user ? [employeeAccount.user.id] : [],
    });

    if (R.isFailure(result)) {
      pushNotif({
        type: NotificationType.Danger,
        message: tErrors('somethingWrong'),
      });

      logger.error("Couldn't delete employee account", {
        scope: 'prepare',
        team: 'accounting-integration',
        result,
      });
    }
  }

  async function handleSetDefaultEmployeeAccount__deprecated(
    defaultEmployeeAccount: DefaultEmployeeAccount,
  ) {
    // For accounts payable, an account update should include the id
    // only if we're deleting the account
    const result = await setDefaultEmployeeAccount__deprecated(
      {
        ...defaultEmployeeAccount,
        id: defaultEmployeeAccount.isArchived
          ? defaultEmployeeAccount.id
          : undefined,
      },
      defaultEmployeeAccount.isArchived,
    );

    await Promise.all([
      queryClient.invalidateQueries(['useIntegrationStatusQuery']),
      queryClient.invalidateQueries(['getDefaultEmployeeAccountQuery']), // Deprecated, use `useGetDefaultEmployeeAccountQuery` instead
      queryClient.invalidateQueries(['useGetDefaultEmployeeAccountQuery']),
      queryClient.invalidateQueries(['accountsPayable']),
      queryClient.invalidateQueries(['useGetUsersWithoutEmployeeAccountQuery']),
    ]);

    if (hasIntegrationFileBasedExport(integrationStatus.integration)) {
      pushNotif({
        type: result.reason
          ? NotificationType.Danger
          : NotificationType.Success,
        message: result.reason
          ? t(
              'bookkeep.integrations.settings.employeeAccountsTable.editDefaultAccountFailure',
            )
          : t(
              'bookkeep.integrations.settings.employeeAccountsTable.editDefaultAccountSuccess',
            ),
      });
    }
  }

  async function handleSetDefaultEmployeeAccount(
    defaultEmployeeAccount: DefaultEmployeeAccount,
  ) {
    await setDefaultEmployeeAccount(defaultEmployeeAccount);
  }

  switch (employeeAccountsCapability) {
    case 'pullWithDefaultAccounts':
      return (
        <EmployeeAccountsPullWithDefaultAccountsSection
          integrationStatus={integrationStatus}
          employeeAccountsQueryState={employeeAccountsQueryState}
        />
      );
    case 'localOnlyWithDefaultAccounts':
      return (
        // TODO: remove GraphQLProvider
        <GraphQLProvider>
          <EmployeeAccountsLocalOnlyWithDefaultAccountsSection
            employeeAccounts={
              employeeAccountsQueryState.status === 'success'
                ? employeeAccountsQueryState.data
                : []
            }
            setDefaultEmployeeAccountQueryState={
              setDefaultEmployeeAccountQueryState
            }
            hasNextPage={employeeAccountsQueryState.hasNextPage}
            fetchNextPage={employeeAccountsQueryState.fetchNextPage}
            getDefaultEmployeeAccountQueryState={
              defaultEmployeeAccountQueryState
            }
            onAdd={handleAddEmployeeAccount}
            onDelete={handleDeleteEmployeeAccount}
            onSetDefault={handleSetDefaultEmployeeAccount}
            integrationStatus={integrationStatus}
            isLoading={employeeAccountsQueryState.status === 'loading'}
            isDefaultEmployeeAccountToggleChecked={
              defaultEmployeeAccountQueryState.status === 'success' &&
              defaultEmployeeAccountQueryState.data?.isArchived === false
            }
          />
        </GraphQLProvider>
      );

    case 'localOnly':
      return (
        <GraphQLProvider>
          <EmployeeAccountsLocalOnlySection
            employeeAccounts={
              employeeAccountsQueryState.status === 'success'
                ? employeeAccountsQueryState.data
                : []
            }
            hasNextPage={employeeAccountsQueryState.hasNextPage}
            fetchNextPage={employeeAccountsQueryState.fetchNextPage}
            defaultEmployeeAccount={getDefaultEmployeeAccount(
              defaultEmployeeAccountQueryState__deprecated,
            )}
            onAdd={handleAddEmployeeAccount}
            onDelete={handleDeleteEmployeeAccount}
            onSetDefault={handleSetDefaultEmployeeAccount__deprecated}
            integrationStatus={integrationStatus}
            isLoading={employeeAccountsQueryState.status === 'loading'}
            isDefaultEmployeeAccountToggleChecked={
              defaultEmployeeAccountQueryState__deprecated.status ===
                'success' &&
              defaultEmployeeAccountQueryState__deprecated.data[0] !== undefined
            }
          />
        </GraphQLProvider>
      );
    default:
      rejectUnexpectedValue(
        'employeeAccountsCapability',
        employeeAccountsCapability,
      );
  }
};

function getDefaultEmployeeAccount(
  queryState: QueryState<DefaultEmployeeAccount[]>,
): DefaultEmployeeAccount {
  if (queryState.status === 'success') {
    return (
      queryState.data[0] ?? {
        generalAccountCode: '',
        auxiliaryAccountCode: undefined,
        name: '',
        isArchived: false,
      }
    );
  }

  return {
    generalAccountCode: '',
    auxiliaryAccountCode: undefined,
    name: '',
    isArchived: false,
  };
}
