import { type Edge } from '@xyflow/react';

import { type EdgeData } from './components/node-components/LabelEdge';
import { type CustomNode } from './node';

export const initialEdges: Edge<EdgeData>[] = [
  {
    id: 'initialEdge',
    source: 'initialLabelNode',
    target: 'initialStandardApprovalFlowNode',
    sourceHandle: 'bottomHandle',
    type: 'labelEdge',
    data: {
      labelText: 'If no other matching condition found, then',
      labelVariant: 'blank',
      direction: 'vertical',
    },
  },
];

export const initialNodes: CustomNode[] = [
  {
    id: 'initialLabelNode',
    position: { x: 0, y: 0 },
    draggable: false,
    type: 'labelNode',
    data: {
      nodeType: 'labelNode',
    },
  },
  {
    id: 'initialStandardApprovalFlowNode',
    position: { x: -78, y: 150 },
    draggable: false,
    type: 'standardApprovalFlowNode',
    data: {
      schemeId: undefined,
    },
  },
];
