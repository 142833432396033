import { createSlice, createSelector } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

import { PaymentContext } from 'modules/invoices/hooks';
import type { AppState } from 'src/core/reducers';
import { getCompanyCurrency } from 'src/core/selectors/globalSelectors';

import { type Transfer, sumTransfersAmount } from '../models';

type Type = 'invoice' | 'expense';
type TransferState = {
  [type in Type]: {
    transfersSelected: Transfer[];
  };
};

const initialState: TransferState = {
  expense: {
    transfersSelected: [],
  },
  invoice: {
    transfersSelected: [],
  },
};

const transfersSlice = createSlice({
  name: 'invoices/transfers',
  initialState,
  reducers: {
    setTransfersSelected: (
      state,
      { payload }: PayloadAction<{ transfersSelected: Transfer[]; type: Type }>,
    ) => {
      state[payload.type].transfersSelected = payload.transfersSelected;
    },

    resetTransfersSelection: (
      state,
      { payload }: PayloadAction<{ type: Type }>,
    ) => {
      state[payload.type].transfersSelected = [];
    },
    unselectTransfers: (
      state,
      {
        payload,
      }: PayloadAction<{ transfersToUnselect: Transfer[]; type: Type }>,
    ) => {
      state[payload.type].transfersSelected = state[
        payload.type
      ].transfersSelected.filter((selectedTransfer) => {
        return !payload.transfersToUnselect.some(
          (transferToRemove) => transferToRemove.id === selectedTransfer.id,
        );
      });
    },
  },
});

const selectTransfersSlice = (state: AppState): TransferState =>
  state.invoices.transfers;

const selectTransfersStateForType = createSelector(
  selectTransfersSlice,
  (_, type: Type) => type,
  (transfersSliceState: TransferState, type: Type) => transfersSliceState[type],
);
const selectTransfersSelected = createSelector(
  selectTransfersStateForType,
  (transfersSliceState) => transfersSliceState.transfersSelected,
);

const selectTransfersSelectedCount = createSelector(
  selectTransfersSelected,
  (transfersSelected) => transfersSelected.length,
);

const selectTransfersSelectedAmountSum = createSelector(
  selectTransfersSelected,
  getCompanyCurrency,
  sumTransfersAmount,
);

export const selectors = {
  selectTransfersSelected,
  selectTransfersSelectedCount,
  selectTransfersSelectedAmountSum,
};

export const useSelectorWithContext = <T>(
  selector: (state: AppState, type: Type) => T,
): T => {
  const { type } = PaymentContext.useContext();
  return useSelector((state: AppState) => selector(state, type));
};

export const { actions, reducer } = transfersSlice;
