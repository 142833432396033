import { useState } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import placeholderImageSrc from 'modules/onboarding/setup-hub/components/VideoBlock/assets/placeholder.png';

import { VideoPageModal } from '../../../VideoPageModal';
import { type VideoPageModalProps } from '../../../VideoPageModal/types';

export const VideoBanner = ({
  title,
  url,
}: Pick<VideoPageModalProps, 'title' | 'url'>) => {
  const { t } = useTranslation('global');

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  return (
    <>
      <div className="flex items-center justify-between gap-s rounded-xs bg-primary-lightest px-m py-s">
        <div className="flex flex-col gap-s">
          <p className="text-complementary body-xl">
            {t('setupHub.accounting.help.title')}
          </p>
          <p className="text-neutral-darker">
            {t('setupHub.accounting.help.description')}
          </p>
        </div>
        <button
          aria-label={t('setupHub.accounting.help.title')}
          className="cursor-pointer border-0 transition-transform hover:scale-105"
          onClick={() => setIsModalOpen(true)}
          type="button"
        >
          <img className="w-[114px]" src={placeholderImageSrc} alt="" />
        </button>
      </div>
      <VideoPageModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={title}
        url={url}
      />
    </>
  );
};
