import { GeneralLimitCallout } from './GeneralLimitCallout';
import { StatusByCategoryLimitCallout } from './StatusByCategoryLimitCallout';
import { StatusByRequestLimitCallout } from './StatusByRequestLimitCallout';

type Props = {
  className?: string;
  requestDate?: Date;
  requestAmount?: number;
  expenseCategoryId?: string;
  requestId?: string;
  currency: string;
};

export const SpendLimitCallout = ({
  className,
  requestDate,
  requestAmount,
  expenseCategoryId,
  requestId,
  currency,
}: Props) => {
  const shouldRenderGeneralLimitCallout =
    Boolean(expenseCategoryId) && (!requestDate || !requestAmount);
  const shouldRenderStatusByRequestLimitCallout =
    Boolean(requestId) &&
    Boolean(requestAmount) &&
    Boolean(requestDate) &&
    !expenseCategoryId;
  const shouldRenderStatusByCategoryLimitCallout =
    Boolean(expenseCategoryId) &&
    Boolean(requestDate) &&
    Boolean(requestAmount);

  const renderGeneralLimitCallout = () => {
    if (!expenseCategoryId) {
      return null;
    }
    return (
      <div className={className}>
        <GeneralLimitCallout expenseCategoryId={expenseCategoryId} />
      </div>
    );
  };

  const renderStatusByRequestLimitCallout = () => {
    if (!requestId || !requestAmount || !requestDate) {
      return null;
    }
    return (
      <div className={className}>
        <StatusByRequestLimitCallout requestId={requestId} />
      </div>
    );
  };

  const renderStatusByCategoryLimitCallout = () => {
    if (!expenseCategoryId || !requestDate || !requestAmount) {
      return null;
    }
    return (
      <div className={className}>
        <StatusByCategoryLimitCallout
          requestAmount={requestAmount}
          requestDate={requestDate}
          currency={currency}
          expenseCategoryId={expenseCategoryId}
        />
      </div>
    );
  };

  return (
    <div className={className}>
      {shouldRenderGeneralLimitCallout && renderGeneralLimitCallout()}
      {shouldRenderStatusByRequestLimitCallout &&
        renderStatusByRequestLimitCallout()}
      {shouldRenderStatusByCategoryLimitCallout &&
        renderStatusByCategoryLimitCallout()}
    </div>
  );
};
