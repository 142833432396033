import { Button, FormField, Label, Modal } from '@dev-spendesk/grapes';
import { type FormikProps } from 'formik';
import React from 'react';

import InputTagsSelect from 'src/core/common/components/legacy/InputTagsSelect/InputTagsSelect';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { ColumnDisplayOptionsContainer } from './ColumnDisplayOptionsContainer';
import { CommonEditComponents } from './CommonEditComponents';
import { type FormValues } from './form';
import './ColumnEditModal.css';
import { type RowDisplayOption } from '../../../export';

type CustomContentOption = {
  id: string;
  name: string;
};

type ParentCustomContentOption = CustomContentOption & {
  subItems: CustomContentOption[];
};

type Props = {
  isOpen: boolean;
  title: string;
  description: string;
  customContentOptions: (ParentCustomContentOption | CustomContentOption)[];
  isDeletable: boolean;
  isCustomContent: boolean;
  getCustomContentValueLabel: (value: string) => string;
  isStaticCustomContentValue: (value: string) => boolean;
  onRemoveColumn: () => void;
  onClose: () => void;
  isAnalyticalColumn: boolean;
  rowDisplayOptions?: RowDisplayOption[];
} & FormikProps<FormValues>;

export const ColumnEditModal = ({
  isOpen,
  title,
  description,
  customContentOptions,
  isDeletable,
  isCustomContent,
  getCustomContentValueLabel,
  isStaticCustomContentValue,
  onRemoveColumn,
  onClose,
  // Formik
  values,
  errors,
  touched,
  handleChange,
  setFieldValue,
  handleSubmit,
  rowDisplayOptions,
  isAnalyticalColumn,
  ...rest
}: Props) => {
  const { t } = useTranslation('global');

  const noColumnDisplaySelected =
    rowDisplayOptions &&
    values.displayOptions &&
    !values.displayOptions.expense.active &&
    !values.displayOptions.analytical.active &&
    !values.displayOptions.vat.active &&
    !values.displayOptions.supplier.active;

  return (
    <Modal
      isOpen={isOpen}
      title={t('exports.columnEditModalTitle', { title })}
      subtitle={description}
      onClose={onClose}
      iconName="pen"
      actions={[
        <Button
          key="cancel"
          variant="secondary"
          text={t('misc.cancel')}
          onClick={onClose}
        />,
        <Button
          key="saveChanges"
          text={t('misc.saveChanges')}
          isDisabled={noColumnDisplaySelected}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onClick={(event: any) => {
            handleSubmit(event);
          }}
        />,
      ]}
    >
      <div className="text-left">
        <CommonEditComponents
          values={values}
          handleChange={handleChange}
          touched={touched}
          errors={errors}
          setFieldValue={setFieldValue}
          handleSubmit={handleSubmit}
          {...rest}
        />
        {isCustomContent && (
          <div className="mt-m flex flex-col">
            <Label
              className="mb-xs"
              label={t('exports.changeCustomColumnContent')}
            />

            <InputTagsSelect
              className="ColumnEditModal__tag-selector"
              selectedOptions={values.customContent}
              options={customContentOptions}
              getTagDisplayValue={getCustomContentValueLabel}
              getTagDisplayTheme={(value: string) =>
                isStaticCustomContentValue(value) ? 'success' : 'info'
              }
              onChange={(parts: unknown) => {
                setFieldValue('customContent', parts);
              }}
              allowCreateNewItem
              createNewItemText={t('exports.entryId.createStaticValue')}
            />
          </div>
        )}
        {rowDisplayOptions && (
          <FormField
            label={t(
              'bookkeep.settings.export.columnDisplayOptions.headerFormLabel',
            )}
            className="mt-m"
            alertMessage={
              noColumnDisplaySelected
                ? t(
                    `bookkeep.settings.export.columnDisplayOptions.selectColumnAlert`,
                  )
                : ''
            }
          >
            <ColumnDisplayOptionsContainer
              displayOptions={values.displayOptions}
              onDisplayOptionChange={(upd) =>
                setFieldValue('displayOptions', upd)
              }
              rowDisplayOptions={rowDisplayOptions}
              isAnalyticalColumn={isAnalyticalColumn}
            />
          </FormField>
        )}
        {isDeletable && (
          <Button
            hasNegativeMargins
            className="mt-m"
            variant="ghost"
            iconName="minus-circle"
            iconPosition="left"
            text={t('exports.removeColumn')}
            onClick={onRemoveColumn}
          />
        )}
      </div>
    </Modal>
  );
};
