import { useBookkeepingSettings } from 'modules/bookkeep/apis/useBookkeepingSettings';
import {
  getIsPayableExported,
  type PayableState,
} from 'modules/bookkeep/payables/models/payable';
import { unwrapQuery } from 'src/core/api/unwrapQuery';

export const useWasUnlockablePayable = (payable: {
  state: PayableState;
}): boolean => {
  const bookkeepingSettingsQueryResult = useBookkeepingSettings({
    isEnabled: true,
  });

  const bookkeepingSettings = unwrapQuery(bookkeepingSettingsQueryResult);

  const isPayableExported = getIsPayableExported(payable);

  if (!bookkeepingSettings || !isPayableExported) {
    return false;
  }

  return (
    bookkeepingSettings.editPayablesAfterExport?.hasOptedIn === false &&
    bookkeepingSettings.editPayablesAfterExport?.wasOptedIn === true
  );
};
