import { SkeletonText } from '@dev-spendesk/grapes';
import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';

import { AnalyticEventName, track } from 'src/core/utils/analytics';

import { EntityListEmptyState } from './EntityListEmptyState';
import { EntityListErrorCallout } from './EntityListErrorCallout';
import { EntityListGroupSection } from './EntityListGroupSection/EntityListGroupSection';
import { useOrganisationCompanyList } from '../../../hooks/data/useOrganisationCompanyList';
import { usePinnedEntities } from '../../../hooks/usePinnedEntities';
import {
  isEntityInformationEnriched,
  type EnrichedEntityInformation,
  type EntityBasicInformation,
} from '../../../types';
import {
  EntityListItem,
  type OnDataLoadedResult,
} from '../entity-list-item/EntityListItem';

type Props = {
  entities: (EntityBasicInformation | EnrichedEntityInformation)[];
  onDataLoaded: (entities: EnrichedEntityInformation[]) => void;
};

export const EntityListContainer = ({ entities, onDataLoaded }: Props) => {
  const total = useOrganisationCompanyList().length;

  const [fetchedEntitiesResult, setFetchedEntitiesResult] = useState<{
    succeededEntities: EnrichedEntityInformation[];
    failedEntities: EntityBasicInformation[];
  }>({ succeededEntities: [], failedEntities: [] });

  const [isDataFullyLoaded, setIsDataFullyLoaded] = useState(false);
  useEffect(() => {
    const { failedEntities, succeededEntities } = fetchedEntitiesResult;

    if (failedEntities.length + succeededEntities.length === entities.length) {
      setIsDataFullyLoaded(true);
      onDataLoaded(fetchedEntitiesResult.succeededEntities);
      return () => {};
    }
  }, [fetchedEntitiesResult]);

  const onEntityDataLoaded = ({ entityData, status }: OnDataLoadedResult) => {
    if (status === 'success') {
      setFetchedEntitiesResult((previous) => ({
        ...previous,
        succeededEntities: [...previous.succeededEntities, entityData],
      }));
    } else if (status === 'error') {
      setFetchedEntitiesResult((previous) => ({
        ...previous,
        failedEntities: [...previous.failedEntities, entityData],
      }));
    }
  };

  const { isPinned, handleTogglePinnedEntity, pinnedEntities } =
    usePinnedEntities();
  const onTogglingPin = (entityId: string) => {
    handleTogglePinnedEntity(entityId);
    track(AnalyticEventName.ORGANISATION_REPORTING_PIN_ENTITY_BUTTON_CLICKED, {
      pinnedEntities: (pinnedEntities ?? []).length,
      totalEntities: total,
    });
  };

  if (
    fetchedEntitiesResult.failedEntities.length === 0 &&
    entities.length === 0
  ) {
    return <EntityListEmptyState />;
  }

  return (
    <>
      <EntityListErrorCallout
        failedEntities={fetchedEntitiesResult.failedEntities}
        total={total}
      />

      <h2 className="flex gap-xs text-neutral-dark title-xl">
        <Trans
          i18nKey="organisation.reporting.page.titleCount"
          values={{
            count: entities.length,
            total,
          }}
          components={{
            strong: isDataFullyLoaded ? (
              <strong className="text-complementary">-</strong>
            ) : (
              <SkeletonText width="70px" size="xl" className="self-center" />
            ),
          }}
        />
      </h2>

      <div className="flex flex-col content-stretch gap-m">
        <EntityListGroupSection
          isDataFullyLoaded={isDataFullyLoaded}
          entityDetails={entities.filter(isEntityInformationEnriched)}
        />

        <div className="flex flex-col content-stretch gap-s">
          {entities
            .sort((a, b) => entitySortFunction(a, b, isPinned))
            .map((entity) => (
              <EntityListItem
                key={entity.id}
                isPinned={isPinned(entity)}
                togglePinnedEntity={onTogglingPin}
                entity={entity}
                onDataLoaded={onEntityDataLoaded}
              />
            ))}
        </div>
      </div>
    </>
  );
};

/**
 * Pinned items come first alphabetically, then the rest sorted alphabetically
 */
const entitySortFunction = (
  reference: EntityBasicInformation,
  compare: EntityBasicInformation,
  hasPriorityFunction: (entity: EntityBasicInformation) => boolean,
) => {
  const isReferencePinned = hasPriorityFunction(reference);
  const isComparePinned = hasPriorityFunction(compare);

  if (isReferencePinned && isComparePinned) {
    return reference.name.localeCompare(compare.name);
  }
  if (isReferencePinned) {
    return -1;
  }
  if (isComparePinned) {
    return 1;
  }
  return reference.name.localeCompare(compare.name);
};
