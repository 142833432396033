import { z } from 'zod';

import {
  type I18nKey,
  type TGlobalFunctionTyped,
} from '../../../../common/hooks/useTranslation';

export const publicApiScopeListEntry = z.object({
  name: z.string(),
  translationKey: z.enum([
    'bank-fee_read',
    'payable_read',
    'payable-attachment_read',
    'settlement_read',
    'wallet-load_read',
    'wallet-summary_read',
    'user_read',
    'supplier_read',
    'analytical-field_read',
    'cost-center_read',
    'expense-category_read',
  ]),
  experimental: z.boolean().optional(),
});

export type PublicApiScopeListEntry = z.infer<typeof publicApiScopeListEntry>;

const getTranslationKeysByScopeName = (
  scopeEntries: PublicApiScopeListEntry[],
): Map<string, PublicApiScopeListEntry['translationKey']> =>
  new Map(
    scopeEntries.map(({ name, translationKey }) => [name, translationKey]),
  );

export const getPublicApiScopeTranslation = ({
  t,
  scopeEntries,
  type,
}: {
  t: TGlobalFunctionTyped;
  scopeEntries: PublicApiScopeListEntry[];
  type: 'title' | 'description';
}) => {
  const scopeTranslationKeysByScopeName =
    getTranslationKeysByScopeName(scopeEntries);

  return (scopeName: string): string => {
    const translationKey = scopeTranslationKeysByScopeName.get(scopeName);

    if (!translationKey) {
      // TODO decide what to do in case of scope not found
      return '';
    }

    if (type === 'description') {
      return t(scopesListDescriptionToI18nKey[translationKey]);
    }
    if (type === 'title') {
      return t(scopesListTitleToI18nKey[translationKey]);
    }
    return '';
  };
};

const scopesListTitleToI18nKey: Record<
  | 'bank-fee_read'
  | 'payable_read'
  | 'payable-attachment_read'
  | 'settlement_read'
  | 'wallet-load_read'
  | 'wallet-summary_read'
  | 'user_read'
  | 'supplier_read'
  | 'analytical-field_read'
  | 'cost-center_read'
  | 'expense-category_read',
  I18nKey
> = {
  'bank-fee_read': 'publicApi.scopes.list.bank-fee_read.title',
  payable_read: 'publicApi.scopes.list.payable_read.title',
  'payable-attachment_read':
    'publicApi.scopes.list.payable-attachment_read.title',
  settlement_read: 'publicApi.scopes.list.settlement_read.title',
  'wallet-load_read': 'publicApi.scopes.list.wallet-load_read.title',
  'wallet-summary_read': 'publicApi.scopes.list.wallet-summary_read.title',
  user_read: 'publicApi.scopes.list.user_read.title',
  supplier_read: 'publicApi.scopes.list.supplier_read.title',
  'analytical-field_read': 'publicApi.scopes.list.analytical-field_read.title',
  'cost-center_read': 'publicApi.scopes.list.cost-center_read.title',
  'expense-category_read': 'publicApi.scopes.list.expense-category_read.title',
};

const scopesListDescriptionToI18nKey: Record<
  | 'bank-fee_read'
  | 'payable_read'
  | 'payable-attachment_read'
  | 'settlement_read'
  | 'wallet-load_read'
  | 'wallet-summary_read'
  | 'user_read'
  | 'supplier_read'
  | 'analytical-field_read'
  | 'cost-center_read'
  | 'expense-category_read',
  I18nKey
> = {
  'bank-fee_read': 'publicApi.scopes.list.bank-fee_read.description',
  payable_read: 'publicApi.scopes.list.payable_read.description',
  'payable-attachment_read':
    'publicApi.scopes.list.payable-attachment_read.description',
  settlement_read: 'publicApi.scopes.list.settlement_read.description',
  'wallet-load_read': 'publicApi.scopes.list.wallet-load_read.description',
  'wallet-summary_read':
    'publicApi.scopes.list.wallet-summary_read.description',
  user_read: 'publicApi.scopes.list.user_read.description',
  supplier_read: 'publicApi.scopes.list.supplier_read.description',
  'analytical-field_read':
    'publicApi.scopes.list.analytical-field_read.description',
  'cost-center_read': 'publicApi.scopes.list.cost-center_read.description',
  'expense-category_read':
    'publicApi.scopes.list.expense-category_read.description',
};
