import { useCompany } from 'modules/app/hooks/useCompany';
import { ErrorState } from 'src/core/common/components/ErrorState';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { BillingInformationFormContainer } from './components/BillingInformationForm';
import { useGetBillingInformationQuery } from './hooks/useGetBillingInformationQuery';
import { CompanySectionLoader } from '../../../components/CompanySectionLoader';

export const BillingInformationSection = () => {
  const { t } = useTranslation('global');
  const company = useCompany();
  const getBillingAddressQuery = useGetBillingInformationQuery();

  if (getBillingAddressQuery.status === 'error') {
    return (
      <ErrorState
        className="ml-xxl justify-start"
        title={t('billing.loadErrorTitle')}
        description={t('billing.loadErrorDescription')}
      />
    );
  }

  return (
    <section aria-labelledby="billingInformation" className="mb-l">
      <h1 id="billingInformation" className="text-complementary title-xl">
        {t('billing.billingInfoTitle')}
      </h1>
      {getBillingAddressQuery.status === 'loading' && <CompanySectionLoader />}
      {getBillingAddressQuery.status === 'success' &&
        getBillingAddressQuery.data !== undefined && (
          <BillingInformationFormContainer
            billingInformation={
              getBillingAddressQuery.data.isBillingInfoSameAsCompany
                ? {
                    ...getBillingAddressQuery.data,
                    address: company.address,
                    address2: company.address2,
                    city: company.city,
                    country: company.country,
                    name: company.name,
                    zipcode: company.zipcode,
                  }
                : getBillingAddressQuery.data
            }
          />
        )}
    </section>
  );
};
