import { useFeature } from 'src/core/common/hooks/useFeature';
import FEATURES from 'src/core/constants/features';

export type OrganisationFeatures = ReturnType<typeof useOrganisationFeatures>;

export const useOrganisationFeatures = () => {
  return {
    page: hasAccess(FEATURES.ORGANISATION_REPORTING_PAGE),
    group: hasAccess(FEATURES.TMP_ORGANISATION_REPORTING_GROUP),
    wallet: hasAccess(FEATURES.TMP_ORGANISATION_REPORTING_WALLET),
    // Actions
    invoicesInbox: hasAccess(FEATURES.BILL_INBOX),
    requests: hasAccess(
      FEATURES.TMP_ORGANISATION_REPORTING_REQUESTS_TO_APPROVE,
    ),
    invoices: hasAccess(
      FEATURES.SUBMIT_MY_INVOICE,
      FEATURES.TMP_ORGANISATION_REPORTING_INVOICES_TO_PAY,
    ),
    expenseClaims: hasAccess(FEATURES.EXPENSE_CLAIMS),
    receipts: {
      lateReceipts: hasAccess(
        FEATURES.PLAY_BY_RULES,
        FEATURES.TMP_ORGANISATION_REPORTING_LATE_RECEIPTS,
      ),
      missingReceipts: hasAccess(
        FEATURES.TMP_ORGANISATION_REPORTING_LATE_RECEIPTS,
      ),
    },
    payables: hasAccess(FEATURES.TMP_ORGANISATION_REPORTING_PAYABLES_TO_REVIEW),
  };
};

const hasAccess = (...featureToggles: FEATURES[]) =>
  featureToggles.map(useFeature).every(Boolean);
