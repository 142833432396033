import { useHistory } from 'react-router-dom';

import { SiderPageLayout } from 'common/components/layout';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { type Team } from 'modules/budgets/models/team';
import { type RawCustomField } from 'modules/custom-fields/models/customField';
import { ErrorBoundary } from 'src/core/common/components/withErrorBoundary';
import { routeFor, routes } from 'src/core/constants/routes';

import { PaymentFilters } from './PaymentFilters/PaymentFilters';
import { getLegacyFiltersFromUrl } from './PaymentFilters/types';
import { PaymentsList } from './PaymentsList';
import { type Selection } from './types';
import { type MonthStats } from '../../graphql/allPayments/stats';
import {
  type Invoice,
  type Payment as PanelPaymentType,
} from '../../models/payment';
import { type AccountingStats } from '../../utils/navigation';
import { BulkEditPaymentsContainer } from '../all/BulkEditPaymentsContainer';
import { type Payment } from '../all/paymentType';
import { LazyPanelPayment } from '../panel/LazyPanelPayment';

import './Payments.css';

type Props = {
  itemId?: string;
  isLoading?: boolean;
  payments?: Payment[];
  bulkEditPayments?: Payment[];
  payment: PanelPaymentType;
  invoices?: Invoice[];
  teams?: Team[];
  users?: { id: string; fullname: string }[];
  customFields: RawCustomField[];
  expenseCategoryCustomFieldId?: string;
  paymentStats?: MonthStats[];
  accountingStats: AccountingStats | null;
  pageInfo?: {
    hasNextPage: boolean;
    endCursor: string;
  };
  selection: Selection;
  counters?: {
    remindable_for_invoice: number;
    downloadable: number;
    editable: number;
  };
  bulkActions?: {
    download?: { processing: boolean };
    edit?: { processing: boolean };
    remindInvoices?: { processing: boolean };
    markAsMissing?: { processing: boolean };
  };
  isSupervisionActive: boolean;
  filters?: Record<string, unknown>;
  // TODO: @CORE-5111 this should be the payments all type, which is currently incompatible with the panel payment type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updatePaymentLocally: (payment: any) => void;
  fetchPayment: (paymentId: string) => void;
  deleteInvoice: (invoiceId: string) => void;
  fetchPaymentInvoices: (
    paymentId: string,
    toExport?: boolean,
    options?: {
      retryUntilSizeMatches?: number;
      emptyWhileRefreshing?: boolean;
    },
  ) => void;
  incrementPaymentInvoices: ({ id }: { id: string }) => void;
  deleteDocumentaryEvidence: (arguments_: {
    paymentId: string;
    documentaryEvidenceId: string;
  }) => void;
  fetchPayments: (options: {
    first?: number;
    after?: string;
    filters?: Record<string, unknown>;
  }) => void;
  updateSelection: (ids: {
    all?: boolean;
    include?: string[];
    exclude?: string[];
  }) => void;
  bulkEdit: (arguments_: { selection: Selection }) => void;
  download: (arguments_: {
    selection: Selection;
    withReceipts: boolean;
  }) => void;
  remindInvoices: (arguments_: { selection: Selection }) => void;
  bulkMarkAsMissing: (arguments_: {
    selection: Selection;
  }) => Promise<{ nbPaymentsMarked: number }>;
  hidePanel: () => void;
  updateFilters: (filters: {
    [key: string]: { value: string | string[] } | string | string[];
  }) => void;
};

export const Payments = ({
  itemId,
  isLoading,
  payments,
  bulkEditPayments,
  payment,
  invoices,
  teams,
  users,
  customFields,
  expenseCategoryCustomFieldId,
  paymentStats,
  pageInfo,
  selection,
  counters,
  bulkActions,
  isSupervisionActive,
  filters,
  updatePaymentLocally,
  fetchPayment,
  deleteInvoice,
  fetchPaymentInvoices,
  incrementPaymentInvoices,
  deleteDocumentaryEvidence,
  fetchPayments,
  updateSelection,
  bulkEdit,
  download,
  remindInvoices,
  bulkMarkAsMissing,
  hidePanel,
  updateFilters,
}: Props) => {
  const history = useHistory();

  const companyId = useCompanyId();

  const renderPanel = () => {
    if (itemId) {
      return (
        <ErrorBoundary
          context={{
            team: 'finance-accountant',
            scope: 'payment::panel',
          }}
        >
          <LazyPanelPayment
            key={itemId}
            payment={payment}
            invoices={invoices}
            groups={teams}
            expenseCategoryCustomFieldId={expenseCategoryCustomFieldId}
            updatePaymentLocally={updatePaymentLocally}
            fetchPayment={fetchPayment}
            deleteInvoice={deleteInvoice}
            fetchPaymentInvoices={fetchPaymentInvoices}
            incrementPaymentInvoices={incrementPaymentInvoices}
            deleteDocumentaryEvidence={deleteDocumentaryEvidence}
          />
        </ErrorBoundary>
      );
    }

    if (bulkEditPayments) {
      return (
        <ErrorBoundary
          context={{
            team: 'finance-accountant',
            scope: 'payment::panel',
          }}
        >
          <BulkEditPaymentsContainer
            groups={teams ?? []}
            payments={bulkEditPayments}
            customFields={customFields}
            onDone={() => {
              updateFilters(getLegacyFiltersFromUrl(history.location.search));
              hidePanel();
            }}
            onClose={hidePanel}
          />
        </ErrorBoundary>
      );
    }
  };

  return (
    <div className="page__container">
      <div className="PaymentsPage">
        <PaymentFilters
          users={users ?? []}
          teams={teams ?? []}
          setPaymentFilters={(newFilters) => {
            // Trigger the refetch of the payments
            updateFilters(
              Object.fromEntries(
                newFilters.map(({ type, value }) => [type, { value }]),
              ),
            );

            // Update the URL
            const queryParams = new URLSearchParams();

            newFilters.forEach(({ type, value, label }) => {
              if (Array.isArray(value)) {
                value.forEach((v) => queryParams.append(type, v));
              } else {
                // Use the label in order to display something for async autocomplete field
                queryParams.set(type, `${value}${label ? `/${label}` : ''}`);
              }
            });

            history.push({
              pathname: routeFor(routes.PAYMENTS_ALL.path, {
                company: companyId,
              }),
              search: queryParams.toString(),
            });
          }}
        />
        <SiderPageLayout
          className="PaymentsPage__content"
          panel={renderPanel()}
        >
          <PaymentsList
            isLoading={isLoading}
            payments={payments}
            bulkEditPayments={bulkEditPayments}
            activePayment={itemId}
            paymentStats={paymentStats ?? []}
            pageInfo={pageInfo}
            selection={selection}
            fetchPayments={fetchPayments}
            counters={counters}
            bulkActions={bulkActions}
            isSupervisionActive={isSupervisionActive}
            filters={filters}
            bulkEdit={bulkEdit}
            download={download}
            remindInvoices={remindInvoices}
            bulkMarkAsMissing={bulkMarkAsMissing}
            updateSelection={updateSelection}
          />
        </SiderPageLayout>
      </div>
    </div>
  );
};
