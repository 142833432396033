import { type Dispatch, type SetStateAction, useState } from 'react';

import { LocalStorageKey } from 'src/core/constants/storage';
import { getLocalItem } from 'src/core/utils/storage';

export function useNavigationPinState(): [
  boolean,
  Dispatch<SetStateAction<boolean>>,
] {
  const [isExpanded, setIsExpanded] = useState(() => {
    const shouldBeExpanded = getLocalItem(
      LocalStorageKey.ExpandNavigation,
      true,
    );

    // If user forced a state, use it
    if (shouldBeExpanded === '1' || shouldBeExpanded === '0') {
      return shouldBeExpanded === '1';
    }

    // Otherwise, expand the navigation if viewport > 1550px
    return window.innerWidth > 1550;
  });

  return [isExpanded, setIsExpanded];
}
