import { useTranslation } from 'common/hooks/useTranslation';
import { NotificationType, useNotifications } from 'modules/app/notifications';

import { CustomFieldEditionModal } from '../../components/CustomFieldEditionModal/CustomFieldEditionModal';
import { useUpdateCustomFieldMutation } from '../../hooks/useUpdateCustomFieldMutation';
import { type CustomField } from '../../models/customField';

type Props = {
  isOpen: boolean;
  customField: CustomField;
  onClose(): void;
};

export type CustomFieldCreationForm = {
  name: string;
  type: 'optional' | 'mandatory';
  isSplittable?: boolean;
};

export const CustomFieldEditionModalContainer = ({
  isOpen,
  customField,
  onClose,
}: Props) => {
  const [updateCustomField] = useUpdateCustomFieldMutation(customField.id);
  const { t } = useTranslation('global');
  const { pushNotif } = useNotifications();

  const handleSubmit = async (values: CustomFieldCreationForm) => {
    try {
      await updateCustomField({
        name: values.name,
        isRequired: values.type === 'mandatory',
        isSplittable: values.isSplittable,
      });
      pushNotif({
        type: NotificationType.Success,
        message: t('customFields.edition.successRequest'),
      });
      onClose();
    } catch {
      pushNotif({
        type: NotificationType.Danger,
        message: t('customFields.edition.errorRequest'),
      });
    }
  };

  return (
    <CustomFieldEditionModal
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit}
      formValues={{
        name: customField.name,
        type: customField.isRequired ? 'mandatory' : 'optional',
        isSplittable: customField.isSplittable,
      }}
    />
  );
};
