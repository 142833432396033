import { type getEntityStatus } from './EntityListItem';

export type EntityStatus =
  | 'awaitingKycApproval'
  | 'kycInProgress'
  | 'churning'
  | 'churningWithRemainingFunds'
  | 'churned'
  | 'churnedWithRemainingFunds'
  | null; // null is used to indicate that the entity is active

export const isEntityInactive = (
  entityStatus: ReturnType<typeof getEntityStatus>,
) => {
  if (!entityStatus) {
    return false;
  }

  return ['kycInProgress', 'awaitingKycApproval'].includes(entityStatus);
};
