import { useTranslation } from 'common/hooks/useTranslation';
import { NotificationType, useNotifications } from 'modules/app/notifications';

import { useDeleteCustomFieldMutation } from './useDeleteCustomFieldMutation';
import { CustomFieldDeletionModal } from '../../components/CustomFieldDeletionModal';
import { type CustomField } from '../../models/customField';

type Props = {
  isOpen: boolean;
  customField: CustomField;
  onClose(): void;
};

export const CustomFieldDeletionModalContainer = ({
  isOpen,
  customField,
  onClose,
}: Props) => {
  const [deleteCustomField] = useDeleteCustomFieldMutation(customField.id);
  const { t } = useTranslation('global');
  const { pushNotif } = useNotifications();

  const handleSubmit = async () => {
    try {
      await deleteCustomField();
      pushNotif({
        type: NotificationType.Success,
        message: t('customFields.deletion.successRequest'),
      });
      onClose();
    } catch {
      pushNotif({
        type: NotificationType.Danger,
        message: t('customFields.deletion.errorRequest'),
      });
    }
  };

  return (
    <CustomFieldDeletionModal
      name={customField.name}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit}
    />
  );
};
