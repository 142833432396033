import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useIsAnalyticalSplitActivated } from 'modules/bookkeep/apis/analytical-split/useAnalyticalSplitStatus';
import { PayableState } from 'modules/bookkeep/graphql/mappers/payableState';
import {
  type AccountingSoftware,
  hasIntegrationFileBasedExport,
  type IntegrationStatus,
  type NativeAccountingIntegration,
  hasExternalConnection,
} from 'modules/bookkeep/integration/status';
import { useIsAnalyticalSplitAvailable } from 'src/core/modules/bookkeep/apis/analytical-split';
import { PreloginModal } from 'src/core/modules/bookkeep/settings/integrations/pages/LegacyIntegrationsAccountingPage/sections/AuthSection/PreloginModal';

import { AccountingBaseBetaWarningModal } from './AccountingBaseBetaWarningModal';
import { AccountingBaseSelectModal } from './AccountingBaseSelectModal';
import { AccountingBaseSwitchConfirmationModal } from './AccountingBaseSwitchConfirmationModal';
import { AccountingBaseSwitchFailureModal } from './AccountingBaseSwitchFailureModal';
import { AccountingBaseSwitchHowItWorksModal } from './AccountingBaseSwitchHowItWorksModal';
import { AccountingBaseSwitchSmoothTransitionModal } from './AccountingBaseSwitchSmoothTransitionModal';
import { AccountingBaseSwitchSuccessModal } from './AccountingBaseSwitchSuccessModal';
import { AccountingBaseSwitchWarningModal } from './AccountingBaseSwitchWarningModal';
import { AccountingBaseSwitchingModal } from './AccountingBaseSwitchingModal';
import { integrationsOnEMVPFlow } from './helper';
import { getAccountingIntegrationDetails } from '../../../../integrations/helper';
import {
  trackAccountingSoftwareNotSwitched,
  trackAccountingSoftwareSwitched,
} from '../../../analytics';
import {
  type Oauth1TokenSet,
  useGetPayablesCount,
  useSetAccountingIntegrationMutation,
} from '../../../graphql/hooks';

type Props = {
  initialIntegrationStatus: IntegrationStatus;
  isOpen: boolean;
  onClose: () => void;
  setIsOpen: (isOpen: boolean) => void;
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export function AccountingBaseSelect({
  initialIntegrationStatus,
  isOpen,
  onClose,
  setIsOpen,
}: Props) {
  const location = useLocation();
  const history = useHistory();
  const [selectedAccountingIntegration, setSelectedAccountingIntegration] =
    useState<'noIntegration' | 'switchInProgress' | AccountingSoftware>(
      'noIntegration',
    );

  const setAccountingIntegration = useSetAccountingIntegrationMutation({
    isAccountingSwitch:
      initialIntegrationStatus.integration !== 'noIntegration',
  });

  const [switchFailureReason, setSwitchFailureReason] = useState<
    string | undefined
  >();
  const [openModal, setOpenModal] = useState<
    | 'switchConfirmation'
    | 'howItWorks'
    | 'smoothTransition'
    | 'warning'
    | 'nativeSetup'
    | 'waitingSwitch'
    | 'switchSuccess'
    | 'switchFailure'
    | 'betaWarning'
    | null
  >(
    initialIntegrationStatus.integration === 'switchInProgress'
      ? 'waitingSwitch'
      : null,
  );

  const [oAuth1TokenSet, setOauth1TokenSet] = useState<Oauth1TokenSet>({
    accountId: '',
    consumerId: '',
    consumerSecret: '',
  });

  const isAnalyticalSplitActivated = useIsAnalyticalSplitActivated();

  const { connectionOutcome } = queryString.parse(location.search);

  useEffect(() => {
    if (connectionOutcome === 'success') {
      history.replace(`${location.pathname}`);
      setIsOpen(true);
      setOpenModal('switchSuccess');
    }
    if (connectionOutcome === 'failure') {
      history.replace(`${location.pathname}`);
    }
  }, [connectionOutcome]);

  const getPayablesCount = useGetPayablesCount({
    state: [
      PayableState.Prepared,
      PayableState.ToAccountingPending,
      PayableState.ToAccountingFailed,
    ],
  });

  const onConfirmSwitch = async (): Promise<void> => {
    try {
      if (
        selectedAccountingIntegration === 'noIntegration' ||
        selectedAccountingIntegration === 'switchInProgress'
      ) {
        return;
      }
      const { error, errorDetail } = await setAccountingIntegration(
        selectedAccountingIntegration,
        oAuth1TokenSet,
      );

      if (error === 'oauth1Error') {
        history.replace(`${location.pathname}`);
        setIsOpen(false);
        setOpenModal(null);
        return;
      }

      if (error) {
        trackAccountingSoftwareNotSwitched();
        setSwitchFailureReason(errorDetail);
        setOpenModal('switchFailure');
        return;
      }

      trackAccountingSoftwareSwitched();

      if (hasIntegrationFileBasedExport(selectedAccountingIntegration)) {
        setOpenModal('switchSuccess');
      }
    } catch {
      trackAccountingSoftwareNotSwitched();
    }
  };

  const onSwitch = async (): Promise<void> => {
    if (
      selectedAccountingIntegration === 'noIntegration' ||
      selectedAccountingIntegration === 'switchInProgress'
    ) {
      return;
    }
    if (
      !hasIntegrationFileBasedExport(selectedAccountingIntegration) &&
      hasExternalConnection(selectedAccountingIntegration)
    ) {
      setOpenModal('nativeSetup');
    } else {
      setOpenModal('waitingSwitch');
      setTimeout(async () => {
        await onConfirmSwitch();
      }, MIN_WAITING_MODAL_DURATION_MS);
    }
  };

  // TODO Merge with onSwitch?
  const switchToNativeAndConnect = async () => {
    if (
      selectedAccountingIntegration === 'noIntegration' ||
      selectedAccountingIntegration === 'switchInProgress'
    ) {
      return;
    }

    setOpenModal('waitingSwitch');
    setTimeout(
      async () => {
        await onConfirmSwitch();
      },
      hasIntegrationFileBasedExport(selectedAccountingIntegration)
        ? MIN_WAITING_MODAL_DURATION_MS
        : 0,
    );
  };

  const numberOfPayablesInExportTab = getPayablesCount.data ?? 0;

  const isSwitchingFromFileBasedToNative =
    hasIntegrationFileBasedExport(initialIntegrationStatus.integration) &&
    isNativeIntegration(selectedAccountingIntegration);

  const isAnalyticalSplitAvailable = useIsAnalyticalSplitAvailable(
    selectedAccountingIntegration,
  );

  const displayMissingAnalyticalSplitWarning =
    isSwitchingFromFileBasedToNative &&
    isAnalyticalSplitActivated &&
    !isAnalyticalSplitAvailable;

  const canSkipPayablesInExportConfirmation =
    numberOfPayablesInExportTab === 0 &&
    !(isSwitchingFromFileBasedToNative && isAnalyticalSplitActivated);

  const fromOrToNative =
    isNativeIntegration(selectedAccountingIntegration) ||
    isNativeIntegration(initialIntegrationStatus.integration);

  if (
    openModal === 'switchConfirmation' &&
    getPayablesCount.data !== undefined
  ) {
    return (
      <AccountingBaseSwitchConfirmationModal
        numberOfPayablesInExportTab={numberOfPayablesInExportTab}
        displayMissingAnalyticalSplitWarning={
          displayMissingAnalyticalSplitWarning
        }
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setOpenModal(null);
          setIsOpen(false);
          setSelectedAccountingIntegration('noIntegration');
          trackAccountingSoftwareNotSwitched();
        }}
        onConfirm={() => {
          setOpenModal(fromOrToNative ? 'smoothTransition' : 'howItWorks');
        }}
      />
    );
  }

  if (openModal === 'howItWorks') {
    return (
      <AccountingBaseSwitchHowItWorksModal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setOpenModal(null);
          setIsOpen(false);
          setSelectedAccountingIntegration('noIntegration');
          trackAccountingSoftwareNotSwitched();
        }}
        onConfirm={() => {
          setOpenModal('smoothTransition');
        }}
      />
    );
  }

  if (openModal === 'smoothTransition') {
    return (
      <AccountingBaseSwitchSmoothTransitionModal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setOpenModal(null);
          setIsOpen(false);
          setSelectedAccountingIntegration('noIntegration');
          trackAccountingSoftwareNotSwitched();
        }}
        onConfirm={() => {
          setOpenModal('warning');
        }}
      />
    );
  }

  if (openModal === 'betaWarning') {
    return (
      <AccountingBaseBetaWarningModal
        accountingIntegration={selectedAccountingIntegration}
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setOpenModal(null);
          setIsOpen(false);
          setSelectedAccountingIntegration('noIntegration');
          trackAccountingSoftwareNotSwitched();
        }}
        onConfirm={async () => {
          if (initialIntegrationStatus.integration === 'noIntegration') {
            await onSwitch();
          } else if (
            integrationsOnEMVPFlow.includes(selectedAccountingIntegration)
          ) {
            setOpenModal('warning');
          } else if (canSkipPayablesInExportConfirmation) {
            setOpenModal(fromOrToNative ? 'smoothTransition' : 'howItWorks');
          } else {
            setOpenModal('switchConfirmation');
          }
        }}
      />
    );
  }

  if (
    openModal === 'warning' &&
    initialIntegrationStatus.integration !== 'switchInProgress' &&
    selectedAccountingIntegration !== 'noIntegration' &&
    selectedAccountingIntegration !== 'switchInProgress'
  ) {
    return (
      <AccountingBaseSwitchWarningModal
        accountingIntegration={selectedAccountingIntegration}
        initialAccountingIntegration={initialIntegrationStatus.integration}
        numberOfPayablesInExportTab={numberOfPayablesInExportTab}
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setOpenModal(null);
          setIsOpen(false);
          setSelectedAccountingIntegration('noIntegration');
          trackAccountingSoftwareNotSwitched();
        }}
        onConfirm={async () => {
          await onSwitch();
        }}
      />
    );
  }

  if (
    openModal === 'nativeSetup' &&
    hasExternalConnection(selectedAccountingIntegration)
  ) {
    return (
      <PreloginModal
        integration={
          selectedAccountingIntegration as NativeAccountingIntegration
        }
        isAccountingSwitching={
          initialIntegrationStatus.integration !== 'noIntegration'
        }
        isOpen={isOpen}
        onCancel={() => {
          onClose();
          setOpenModal(null);
          setIsOpen(false);
          setSelectedAccountingIntegration('noIntegration');
          trackAccountingSoftwareNotSwitched();
        }}
        onConfirm={async () => {
          await switchToNativeAndConnect();
        }}
        tokenSet={oAuth1TokenSet}
        setTokenSet={setOauth1TokenSet}
      />
    );
  }

  if (openModal === 'waitingSwitch') {
    return selectedAccountingIntegration === 'noIntegration' ? null : (
      <AccountingBaseSwitchingModal
        isInitialChoice={
          initialIntegrationStatus.integration === 'noIntegration'
        }
        accountingIntegration={selectedAccountingIntegration}
        isOpen={isOpen}
      />
    );
  }

  if (openModal === 'switchSuccess') {
    return selectedAccountingIntegration === 'noIntegration' ||
      selectedAccountingIntegration === 'switchInProgress' ? null : (
      <AccountingBaseSwitchSuccessModal
        accountingIntegration={selectedAccountingIntegration}
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setOpenModal(null);
          setIsOpen(false);
        }}
      />
    );
  }

  if (openModal === 'switchFailure') {
    return (
      <AccountingBaseSwitchFailureModal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setOpenModal(null);
          setIsOpen(false);
        }}
        reason={switchFailureReason}
      />
    );
  }

  if (
    initialIntegrationStatus.integration === 'switchInProgress' ||
    selectedAccountingIntegration === 'switchInProgress'
  ) {
    return null;
  }

  return (
    <AccountingBaseSelectModal
      accountingIntegration={selectedAccountingIntegration}
      initialAccountingIntegration={initialIntegrationStatus.integration}
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setOpenModal(null);
        setIsOpen(false);
        setSelectedAccountingIntegration('noIntegration');
        trackAccountingSoftwareNotSwitched();
      }}
      onSelect={(accountingIntegration) => {
        setSelectedAccountingIntegration(accountingIntegration);
      }}
      onConfirm={async () => {
        if (
          selectedAccountingIntegration !== 'noIntegration' &&
          getAccountingIntegrationDetails(selectedAccountingIntegration).isBeta
        ) {
          setOpenModal('betaWarning');
        } else if (initialIntegrationStatus.integration === 'noIntegration') {
          await onSwitch();
        } else if (
          integrationsOnEMVPFlow.includes(selectedAccountingIntegration)
        ) {
          setOpenModal('warning');
        } else if (canSkipPayablesInExportConfirmation) {
          setOpenModal(fromOrToNative ? 'smoothTransition' : 'howItWorks');
        } else {
          setOpenModal('switchConfirmation');
        }
      }}
    />
  );
}

const isNativeIntegration = (
  integration: AccountingSoftware | 'noIntegration' | 'switchInProgress',
): boolean =>
  integration !== 'switchInProgress' &&
  integration !== 'noIntegration' &&
  !hasIntegrationFileBasedExport(integration);

const MIN_WAITING_MODAL_DURATION_MS = 1500;
