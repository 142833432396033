import type { ExpenseClaimsCounts } from 'modules/reimbursements/types';
import { useQuery } from 'src/core/api/hooks/useQuery';

type Params = {
  isEnabled?: boolean;
};

export const useNewExpensesCountsQuery = ({
  isEnabled = true,
}: Params = {}) => {
  return useQuery<ExpenseClaimsCounts, ExpenseClaimsCounts>({
    key: ['transfer-scheduling', 'counts', { type: 'expense' }],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/transfer-scheduling/counts?type=expense',
    },
    isEnabled,
  });
};
