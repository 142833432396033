import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { type OrganisationWalletSummaryBreakdown } from 'src/core/modules/organisation-reporting/types';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

import {
  BreakdownGauge,
  type BreakdownGaugeBar,
  BreakdownGaugeLegend,
} from '../../../common/BreakdownGauge';

type Props = {
  currency: string;
  breakdown: OrganisationWalletSummaryBreakdown;
};

export const GroupBreakdownBlock = ({ currency, breakdown }: Props) => {
  const { t } = useTranslation('global');

  const formatAsMonetaryValue = (amount: number): string =>
    formatMonetaryValue({
      amount,
      currency,
      precision: 2,
    });

  const breakdownBars: BreakdownGaugeBar[] = [
    {
      name: t(
        'organisation.reporting.page.entities.entity.breakdown.scheduledTransfers.label',
      ),
      value: breakdown.scheduledTransfers,
      valueColor: 'positive',
      valueLabel: formatAsMonetaryValue(breakdown.scheduledTransfers),
      valueLabelColor: breakdown.scheduledTransfers > 0 ? undefined : 'neutral',
      valueLabelTooltip: t(
        'organisation.reporting.page.group.breakdown.scheduledTransfers.tooltip',
      ),
      type: 'full',
    },
    {
      name: t(
        'organisation.reporting.page.entities.entity.breakdown.cardsLimits.label',
      ),
      value: breakdown.cardsLimits,
      valueColor: 'positive',
      valueLabel: formatAsMonetaryValue(breakdown.cardsLimits),
      valueLabelColor: breakdown.cardsLimits > 0 ? undefined : 'neutral',
      valueLabelTooltip: t(
        'organisation.reporting.page.group.breakdown.cardsLimits.tooltip',
      ),
      type: 'striped',
    },
    {
      name: t('organisation.reporting.page.group.breakdown.shortfall.label'),
      value: breakdown.shortfall,
      valueColor: 'warning',
      valueLabel: formatAsMonetaryValue(breakdown.shortfall),
      valueLabelColor: 'warning',
      valueLabelSubtext: t(
        'organisation.reporting.page.entities.entity.count',
        {
          count: breakdown.shortfallCount,
        },
      ),
      valueLabelTooltip: t(
        'organisation.reporting.page.group.breakdown.shortfall.tooltip',
      ),
      type: 'full',
    },
    {
      name: t('organisation.reporting.page.group.breakdown.available.label'),
      value: breakdown.available,
      valueColor: 'neutral',
      valueLabel: formatAsMonetaryValue(breakdown.available),
      valueLabelColor: 'positive',
      valueLabelSubtext: t(
        'organisation.reporting.page.entities.entity.count',
        {
          count: breakdown.availableCount,
        },
      ),
      valueLabelTooltip: t(
        'organisation.reporting.page.group.breakdown.available.tooltip',
      ),
      type: 'empty',
    },
  ];

  return (
    <div>
      <BreakdownGauge
        bars={breakdownBars}
        trend={breakdown.trend}
        isInactive={false}
      >
        {(preparedBars) => <BreakdownGaugeLegend preparedBars={preparedBars} />}
      </BreakdownGauge>
    </div>
  );
};
