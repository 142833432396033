import { type Payable } from 'modules/bookkeep/payables/containers/PayablePanelContainer';
import { type PayableUpdate } from 'src/core/modules/payable';

export const getEditedFields = ({
  payable,
  update,
}: {
  payable: Payable;
  update: PayableUpdate;
}): string[] => {
  const editedFields: string[] = [];

  if (update.description && payable.description !== update.description) {
    editedFields.push('description');
  }

  if (
    update.itemLines?.some(
      (itemLine, index) =>
        itemLine.expenseAccountId &&
        payable.itemLines[index]?.expenseAccount?.id !==
          itemLine.expenseAccountId,
    )
  ) {
    editedFields.push('expenseAccount');
  }

  if (
    update.itemLines?.some(
      (itemLine, index) =>
        itemLine.taxAccountId &&
        payable.itemLines[index]?.taxAccount?.id !== itemLine.taxAccountId,
    )
  ) {
    editedFields.push('vatLine');
  }

  if (update.customFieldAssociations) {
    const existingCustomFieldIds = (
      payable.itemLines[0]?.analyticalFieldAssociations || []
    )
      .filter(({ fieldKind }) => fieldKind !== 'costCenter')
      .map(({ fieldEntityValueId }) => fieldEntityValueId);

    const newCustomFieldIds = update.customFieldAssociations.map(
      (customFieldAssociation) =>
        customFieldAssociation.customFieldListValueId ||
        customFieldAssociation.customFieldBooleanValue,
    );

    if (existingCustomFieldIds.join(',') !== newCustomFieldIds.join(',')) {
      editedFields.push('customField');
    }
  }

  if (update.teamId && payable.team?.id !== update.teamId) {
    editedFields.push('team');
  }

  if (update.costCenterId && payable.costCenter?.id !== update.costCenterId) {
    editedFields.push('costCenter');
  }

  return editedFields;
};
