import cx from 'classnames';
import { useEffect, useState } from 'react';

import { Spinner } from 'common/components/Spinner/Spinner';
import { useTranslation } from 'common/hooks/useTranslation';
import { companyAPI } from 'src/core/api/axios';

import './MarqetaSetPin.css';

type Props = {
  cardId: string;
  companyId: string;
  onFinish(): void;
};

// Wrapper for the Marqeta Set PIN widget
export const MarqetaSetPin = ({ cardId, companyId, onFinish }: Props) => {
  const { i18n } = useTranslation('global');
  const [url, setUrl] = useState<string>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getMarqetaPinUrl = async () => {
      const { data } = await companyAPI.get(
        `/cards/${cardId}/pin-widget-url?lang=${i18n.language}`,
        {
          companyId,
        },
      );
      setUrl(data.url);
    };
    getMarqetaPinUrl();
  }, [cardId, companyId, i18n.language, setUrl]);

  const onSuccess = async () => {
    // Let's save a new activity on card
    await companyAPI.post(`/cards/${cardId}/pin-set-success`, {
      companyId,
    });
    onFinish();
  };

  // At the end of process Marqeta redirects
  // the iframe to a success url we host. The
  // page has some JS that will tell the parent
  // (i.e. this page) that the process has been
  // a success via a message

  useEffect(() => {
    const onMessage = ({ data }: { data: string }) => {
      if (data === 'pinCodeSuccess') {
        onSuccess();
      }
    };
    window.addEventListener('message', onMessage);
    return () => {
      window.removeEventListener('message', onMessage, false);
    };
  }, [onSuccess]);

  const content = (
    <>
      {isLoading && (
        <Spinner
          width={45}
          height={45}
          className="MarqetaSetPin__loader grey"
        />
      )}
      {url && (
        <iframe
          src={url}
          scrolling="yes"
          frameBorder="0"
          title="Spendesk - Marqeta Pin"
          onLoad={() => setIsLoading(false)}
          className={cx('MarqetaSetPin__iframe', {
            'MarqetaSetPin__iframe--loading': isLoading,
          })}
        />
      )}
    </>
  );

  return <div className="MarqetaSetPin">{content}</div>;
};
