import { useQueryClient } from 'react-query';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useQuery } from 'src/core/api/hooks/useQuery';

import { getFailedExportBankFeesQueryKey } from './query-key-registry';
import {
  type IntegrationFailedBankFee,
  type BankFeeAPIEvent,
} from '../../bookkeep/export/pages/IntegrationExportPage/bankFee';
import { type Settlement } from '../../bookkeep/export/pages/IntegrationExportPage/settlement';

/**
 * Query and cache config
 */
type FailedExportBankFeesRawResponse = {
  settlement: Settlement;
  bankFee: BankFeeAPIEvent;
  error: {
    reason: string;
    errorData?: {
      errorMessage?: string;
    };
    rawError?: {
      errorMessage?: string;
    };
  };
}[];

type FailedExportBankFeeResponse = IntegrationFailedBankFee[];

export const useUpdateFailedExportBankFeesQueryCache = () => {
  const queryClient = useQueryClient();

  return async (
    companyId: string,
    bankFees: FailedExportBankFeeResponse,
  ): Promise<void> => {
    const queryKey = getFailedExportBankFeesQueryKey(companyId);
    queryClient.setQueryData(queryKey, bankFees);
  };
};

/**
 * REST query hook
 */

export const useFailedExportBankFees = (isEnabled = true) => {
  const companyId = useCompanyId();

  return useQuery<FailedExportBankFeeResponse, FailedExportBankFeesRawResponse>(
    {
      isEnabled,
      key: getFailedExportBankFeesQueryKey(companyId),
      request: {
        type: 'rest',
        target: 'companyAPI',
        endpoint: '/accounting-integration/export/failed-bank-fees',
      },
      reshapeData: (rawFailedBankFees) =>
        rawFailedBankFees.map((rawFailedBankFee) => {
          let errorData: undefined | { errorMessage: string };

          if (rawFailedBankFee?.error?.rawError?.errorMessage) {
            errorData = {
              errorMessage: rawFailedBankFee.error.rawError.errorMessage,
            };
          } else if (rawFailedBankFee?.error.errorData?.errorMessage) {
            errorData = {
              errorMessage: rawFailedBankFee?.error.errorData?.errorMessage,
            };
          }

          return {
            settlement: rawFailedBankFee.settlement,
            bankFee: rawFailedBankFee.bankFee,
            error: {
              reason: rawFailedBankFee.error.reason,
              rawError: rawFailedBankFee.error.rawError,
              errorData,
            },
          };
        }),
    },
  );
};
