import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { useFeature } from 'src/core/common/hooks/useFeature';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { getQuestionsFactory } from 'src/core/common/utils/help-center';
import FEATURES from 'src/core/constants/features';

import { useUser } from '../../../../hooks/useUser';

export const useQuestions = () => {
  const { t, activeLanguage } = useTranslation('global');
  const user = useUser();
  const hasPaymentInboxFeature = useFeature(FEATURES.PAYMENT_INBOX);
  const location = useLocation();

  // using useMemo with i18n.language is unecessary as we reload the page when changing language
  // but that may change in the future
  const getQuestions = useMemo(() => {
    return getQuestionsFactory({
      featureFlags: {
        [FEATURES.PAYMENT_INBOX]: hasPaymentInboxFeature,
      },
      lang: activeLanguage,
      t,
      user,
    });
  }, [activeLanguage]);

  const {
    questions = [],
    helpCenterIndex,
    title,
  } = useMemo(
    () => getQuestions(location.pathname),
    [location.pathname, getQuestions],
  );

  return {
    questions,
    helpCenterIndex,
    title,
  };
};
