/* eslint-disable unicorn/no-useless-switch-case */
/* eslint-disable sonarjs/no-nested-switch */
import type { TGlobalFunctionTyped } from 'common/hooks/useTranslation';

import { type Payable, type PayableUpdate } from '../../../../models';

/**
 * Transforms an array of expense fields to a translated string of text.
 *
 * @param {Array} fields The list of fields
 * @param {Function} t The translator function
 * @returns {String} The translated string of text
 */
// TODO: Improve fields type
// TODO: remove chargeItemGroup and vatItemGroup?
export const expenseFieldsToText = (
  fields: string[],
  t: TGlobalFunctionTyped,
) =>
  fields
    .map((field) => {
      switch (field) {
        case 'chargeItemGroup':
          return t('expenseInbox.expenseEditor.expenseAccount');
        case 'vatItemGroup':
          return t('expenseInbox.expenseEditor.vatAccount');
        case 'taxAccount':
          return t('expenseInbox.expenseEditor.vat');
        case 'supplierAccount':
          return t('expenseInbox.expenseEditor.supplierAccount');
        case 'employeeAccount':
          return t('expenseInbox.expenseEditor.employeeAccount');
        default:
          return undefined;
      }
    })
    .filter((translation) => translation !== undefined)
    .join(', ');

export const isPrepared = (payable: Payable): boolean =>
  !!payable && payable.prepared;

export const isPayableWithSupplierForCard = (
  payable: Pick<Payable, 'type'>,
): boolean => {
  return payable.type === 'card_purchase' || payable.type === 'reversal';
};

export const isPayableWithSupplierForInvoice = (
  payable: Pick<Payable, 'type' | 'subType'>,
): boolean => {
  switch (payable.type) {
    case 'invoice_purchase':
      return true;
    case 'reversal':
      switch (payable.subType) {
        case 'creditNote':
          return true;
        case 'plasticCard':
        case 'singleUseCard':
        case 'subscriptionCard':
        default:
          return false;
      }
    case 'expense_claim':
    case 'mileage_allowance':
    case 'per_diem_allowance':
    case 'card_purchase':
    default:
      return false;
  }
};

export const isPayableWithSupplier = (
  payable: Pick<Payable, 'type' | 'subType'>,
): boolean => {
  return (
    isPayableWithSupplierForCard(payable) ||
    isPayableWithSupplierForInvoice(payable)
  );
};

export const isAccountPayableEditable = (
  payable: Pick<Payable, 'type' | 'subType' | 'accountPayableId'>,
  isAccountPayableDeleted = false,
): boolean => {
  if (!isPayableWithSupplier(payable)) {
    return false;
  }

  switch (payable.type) {
    case 'expense_claim':
    case 'mileage_allowance':
    case 'per_diem_allowance':
      return false;
    case 'reversal':
      switch (payable.subType) {
        case 'creditNote':
          return true;
        case 'plasticCard':
        case 'singleUseCard':
        case 'subscriptionCard':
          // If we have a refund for supplier with no account payable
          // and there is no default account payable for the company
          // we need to let the user select it
          return (
            isAccountPayableDeleted || payable.accountPayableId === undefined
          );
        default:
          // Special case for billing credit notes:
          // if we have a refund for supplier with no account payable
          // and there is no default account payable for the company
          // we need to let the user select it
          return (
            isAccountPayableDeleted || payable.accountPayableId === undefined
          );
      }
    case 'card_purchase':
    case 'invoice_purchase':
      return true;
    default:
      return false;
  }
};

export const isPayableSupplierEditable = (payable: Payable): boolean => {
  return ['card_purchase', 'reversal'].includes(payable.type);
};

export const getEditedFields = ({
  payable,
  update,
}: {
  payable: Payable;
  update: PayableUpdate;
}): string[] => {
  const editedFields: string[] = [];

  if (update.supplier && payable.supplier?.id !== update.supplier?.id) {
    editedFields.push('supplier');
  }

  if (update.description && payable.description !== update.description) {
    editedFields.push('description');
  }

  if (
    update.supplierAccount &&
    payable.supplierAccount?.id !== update.supplierAccount
  ) {
    editedFields.push('accountPayable');
  }

  if (
    update.itemLines?.some(
      (itemLine, index) =>
        itemLine.expense &&
        payable.itemLines[index]?.expense?.expenseAccount?.id !==
          itemLine.expense.accountId,
    )
  ) {
    editedFields.push('expenseAccount');
  }

  if (
    update.itemLines?.some(
      (itemLine, index) =>
        itemLine.vat &&
        payable.itemLines[index]?.vat?.vatAccount?.id !==
          itemLine.vat.accountId,
    )
  ) {
    editedFields.push('vatLine');
  }

  if (update.team && payable.team?.id !== update.team) {
    editedFields.push('team');
  }

  if (update.costCenter && payable.costCenter?.id !== update.costCenter) {
    editedFields.push('costCenter');
  }

  if (
    Object.keys(payable.customFields).some(
      (key) =>
        update.customFields?.[key] !== undefined &&
        payable.customFields[key] !== update.customFields[key],
    )
  ) {
    editedFields.push('customField');
  }

  return editedFields;
};
