import { useTranslation } from 'common/hooks/useTranslation';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { type OperationalOnboardingData } from '../../models';

type RawOperationalOnboardingData = {
  onboarding: OperationalOnboardingData | undefined;
};

export const useGetWelcomeDashboardQuery = (): QueryState<
  OperationalOnboardingData | undefined
> => {
  const { activeLanguage } = useTranslation('global');
  const companyId = useCompanyId();

  return useQuery<
    OperationalOnboardingData | undefined,
    RawOperationalOnboardingData
  >({
    key: ['welcomeDashboard', companyId],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/onboarding-tasks/welcome-dashboard',
      params: {
        locale: activeLanguage,
      },
    },
    reshapeData: (rawOperationalOnboardingData) =>
      rawOperationalOnboardingData.onboarding,
  });
};
