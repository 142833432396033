import { type MonetaryValue } from 'ezmoney';

import { type I18nKey } from 'src/core/common/hooks/useTranslation';

import type { SelectionStatus } from './selectionStatus';

export type BucketType =
  | 'natural'
  | 'withProof'
  | 'withoutProof'
  | 'missingProof';

type NaturalBucket = {
  id: 'natural';
  totalCount: number;
};

type SmartBucket = {
  id: Exclude<BucketType, 'natural'>;
  totalCount: number;
  label: string;
  totalAmount: MonetaryValue;
  selectionStatus: SelectionStatus;
};

export type Bucket = NaturalBucket | SmartBucket;

export type BucketsStats = {
  expenses: {
    totalCount: number;
  };
  expensesFiltered: {
    connectionId: string;
    totalCount: number;
    totalAmount: MonetaryValue;
  };
  expensesWithProof: {
    connectionId: string;
    totalCount: number;
    totalAmount: MonetaryValue;
  };
  expensesWithoutProof: {
    connectionId: string;
    totalCount: number;
    totalAmount: MonetaryValue;
  };
  expensesMissingProof: {
    connectionId: string;
    totalCount: number;
    totalAmount: MonetaryValue;
  };
};

export function isNaturalBucket(
  bucket: NaturalBucket | SmartBucket,
): bucket is NaturalBucket {
  return (bucket as NaturalBucket).id === 'natural';
}

export function isSmartBucketId(id: Bucket['id']): boolean {
  return ['withProof', 'withoutProof', 'missingProof'].includes(id);
}

export function isSmartBucket(
  bucket: NaturalBucket | SmartBucket,
): bucket is SmartBucket {
  return isSmartBucketId(bucket.id);
}

export const getTranslationKeyFromBucketType = (
  bucketType: BucketType,
): I18nKey => {
  switch (bucketType) {
    case 'withProof':
      return 'bookkeep.prepare.buckets.withProof';
    case 'withoutProof':
      return 'bookkeep.prepare.buckets.withoutProof';
    case 'missingProof':
      return 'bookkeep.prepare.buckets.missingProof';
    default:
      throw new Error(`No translation key for bucket type: ${bucketType}`);
  }
};
