import { useQuery, type UseQueryResult } from 'react-query';

import { type QueryError } from 'src/core/api/queryError';
import { type QueryState } from 'src/core/api/queryState';
import { apiV2Url } from 'src/core/utils/api';

import { useCompany } from '../../app/hooks/useCompany';
import { type SpendLimit } from '../models/spendLimit';

export const useSpendLimitsQuery = (): QueryState<SpendLimit[]> => {
  const company = useCompany();
  const result = useQuery(
    ['spend-limits'],
    async (): Promise<SpendLimit[]> => {
      const response = await fetch(apiV2Url('/spend-limits', company.id), {
        method: 'GET',
        credentials: 'include',
        headers: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch spend limits');
      }
      return response.json();
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );
  return mapQueryResultToQueryState(result);
};

const mapQueryResultToQueryState = <T>(
  result: UseQueryResult<T>,
): QueryState<T> => {
  if (result.status === 'loading') {
    return { status: 'loading' };
  }
  if (result.status === 'error') {
    return { status: 'error', error: result.error as QueryError<unknown> };
  }
  return { status: 'success', data: result.data as T };
};
