import { fromNumber, add, type MonetaryValue } from 'ezmoney';
import { type FormikErrors } from 'formik';

import { useNotifications } from 'modules/app/notifications';
import { type AmortisationCapability } from 'modules/bookkeep/integration/status';
import {
  useUpdatePayable,
  useUpdatePayableErrorMessage,
} from 'modules/payable/hooks';
import { useFormikWithValidationStrategy } from 'src/core/common/hooks/useFormikWithValidationStrategy';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

import { getEditedFields } from '../../../components/PayablePanel/helpers';
import { type Payable } from '../../PayablePanelContainer';
import { useUpdatePayableQueryCache } from '../../PayablePanelContainer/hooks';
import { type RawAccountPayable } from '../../PayablePanelContainer/hooks/usePayableQuery/query';

type CostCenter = {
  id: string;
  name: string;
};

export type AccountingFormValues = {
  costCenter: CostCenter | undefined;
  accountPayable: Payable['accountPayable'] | undefined;
  expenseAccount: Payable['itemLines'][number]['expenseAccount'] | undefined;
  expenseAmount: MonetaryValue | undefined;
  taxAccount: Payable['itemLines'][number]['taxAccount'] | undefined;
  taxAmount: MonetaryValue | undefined;
  customFields: Record<string, string | undefined>;
  amortisation:
    | {
        date?: {
          from: Date;
          to: Date;
        };
        schemeId?: string;
        schemeName?: string;
      }
    | undefined
    | null;
};

export const usePayableAccountingEditForm = ({
  payable,
  amortisationCapability,
  hasCostCentersFeature,
}: {
  payable: Payable;
  amortisationCapability: AmortisationCapability | undefined;
  hasCostCentersFeature: boolean;
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const { t } = useTranslation('global');
  const [updatePayable] = useUpdatePayable(payable.id);
  const getUpdatePayableErrorMessage = useUpdatePayableErrorMessage();
  const updatePayableQueryCache = useUpdatePayableQueryCache();
  const { dangerNotif } = useNotifications();

  const handleOnSectionCancelEdit = () => formik.resetForm();
  const handleOnSectionSaveEdit = async () => {
    // this will trigger 2 form validations
    // it is due to a formik bug that doesn't reject the promise when calling submitForm and there are validation errors
    // https://github.com/formium/formik/issues/1580
    const { submitForm, validateForm } = formik;
    const errors = await validateForm();
    const isValid = Object.keys(errors).length === 0;
    if (!isValid) {
      throw new Error('Validation error');
    }
    return submitForm();
  };

  const formik = useFormikWithValidationStrategy<AccountingFormValues>({
    initialValues: {
      costCenter: payable.costCenter,
      amortisation: payable.amortisation,
      taxAccount: payable.itemLines[0].taxAccount,
      taxAmount: payable.itemLines[0].taxAmount,
      expenseAccount: payable.itemLines[0].expenseAccount,
      expenseAmount: payable.itemLines[0].netAmount,
      accountPayable: payable.accountPayable,
      // Only handle the first item line
      customFields: payable.itemLines[0].analyticalFieldAssociations
        .filter(({ fieldKind }) => fieldKind === 'customField')
        .reduce(
          (accumulator, { fieldEntityId, fieldEntityValueId }) => {
            accumulator[fieldEntityId] = fieldEntityValueId;
            return accumulator;
          },
          {} as Record<string, string>,
        ),
    },

    enableReinitialize: true,
    validate: (values) => {
      const errors: FormikErrors<AccountingFormValues> = {};

      if (amortisationCapability && values.amortisation) {
        if (!values.amortisation?.date) {
          errors.amortisation = t('payables.panel.missingAmortisationDate');
        } else if (!values.amortisation?.date?.from) {
          errors.amortisation = t(
            'payables.panel.missingAmortisationStartDate',
          );
        } else if (!values.amortisation?.date?.to) {
          errors.amortisation = t('payables.panel.missingAmortisationEndDate');
        }
      }

      if (payable.accountPayable && !values.accountPayable) {
        errors.accountPayable = t(
          'payables.panel.detailsSection.validation.missingAccountPayable',
        );
      }

      if (
        hasCostCentersFeature &&
        payable.itemLines.length === 1 && // Edition disabled for now for payables with split values
        !values.costCenter
      ) {
        errors.costCenter = t(
          'payables.panel.detailsSection.validation.costCenter',
        );
      }
      return errors;
    },

    onSubmit: async (values) => {
      try {
        // make sure the value is set (even no change is made)
        if (
          hasCostCentersFeature &&
          payable.itemLines.length === 1 && // Edition disabled for now for payables with split values
          !values.costCenter
        ) {
          throw new Error('Missing cost center');
        }

        const customFieldAssociations = Object.entries(
          values.customFields ?? {},
        ).map(([customFieldId, customFieldListValueId]) => ({
          customFieldId,
          customFieldListValueId,
        }));

        track(AnalyticEventName.BOOKKEEP_PAYABLE_ALL_PAYABLE_EDITED, {
          payableId: payable.id,
          whatChanged: 'costCenter',
        });

        const itemLines = payable.itemLines.map((itemLine) => {
          const vatRate = values.taxAccount?.rate;

          const vatRateAmount =
            vatRate === null || !vatRate || vatRate === -1 ? 0 : vatRate;

          const netAmount =
            values.expenseAmount ??
            fromNumber(
              0,
              payable.grossAmount.currency,
              payable.grossAmount.precision,
            );

          const vatAmount =
            values.taxAmount ??
            fromNumber(
              0,
              payable.grossAmount.currency,
              payable.grossAmount.precision,
            );

          const grossAmount = add(netAmount, vatAmount);

          return {
            ...itemLine,
            taxAccountId: values.taxAccount?.id ?? null,
            expenseAccountId: values.expenseAccount?.id ?? null,
            netAmount,
            grossAmount,
            vatAmount,
            vatAdjustment: fromNumber(
              0,
              payable.grossAmount.currency,
              payable.grossAmount.precision,
            ),
            vatRate: {
              amount: vatRateAmount,
              precision: 4,
            },
            natureId: 'natureId',
          };
        });

        const payload = {
          payableVersion: payable.version,
          update: {
            costCenterId: values.costCenter?.id,
            amortisationDate:
              values.amortisation === null ? null : values.amortisation?.date,
            amortisationSchemeId:
              values.amortisation === null
                ? null
                : values.amortisation?.schemeId,
            accountPayableId: values.accountPayable?.id,
            customFieldAssociations,
            itemLines,
          },
        };

        const { newPayableVersion } = await updatePayable(payload);

        track(AnalyticEventName.BOOKKEEP_PAYABLE_ALL_PAYABLE_SAVED, {
          payableId: payable.id,
          editedFields: getEditedFields({
            payable,
            update: payload.update,
          }),
          state: payable.state,
        });

        updatePayableQueryCache(payable.id, {
          version: newPayableVersion,
          costCenter: values.costCenter,
          accountPayable: values.accountPayable
            ? ({
                id: values.accountPayable.id,
                generalAccountCode: values.accountPayable.generalAccountCode,
              } as RawAccountPayable)
            : undefined,
          itemLines: payable.itemLines.map((itemLine) => ({
            ...itemLine,
            expenseAccount: values.expenseAccount,
            analyticalFieldAssociations: itemLine.analyticalFieldAssociations
              .filter(({ fieldKind }) => fieldKind === 'costCenter')
              .map((field) => ({
                ...field,
                fieldEntityValueId:
                  values.costCenter?.id ?? field.fieldEntityValueId,
              }))
              .concat(
                customFieldAssociations.flatMap(
                  ({ customFieldId, customFieldListValueId }) =>
                    customFieldListValueId
                      ? [
                          {
                            fieldKind: 'customField',
                            fieldEntityId: customFieldId,
                            fieldEntityValueId: customFieldListValueId,
                          },
                        ]
                      : [],
                ),
              ),
          })),
          amortisation: values.amortisation?.date
            ? {
                date: {
                  from: values.amortisation.date.from.toString(),
                  to: values.amortisation.date.to.toString(),
                },
                schemeId: values.amortisation.schemeId,
              }
            : undefined,
        });
      } catch (error) {
        const errorMessage = getUpdatePayableErrorMessage(error);
        dangerNotif(errorMessage);
        throw error;
      }
    },
  });

  return {
    formik,
    handleOnSectionCancelEdit,
    handleOnSectionSaveEdit,
  };
};
