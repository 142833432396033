import { useQuery } from 'src/core/api/hooks/useQuery';
import type { QueryState } from 'src/core/api/queryState';

import { type Factor } from '../models';

export const usePhoneFactorQuery = (): QueryState<Factor | undefined> => {
  return useQuery<Factor | undefined>({
    key: 'phoneFactor',
    request: {
      type: 'rest',
      target: 'baseAPI',
      endpoint: '/factors',
    },
    reshapeData: (data: Factor[]): Factor | undefined => {
      return data.find((factor: Factor) => factor.type === 'phone');
    },
    options: {
      cacheTime: 10 * 60 * 1000,
    },
  });
};
