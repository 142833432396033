import {
  Button,
  colors,
  Icon,
  Table,
  type TableColumn,
  type TableVariant,
  Tooltip,
  InfoTip,
  Tag,
} from '@dev-spendesk/grapes';
import React from 'react';

import {
  type TGlobalFunctionTyped,
  useTranslation,
} from 'common/hooks/useTranslation';
import { type IntegrationStatusWithIntegration } from 'modules/bookkeep/integration/status';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';
import { getTopNotificationOffset } from 'src/core/utils/topNotificationOffset';

import styles from './AccountPayableSuppliersMainListTable.module.css';
import { type IntegrationStatus } from '../../../integration/status';
import { type SupplierAccountDefaultFor } from '../../../settings/accounting';
import {
  type AccountPayableSuppliersRow,
  type SuppliersWithoutAccountPayableRow,
} from '../../types';
import { AccountPayableSuppliersAmountCell } from '../AccountPayableSuppliersAmountCell';
import { AccountPayableSuppliersNameCell } from '../AccountPayableSuppliersNameCell';

type PreparedRow = {
  isDefaultSupplierAccount: boolean;
  supplierAccountDefaultFor: SupplierAccountDefaultFor | undefined;
  accountPayable: string | undefined;
  auxiliaryAccountCode: string | undefined;
  hasError: boolean;
  isActive: boolean;
  rowId: string;
  supplierName: JSX.Element;
  totalAmount: JSX.Element;
};

// The selected row resolve process is not straight forward,
// it can be either matched against an accountPayableId or a supplierId
const resolveRowSelection = (
  row: AccountPayableSuppliersRow,
  accountPayableId: string,
  supplierId: string,
): boolean => {
  if (row.totalCount === 1) {
    return row.suppliers[0]?.id === supplierId;
  }
  return row.accountPayable.id === accountPayableId;
};

const prepareColumns = (
  auxiliaryAccountsEnabled: boolean,
  translate: TGlobalFunctionTyped,
): TableColumn<PreparedRow>[] => [
  {
    id: 'accountPayable',
    header: auxiliaryAccountsEnabled
      ? translate('bookkeep.accountsPayable.list.headers.generalAccount')
      : translate('bookkeep.accountsPayable.list.headers.accountPayable'),
    renderCell: ({
      accountPayable,
      isDefaultSupplierAccount,
      supplierAccountDefaultFor,
    }: PreparedRow) => {
      if (accountPayable) {
        let defaultSupplierAccountTooltip = '';

        if (
          isDefaultSupplierAccount &&
          supplierAccountDefaultFor === 'cardSupplier'
        ) {
          defaultSupplierAccountTooltip = translate(
            'bookkeep.accountsPayable.list.defaultCardSupplierAccountTooltip',
          );
        } else if (
          isDefaultSupplierAccount &&
          supplierAccountDefaultFor === 'invoiceSupplier'
        ) {
          defaultSupplierAccountTooltip = translate(
            'bookkeep.accountsPayable.list.defaultInvoiceSupplierAccountTooltip',
          );
        } else if (isDefaultSupplierAccount) {
          defaultSupplierAccountTooltip = translate(
            'bookkeep.accountsPayable.list.defaultSupplierAccountTooltip',
          );
        }

        return (
          <div className="absolute bottom-0 left-0 right-0 top-0 flex w-full items-center p-s">
            <div className="flex items-center gap-xs truncate">
              <span>{accountPayable}</span>
              {isDefaultSupplierAccount && (
                <Tag variant="neutral">
                  {translate('bookkeep.accountsPayable.list.defaultAccount')}
                  <Tooltip
                    content={defaultSupplierAccountTooltip}
                    triggerAsChild
                  >
                    <Icon
                      className="ml-xs"
                      size="s"
                      name="info"
                      color={colors.neutralDark}
                    />
                  </Tooltip>
                </Tag>
              )}
            </div>
          </div>
        );
      }

      return (
        <div className="flex items-center text-neutral-dark">
          <span className="mr-xs">
            {translate('bookkeep.accountsPayable.undefined')}
          </span>
          <InfoTip
            content={translate(
              'bookkeep.accountsPayable.list.undefinedTooltip',
            )}
          />
        </div>
      );
    },
    width: auxiliaryAccountsEnabled ? '20%' : '40%',
  },
  ...(auxiliaryAccountsEnabled
    ? [
        {
          id: 'auxiliaryAccountCode',
          header: translate(
            'bookkeep.accountsPayable.list.headers.auxiliaryAccount',
          ),
          renderCell: ({ auxiliaryAccountCode }: PreparedRow) => (
            <>{auxiliaryAccountCode ?? ''}</>
          ),
          width: '20%',
        },
      ]
    : []),
  {
    id: 'supplierName',
    header: translate('bookkeep.accountsPayable.list.headers.supplierName'),
    renderCell: ({ supplierName }) => supplierName,
    width: '40%',
  },
  {
    id: 'totalAmount',
    header: translate('bookkeep.accountsPayable.list.headers.totalAmount'),
    renderCell: ({ totalAmount }) => totalAmount,
    width: '20%',
    align: 'right' as const,
  },
];

const renderSupplierName = (
  row: AccountPayableSuppliersRow | SuppliersWithoutAccountPayableRow,
): JSX.Element => (
  <AccountPayableSuppliersNameCell
    totalCount={row.totalCount}
    supplierName={row.suppliers[0]?.name}
    logosUrls={row.logosUrls}
  />
);

const reshapeSuppliersRows = (
  suppliersRows: AccountPayableSuppliersRow[],
  accountPayableIdParameter: string,
  supplierIdParameter: string,
  invalidSupplierAccountIds: string[],
  integrationStatus: IntegrationStatus,
): PreparedRow[] =>
  suppliersRows.map((row) => ({
    isDefaultSupplierAccount: row.accountPayable.isDefault,
    supplierAccountDefaultFor: row.accountPayable.defaultFor,
    accountPayable: row.accountPayable.generalAccountCode,
    auxiliaryAccountCode: row.accountPayable.auxiliaryAccountCode,
    hasError: invalidSupplierAccountIds.includes(row.accountPayable.id),
    supplierName: renderSupplierName(row),
    totalAmount: (
      <AccountPayableSuppliersAmountCell
        shouldShowArchiveButton={
          row.totalCount === 0 &&
          !row.accountPayable.isDefault &&
          !!(
            integrationStatus.integration !== 'noIntegration' &&
            integrationStatus.integration !== 'switchInProgress' &&
            integrationStatus.capabilities.supplierAccounts &&
            integrationStatus.capabilities.supplierAccounts !==
              'pullWithDefaultAccounts'
          )
        }
        amount={
          row.totalCount > 0 && row.totalAmount
            ? formatMonetaryValue(row.totalAmount)
            : ''
        }
        accountPayable={row.accountPayable}
      />
    ),
    rowId: row.totalCount === 1 ? row.suppliers[0].id : row.accountPayable.id,
    isActive: resolveRowSelection(
      row,
      accountPayableIdParameter,
      supplierIdParameter,
    ),
  }));

const reshapeSuppliersWithoutAccountPayable = (
  row: SuppliersWithoutAccountPayableRow,
  accountPayableIdParameter: string,
  supplierIdParameter: string,
): PreparedRow => ({
  isDefaultSupplierAccount: false,
  supplierAccountDefaultFor: undefined,
  accountPayable: undefined,
  auxiliaryAccountCode: undefined,
  hasError: false,
  isActive:
    row.totalCount === 1
      ? supplierIdParameter === row.suppliers[0].id
      : accountPayableIdParameter === '-',
  rowId: '-',
  supplierName: renderSupplierName(row),
  totalAmount: (
    <AccountPayableSuppliersAmountCell
      shouldShowArchiveButton={false}
      amount=""
    />
  ),
});

const getRowVariant = (row: PreparedRow): TableVariant | undefined =>
  row.hasError ? 'alert' : undefined;

export function AccountPayableSuppliersMainListTable({
  hasNextPage,
  auxiliaryAccountsEnabled,
  accountPayableIdParameter,
  suppliers,
  supplierIdParameter,
  suppliersWithoutAccountPayable,
  invalidSupplierAccounts,
  integrationStatus,
  topErrorsOffset,
  handleRowClick,
  onFetchNextPage,
}: {
  hasNextPage: boolean;
  auxiliaryAccountsEnabled: boolean;
  accountPayableIdParameter: string;
  suppliers: AccountPayableSuppliersRow[];
  supplierIdParameter: string;
  suppliersWithoutAccountPayable: SuppliersWithoutAccountPayableRow;
  invalidSupplierAccounts: string[];
  integrationStatus: IntegrationStatusWithIntegration;
  topErrorsOffset: number;
  handleRowClick(row: PreparedRow): void;
  onFetchNextPage: React.MouseEventHandler<HTMLButtonElement>;
}) {
  const { t } = useTranslation('global');

  return (
    <Table
      data-testid="account-suppliers-table"
      columns={prepareColumns(auxiliaryAccountsEnabled, t)}
      getRowVariant={getRowVariant}
      className={styles.accountsPayableTable}
      emptyState={{
        title: t('bookkeep.accountsPayable.list.noResultsTitle'),
      }}
      data={
        suppliersWithoutAccountPayable.totalCount > 0
          ? [
              reshapeSuppliersWithoutAccountPayable(
                suppliersWithoutAccountPayable,
                accountPayableIdParameter,
                supplierIdParameter,
              ),
              ...reshapeSuppliersRows(
                suppliers,
                accountPayableIdParameter,
                supplierIdParameter,
                invalidSupplierAccounts,
                integrationStatus,
              ),
            ]
          : reshapeSuppliersRows(
              suppliers,
              accountPayableIdParameter,
              supplierIdParameter,
              invalidSupplierAccounts,
              integrationStatus,
            )
      }
      onRowClick={handleRowClick}
      maxHeight={`calc(100vh - 302px - ${
        getTopNotificationOffset() + topErrorsOffset
      }px)`}
      footer={
        hasNextPage ? (
          <Button
            variant="secondary"
            text={t('bookkeep.accountsPayable.list.loadMore')}
            onClick={onFetchNextPage}
          />
        ) : undefined
      }
      getIsRowActive={(row) => row.isActive}
    />
  );
}
