import { useQueryClient, type InfiniteData } from 'react-query';

import { type QueryClient } from 'src/core/api/client';
import { useInfiniteQuery } from 'src/core/api/hooks/useInfiniteQuery';
import { type InfiniteQueryState } from 'src/core/api/queryState';

import { type Transfer } from '../../models';

interface RawTransfersResponse {
  transfers: Transfer[];
  nextCursor: null | number;
}

const transfersPerPage = 50;
const getQueryKey = ({ type }: Params) => [
  'transfer-scheduling',
  'transfers',
  { status: 'to_confirm', type },
];

type Params = {
  type: 'invoice' | 'expense';
};

export const useFetchTransfers = (
  params: Params,
): InfiniteQueryState<Transfer[]> => {
  return useInfiniteQuery<Transfer, RawTransfersResponse>({
    key: getQueryKey(params),
    getRequest: (cursor) => ({
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/transfer-scheduling/transfers?status=to_confirm&type=${params.type}`,
      params: {
        cursor,
        limit: transfersPerPage,
      },
    }),
    getNextPageParam(data) {
      return data.nextCursor?.toString();
    },
    reshapeData: (data) => {
      return data.transfers;
    },
  });
};

export const invalidateTransferQueries = (queryClient: QueryClient): void => {
  queryClient.invalidateQueries(['transfer-scheduling', 'transfers']);
};

export const useGetTransfersQueryData = () => {
  const queryClient = useQueryClient();

  return (params: Params): Transfer[] | undefined => {
    const data: InfiniteData<{ transfers: Transfer[] }> | undefined =
      queryClient.getQueryData(getQueryKey(params));
    return data?.pages.flatMap((page) => page.transfers);
  };
};
