import { GridContainer, GridItem } from '@dev-spendesk/grapes';
import React from 'react';

import { SiderPageLayout } from 'common/components/layout/SiderPageLayout';
import { TransferListSkeleton } from 'modules/invoices/transfer';

export const TransferConfirmPageSkeleton = () => (
  <SiderPageLayout>
    <GridContainer>
      <GridItem columnSpan={8} columnStartLine={3}>
        <TransferListSkeleton />
      </GridItem>
    </GridContainer>
  </SiderPageLayout>
);
