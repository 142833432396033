import { Button } from '@dev-spendesk/grapes';
import classNames from 'classnames';
import { useMemo } from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import styles from './TaskStepLayout.module.css';
import { NavigationBreadcrumb } from './components/NavigationBreadcrumb';
import { type TaskStep } from './components/NavigationBreadcrumb/NavigationBreadcrumb';
import { VideoBanner } from './components/VideoBanner';
import { type VideoPageModalProps } from '../VideoPageModal/types';

type Props = {
  children: React.ReactNode;
  childrenWidth?: string;
  description?: string;
  extraDescription?: React.ReactNode;
  isLastStep?: boolean;
  isNextDisabled?: boolean;
  onBack: () => void;
  backButtonText?: string;
  onNext: () => void;
  nextButtonText?: string;
  title: string | React.ReactNode;
  video?: Pick<VideoPageModalProps, 'title' | 'url'>;
  breadcrumbSteps?: TaskStep[];
};

export const TaskStepLayout = ({
  children,
  childrenWidth,
  description,
  extraDescription,
  isLastStep = false,
  isNextDisabled = false,
  onBack,
  backButtonText,
  onNext,
  nextButtonText,
  title,
  video,
  breadcrumbSteps,
}: Props) => {
  const { t } = useTranslation('global');

  const nextButtonLabel = useMemo(() => {
    if (nextButtonText) {
      return nextButtonText;
    }
    return isLastStep ? t('misc.done') : t('misc.next');
  }, [nextButtonText, isLastStep]);

  return (
    <>
      {breadcrumbSteps && (
        <header className={styles.navigation}>
          <NavigationBreadcrumb steps={breadcrumbSteps} />
        </header>
      )}
      <div
        className={classNames(styles.body, {
          [styles.bodyWithHeader]: breadcrumbSteps,
          [styles.bodyWithoutHeader]: !breadcrumbSteps,
        })}
      >
        <div className={classNames(styles.section, styles.sectionLeft)}>
          <div className="my-auto">
            <h1 className="title-xxl">{title}</h1>
            {description && (
              <p className="mt-m text-neutral-dark body-l">{description}</p>
            )}
            {extraDescription && <div className="mt-m">{extraDescription}</div>}
            {video && (
              <div className="mt-xxl">
                <VideoBanner {...video} />
              </div>
            )}
          </div>
        </div>
        <div className={classNames(styles.section, styles.sectionRight)}>
          <div className={styles.children} style={{ width: childrenWidth }}>
            {children}
          </div>
        </div>
      </div>
      <footer className={styles.footer}>
        <div className={styles.footerContent}>
          <Button
            variant="secondary"
            onClick={onBack}
            text={backButtonText || t('misc.back')}
            iconName="caret-left"
            iconPosition="left"
          />
          <Button
            onClick={onNext}
            isDisabled={isNextDisabled}
            text={nextButtonLabel}
            iconName={isLastStep ? undefined : 'caret-right'}
            iconPosition={isLastStep ? undefined : 'right'}
          />
        </div>
      </footer>
    </>
  );
};
