import { type FormikErrors } from 'formik';

import { type TGlobalFunctionTyped } from 'src/core/common/hooks/useTranslation';
import { type ApprovalRule } from 'src/core/modules/company/structure/approval-flows';

import { type FormProps } from './ApprovalSchemeEdition';

type FormErrors = FormikErrors<FormProps>;

export const validateForm = (
  values: FormProps,
  translator: TGlobalFunctionTyped,
): FormErrors => {
  const errors: FormErrors = {};
  if (values.approvalRules.length === 0) {
    errors.approvalRules = translator(
      'costCenters.form.errors.approvalFlowEmpty',
    );
  }

  if (
    values.approvalRules.some((rule) =>
      rule.steps.some((step) => step.rights.length === 0),
    )
  ) {
    errors.approvalRules = translator(
      'costCenters.form.errors.approvalFlowEmpty',
    );
  }

  const approvalRulesContainDuplicateApprovers = values.approvalRules.some(
    (approvalRule) => {
      const approverIds = approvalRule.steps.flatMap((step) => {
        return step.rights.map((right) => {
          if (right.approverType === 'user') {
            return `${right.approverType}-${right.approverId}`;
          }
          if (right.approverType === 'reportingManager') {
            return right.approverType;
          }
          return '';
        });
      });
      return approverIds.length !== new Set(approverIds).size;
    },
  );

  if (approvalRulesContainDuplicateApprovers) {
    errors.approvalRules = translator(
      'costCenters.form.errors.approvalFlowDuplicateApprovers',
    );
  }

  const erroredRuleIds = validateApprovalRules(values.approvalRules);
  if (erroredRuleIds.length > 0) {
    errors.approvalRules = erroredRuleIds;
  }

  return errors;
};

const validateApprovalRules = (rules: ApprovalRule[]): string[] => {
  return rules.reduce<string[]>((ids, rule) => {
    if (!rule.steps.length) {
      return ids.concat(rule.id);
    }
    return ids;
  }, []);
};
