import { Callout } from '@dev-spendesk/grapes';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

export const AnalyticalFieldErrorWarring = () => {
  const { t } = useTranslation('global');

  return (
    <Callout
      title={t('bookkeep.integrations.settings.errorLoading')}
      variant="alert"
    />
  );
};
