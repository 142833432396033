import { Badge, Button, Callout, FileCard, Upload } from '@dev-spendesk/grapes';
import { Trans } from 'react-i18next';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

type Props = {
  error?: string;
  file: File | undefined;
  setFile: (file: File | undefined) => void;
};

const csvTemplate =
  'data:application/octet-stream;base64,bmFtZSxsZWdhbF9uYW1lLGJhbmtfY291bnRyeSxhY2NvdW50X3BheWFibGVfZ2VuZXJhbF9jb2Rl';

export const BulkImportSupplierAccountsForm = ({
  error,
  file,
  setFile,
}: Props) => {
  const { t } = useTranslation('global');

  return (
    <div className="flex flex-col gap-m">
      <div className="flex flex-col gap-s">
        <div className="flex items-center gap-s">
          <Badge variant="primary">1</Badge>
          <p className="text-neutral-dark body-l">
            {t('setupHub.supplierAccounts.import.downloadTemplateStep')}
          </p>
        </div>
        <Button
          className="self-center"
          component="a"
          href={csvTemplate}
          download="template.csv"
          text={t('setupHub.supplierAccounts.import.downloadTemplateButton')}
          variant="contrasted"
        />
      </div>

      <div className="flex flex-col gap-s">
        <div className="flex items-center gap-s">
          <Badge variant="primary">2</Badge>
          <p className="text-neutral-dark body-l">
            {t('setupHub.supplierAccounts.import.uploadTemplateStep')}
          </p>
        </div>
        <div className="flex flex-col gap-xs">
          {!file ? (
            <Upload
              accept=".csv"
              activeDragContent={
                <>{t('forms.uploadFiles.activeDragContent')}</>
              }
              content={
                <Trans i18nKey="forms.uploadFiles.content">
                  <br />-
                  <span className="text-complementary underline title-m">
                    -
                  </span>
                </Trans>
              }
              onUpload={(files) => setFile(files[0])}
            />
          ) : (
            <FileCard title={file.name} onDelete={() => setFile(undefined)} />
          )}
          {error && <Callout title={error} variant="alert" />}
        </div>
      </div>
    </div>
  );
};
