import { useQueryClient } from 'react-query';

import { reshapeAutomation } from 'modules/bookkeep/apis/prepare-payable/useGetPayableQuery/graphql-reshaper';
import { type PayableId } from 'modules/payable/models';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import type { Payable } from './payable';
import { type RawResult, query } from './query';
import {
  reshapeAllocation,
  reshapeAllocationPeriod,
  reshapeCounterparty,
  reshapeCustomField,
  reshapeDocumentaryEvidence,
  reshapeDocumentaryEvidenceCannotBeProvided,
  reshapeItemLine,
  reshapeMember,
  reshapeMileageDetails,
  reshapeCreditNoteDetails,
  reshapePayableState,
  reshapePayableType,
  reshapePerDiem,
  reshapeSupplier,
  reshapeTeam,
} from './reshaper';

export type PayableGraphQLRawResponse = RawResult;

export type PayableGraphQlVariables = { payableId: string };

export const getPayableQueryKey = (payableId: string | null) =>
  `payablePanel::${payableId}`;

export const useUpdatePayableQueryCache = () => {
  const queryClient = useQueryClient();

  return (
    payableId: PayableId | null,
    payableUpdate: Partial<RawResult['payable']>,
  ) => {
    const payable: RawResult['payable'] | undefined =
      queryClient.getQueryData<PayableGraphQLRawResponse>(
        getPayableQueryKey(payableId),
      )?.payable;

    // Skip update if we don't have a cached payable or if the update has not a higher version
    if (
      (payable?.version ?? Number.POSITIVE_INFINITY) >
      (payableUpdate?.version ?? 0)
    ) {
      return;
    }

    if (payableId) {
      queryClient.setQueryData<PayableGraphQLRawResponse>(
        getPayableQueryKey(payableId),
        {
          payable: {
            id: payableId,
            ...payable,
            ...payableUpdate,
          } as RawResult['payable'],
        },
      );
    }
  };
};

export const useInvalidateGetPayableQueryCache = () => {
  const queryClient = useQueryClient();

  return async (payableId: PayableId | null): Promise<void> => {
    queryClient.invalidateQueries(getPayableQueryKey(payableId));
  };
};

export const usePayableQuery = (
  payableId: string,
): QueryState<Payable | undefined> => {
  return useQuery<Payable | undefined, RawResult>({
    key: getPayableQueryKey(payableId),
    request: {
      type: 'graphQL',
      target: 'v2',
      query,
      variables: {
        payableId,
      },
    },
    reshapeData({ payable: rawPayable }) {
      if (!rawPayable) {
        return;
      }

      return {
        id: rawPayable.id,
        version: rawPayable.version,
        kind: rawPayable.kind,
        type: reshapePayableType(rawPayable.type),
        subtype: rawPayable.subtype || undefined,
        state: reshapePayableState(rawPayable.state),
        description: rawPayable.description,
        grossAmount: rawPayable.grossAmount,
        functionalExchangeRate: rawPayable.functionalExchangeRate,
        functionalAmount: rawPayable.functionalAmount,
        counterparty: rawPayable.counterparty
          ? reshapeCounterparty(rawPayable.counterparty)
          : undefined,
        member: rawPayable.member
          ? reshapeMember(rawPayable.member)
          : undefined,
        supplier: rawPayable.supplier
          ? reshapeSupplier(rawPayable.supplier)
          : undefined,
        team: rawPayable.team ? reshapeTeam(rawPayable.team) : undefined,
        costCenter: rawPayable.costCenter,
        accountPayable: rawPayable.accountPayable,
        documentaryEvidence: rawPayable.documentaryEvidence
          ? reshapeDocumentaryEvidence(rawPayable.documentaryEvidence)
          : undefined,
        documentaryEvidenceCannotBeProvided:
          rawPayable.documentaryEvidenceCannotBeProvided
            ? reshapeDocumentaryEvidenceCannotBeProvided(
                rawPayable.documentaryEvidenceCannotBeProvided,
              )
            : undefined,
        itemLines: rawPayable.itemLines.map(reshapeItemLine),
        customFields: rawPayable.customFields.map(reshapeCustomField),
        allocations: rawPayable.allocations.map(reshapeAllocation),
        creationDate: new Date(rawPayable.creationDate),
        mileageDetails: rawPayable.mileageDetails
          ? reshapeMileageDetails(rawPayable.mileageDetails)
          : undefined,
        perDiem: rawPayable.perDiem
          ? reshapePerDiem(rawPayable.perDiem)
          : undefined,
        creditNoteDetails: rawPayable.creditNoteDetails
          ? reshapeCreditNoteDetails(rawPayable.creditNoteDetails)
          : undefined,
        spendingAllocationPeriod: rawPayable.spendingAllocationPeriod
          ? reshapeAllocationPeriod(rawPayable.spendingAllocationPeriod)
          : undefined,
        accountingDate: rawPayable.accountingDate
          ? new Date(rawPayable.accountingDate)
          : undefined,
        amortisation: rawPayable.amortisation
          ? {
              schemeId: rawPayable.amortisation.schemeId,
              schemeName: rawPayable.amortisation.schemeName,
              date: {
                from: new Date(rawPayable.amortisation.date.from),
                to: new Date(rawPayable.amortisation.date.to),
              },
            }
          : undefined,
        automation: reshapeAutomation(rawPayable.automation),
      };
    },
  });
};
