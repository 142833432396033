import { useQuery } from 'react-query';

import { useCompany } from 'src/core/modules/app/hooks/useCompany';
import { apiV2Url } from 'src/core/utils/api';

type ApprovalWorkflow = {
  id: string;
  definition: {
    approvalSchemeId: string;
  };
};

export const useApprovalWorkflowQuery = () => {
  const company = useCompany();
  return useQuery(
    ['approval-workflows', 'active'],
    async (): Promise<ApprovalWorkflow> => {
      const response = await fetch(
        apiV2Url('/approval-workflows/active', company.id),
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Content-Type': 'application/json',
          },
        },
      );
      return response.json();
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );
};
