import { Button } from '@dev-spendesk/grapes';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ModalMFAContainer } from 'common/components/ModalMFA';
import { useTranslation } from 'common/hooks/useTranslation';
import { useCompany } from 'modules/app/hooks/useCompany';
import { useUser } from 'modules/app/hooks/useUser';
import { HubTaskWrapper } from 'modules/onboarding/components/HubTaskWrapper';
import { ReadyToSpendModalSlideshow } from 'modules/onboarding/setup-hub/components/ReadyToSpendModalSlideshow';
import { getContentByTaskType } from 'modules/onboarding/setup-hub/components/ReadyToSpendPopover/components/ReadyToSpendTaskItem/utils';
import { useCompleteOnboardingUserTaskMutation } from 'modules/onboarding/setup-hub/hooks/useCompleteOnboardingUserTaskMutation';
import {
  type UserTaskType,
  type UserTask,
} from 'modules/onboarding/setup-hub/types/userTask';
import { useCardsAccessQuery } from 'modules/physical-cards/hooks/useCardAccessQuery';
import { useCardAccessRestricted } from 'modules/requests/hooks/useCardAccessRestricted';
import { routeFor, routes } from 'src/core/constants/routes';
import { LocalStorageKey } from 'src/core/constants/storage';
import { setLocalItem } from 'src/core/utils/storage';

type Props = {
  task: UserTask;
  sectionId: string;
};
export const UserTaskItem = ({ task, sectionId }: Props) => {
  const { t } = useTranslation('global');
  const content = getContentByTaskType(task.type);
  const company = useCompany();
  const history = useHistory();
  const user = useUser();

  const [isSlideshowOpen, setIsSlideshowOpen] = useState(false);
  const [isMfaModalOpen, setIsMfaModalOpen] = useState(false);

  const [completeOnboardingUserTaskMutation] =
    useCompleteOnboardingUserTaskMutation();
  const cardsAccessQueryState = useCardsAccessQuery(user.id);
  const cardAccessRestricted = useCardAccessRestricted({
    cardsAccess:
      cardsAccessQueryState.status === 'success'
        ? {
            isLoading: false,
            ...cardsAccessQueryState.data,
          }
        : { isLoading: true },
    company,
  });

  if (!content) {
    return null;
  }

  const openRequestsPage = (type: UserTaskType) => {
    const path = routeFor(routes.REQUESTS.path, {
      company: company.id,
      type: 'all',
      id: 'new',
      newRequestType: 'virtual',
    });
    const params = type === 'subscription' ? `?recurring=true` : '';
    history.push(`${path}${params}`, {
      productTourToDisplay:
        task.type === 'single_use' ? 'single_purchase' : 'subscription',
    });
  };

  const openTask = async () => {
    setLocalItem(LocalStorageKey.LastOnboardingSectionOpened, sectionId);
    switch (task.type) {
      case 'download_app':
      case 'learn_to_approve':
      case 'upload_receipt':
        setIsSlideshowOpen(true);
        break;
      case 'expense_claim': {
        const path = routeFor(routes.REQUESTS.path, {
          company: company.id,
          type: 'all',
          id: 'new',
          newRequestType: 'reimbursement',
        });
        history.push(path, { productTourToDisplay: 'reimbursement' });
        break;
      }
      case 'single_use':
      case 'subscription': {
        if (
          cardAccessRestricted === 'mfaMissing' ||
          cardAccessRestricted === 'userPhoneMissing'
        ) {
          setIsMfaModalOpen(true);
        } else {
          openRequestsPage(task.type);
        }
        break;
      }
      default:
        break;
    }
  };

  const skipTask = async () => {
    setLocalItem(
      LocalStorageKey.LastOnboardingSectionOpened,
      'ready_to_spend',
      true,
    );
    if (task.status !== 'completed') {
      await completeOnboardingUserTaskMutation({
        endpointParams: { taskType: task.type },
      });
    }
  };

  return (
    <>
      <HubTaskWrapper
        title={t(content.title)}
        description={t(content.decription)}
        task={task}
        actions={[
          task.status !== 'completed' ? (
            <Button
              key="skip"
              iconName="checked"
              variant="secondary"
              onClick={skipTask}
              text={t('misc.skip')}
            />
          ) : null,
          <Button
            key="start"
            variant="primary"
            onClick={openTask}
            text={t(content.cta)}
          />,
        ].filter(Boolean)}
      />
      {isSlideshowOpen && (
        <ReadyToSpendModalSlideshow
          onCloseClicked={() => setIsSlideshowOpen(false)}
          type={task.type}
        />
      )}
      {isMfaModalOpen && (
        <ModalMFAContainer
          isOpen
          onModalClose={() => setIsMfaModalOpen(false)}
          onFactorActivated={() => openRequestsPage(task.type)}
        />
      )}
    </>
  );
};
