import { Callout, DropdownItem } from '@dev-spendesk/grapes';
import { useQueryClient } from 'react-query';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useNotifications } from 'modules/app/notifications';
import { useIsAnalyticalSplitActivated } from 'modules/bookkeep/apis/analytical-split';
import { AutomationIcon as AnalyticalFieldAssociationAutomationIcon } from 'modules/bookkeep/prepare-payables/components/PreparePayablesInbox/components/PreparePayablesAnalyticalFieldAssociation/PreparePayablesAnalyticalFieldAssociation';
import {
  isEditableCustomField,
  removeCustomFieldsSelectsWithNoValues,
} from 'modules/bookkeep/prepare-payables/components/PreparePayablesInbox/components/PreparePayablesFormFields/removeCustomFieldsSelectsWithNoValues';
import { useCreateCustomFieldMutation } from 'modules/bookkeep/prepare-payables/components/PreparePayablesInbox/hooks/useCreateCustomFieldMutation/useCreateCustomFieldMutation';
import {
  type CustomFieldValue,
  useValuesOfCustomFieldsQuery,
} from 'modules/bookkeep/prepare-payables/components/PreparePayablesInbox/hooks/useValuesOfCustomFieldsQuery';
import {
  type InputValueEntry,
  type PreparePayablesInputConfig,
} from 'modules/bookkeep/prepare-payables/components/PreparePayablesInbox/panelInputConfig';
import {
  type Payable,
  type PayableType,
} from 'modules/bookkeep/prepare-payables/models';
import { useDesignatedCustomFieldQuery } from 'modules/custom-fields/hooks/useDesignatedCustomFieldQuery';
import { PayableInputComponent } from 'modules/payable/components/PayableInputComponent';
import {
  buildCustomFieldsInputsConfig,
  useGetPayableFieldValues,
} from 'modules/payable/hooks/api/useGetPayableFieldValues';
import { type AnalyticalFieldAssociationAutomation } from 'modules/payable/models';
import { useFeature } from 'src/core/common/hooks/useFeature';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';

export const PayableCustomFields = ({
  payable,
  customFieldsInputsConfig,
  customFields,
  errors,
  onChange,
  spacing = 'm',
  editSplitValues = false,
}: {
  payable: Pick<Payable, 'type' | 'automation'>;
  customFieldsInputsConfig: PreparePayablesInputConfig[];
  customFields: Record<string, string | undefined>;
  errors: Record<string, string | undefined>;
  onChange: (key: string, value: string | boolean | null) => void;
  spacing?: 's' | 'm' | 'l';
  editSplitValues?: boolean;
}) => {
  const { t } = useTranslation('global');
  const { dangerNotif } = useNotifications();

  const valuesOfCustomFieldsQuery = useValuesOfCustomFieldsQuery({
    customFieldValuesIds: Object.values(customFields).filter(
      (value) => typeof value === 'string',
    ),
    customFieldIds: Object.keys(customFields),
  });

  const valuesOfCustomFields =
    valuesOfCustomFieldsQuery.status === 'success'
      ? valuesOfCustomFieldsQuery.data
      : new Map<string, CustomFieldValue>();

  const getCustomFieldWarningMessage = (
    inputConfig: PreparePayablesInputConfig,
  ) => {
    if (inputConfig.type === 'select') {
      const selectedCustomFieldValue = inputConfig.values.find(
        (value) => value.key === customFields[inputConfig.id],
      );
      if (
        selectedCustomFieldValue &&
        selectedCustomFieldValue.archiveDate !== null
      ) {
        return t('expenseInbox.expenseEditor.customFieldDeleted');
      }
    }
  };

  return removeCustomFieldsSelectsWithNoValues(customFieldsInputsConfig)
    .filter((input) => isEditableCustomField(input, editSplitValues))
    .map((inputConfig) => {
      const analyticalFieldAssociationAutomation = getCustomFieldAutomation({
        analyticalFieldAssociationAutomation:
          payable.automation?.analyticalFieldAssociations,
        customFieldId: inputConfig.id,
      });

      const isCurrentlyAutomatedValue = getIsCurrentlyAutomatedCustomFieldValue(
        customFields[inputConfig.id] as string, // TODO @financeAccountant boolean boolean custom fields not handled yet
        analyticalFieldAssociationAutomation,
      );

      return (
        <div
          key={inputConfig.id}
          className={`flex flex-col gap-xs mb-${spacing}`}
        >
          <PayableInputComponent
            input={{
              ...inputConfig,
              'data-testid': `standalone-${inputConfig.id}`,
              inputVariant: isCurrentlyAutomatedValue
                ? 'magicGradient'
                : 'default',
              renderPrefix: () =>
                isCurrentlyAutomatedValue && (
                  <AnalyticalFieldAssociationAutomationIcon
                    automationKind={analyticalFieldAssociationAutomation?.kind}
                    t={t}
                  />
                ),
              renderOption: (
                option: InputValueEntry,
                state: { isSelected: boolean; isHighlighted: boolean },
              ) => {
                return (
                  <DropdownItem
                    key={option.key}
                    label={option.label}
                    isSelected={state.isSelected && !option.archiveDate}
                    isHighlighted={state.isHighlighted}
                    isDisabled={!!option.archiveDate}
                    suffix={
                      option.archiveDate
                        ? t('misc.deleted')
                        : option.key ===
                            analyticalFieldAssociationAutomation?.fieldEntityValueId && (
                            <AnalyticalFieldAssociationAutomationIcon
                              automationKind={
                                analyticalFieldAssociationAutomation?.kind
                              }
                              t={t}
                            />
                          )
                    }
                  />
                );
              },
            }}
            allowNewValue={inputConfig.allowNewValue}
            error={errors[inputConfig.id]}
            value={customFields[inputConfig.id] ?? undefined}
            warningMessage={getCustomFieldWarningMessage(inputConfig)}
            onChange={(value) => {
              // Do not trigger change if the new value and the previous value are both null
              if (value === null && !customFields[inputConfig.id]) {
                return;
              }
              onChange(inputConfig.id, value);
            }}
            onCreateNewValue={async (newValue) => {
              if (!inputConfig.onCreateNewValue) {
                return '';
              }
              try {
                return await inputConfig.onCreateNewValue(newValue);
              } catch (error) {
                dangerNotif(
                  t('expenseInbox.errors.customFieldValueCreationError', {
                    value: newValue,
                    field: inputConfig.name,
                  }),
                );

                throw error;
              }
            }}
          />

          {valuesOfCustomFields.get(customFields?.[inputConfig.id] as string)
            ?.archiveDate && (
            <Callout
              variant="warning"
              className="my-xs"
              title={t('expenseInbox.expenseEditor.customFieldValueDeleted', {
                value: valuesOfCustomFields.get(
                  customFields?.[inputConfig.id] as string,
                )?.name,
              })}
            />
          )}
        </div>
      );
    });
};

/**
 * Helpers
 */

const getCustomFieldAutomation = ({
  analyticalFieldAssociationAutomation = [],
  customFieldId,
}: {
  analyticalFieldAssociationAutomation?: AnalyticalFieldAssociationAutomation[];
  customFieldId: string;
}) => {
  return analyticalFieldAssociationAutomation.find(
    ({ fieldEntityId }) => customFieldId === fieldEntityId,
  );
};

const getIsCurrentlyAutomatedCustomFieldValue = (
  currentValue: string,
  analyticalFieldAssociationAutomation?: AnalyticalFieldAssociationAutomation,
) =>
  analyticalFieldAssociationAutomation?.isAppliedOnPayable &&
  currentValue === analyticalFieldAssociationAutomation.fieldEntityValueId;

export const useCustomFieldsInputsConfig = ({
  payableType,
  teamId,
  customFields,
}: {
  payableType: PayableType;
  teamId: string | null | undefined;
  customFields: Record<string, string | undefined>;
}) => {
  const { t } = useTranslation('global');

  const companyId = useCompanyId();
  const hasCustomFieldsFeature = useFeature(FEATURES.CUSTOM_FIELDS);

  const fieldValuesQuery = useGetPayableFieldValues();
  const [createCustomField] = useCreateCustomFieldMutation(); // check if this is the correct mutation
  const queryClient = useQueryClient();

  const designatedCustomFieldQueryState = useDesignatedCustomFieldQuery();

  const expenseCategoryCustomFieldId =
    designatedCustomFieldQueryState.status === 'success'
      ? designatedCustomFieldQueryState.data.customFieldId
      : undefined;

  const isAnalyticalSplitActivated = useIsAnalyticalSplitActivated();

  return hasCustomFieldsFeature && fieldValuesQuery.status === 'success'
    ? buildCustomFieldsInputsConfig({
        customFields: fieldValuesQuery.data.customFields.sort((a) =>
          a.id === expenseCategoryCustomFieldId ? -1 : 0,
        ),
        companyId,
        queryClient,
        createCustomField,
        customFieldsValues: Object.fromEntries(
          Object.entries(customFields).filter(([, value]) => value !== null),
        ) as Record<string, string | boolean>,
        teamId: teamId ?? undefined,
        expenseTypes: [payableType],
        expenseCategoryCustomFieldId,
        translator: t,
        isAnalyticalSplitActivated,
      })
    : [];
};
