import { useFormik } from 'formik';

import { NotificationType, useNotifications } from 'modules/app/notifications';
import { PanelItemsSection } from 'src/core/common/components/Panel';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { COUNTRIES } from 'src/core/config/country';

import { useUpdateSupplierDetailsMutation } from '../../../hooks/useUpdateSupplierDetailsMutation';
import {
  type LegalDetailsFormValues,
  type SupplierDetails,
} from '../../../types';
import { AccountPayableSuppliersPanelLegalDetailsForm } from '../AccountPayableSuppliersPanelLegalDetailsForm';

type Props = {
  supplier: SupplierDetails;
};

export const AccountPayableSuppliersPanelLegalDetailsSection = ({
  supplier,
}: Props) => {
  const { t } = useTranslation('global');

  const { pushNotif } = useNotifications();

  const [updateSupplierDetailsMutation] = useUpdateSupplierDetailsMutation();

  const initialValues = {
    legalName: supplier.legalDetails?.legalName ?? '',
    registrationNumber: supplier.legalDetails?.registrationNumber ?? '',
    vatNumber: supplier.legalDetails?.vatNumber ?? '',
    addressCountry: supplier.legalDetails?.addressCountry ?? '',
    postalCode: supplier.legalDetails?.postalCode ?? '',
    streetAddress: supplier.legalDetails?.streetAddress ?? '',
    addressLocality: supplier.legalDetails?.addressLocality ?? '',
  };

  const formikProps = useFormik<LegalDetailsFormValues>({
    enableReinitialize: true,
    initialValues,
    validateOnChange: false,
    onSubmit: async (values: LegalDetailsFormValues) => {
      const updateSupplierDetailsMutationResult =
        await updateSupplierDetailsMutation({
          supplierId: supplier.id,
          bankDetails: {
            bankCountry: supplier.bankDetails?.bankCountry,
          },
          legalDetails: values,
        });

      if (updateSupplierDetailsMutationResult.updateSupplierDetails.reason) {
        pushNotif({
          type: NotificationType.Danger,
          message: t(
            'bookkeep.accountsPayable.panel.legalDetailsSection.updateErrorNotification',
          ),
        });
      } else {
        pushNotif({
          type: NotificationType.Success,
          message: t(
            'bookkeep.accountsPayable.panel.legalDetailsSection.updateSuccessNotification',
          ),
        });
      }
    },
  });

  return (
    <PanelItemsSection
      title={t('bookkeep.accountsPayable.panel.legalDetailsSection.title')}
      items={[
        {
          label: t(
            'bookkeep.accountsPayable.panel.legalDetailsSection.legalNameLabel',
          ),
          value: supplier.legalDetails?.legalName || '-',
        },
        {
          label: t(
            'bookkeep.accountsPayable.panel.legalDetailsSection.registrationNumberLabel',
          ),
          value: supplier.legalDetails?.registrationNumber || '-',
        },
        {
          label: t(
            'bookkeep.accountsPayable.panel.legalDetailsSection.vatNumberLabel',
          ),
          value: supplier.legalDetails?.vatNumber || '-',
        },
        {
          label: t(
            'bookkeep.accountsPayable.panel.legalDetailsSection.companyAddressLabel',
          ),
          value: supplier.legalDetails?.streetAddress
            ? `${supplier.legalDetails?.streetAddress}, ${
                supplier.legalDetails?.postalCode ?? ''
              } ${supplier.legalDetails?.addressLocality ?? ''}`
            : '-',
        },
        {
          label: t(
            'bookkeep.accountsPayable.panel.legalDetailsSection.supplierCountryLabel',
          ),
          value:
            supplier.legalDetails?.addressCountry &&
            COUNTRIES[supplier.legalDetails?.addressCountry]
              ? t(
                  COUNTRIES[supplier.legalDetails?.addressCountry]
                    .translationKey,
                )
              : '-',
        },
      ]}
      isEditable
      cancelTranslation={t('misc.cancel')}
      saveTranslation={t('misc.saveChanges')}
      editSection={
        <AccountPayableSuppliersPanelLegalDetailsForm {...formikProps} />
      }
      onCancel={() => {
        formikProps.resetForm();
      }}
      onSave={async () => {
        // this will trigger 2 form validations
        // it is due to a formik bug that doesn't reject the promise when calling submitForm and there are validation errors
        // https://github.com/formium/formik/issues/1580
        const { submitForm, validateForm } = formikProps;
        const errors = await validateForm();
        const isValid = Object.keys(errors).length === 0;
        if (!isValid) {
          throw new Error('Validation error');
        }
        return submitForm();
      }}
    />
  );
};
