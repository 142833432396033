import { AutocompleteMultiple, Select } from '@dev-spendesk/grapes';
import type { SavedFilter } from '@spendesk/bookkeeping-core-types';

import {
  FilterBuilder,
  getNonEmptyArraySubfilterValue,
} from 'src/core/common/components/FilterBuilder';
import { useFeature } from 'src/core/common/hooks/useFeature';
import {
  type TGlobalFunctionTyped,
  useTranslation,
} from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';

export const PayableFilterBuilder = () => {
  const { t } = useTranslation('global');

  const fields = getFields(t);

  const hasPayableFilterFeature = useFeature(FEATURES.TMP_PAYABLE_FILTERS);
  if (!hasPayableFilterFeature) {
    return null;
  }

  // TODO@financeAccountant@CORE-5208: Get saved filters from the backend
  const savedFilters: SavedFilter[] = [
    // {
    //   name: 'My saved filter',
    //   companyId: 'company-id',
    //   userId: 'user-id',
    //   filter: {
    //     operator: 'and',
    //     subfilters: [
    //       {
    //         operator: 'and',
    //         subfilters: [
    //           {
    //             field: 'payableType',
    //             operator: 'is',
    //             value: ['singleCardPurchase'],
    //           },
    //         ],
    //       },
    //     ],
    //   },
    // },
  ];

  return (
    <FilterBuilder
      fields={fields}
      savedFilters={savedFilters}
      applyFilter={() => {
        // TODO@financeAccountant@CORE-5208: Send filters to the backend
      }}
      renderSubfilterValue={({ subfilterValue, updateSubfilterValue }) => {
        switch (subfilterValue.field) {
          case 'payableType': {
            const rawValue = subfilterValue.value ?? [];

            const keysOfValues: string[] = Array.isArray(rawValue)
              ? rawValue
              : [rawValue];

            const payableTypeOptions = getPayableTypeOptions(t);

            const values = payableTypeOptions.filter((option) =>
              keysOfValues.includes(option.key),
            );

            const idOperatorOptions = getIdOperatorOptions(
              t,
              values.length > 1,
            );

            const hasSecondField = subfilterValue.operator
              ? ['is', 'isNot'].includes(subfilterValue.operator)
              : undefined;

            return (
              <>
                <Select
                  onSelect={({ key }) => {
                    updateSubfilterValue({
                      ...subfilterValue,
                      operator: key,
                    });
                  }}
                  options={idOperatorOptions}
                  value={idOperatorOptions.find(
                    (option) => option.key === subfilterValue.operator,
                  )}
                  className={hasSecondField === false ? 'w-full' : 'w-[140px]'}
                  fit={hasSecondField === false ? 'parent' : 'content'}
                />

                {hasSecondField && (
                  <AutocompleteMultiple
                    onSelect={(newValues) => {
                      updateSubfilterValue({
                        ...subfilterValue,
                        value: getNonEmptyArraySubfilterValue(
                          newValues.map((v) => v.key),
                        ),
                      });
                    }}
                    options={payableTypeOptions}
                    values={values}
                    translations={{
                      selectAll: t('misc.selectAll'),
                      selected: values.map((v) => v.label).join(', '),
                    }}
                    onSearch={() => {
                      // Do nothing
                    }}
                    fit="parent"
                  />
                )}

                {hasSecondField === undefined && <div className="w-full" />}
              </>
            );
          }

          // TODO@financeAccountant@CORE-5208: Implement other fields

          default:
            return <div className="w-full" />;
        }
      }}
    />
  );
};

/**
 * Helpers
 */

const getFields = (t: TGlobalFunctionTyped) => {
  return [
    { key: 'payableType', label: t('payables.filterFields.payableType') },
    // TODO@financeAccountant@CORE-5208: Implement other fields
  ];
};

const getIdOperatorOptions = (t: TGlobalFunctionTyped, isMultiple: boolean) => {
  if (isMultiple) {
    return [
      { key: 'is', label: t('payables.filterOperators.isAnyOf') },
      { key: 'isNot', label: t('payables.filterOperators.isNoneOf') },
    ];
  }

  return [
    { key: 'is', label: t('payables.filterOperators.is') },
    { key: 'isNot', label: t('payables.filterOperators.isNot') },
    { key: 'isEmpty', label: t('payables.filterOperators.isEmpty') },
    { key: 'isNotEmpty', label: t('payables.filterOperators.isNotEmpty') },
  ];
};

const getPayableTypeOptions = (t: TGlobalFunctionTyped) => {
  return [
    {
      key: 'singleCardPurchase',
      label: t('payables.payableTypes.singleCardPurchase'),
    },
    {
      key: 'subscription',
      label: t('payables.payableTypes.subscription'),
    },
    {
      key: 'physicalCardPurchase',
      label: t('payables.payableTypes.physicalCardPurchase'),
    },
    {
      key: 'cardRefund',
      label: t('payables.payableTypes.cardRefund'),
    },
    {
      key: 'invoicePurchase',
      label: t('payables.payableTypes.invoicePurchase'),
    },
    {
      key: 'creditNote',
      label: t('payables.payableTypes.creditNote'),
    },
    {
      key: 'expenseClaim',
      label: t('payables.payableTypes.expenseClaim'),
    },
    {
      key: 'mileageAllowance',
      label: t('payables.payableTypes.mileageAllowance'),
    },
    {
      key: 'perDiemAllowance',
      label: t('payables.payableTypes.perDiemAllowance'),
    },
  ];
};
