import React from 'react';

import { ApprovalRules } from '../components/ApprovalRules';
import { createRule, updateRule, deleteRule } from '../models';
import { type ApprovalRule, type Member } from '../types';

type Props = {
  rules: ApprovalRule[];
  members: Member[];
  erroredRulesIds?: string[];
  mode?: 'approvalByDimensionsPanel' | 'default';
  onChange(rules: ApprovalRule[], isNewRule: boolean): void;
};

export const ApprovalRulesContainer = ({
  rules,
  erroredRulesIds = [],
  members,
  mode = 'default',
  onChange,
}: Props) => {
  const handleCreateRule = () => {
    onChange(createRule(rules), true);
  };

  const handleUpdateRule = (ruleIndex: number, rule: ApprovalRule) => {
    onChange(updateRule(rules, ruleIndex, rule), false);
  };

  const handleDeleteRule = (ruleId: string) => {
    onChange(deleteRule(rules, ruleId), false);
  };

  return (
    <ApprovalRules
      rules={rules}
      mode={mode}
      members={members}
      erroredRulesIds={erroredRulesIds}
      onCreateRule={handleCreateRule}
      onUpdateRule={handleUpdateRule}
      onDeleteRule={handleDeleteRule}
    />
  );
};
