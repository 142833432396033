import { type AccountingSoftware } from 'modules/bookkeep/integration/status';
import { type I18nKey } from 'src/core/common/hooks/useTranslation';
import { rejectUnexpectedValue } from 'src/core/utils/switchGuard';

export function analyticalFieldsMappingsTableExternalNameI18nKey(
  integration: AccountingSoftware,
): I18nKey {
  switch (integration) {
    case 'Quickbooks':
      return 'bookkeep.integrations.quickbooks.analyticalFieldsMappingsTable.externalName';
    case 'Xero':
      return 'bookkeep.integrations.xero.analyticalFieldsMappingsTable.externalName';
    case 'Datev':
      return 'bookkeep.integrations.datev.analyticalFieldsMappingsTable.externalName';
    case 'SpendeskAccounting':
      // @ts-expect-error: Not an helpful comment
      return 'spendeskAccounting';
    case 'SpendeskAccountingSingleEntry':
      // @ts-expect-error: Not an helpful comment
      return 'spendeskAccountingSingleEntry';
    case 'Netsuite':
      // @ts-expect-error: Not an helpful comment
      return 'netsuite';
    case 'Sage':
      // @ts-expect-error: Not an helpful comment
      return 'sage';
    case 'Cegid':
      // @ts-expect-error: Not an helpful comment
      return 'cegid';
    case 'Sage100':
      // TODO
      // @ts-expect-error: Not an helpful comment
      return 'sage100';
    case 'ACD':
      // @ts-expect-error: Not an helpful comment
      return 'acd';
    case 'Odoo':
      // @ts-expect-error: Not an helpful comment
      return 'odoo';
    case 'ExactOnlineFr':
      // @ts-expect-error: Not an helpful comment
      return 'exactOnlineFr';
    default:
      rejectUnexpectedValue('integration type', integration);
  }
}

export function getAnalyticalFieldsHeaderI18Key(
  integration: AccountingSoftware,
): I18nKey {
  switch (integration) {
    case 'Xero':
      return 'bookkeep.integrations.settings.sectionAnalyticalFields';
    case 'Datev':
      return 'bookkeep.integrations.datev.analyticalFieldsHeader';
    case 'SpendeskAccounting':
      // @ts-expect-error: Not an helpful comment
      return 'spendeskAccounting';
    case 'SpendeskAccountingSingleEntry':
      // @ts-expect-error: Not an helpful comment
      return 'spendeskAccountingSingleEntry';
    case 'Netsuite':
      // @ts-expect-error: Not an helpful comment
      return 'netsuite';
    case 'Sage':
      // @ts-expect-error: Not an helpful comment
      return 'sage';
    case 'Cegid':
      // @ts-expect-error: Not an helpful comment
      return 'cegid';
    case 'Quickbooks':
      return 'bookkeep.integrations.settings.sectionAnalyticalFields';
    case 'Sage100':
      // TODO
      // @ts-expect-error: Not an helpful comment
      return 'sage100';
    case 'ACD':
      // @ts-expect-error: Not an helpful comment
      return 'acd';
    case 'Odoo':
      // @ts-expect-error: Not an helpful comment
      return 'odoo';
    case 'ExactOnlineFr':
      // @ts-expect-error: Not an helpful comment
      return 'exactOnlineFr';
    default:
      rejectUnexpectedValue('integration type', integration);
  }
}

export function getCustomFieldsDescriptionI18nKey(
  integration: AccountingSoftware,
): I18nKey {
  switch (integration) {
    case 'Xero':
      return 'bookkeep.integrations.xero.analyticalFieldsPullAndMapWithValuesDescription';
    case 'Datev':
      return 'bookkeep.integrations.datev.analyticalFieldsDescription';
    case 'SpendeskAccounting':
      // @ts-expect-error: Not an helpful comment
      return 'spendeskAccounting';
    case 'SpendeskAccountingSingleEntry':
      // @ts-expect-error: Not an helpful comment
      return 'spendeskAccountingSingleEntry';
    case 'Netsuite':
      // @ts-expect-error: Not an helpful comment
      return 'netsuite';
    case 'Sage':
      // @ts-expect-error: Not an helpful comment
      return 'sage';
    case 'Cegid':
      // @ts-expect-error: Not an helpful comment
      return 'cegid';
    case 'Quickbooks':
      return 'bookkeep.integrations.quickbooks.analyticalFieldsDescription';
    case 'Sage100':
      // TODO
      // @ts-expect-error: Not an helpful comment
      return 'sage100';
    case 'ACD':
      // @ts-expect-error: Not an helpful comment
      return 'acd';
    case 'Odoo':
      // @ts-expect-error: Not an helpful comment
      return 'odoo';
    case 'ExactOnlineFr':
      // @ts-expect-error: Not an helpful comment
      return 'exactOnlineFr';
    default:
      rejectUnexpectedValue('integration type', integration);
  }
}
