import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useNotifications, NotificationType } from 'modules/app/notifications';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { apiUrl } from 'src/core/utils/api';

import { CostCenterImportModal } from './CostCenterImportModal';
import { CostCenterImportModalError } from './CostCenterImportModalError';
import { CostCenterImportModalLoader } from './CostCenterImportModalLoader';
import {
  useImportCostCentersMutation,
  reshapeToFormData,
} from './useImportCostCentersMutation';

export type ImportType = 'create' | 'edit';
type Props = {
  type: ImportType;
  isOpen: boolean;
  showBackButton?: boolean;
  onExit(): void;
  onCancel(): void;
  onSuccess(): void;
};

export const CostCenterImportModalContainer = ({
  onExit,
  onSuccess,
  type,
  isOpen,
  onCancel,
  showBackButton = false,
}: Props) => {
  const { t } = useTranslation('global');
  const companyId = useCompanyId();
  const { pushNotif } = useNotifications();

  const [importCostCenters, mutationState, resetImportCostCentersMutation] =
    useImportCostCentersMutation();

  async function handleUpload(file: File) {
    const payload = reshapeToFormData(file);
    try {
      await importCostCenters(payload);
      pushNotif({
        type: NotificationType.Success,
        message: t(
          'budget.budgetaryExercise.importModal.successUploadNotification',
        ),
      });
      onSuccess();
    } catch {
      // caught below as mutationState.error
    }
  }

  if (mutationState.status === 'loading') {
    return <CostCenterImportModalLoader />;
  }

  if (
    mutationState.status === 'error' &&
    mutationState.error.type === 'RequestError' &&
    (mutationState.error.data.reason === 'fileInvalid' ||
      mutationState.error.data.reason === 'fileDataInvalid')
  ) {
    return (
      <CostCenterImportModalError
        onRetry={resetImportCostCentersMutation}
        onCancel={() => {
          resetImportCostCentersMutation();
          onCancel();
        }}
        error={mutationState.error.data}
      />
    );
  }

  if (mutationState.status === 'error') {
    pushNotif({
      type: NotificationType.Danger,
      message: t('costCenters.importModal.uploadErrorNotification'),
    });
    resetImportCostCentersMutation();
  }

  return (
    <CostCenterImportModal
      downloadLink={apiUrl(`/cost-centers/export?fileFormat=xlsx`, companyId)}
      onUpload={handleUpload}
      onCancel={onExit}
      type={type}
      isOpen={isOpen}
      showBackButton={showBackButton}
    />
  );
};
