import { SkeletonAvatar, SkeletonButton, SkeletonText } from "@dev-spendesk/grapes";

export const Loading = () => {
  return (
    <div className="flex flex-col gap-xs pb-s">
      <SkeletonButton width="183px" />
      <div className="my-xs flex items-center gap-xs">
        <SkeletonAvatar />
        <SkeletonText size="m" width="180px" />
      </div>
      <SkeletonButton width="142px" />
      <SkeletonButton width="100%" />
    </div>
  );
};
