import {
  Button,
  FormField,
  Label,
  Modal,
  TextInput,
} from '@dev-spendesk/grapes';
import { type FormikProps } from 'formik';
import React from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { ColumnDisplayOptionsContainer } from './ColumnDisplayOptionsContainer';
import { CommonEditComponents } from './CommonEditComponents';
import type { DirectionFormValues } from './form';
import { type RowDisplayOption } from '../../../export';

type Props = {
  isOpen: boolean;
  title: string;
  description: string;
  isDeletable: boolean;
  onRemoveColumn: () => void;
  onClose: () => void;
  rowDisplayOptions?: RowDisplayOption[];
} & FormikProps<DirectionFormValues>;

export const DirectionColumnEditModal = ({
  isOpen,
  title,
  description,
  isDeletable,
  onRemoveColumn,
  onClose,
  // Formik
  values,
  errors,
  touched,
  handleChange,
  setFieldValue,
  handleSubmit,
  rowDisplayOptions,
  ...rest
}: Props) => {
  const { t } = useTranslation('global');

  const noColumnDisplaySelected =
    rowDisplayOptions &&
    values.displayOptions &&
    !values.displayOptions.expense.active &&
    !values.displayOptions.analytical.active &&
    !values.displayOptions.vat.active &&
    !values.displayOptions.supplier.active;

  return (
    <Modal
      isOpen={isOpen}
      title={t('exports.columnEditModalTitle', { title })}
      subtitle={description}
      onClose={onClose}
      iconName="pen"
      actions={[
        <Button
          key="cancel"
          variant="secondary"
          text={t('misc.cancel')}
          onClick={onClose}
        />,
        <Button
          key="saveChanges"
          text={t('misc.saveChanges')}
          isDisabled={noColumnDisplaySelected}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onClick={(event: any) => {
            handleSubmit(event);
          }}
        />,
      ]}
    >
      <div className="text-left">
        <CommonEditComponents
          values={values}
          handleChange={handleChange}
          touched={touched}
          errors={errors}
          setFieldValue={setFieldValue}
          handleSubmit={handleSubmit}
          {...rest}
        />

        <div className="mt-m flex flex-col">
          <Label
            className="mb-xs"
            label={t('bookkeep.settings.export.directionOptions.header')}
          />
          {/* Debit */}
          <FormField
            label={t('bookkeep.settings.export.directionOptions.debit')}
            className="mb-xs"
            alertMessage={
              touched.debit && Boolean(errors.debit) ? errors.debit : undefined
            }
          >
            <TextInput
              name="directionDebit"
              id="directionDebit"
              value={values.debit || ''}
              onChange={(e) => {
                handleChange(e);
                setFieldValue('debit', e.target.value);
              }}
              isInvalid={touched.debit && Boolean(errors.debit)}
            />
          </FormField>

          {/* Credit */}
          <FormField
            label={t('bookkeep.settings.export.directionOptions.credit')}
            className="mb-xs"
            alertMessage={
              touched.credit && Boolean(errors.credit)
                ? errors.credit
                : undefined
            }
          >
            <TextInput
              name="directionCredit"
              id="directionCredit"
              value={values.credit || ''}
              onChange={(e) => {
                handleChange(e);
                setFieldValue('credit', e.target.value);
              }}
              isInvalid={touched.credit && Boolean(errors.credit)}
            />
          </FormField>
        </div>

        {rowDisplayOptions && (
          <FormField
            label={t(
              'bookkeep.settings.export.columnDisplayOptions.headerFormLabel',
            )}
            className="mt-m"
            alertMessage={
              noColumnDisplaySelected
                ? t(
                    `bookkeep.settings.export.columnDisplayOptions.selectColumnAlert`,
                  )
                : ''
            }
          >
            <ColumnDisplayOptionsContainer
              displayOptions={values.displayOptions}
              onDisplayOptionChange={(upd) =>
                setFieldValue('displayOptions', upd)
              }
              rowDisplayOptions={rowDisplayOptions}
              isAnalyticalColumn={false}
            />
          </FormField>
        )}

        {isDeletable && (
          <Button
            hasNegativeMargins
            className="mt-m"
            variant="ghost"
            iconName="minus-circle"
            iconPosition="left"
            text={t('exports.removeColumn')}
            onClick={onRemoveColumn}
          />
        )}
      </div>
    </Modal>
  );
};
