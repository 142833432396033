import { CheckboxField } from '@dev-spendesk/grapes';
import { useFormik, type FormikErrors } from 'formik';

import { useCompany } from 'modules/app/hooks/useCompany';
import { useNotifications, NotificationType } from 'modules/app/notifications';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import Validations from 'src/core/utils/validations';

import { BillingInformationForm } from './BillingInformationForm';
import { type BillingInformationFormValues } from './form';
import { validateAddress } from '../../../../../general-settings/components/CompanyInformationPage/components/AddressForm';
import { type BillingInformation } from '../../../../models';
import {
  useUpdateBillingInformationMutation,
  reshapeApiBillingInformation,
} from '../../hooks/useUpdateBillingInformationMutation';

type Props = {
  billingInformation: BillingInformation;
};

export const BillingInformationFormContainer = ({
  billingInformation,
}: Props) => {
  const { t } = useTranslation('global');
  const { pushNotif } = useNotifications();
  const company = useCompany();
  const [updateBillingInformation] = useUpdateBillingInformationMutation();

  const formikProps = useFormik<BillingInformationFormValues>({
    initialValues: {
      address: billingInformation.address ?? '',
      address2: billingInformation.address2 ?? '',
      city: billingInformation.city ?? '',
      country: billingInformation.country ?? '',
      name: billingInformation.name ?? '',
      zipcode: billingInformation.zipcode ?? '',
      isBillingInfoSameAsCompany: billingInformation.isBillingInfoSameAsCompany,
      email: billingInformation.email ?? '',
      vat: billingInformation.vat ?? '',
    },
    initialErrors: {
      email: !Validations.validateEmail(billingInformation.email)
        ? 'billing.billingInformation.emailError'
        : undefined,
      vat: !Validations.validateVat(billingInformation.vat)
        ? 'billing.billingInformation.vatError'
        : undefined,
    },
    enableReinitialize: true,
    validateOnChange: true,
    validate: async (values) => {
      const errors: FormikErrors<BillingInformationFormValues> = {};

      const addressErrors = await validateAddress(values);

      if (!Validations.validateEmail(values.email)) {
        errors.email = 'billing.billingInformation.emailError';
      }

      if (!Validations.validateVat(values.vat)) {
        errors.vat = 'billing.billingInformation.vatError';
      }

      return {
        ...addressErrors,
        ...errors,
      };
    },
    onSubmit: async (values) => {
      try {
        await updateBillingInformation({
          ...reshapeApiBillingInformation(billingInformation),
          address1: values.address.trim(),
          address2: values.address2.trim(),
          city: values.city.trim(),
          country: values.country.trim(),
          name: values.name.trim(),
          zipcode: values.zipcode.trim(),
          email: values.email.trim(),
          vat: values.vat.trim(),
        });
      } catch (error) {
        let message = t('billing.errorSaveBillingInformation');

        if (error?.data?.errors?.includes('invalidVat')) {
          message = t('billing.errorSaveBillingInformationVat');
        }

        pushNotif({
          type: NotificationType.Danger,
          message,
        });
        return;
      }

      pushNotif({
        type: NotificationType.Success,
        message: t('billing.successSaveBillingInformation'),
      });
    },
  });

  return (
    <>
      <CheckboxField
        isChecked={billingInformation.isBillingInfoSameAsCompany}
        onChange={async (e) => {
          try {
            if (billingInformation.isBillingInfoSameAsCompany) {
              await updateBillingInformation({
                ...reshapeApiBillingInformation(billingInformation),
                address1: billingInformation.address,
                address2: billingInformation.address2,
                city: billingInformation.city,
                country: billingInformation.country,
                name: billingInformation.name,
                zipcode: billingInformation.zipcode,
                is_billing_info_same_as_company: e.target.checked,
              });
            } else {
              await updateBillingInformation({
                ...reshapeApiBillingInformation(billingInformation),
                address1: company.address,
                address2: company.address2,
                city: company.city,
                country: company.country,
                name: company.name,
                zipcode: company.zipcode,
                is_billing_info_same_as_company: e.target.checked,
              });
            }
          } catch {
            if (formikProps.errors.email) {
              pushNotif({
                type: NotificationType.Danger,
                message: t('billing.errorSaveBillingInformationEmail'),
              });
              return;
            }

            pushNotif({
              type: NotificationType.Danger,
              message: t('billing.errorSaveBillingInformation'),
            });
            return;
          }

          pushNotif({
            type: NotificationType.Success,
            message: t('billing.successSaveBillingInformation'),
          });
        }}
        label={t('billing.billingInformation.billingInfoSameLabel')}
      />
      <BillingInformationForm {...formikProps} />
    </>
  );
};
