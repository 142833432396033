import type * as Money from 'ezmoney';

import { type CurrenciesKey } from 'src/core/config/money';

import { type Attachment } from './attachment';
import { type Counterparty } from './counterparty';

export type InvoiceBill = {
  id: string;
  type: 'invoice';
  dueDate: string;
  documentaryEvidence: {
    invoiceNumber: string;
    attachments: Attachment[];
  };
  spendingCommitment: {
    issueDate: string;
    description: string;
  };
  amount: Money.MonetaryValue<CurrenciesKey>;
  counterparty: Counterparty;
  requestId: string;
};

export type ExpenseBill = {
  id: string;
  type: 'expense' | 'invoice';
  amount: Money.MonetaryValue<CurrenciesKey>;
  counterparty: Counterparty;
  requestId: string;
};

export type Bill = InvoiceBill | ExpenseBill;

export const isInvoiceBill = (bill: Bill): bill is InvoiceBill =>
  bill.type === 'invoice';
