import type { QueryState } from 'src/core/api/queryState';

import { EntityItemActions } from './EntityItemActions/EntityItemActions';
import { EntityItemHeader } from './EntityItemHeader/EntityItemHeader';
import { EntityItemLayoutWrapper } from './EntityItemLayoutWrapper';
import { EntityItemStatusCallout } from './EntityItemStatusCallout/EntityItemStatusCallout';
import { EntityItemWalletSummary } from './EntityItemWalletSummary/EntityItemWalletSummary';
import { type EntityStatus } from './types';
import { useOrganisationEntityWalletSummaryQuery } from '../../../hooks/data/useOrganisationEntityWalletSummaryQuery';
import { useOrganisationFeatures } from '../../../hooks/useOrganisationFeatures';
import type {
  EnrichedEntityInformation,
  EntityBasicInformation,
} from '../../../types';

export type OnDataLoadedResult =
  | {
      entityData: EntityBasicInformation;
      status: Extract<QueryState['status'], 'error'>;
    }
  | {
      entityData: EnrichedEntityInformation;
      status: Extract<QueryState['status'], 'success'>;
    };

type Props = {
  entity: EntityBasicInformation;
  isPinned: boolean;
  togglePinnedEntity: (entityId: string) => void;
  onDataLoaded: (result: OnDataLoadedResult) => void;
};

export const EntityListItem = ({
  entity,
  isPinned,
  togglePinnedEntity,
  onDataLoaded,
}: Props) => {
  const features = useOrganisationFeatures();

  const entityWalletSummaryQueryState = useOrganisationEntityWalletSummaryQuery(
    entity.id,
    { isEnabled: features.wallet },
  );

  const entityStatus = getEntityStatus(entity, entityWalletSummaryQueryState);

  return (
    <>
      <EntityItemLayoutWrapper entityStatus={entityStatus}>
        <EntityItemHeader
          entity={entity}
          canAccessEntity={entityStatus !== 'awaitingKycApproval'}
          isPinned={isPinned}
          togglePinnedEntity={togglePinnedEntity}
        />
        <hr
          className="separator"
          style={{
            borderTop: 'none',
            transform: 'translateX(-24px)',
            width: 'calc(100% + 2 * 24px)',
          }}
        />

        <div className="flex items-stretch gap-[12px]">
          {features.wallet && (
            <EntityItemWalletSummary
              entity={entity}
              entityStatus={entityStatus}
              entityWalletSummaryQueryState={entityWalletSummaryQueryState}
              onWalletSummaryLoaded={onDataLoaded}
            />
          )}

          <EntityItemActions entity={entity} entityStatus={entityStatus} />
        </div>
      </EntityItemLayoutWrapper>

      {entityStatus && (
        <EntityItemStatusCallout state={entityStatus} entityId={entity.id} />
      )}
    </>
  );
};

/**
 * Get the status of the entity to display in the callout
 * `null` means the entity is active,
 * `undefined` indicates that the status is not yet available (query is still loading)
 */
export const getEntityStatus = (
  entity: EntityBasicInformation,
  entityWalletSummaryQueryState: ReturnType<
    typeof useOrganisationEntityWalletSummaryQuery
  >,
): EntityStatus | undefined => {
  if (entityWalletSummaryQueryState.status !== 'success') {
    return undefined;
  }

  const { data } = entityWalletSummaryQueryState;

  if (data.isKycInProgress) {
    return 'kycInProgress';
  }
  if (data.isKycAwaitingApproval) {
    return 'awaitingKycApproval';
  }
  if (entity.isChurning) {
    return data.walletBalance > 0 ? 'churningWithRemainingFunds' : 'churning';
  }
  if (entity.hasChurned) {
    return data.walletBalance > 0 ? 'churnedWithRemainingFunds' : 'churned';
  }

  return null;
};
