import {
  Button,
  CheckboxInput,
  DropdownItem,
  DropdownMenu,
} from '@dev-spendesk/grapes';
import classNames from 'classnames';

import { useTranslation } from 'common/hooks/useTranslation';

import './PayableStateFilter.css';

type Props = {
  selectedPayableStates: string[] | undefined;
  setPayableStates(payableStates: string[] | undefined): void;
};

export const PayableStateFilter = ({
  selectedPayableStates,
  setPayableStates,
}: Props) => {
  const { t } = useTranslation('global');

  return (
    <DropdownMenu
      keepOpenOnSelect
      placement="bottom-start"
      renderButton={(getToggleButtonProps, isOpen) => (
        <Button
          {...getToggleButtonProps()}
          className={classNames('PayableStateFilter__button', {
            'PayableStateFilter__button--open': isOpen,
            'PayableStateFilter__button--active': selectedPayableStates?.length,
          })}
          text={t('payables.filters.payableState')}
          variant="ghost"
        />
      )}
      options={[
        {
          key: 'toPrepare',
          label: t('payables.filters.payableStateFilter.toPrepare'),
        },
        {
          key: 'toExport',
          label: t('payables.filters.payableStateFilter.toExport'),
        },
        {
          key: 'exported',
          label: t('payables.filters.payableStateFilter.exported'),
        },
      ]}
      onSelect={(option) => {
        if (selectedPayableStates?.includes(option.key)) {
          const filteredPayableStates = selectedPayableStates?.filter(
            (s) => s !== option.key,
          );
          setPayableStates(
            filteredPayableStates.length ? filteredPayableStates : undefined,
          );
        } else {
          setPayableStates([...(selectedPayableStates ?? []), option.key]);
        }
      }}
      renderOption={(option) => {
        const isSelected = selectedPayableStates
          ? selectedPayableStates.includes(option.key)
          : false;
        return (
          <DropdownItem
            key={option.key}
            label={option.label}
            isSelected={isSelected}
            isHighlighted={isSelected}
            // TODO: we shouldn't use the checkbox component only for visual clue (but a simple icon)
            prefix={
              <CheckboxInput isChecked={isSelected} onChange={() => {}} />
            }
          />
        );
      }}
    />
  );
};
