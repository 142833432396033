import { type MonetaryValue } from 'ezmoney';

import { useInfiniteQuery } from 'src/core/api/hooks/useInfiniteQuery';
import { type InfiniteQueryState } from 'src/core/api/queryState';

import { type SupplierAccountDefaultFor } from '../../settings/accounting';
import { useHasDefaultForCapability } from '../../utils/accounting/accounts-payable';
import { getGetAccountPayableSuppliersListQuery } from '../queries/getAccountPayableSuppliersList';
import { type AccountPayableSuppliersRow } from '../types';

type RawData = {
  company: {
    chartOfAccounts: {
      supplierAccounts: {
        totalCount: number;
        pageInfo: {
          hasNextPage: boolean;
        };
        edges: {
          cursor: string;
          node: {
            payablesAggregate: {
              sum: {
                functionalAmount: MonetaryValue;
              };
            };
            id: string;
            generalAccountCode: string;
            auxiliaryAccountCode: string | undefined;
            isDefault: boolean;
            defaultFor: SupplierAccountDefaultFor | undefined;
            isArchived: boolean;
            suppliers: {
              totalCount: number;
              edges: {
                node: {
                  thumbnailUrl: string;
                  name: string;
                  id: string;
                };
              }[];
            };
          };
        }[];
      };
    };
  };
};

export const useAccountPayableSuppliersListQuery = (
  search: string,
  archivalStatus: 'current' | 'archived' | undefined,
): InfiniteQueryState<AccountPayableSuppliersRow[]> => {
  const hasDefaultFor = useHasDefaultForCapability();

  let isSupplierArchived: boolean | undefined;
  switch (archivalStatus) {
    case 'current':
      isSupplierArchived = false;
      break;
    case 'archived':
      isSupplierArchived = true;
      break;
    default:
      break;
  }

  return useInfiniteQuery<AccountPayableSuppliersRow, RawData>({
    key: ['useAccountPayableSuppliersListQuery', search, archivalStatus],
    getRequest: (cursor) => ({
      type: 'graphQL',
      target: 'v2',
      query: getGetAccountPayableSuppliersListQuery({
        hasDefaultFor,
      }),
      variables: {
        filter: {
          includeArchived: false,
          search,
          isMapped: true,
        },
        supplierFilter: {
          isArchived: isSupplierArchived,
          search,
        },
        after: cursor,
      },
    }),
    getNextPageParam: (data) => {
      const { edges, pageInfo } = data.company.chartOfAccounts.supplierAccounts;

      if (pageInfo.hasNextPage) {
        return edges.at(-1)?.cursor;
      }
      return undefined;
    },
    reshapeData: (rawData) => {
      const { edges } = rawData.company.chartOfAccounts.supplierAccounts;

      return (
        edges
          .map(({ node }) => {
            const logosUrls =
              node.suppliers.totalCount === 1
                ? [node.suppliers.edges[0]?.node?.thumbnailUrl]
                : [
                    node.suppliers.edges[0]?.node?.thumbnailUrl,
                    node.suppliers.edges[1]?.node?.thumbnailUrl,
                  ];

            return {
              accountPayable: {
                id: node.id,
                generalAccountCode: node.generalAccountCode,
                auxiliaryAccountCode: node.auxiliaryAccountCode,
                isDefault: node.isDefault,
                defaultFor: node.defaultFor || undefined,
                isArchived: node.isArchived,
              },
              totalCount: node.suppliers.totalCount,
              totalAmount: node.payablesAggregate.sum.functionalAmount,
              suppliers: node.suppliers.edges.map((edge) => ({
                id: edge.node.id,
                name: edge.node.name,
              })),
              logosUrls,
            };
          })
          // We don't want the customer to be able to archive A/P from the archived tab if it has suppliers
          // While the filter could be backend side, we want to change the A/P + suppliers page anyway
          // as it doesn't make much sense to have a first A/P layer before Suppliers
          .filter((accountPayable) => {
            return archivalStatus === 'archived'
              ? accountPayable.totalCount > 0
              : true;
          })
      );
    },
  });
};
