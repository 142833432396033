import { Modal } from '@dev-spendesk/grapes';
import { type TFunction } from 'i18next';
import { Trans } from 'react-i18next';

import { useTranslation } from 'common/hooks/useTranslation';

import styles from './CostCenterActivationProcessingModal.module.css';

type Props = {
  isOpen: boolean;
};
export const CostCenterActivationProcessingModal = ({ isOpen }: Props) => {
  const { t } = useTranslation('global');

  return (
    <Modal
      iconName="sync"
      title={t('costCenters.activation.modal.processing.title')}
      actions={[]}
      isOpen={isOpen}
      className={styles.modal}
    >
      <Trans
        i18nKey="costCenters.activation.modal.processing.content"
        t={t as TFunction}
      />
    </Modal>
  );
};
