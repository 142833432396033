import { type AccountingSoftware } from 'modules/bookkeep/integration/status';
import type { I18nKey } from 'src/core/common/hooks/useTranslation';
import { rejectUnexpectedValue } from 'src/core/utils/switchGuard';

export function analyticalFieldsMappingsTableExternalNameI18nKey(
  integration: AccountingSoftware,
): I18nKey {
  switch (integration) {
    case 'Quickbooks':
      return 'bookkeep.integrations.quickbooks.analyticalFieldsMappingsTable.externalName';
    case 'Xero':
      return 'bookkeep.integrations.xero.analyticalFieldsMappingsTable.externalName';
    case 'Datev':
      return 'bookkeep.integrations.datev.analyticalFieldsMappingsTable.externalName';
    case 'SpendeskAccounting':
      // @ts-expect-error: Not an helpful comment
      return 'spendeskAccounting';
    case 'SpendeskAccountingSingleEntry':
      // @ts-expect-error: Not an helpful comment
      return 'spendeskAccountingSingleEntry';
    case 'Netsuite':
      return 'bookkeep.integrations.netsuite.analyticalFieldsMappingsTable.externalName';
    case 'Sage':
      // @ts-expect-error: Not an helpful comment
      return 'sage';
    case 'Cegid':
      // @ts-expect-error: Not an helpful comment
      return 'cegid';
    case 'Sage100':
    case 'ACD':
    case 'Odoo':
    case 'ExactOnlineFr':
      return 'bookkeep.integrations.chift.analyticalFieldsLeftHeader';
    default:
      rejectUnexpectedValue('integration type', integration);
  }
}

export function getAnalyticalFieldsHeaderI18Key(
  integration: AccountingSoftware,
): I18nKey {
  switch (integration) {
    case 'Xero':
      return 'bookkeep.integrations.settings.sectionAnalyticalFields';
    case 'Datev':
      return 'bookkeep.integrations.datev.analyticalFieldsHeader';
    case 'SpendeskAccounting':
      // @ts-expect-error: Not an helpful comment
      return 'spendeskAccounting';
    case 'SpendeskAccountingSingleEntry':
      // @ts-expect-error: Not an helpful comment
      return 'spendeskAccountingSingleEntry';
    case 'Netsuite':
      return 'bookkeep.integrations.netsuite.analyticalFieldsHeader';
    case 'Sage':
      // @ts-expect-error: Not an helpful comment
      return 'sage';
    case 'Cegid':
      // @ts-expect-error: Not an helpful comment
      return 'cegid';
    case 'Quickbooks':
      return 'bookkeep.integrations.settings.sectionAnalyticalFields';
    case 'Sage100':
    case 'ACD':
    case 'Odoo':
    case 'ExactOnlineFr':
      return 'bookkeep.integrations.chift.analyticalFieldsHeader';
    default:
      rejectUnexpectedValue('integration type', integration);
  }
}

export function getCustomFieldsDescriptionI18nKey(
  integration: AccountingSoftware,
): I18nKey {
  switch (integration) {
    case 'Xero':
      return 'bookkeep.integrations.xero.analyticalFieldsDescription';
    case 'Datev':
      return 'bookkeep.integrations.datev.analyticalFieldsDescription';
    case 'SpendeskAccounting':
      // @ts-expect-error: Not an helpful comment
      return 'spendeskAccounting';
    case 'SpendeskAccountingSingleEntry':
      // @ts-expect-error: Not an helpful comment
      return 'spendeskAccountingSingleEntry';
    case 'Netsuite':
      return 'bookkeep.integrations.netsuite.analyticalFieldsDescription';
    case 'Sage':
      // @ts-expect-error: Not an helpful comment
      return 'sage';
    case 'Cegid':
      // @ts-expect-error: Not an helpful comment
      return 'cegid';
    case 'Quickbooks':
      return 'bookkeep.integrations.quickbooks.analyticalFieldsDescription';
    case 'Sage100':
    case 'ACD':
    case 'Odoo':
    case 'ExactOnlineFr':
      return 'bookkeep.integrations.sage100.analyticalFieldsDescription';
    default:
      rejectUnexpectedValue('integration type', integration);
  }
}
