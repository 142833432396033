import {
  Badge,
  Button,
  type ButtonProps,
  Callout,
  CheckboxField,
  FileCard,
  Upload,
} from '@dev-spendesk/grapes';
import { Trans } from 'react-i18next';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { apiUrl } from 'src/core/utils/api';

type Props = {
  templateButtonVariant?: ButtonProps['variant'];
  file: File | undefined;
  hasMissingFileError: boolean;
  prefixNameWithCode: boolean;
  setFile: (file: File | undefined) => void;
  setHasMissingFileError: (hasError: boolean) => void;
  setPrefixNameWithCode: (prefixNameWithCode: boolean) => void;
};

export const BulkImportExpenseAccountForm = ({
  templateButtonVariant = 'secondary',
  file,
  hasMissingFileError,
  prefixNameWithCode,
  setFile,
  setHasMissingFileError,
  setPrefixNameWithCode,
}: Props) => {
  const { t } = useTranslation('global');

  const companyId = useCompanyId();
  const templateLink = apiUrl(
    `/accounting-integration/chart-of-accounts/expense-accounts/export-template`,
    companyId,
  );

  return (
    <div>
      <div className="mb-s flex items-center gap-s text-left text-complementary title-m">
        <Badge variant="primary">1</Badge>
        {t(
          'bookkeep.integrations.settings.expenseAccountsTable.import.instructionsDownload',
        )}
      </div>
      <div className="mb-m mt-s flex justify-center">
        <Button
          component="a"
          href={templateLink}
          text={t(
            'bookkeep.integrations.settings.expenseAccountsTable.import.downloadButton',
          )}
          variant={templateButtonVariant}
        />
      </div>
      <div className="mb-s flex items-center gap-s text-left text-complementary title-m">
        <Badge variant="primary">2</Badge>
        {t(
          'bookkeep.integrations.settings.expenseAccountsTable.import.instructionsUpload',
        )}
      </div>
      <div>
        {!file ? (
          <>
            <Upload
              activeDragContent={
                <>{t('forms.uploadFiles.activeDragContent')}</>
              }
              content={
                <Trans i18nKey="forms.uploadFiles.content">
                  <br />-
                  <span className="text-complementary underline title-m">
                    -
                  </span>
                </Trans>
              }
              onUpload={(files) => {
                setHasMissingFileError(false);
                setFile(files[0]);
              }}
            />
            {hasMissingFileError && (
              <Callout
                className="mt-xs"
                title={t(
                  'bookkeep.integrations.settings.expenseAccountsTable.import.errorMissingFile',
                )}
                variant="alert"
              />
            )}
          </>
        ) : (
          <FileCard title={file.name} onDelete={() => setFile(undefined)} />
        )}
      </div>
      <CheckboxField
        className="mt-s w-full text-left"
        isChecked={prefixNameWithCode}
        label={t(
          'bookkeep.integrations.settings.expenseAccountsTable.import.prefixWithCode',
        )}
        onChange={(event) => setPrefixNameWithCode(event.target.checked)}
      />
    </div>
  );
};
