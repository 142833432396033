import { Route, Switch } from 'react-router-dom';

import { HeaderWithNavigation } from 'src/core/common/components/HeaderWithNavigation/HeaderWithNavigation';
import { type NavigationLinkProps } from 'src/core/common/components/NavigationLink';
import { ProtectedRoute } from 'src/core/common/components/ProtectedRoute';
import { useFeature } from 'src/core/common/hooks/useFeature';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';
import { routeFor, routes } from 'src/core/constants/routes';
import { useCompany } from 'src/core/modules/app/hooks/useCompany';
import { useUser } from 'src/core/modules/app/hooks/useUser';
import { BankInformationPage } from 'src/core/modules/company/general-settings/components/BankInformationPage/BankInformationPage';
import { NotificationSettingsContainer } from 'src/core/modules/company/general-settings/components/NotificationSettings';
import { SpendingMethods } from 'src/core/modules/company/general-settings/components/SpendingMethods';
import { useShouldDisplayBankInformation } from 'src/core/modules/company/general-settings/hooks/useShouldDisplayBankInformation';
import { MembersApprovalPoliciesContainer } from 'src/core/modules/members/containers/MembersApprovalPoliciesContainer';
import { MembersControlRulesTabContainer } from 'src/core/modules/members/containers/MembersControlRulesTabContainer/MembersControlRulesTabContainer';
import { SpendLimitsPage } from 'src/core/modules/spend-limits';

export const SettingsCompanyRulesPage = () => {
  const { t } = useTranslation('global');
  const hasControlOnSpendingMethods = useFeature(
    FEATURES.CONTROL_ON_SPENDING_METHODS,
  );
  const isSpendLimitsFeatureEnabled = useFeature(FEATURES.SPEND_LIMITS);
  const company = useCompany();
  const user = useUser();
  const isAOorAdmin = user.is_account_owner || user.is_admin;
  const shouldDisplayBankInformation = useShouldDisplayBankInformation();

  const canAccessNotifications = isAOorAdmin;
  const canAccessSpendingMethods = hasControlOnSpendingMethods && isAOorAdmin;
  const canAccessBankInformation = shouldDisplayBankInformation && isAOorAdmin;

  const canAccessMembers = isAOorAdmin;

  const links = [
    canAccessMembers
      ? {
          key: 'company-policies',
          text: t('members.navLinks.policies'),
          path: routeFor(routes.COMPANY_POLICIES.path, {
            company: company.id,
          }),
        }
      : null,
    canAccessMembers
      ? {
          key: 'company-control-rules',
          text: t('members.navLinks.controlRules'),
          path: routeFor(routes.COMPANY_CONTROL_RULES.path, {
            company: company.id,
          }),
        }
      : null,
    canAccessSpendingMethods
      ? {
          key: 'company-spending-methods',
          text: t('generalSettings.navLinks.paymentMethods'),
          path: routeFor(routes.COMPANY_GENERAL_SETTINGS_PAYMENT_METHODS.path, {
            company: company.id,
          }),
        }
      : null,
    canAccessNotifications
      ? {
          key: 'company-notifications',
          text: t('generalSettings.navLinks.notifications'),
          path: routeFor(routes.COMPANY_GENERAL_SETTINGS_NOTIFICATIONS.path, {
            company: company.id,
          }),
        }
      : null,
    isSpendLimitsFeatureEnabled
      ? {
          key: 'spend-limits',
          text: t('settings.links.spendLimits'),
          path: routeFor(routes.SPEND_LIMITS.path, {
            company: company.id,
          }),
        }
      : null,
  ].filter((item) => item !== null) as NavigationLinkProps[];

  return (
    <div className="page__container">
      <HeaderWithNavigation links={links}>
        {t('navigation.companyRules')}
      </HeaderWithNavigation>
      <div className="grow bg-page-background p-m">
        <div className="flex max-w-[994px] flex-col gap-xxl">
          <Switch>
            <Route exact path={routes.COMPANY_POLICIES.path}>
              <MembersApprovalPoliciesContainer />
            </Route>

            <Route exact path={routes.COMPANY_CONTROL_RULES.path}>
              <MembersControlRulesTabContainer />
            </Route>

            <ProtectedRoute
              path={[
                routes.COMPANY_GENERAL_SETTINGS_PAYMENT_METHODS.path,
                routes.COMPANY_GENERAL_SETTINGS_BANK_INFORMATION.path,
              ]}
              isAccessAllowed={canAccessSpendingMethods}
            >
              <>
                <SpendingMethods />
                {canAccessBankInformation && <BankInformationPage />}
              </>
            </ProtectedRoute>

            <ProtectedRoute
              isAccessAllowed={canAccessNotifications}
              path={routes.COMPANY_GENERAL_SETTINGS_NOTIFICATIONS.path}
            >
              <NotificationSettingsContainer />
            </ProtectedRoute>

            <ProtectedRoute
              path={routes.SPEND_LIMITS.path}
              isAccessAllowed={isSpendLimitsFeatureEnabled}
            >
              <SpendLimitsPage />
            </ProtectedRoute>
          </Switch>
        </div>
      </div>
    </div>
  );
};
