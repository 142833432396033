import { useTranslation } from 'common/hooks/useTranslation';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { type KybStatusData } from 'modules/kyc/models/kybStatus';
import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

type Response = KybStatusData | undefined;

export const useGetKybStatusQuery = ({
  isEnabled,
}: {
  isEnabled: boolean;
}): QueryState<Response> => {
  const { activeLanguage } = useTranslation('global');
  const companyId = useCompanyId();

  return useQuery<Response>({
    key: ['kybStatus', companyId],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/onboarding-tasks/kyb-status',
      params: {
        locale: activeLanguage,
      },
    },
    reshapeData: (data) => {
      // data is an empty string when we get a 204 response
      // i.e. when there is no KYB status
      if (data === '') {
        return undefined;
      }
      return data;
    },
    isEnabled,
  });
};
