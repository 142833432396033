import React, { type ReactElement, useState } from 'react';

import type { TGlobalFunctionTyped } from 'common/hooks/useTranslation';
import { QuerySuspense } from 'src/core/common/components/QuerySuspense';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { rejectUnexpectedValue } from 'src/core/utils/switchGuard';

import { type ModalState } from './ModalState';
import { PullAndMapWithValuesConfirmChangesModal } from './PullAndMapWithValuesSectionConfirmChangesModal';
import { PullAndMapWithValuesDeleteModal } from './PullAndMapWithValuesSectionDeleteModal';
import { PullAndMapWithValuesSectionModal } from './PullAndMapWithValuesSectionModal';
import { PullAndMapWithValuesTable } from './PullAndMapWithValuesTable';
import {
  getAnalyticalFieldsHeaderI18Key,
  getCustomFieldsDescriptionI18nKey,
} from './translations';
import { useCustomFieldAndAnalyticalFieldQueries } from './useCustomFieldAndAnalyticalFieldQueries';
import { useFeature } from '../../../../../../../../../common/hooks/useFeature';
import FEATURES from '../../../../../../../../../constants/features';
import { type AccountingSoftware } from '../../../../../../../integration/status';
import {
  type AnalyticalFieldMappingCreate,
  type AnalyticalFieldMappingDelete,
} from '../../../../../hooks/useCreateAnalyticalFieldMappingMutation';
import { type AnalyticalFieldMappingUpdate } from '../../../../../hooks/useUpdateAnalyticalFieldMappingMutation';
import { AnalyticalFieldErrorWarring } from '../AnalyticalFieldErrorWarring';
import { AnalyticalFieldSkeletonTable } from '../AnalyticalFieldSkeletonTable';

interface Props {
  integration: AccountingSoftware;
  onCreate: (
    editedAnalyticalField: AnalyticalFieldMappingCreate & {
      externalName: string;
    },
  ) => void;
  onUpdate: (
    editedAnalyticalField: AnalyticalFieldMappingUpdate & {
      externalName: string;
    },
  ) => void;
  onDelete: (
    editedAnalyticalField: AnalyticalFieldMappingDelete & {
      externalName: string;
    },
  ) => void;
}

export const PullAndMapWithValuesSection = ({
  integration,
  onCreate,
  onUpdate,
  onDelete,
}: Props) => {
  const { t } = useTranslation('global');

  const queriesState = useCustomFieldAndAnalyticalFieldQueries();

  const [modalState, setModalState] = useState<ModalState>({
    modalKind: 'closed',
  });

  const hasCostCenterFeature = useFeature(FEATURES.COST_CENTERS);
  const hasCostCenterActive = useFeature(FEATURES.COST_CENTERS_ACTIVATED);
  const hasCustomFieldsActive = useFeature(FEATURES.CUSTOM_FIELDS);

  return (
    <div id="vat-accounts">
      <h3 className="IntegrationAccountingPage__section-title title-xl">
        {t(getAnalyticalFieldsHeaderI18Key(integration))}
      </h3>

      <p className="IntegrationAccountingPage__section-description body-m">
        {t(getCustomFieldsDescriptionI18nKey(integration))}
        {renderHelpCenterLink(integration, t)}
      </p>

      <QuerySuspense
        queryState={queriesState}
        loading={<AnalyticalFieldSkeletonTable />}
        fallback={() => <AnalyticalFieldErrorWarring />}
      >
        {(data) => {
          const onlyNotDeletedCustomFields = data.customFields.filter(
            (cf) => !cf.deleted_at,
          );

          const handleCreateOrUpdate = (type: 'create' | 'update') => {
            if (
              modalState.modalKind === 'map' &&
              modalState.kind === 'mapped'
            ) {
              switch (type) {
                case 'create':
                  onCreate(modalState);
                  break;
                case 'update':
                  onUpdate(modalState);
                  break;
                default:
                  rejectUnexpectedValue('action', type);
              }

              setModalState({ modalKind: 'closed' });
            }
          };

          return (
            <>
              <PullAndMapWithValuesTable
                integration={integration}
                setModalState={setModalState}
                analyticalFieldMapping={data.analyticalFieldMapping}
                customFields={onlyNotDeletedCustomFields}
              />

              <PullAndMapWithValuesSectionModal
                integration={integration}
                customFields={onlyNotDeletedCustomFields}
                modalState={modalState}
                setModalState={setModalState}
                hasCostCenterActive={hasCostCenterActive}
                hasCostCenterFeature={hasCostCenterFeature}
                hasCustomFieldsActive={hasCustomFieldsActive}
                onCreate={() => handleCreateOrUpdate('create')}
                onUpdate={() => handleCreateOrUpdate('update')}
              />

              <PullAndMapWithValuesDeleteModal
                integration={integration}
                modalState={modalState}
                setModalState={setModalState}
                onDelete={onDelete}
              />

              <PullAndMapWithValuesConfirmChangesModal
                modalState={modalState}
                setModalState={setModalState}
                onCreate={() => handleCreateOrUpdate('create')}
              />
            </>
          );
        }}
      </QuerySuspense>
    </div>
  );
};

function renderHelpCenterLink(
  integration: AccountingSoftware,
  t: TGlobalFunctionTyped,
): ReactElement {
  switch (integration) {
    case 'Xero':
      return <></>;
    case 'Datev':
      return (
        <>
          &nbsp;|&nbsp;
          <a
            href="https://helpcenter.spendesk.com/articles/5560143-how-to-set-up-datev-kostenstellen-mapping-bookkeep-2-0"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('misc.helpCenterArticle')}
          </a>
        </>
      );
    case 'SpendeskAccounting':
    case 'SpendeskAccountingSingleEntry':
    case 'Netsuite':
    case 'Sage':
    case 'Cegid':
    case 'Quickbooks':
    case 'Sage100':
    case 'ACD':
    case 'Odoo':
    case 'ExactOnlineFr':
      return <></>;
    default:
      rejectUnexpectedValue('integration type', integration);
  }
}
