import { useTranslation } from 'src/core/common/hooks/useTranslation';

export const TableFooter = () => {
  const { t } = useTranslation('global');

  return (
    <div>
      <h3 className="title-m">
        {t('bookkeep.integrations.settings.noReverseChargeAccountTitle')}
      </h3>
    </div>
  );
};
