import { Button, FormField, Select } from '@dev-spendesk/grapes';
import { useFormik } from 'formik';

import { ApprovalRulesContainer } from 'src/core/modules/company/structure/approval-flows';

const conditionOptions = [
  { key: 'spendType', label: 'Spend Type' },
  { key: 'costCenter', label: 'Cost Center' },
];

const costCenters = [
  { key: 'costCenter1', label: 'Cost Center 1' },
  { key: 'costCenter2', label: 'Cost Center 2' },
  { key: 'costCenter3', label: 'Cost Center 3' },
];

type Props = {
  onCancel: () => void;
};

export const DimensionApprovalFlowPanelContent = ({ onCancel }: Props) => {
  const {
    values: formValues,
    setFieldValue,
    handleSubmit,
  } = useFormik({
    initialValues: {
      condition: 'spendType',
      conditionValue: 'expenseClaim',
    },
    onSubmit: (values) => {
      // eslint-disable-next-line no-console
      console.log('values', values);
    },
  });

  return (
    <>
      <FormField className="text-left" label="When">
        <Select
          name="condition"
          options={[
            { key: 'spendType', label: 'Spend Type' },
            { key: 'costCenter', label: 'Cost Center' },
          ]}
          value={conditionOptions.find(
            (option) => option.key === formValues.condition,
          )}
          onSelect={(option) => setFieldValue('condition', option.key)}
          fit="parent"
          placeholder="Select a condition"
        />
      </FormField>
      <FormField className="mb-m mt-s text-left" label="Is">
        <Select
          name="conditionValue"
          options={getConditionValueOptions(formValues.condition, costCenters)}
          value={getConditionValueOptions(
            formValues.condition,
            costCenters,
          ).find((option) => option.key === formValues.conditionValue)}
          onSelect={(option) => setFieldValue('conditionValue', option.key)}
          fit="parent"
          placeholder="Select a value"
        />
      </FormField>
      <div>
        <ApprovalRulesContainer
          rules={approvalRulesMocked}
          members={membersMocked}
          onChange={() => null}
        />
      </div>
      <div className="flex justify-between gap-s">
        <Button
          text="Save"
          className="flex-grow"
          variant="primary"
          onClick={() => {
            handleSubmit();
          }}
        />
        <Button
          text="Cancel"
          className="flex-grow"
          variant="secondary"
          onClick={() => {
            onCancel();
          }}
        />
      </div>
    </>
  );
};

const getConditionValueOptions = (
  condition: string,
  costCenterOptions: { key: string; label: string }[],
) => {
  switch (condition) {
    case 'spendType':
      return [
        { key: 'expenseClaim', label: 'Expense Claim' },
        { key: 'invoice', label: 'Invoice Request' },
        { key: 'mileage', label: 'Mileage Request' },
        { key: 'perDiem', label: 'Per Diem Request' },
        { key: 'virtualCard', label: 'Virtual Card Request' },
      ];
    case 'costCenter':
      return costCenterOptions;
    default:
      return [];
  }
};

const approvalRulesMocked = [
  {
    isAoRule: false,
    upTo: { value: null, currency: 'EUR' as const },
    steps: [
      {
        rights: [
          { approverId: 'Vvsn2aBtHoQOk4', approverType: 'user' as const },
        ],
      },
    ],
    from: { value: 0, currency: 'EUR' as const },
    id: 'd9f4f7ab-1330-4120-a496-61c4928f20cb',
    index: 0,
    isLastRule: true,
  },
];

const membersMocked = [
  {
    id: 'Vvsn2aBtHoQOk4',
    fullname: 'Rosie Will',
    email: 'Rosie.Will31-8772121735@gmail.com',
    avatar: 'https://avatars.githubusercontent.com/u/33935517',
    isPending: false,
    isAccountOwner: false,
  },
];
