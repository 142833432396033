import { type FormikErrors } from 'formik';

import { type TGlobalFunctionTyped } from 'common/hooks/useTranslation';

export type FormValues = {
  code: string;
  rate: string;
};

export const validate = (values: FormValues, t: TGlobalFunctionTyped) => {
  const errors: FormikErrors<FormValues> = {};

  const code = values.code.trim();
  const rate = values.rate.trim();

  if (!code && !rate) {
    return {};
  }

  if (!code) {
    errors.code = t(
      'bookkeep.integrations.settings.defaultTaxAccountForm.requiredError',
    );
  }
  if (!rate) {
    errors.rate = t(
      'bookkeep.integrations.settings.defaultTaxAccountForm.requiredError',
    );
  } else if (Number.parseFloat(rate) < 0) {
    errors.rate = t(
      'bookkeep.integrations.settings.defaultTaxAccountForm.negativeTaxRateError',
    );
  } else if (Number.parseFloat(rate) > 100) {
    errors.rate = t(
      'bookkeep.integrations.settings.defaultTaxAccountForm.taxRateExceedsMaximumError',
    );
  }

  return errors;
};
