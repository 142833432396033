import { Modal } from '@dev-spendesk/grapes';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

export const CostCenterImportModalLoader = () => {
  const { t } = useTranslation('global');

  return (
    <Modal
      isOpen
      iconName="sync"
      iconVariant="primary"
      title={t('costCenters.importModal.loader.title')}
      subtitle={t('costCenters.importModal.loader.subtitle')}
      actions={[]}
    />
  );
};
