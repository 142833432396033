import type { Location } from 'history';
import queryString from 'query-string';
import { matchPath } from 'react-router-7';

import type { AppRouteKeys, Route } from 'src/core/constants/routes';

import type { IntegrationsSettings } from './analytics';

type UrlInfos = {
  category: string;
  name: string;
  properties: {
    title: string;
    path: string;
    referrer: string | null;
  };
  integrations: IntegrationsSettings | undefined;
};

export const getUrlParams = (search = '') =>
  queryString.parse(decodeURIComponent(search.replace(/^\?/, '')), {
    arrayFormat: 'bracket',
  });

/**
 * Finds the route that matches a given uri
 */
export const findMatchingRoute = ({
  routes,
  location,
}: {
  routes: Record<string, Route>;
  location: {
    pathname: string;
  };
}): Route | undefined => {
  return Object.values(routes).find(({ path }) =>
    Boolean(matchPath(path, location.pathname)),
  );
};

export const getUrlInfos = ({
  routes,
  location,
  prevLocation,
  project,
}: {
  routes: Record<AppRouteKeys, Route>;
  location: Location;
  prevLocation?: Location;
  project?: string;
}): UrlInfos | undefined => {
  // 1. retrieve the matching route definition objects
  const matchingRoute = Object.values(routes).find(({ path }) =>
    Boolean(matchPath(path, location.pathname)),
  );
  const previousMatchingRoute =
    prevLocation &&
    Object.values(routes).find(({ path }) =>
      Boolean(matchPath(path, prevLocation.pathname)),
    );
  if (!matchingRoute || matchingRoute.analytics.disablePageViews) {
    // Note: can be undefined if the route doesn't exist: should we report that?
    return;
  }

  // 2. match path against the found route to resolve params
  // Note: using TS non-null assertion (`!`) because if we have a route we
  // certainly have a route match
  // Note: we pass `company` in the generic for params because all our routes
  // contain at least a `company` ID
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const match = matchPath(matchingRoute.path, location.pathname)!;
  // Note: `prevMatchingRoute` doesn't exist if the user has been redirected
  // after trying to access a non existing route.
  const previousMatch =
    prevLocation && previousMatchingRoute
      ? matchPath(previousMatchingRoute.path, prevLocation.pathname)
      : null;
  let referrer = null;
  if (previousMatchingRoute && previousMatch) {
    referrer = previousMatchingRoute.path;
  } else if (document.referrer?.length > 0) {
    referrer = document.referrer;
  }
  const category =
    typeof matchingRoute.analytics.category === 'function'
      ? matchingRoute.analytics.category(match.params)
      : matchingRoute.analytics.category;
  const name =
    typeof matchingRoute.analytics.name === 'function'
      ? matchingRoute.analytics.name(match.params)
      : matchingRoute.analytics.name;
  const integrations =
    typeof matchingRoute.analytics.integrations === 'function'
      ? matchingRoute.analytics.integrations(match.params)
      : matchingRoute.analytics.integrations;
  // 3. build the UrlInfos
  const urlInfos: UrlInfos = {
    category: matchingRoute.analytics.useCategoryPrefix
      ? `${project} - ${category}`
      : category,
    name: matchingRoute.analytics.useNamePrefix ? `${project} - ${name}` : name,
    properties: {
      title: [project, category, name].filter(Boolean).join(' - '),
      path: matchingRoute.path,
      referrer,
    },
    integrations,
  };

  return urlInfos;
};
