import { type I18nKey } from 'src/core/common/hooks/useTranslation';
import { rejectUnexpectedValue } from 'src/core/utils/switchGuard';

import { type AccountingSoftware } from '../../../../../../integration/status';
import { type SupplierAccountDefaultFor } from '../../../../../accounting';

export function getSupplierAccountsDescriptionI18nKey(
  integration: AccountingSoftware,
): string {
  switch (integration) {
    case 'Xero':
      return 'bookkeep.integrations.xero.supplierAccountsDescription';
    case 'Datev':
      return 'bookkeep.integrations.datev.supplierAccountsDescription';
    case 'SpendeskAccounting':
    case 'SpendeskAccountingSingleEntry':
    case 'Sage':
    case 'Cegid':
      return 'bookkeep.integrations.settings.sectionSupplierAccountsDescription';
    case 'Netsuite':
      return 'bookkeep.integrations.netsuite.supplierAccountsDescription';
    case 'Quickbooks':
      return 'quickbooks';
    case 'Sage100':
    case 'ACD':
    case 'Odoo':
    case 'ExactOnlineFr':
      return 'bookkeep.integrations.chift.supplierAccountsDescription';
    default:
      rejectUnexpectedValue('integration type', integration);
  }
}

export function getDefaultSupplierAccountPlaceholderI18nKey(
  integration: AccountingSoftware,
): I18nKey {
  switch (integration) {
    case 'Netsuite':
      return 'bookkeep.integrations.netsuite.defaultSupplierAccountPlaceholder';
    case 'Quickbooks':
      return 'bookkeep.integrations.quickbooks';
    case 'Xero':
      return 'bookkeep.integrations.xero';
    case 'Datev':
      return 'bookkeep.integrations.datev';
    case 'SpendeskAccounting':
      return 'bookkeep.integrations';
    case 'SpendeskAccountingSingleEntry':
      return 'bookkeep.integrations';
    case 'Sage':
      return 'bookkeep.integrations.sage';
    case 'Cegid':
      return 'bookkeep.integrations.cegid';
    case 'Sage100':
    case 'ACD':
    case 'Odoo':
    case 'ExactOnlineFr':
      return 'bookkeep.integrations.chift.defaultSupplierAccountPlaceholder';
    default:
      rejectUnexpectedValue('integration type', integration);
  }
}

export function getTitleTranslation(
  defaultFor: SupplierAccountDefaultFor,
): I18nKey {
  switch (defaultFor) {
    case 'cardSupplier':
      return 'bookkeep.integrations.settings.defaultSupplierAccountForm.cardTitle';
    case 'invoiceSupplier':
      return 'bookkeep.integrations.settings.defaultSupplierAccountForm.invoiceTitle';
    default:
      rejectUnexpectedValue('integration type', defaultFor);
  }
}

export function getDescriptionTranslation(
  defaultFor: SupplierAccountDefaultFor,
): I18nKey {
  switch (defaultFor) {
    case 'cardSupplier':
      return 'bookkeep.integrations.settings.defaultSupplierAccountForm.cardDescription';
    case 'invoiceSupplier':
      return 'bookkeep.integrations.settings.defaultSupplierAccountForm.invoiceDescription';
    default:
      rejectUnexpectedValue('integration type', defaultFor);
  }
}
