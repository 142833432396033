import { IconButton, Icon, colors } from '@dev-spendesk/grapes';
import { Handle, Position, type NodeProps } from '@xyflow/react';
import classNames from 'classnames';

import { useWorkflowContext } from '../../WorkflowContext';

export type BaseNodeData = {
  schemeId?: string;
};

type Props = NodeProps;

const handleStyle = {
  background: 'transparent',
  border: 'none',
  width: 0,
  height: 0,
};

export const BaseNode = ({ id }: Props) => {
  const { createAndConnectNode, setSelectedNodeId, selectedNodeId } =
    useWorkflowContext();
  const isSelected = selectedNodeId === id;

  return (
    <div className="flex items-center">
      <div
        className={classNames(
          'w-[360px] rounded border border-solid p-s',
          isSelected
            ? 'border-solid border-primary bg-primary-lightest'
            : 'border-neutral-lightest bg-white',
        )}
      >
        <div className="flex items-center justify-between">
          <div>
            <div className="flex items-center gap-xs">
              <div className="flex h-m w-m items-center justify-center rounded-[50%] border border-solid border-primary-lighter bg-primary-lightest">
                <Icon color={colors.primary} name="airplane" size="s" />
              </div>
              <div className="text-complementary body-s">Spend type is</div>
            </div>
          </div>
          <div className="flex">
            <IconButton
              aria-label=""
              iconColor={colors.complementary}
              onClick={() => {
                setSelectedNodeId(id);
              }}
              iconName="pen"
            />
          </div>
        </div>

        <div className="box mt-s p-m">
          <div className="flex items-center gap-xs">
            <div className="flex h-m w-m items-center justify-center rounded-[50%] border border-solid border-neutral-light bg-neutral-lightest">
              <Icon color={colors.neutralDark} name="magic-wand" size="s" />
            </div>
            <div className="text-neutral-darker title-s">
              Approval is required from
            </div>
          </div>
        </div>
      </div>
      <IconButton
        variant="borderless"
        onClick={() => {
          createAndConnectNode({
            sourceNodeId: id,
          });
        }}
        iconName="plus-circle"
        aria-label="plus"
      />
      <Handle
        style={handleStyle}
        type="target"
        id="leftHandle"
        position={Position.Left}
      />
      <Handle
        style={handleStyle}
        type="source"
        id="rightHandle"
        position={Position.Right}
      />
    </div>
  );
};
