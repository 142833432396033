import { Callout, SkeletonText } from '@dev-spendesk/grapes';

import { useCompany } from 'modules/app/hooks/useCompany';
import { QueryError } from 'src/core/common/components/QueryError';
import { QuerySuspense } from 'src/core/common/components/QuerySuspense';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { formatMoney } from 'src/core/utils/money';

import { useSpendLimitsQuery } from '../../hooks/useSpendLimitsQuery';

export const GeneralLimitCallout = ({
  expenseCategoryId,
}: {
  expenseCategoryId: string;
}) => {
  const { t } = useTranslation('global');
  const spendLimitsQueryState = useSpendLimitsQuery();
  const company = useCompany();

  return (
    <QuerySuspense
      loading={<SkeletonText size="xxl" />}
      queryState={spendLimitsQueryState}
      fallback={(error) => (
        <QueryError queryError={error} componentType="Callout" />
      )}
    >
      {(spendLimits) => {
        const spendLimit = spendLimits.find(
          (limit) => limit.customFieldValueId === expenseCategoryId,
        );
        const titleKey =
          spendLimit?.period === 'day'
            ? 'spendLimits.modal.request.preRequestCallout.titleDay'
            : 'spendLimits.modal.request.preRequestCallout.titleMonth';
        if (spendLimit) {
          return (
            <Callout
              variant="info"
              title={t(titleKey, {
                amount: formatMoney(spendLimit.amount, company.currency),
              })}
            />
          );
        }
        return null;
      }}
    </QuerySuspense>
  );
};
