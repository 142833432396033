import * as R from '@dev-spendesk/general-type-helpers/Result';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useUpdatePayableFieldValuesQueryCache } from 'modules/payable/hooks';
import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';
import { type Translations } from 'src/core/common/components/QueryError';
import { useUpdateGetPayableQueryCache } from 'src/core/modules/bookkeep/apis/prepare-payable/useGetPayableQuery';
import { rejectUnexpectedValue } from 'src/core/utils/switchGuard';

export type CreateOrUpdateAccountPayableFailureReason =
  | 'accountNotFound'
  | 'adapterMismatch'
  | 'codeAlreadyExists'
  | 'nameAndRateAlreadyExists'
  | 'emptyCode'
  | 'emptyName'
  | 'accountMutationDisabled'
  | 'negativeTaxRate'
  | 'taxRateExceedsMaximum'
  | 'nothingToSet'
  | 'emptyMemberIds'
  | 'duplicatesInInput'
  | 'integrationValidationFailed'
  | 'invalidAccountId'
  | 'unknownReason';

export type CreateOrUpdateAccountPayableError = void;

type ExistingAccount = {
  id: string;
  kind: 'employeeAccount' | 'supplierAccount';
  isDefault: boolean;
  generalAccountCode: string;
  auxiliaryAccountCode?: string;
};

export type CreateOrUpdateAccountPayableRawResponse =
  | { outcome: 'set'; accounts: { id: string }[] }
  | {
      outcome: 'notSet';
      reason: CreateOrUpdateAccountPayableFailureReason;
      existingAccount?: ExistingAccount;
    }
  | {
      outcome: 'partiallySet';
      setAccounts: { id: string }[];
      notSetAccounts: {
        account: { id: string };
        reason: CreateOrUpdateAccountPayableFailureReason;
        existingAccount?: ExistingAccount;
      }[];
    };

export type CreateOrUpdateAccountPayableResponse = R.Result<
  {
    reason: CreateOrUpdateAccountPayableFailureReason;
    existingAccount?: ExistingAccount;
  },
  { accountPayableId: string }
>;

export type CreateOrUpdateAccountPayablePayload = {
  id?: string;
  name?: string;
  generalAccountCode: string;
  auxiliaryAccountCode?: string | undefined;
  isArchived: boolean;
  memberIds?: string[];
  supplierIds?: string[];
};

/**
 * Mutation
 **/

export const useCreateOrUpdateAccountPayable = (
  kind: 'supplierAccount' | 'employeeAccount',
  payableIdToReset?: string,
): MutationState<
  CreateOrUpdateAccountPayablePayload,
  CreateOrUpdateAccountPayableResponse,
  CreateOrUpdateAccountPayableError
> => {
  const companyId = useCompanyId();
  const updateFieldValuesQueryCache = useUpdatePayableFieldValuesQueryCache();
  const updateGetPayableQuery = useUpdateGetPayableQueryCache();

  return useMutation<
    CreateOrUpdateAccountPayablePayload,
    CreateOrUpdateAccountPayableResponse,
    CreateOrUpdateAccountPayableRawResponse,
    CreateOrUpdateAccountPayableError
  >({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/accounting-integration/chart-of-accounts/${(() => {
        switch (kind) {
          case 'supplierAccount':
            return 'supplier-accounts';
          case 'employeeAccount':
            return 'employee-accounts';
          default:
            rejectUnexpectedValue('Unknown account payable kind', kind);
        }
      })()}`,
      method: 'post',
    },
    options: {
      throwOnError: true,
      onSuccess: ({ client, data, payload }) => {
        if (R.isSuccess(data)) {
          if (kind === 'supplierAccount') {
            updateFieldValuesQueryCache(companyId, {
              action: 'ADD_SUPPLIER_ACCOUNT',
              value: {
                id: data.value.accountPayableId,
                ...payload,
              },
            });
          }

          if (payableIdToReset) {
            updateGetPayableQuery(companyId, payableIdToReset, {
              accountPayable: {
                id: data.value.accountPayableId,
                ...payload,
              },
            });
          }
        }

        return Promise.all([
          // account payables in Bookkeep > Prepare > SupplierAccount field
          client.invalidateQueries(['useGetSupplierAccountsQuery', companyId]),
          // account payables in Settings > Account payables
          client.invalidateQueries(['accountsPayable']),
          client.invalidateQueries(['useIntegrationStatusQuery']),
          client.invalidateQueries(['getDefaultEmployeeAccountQuery']),
          client.invalidateQueries(['useGetDefaultEmployeeAccountQuery']),
          client.invalidateQueries(['useGetUsersWithoutEmployeeAccountQuery']),
        ]);
      },
    },
    reshapeData(data) {
      if (data.outcome === 'notSet') {
        return R.toFailure({
          reason: data.reason,
          existingAccount: data.existingAccount,
        });
      }

      if (data.outcome === 'partiallySet') {
        const firstNotSetAccount = data.notSetAccounts[0];
        const reason = firstNotSetAccount?.reason ?? 'unknownReason';
        return R.toFailure({
          reason,
          existingAccount: firstNotSetAccount?.existingAccount,
        });
      }

      const accountPayableId = data.accounts[0]?.id;

      if (!accountPayableId) {
        return R.toFailure({ reason: 'invalidAccountId' });
      }

      return R.toSuccess({ accountPayableId });
    },
  });
};

/**
 * Error messages
 **/
/** @knipignore */
export const updateAccountPayableErrorTranslations: Translations<CreateOrUpdateAccountPayableError> =
  {
    requestError: () => 'accounting-integration.api.updateAccountPayable.error',
    serverError: 'accounting-integration.api.updateAccountPayable.error',
  };
