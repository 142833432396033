import { apiV2Url } from 'src/core/utils/api';

type Payload = {
  customFieldValueId: string;
  amount: number;
  period: 'day' | 'month';
};

export const createSpendLimit = async ({
  payload,
  companyId,
}: {
  payload: Payload;
  companyId: string;
}): Promise<void> => {
  const response = await fetch(apiV2Url('/spend-limits', companyId), {
    method: 'POST',
    credentials: 'include',
    headers: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
  if (!response.ok) {
    throw new Error('Impossible to edit an approval scheme');
  }
};
