import React from 'react';

import { useIntegrationStatusQuery } from 'src/core/modules/bookkeep/hooks';

import { AuthSection } from '../LegacyIntegrationsAccountingPage/sections/AuthSection';
import { withIntegrationAccounting } from '../withIntegrationAccounting';
import './IntegrationAccountingPage.css';

const IntegrationsAccountingSetupContent = () => {
  const accountingIntegrationStatusQueryResult = useIntegrationStatusQuery();

  // Other statuses are managed in the HOC
  if (accountingIntegrationStatusQueryResult.status !== 'success') {
    return null;
  }

  return <AuthSection status={accountingIntegrationStatusQueryResult.data} />;
};

export const IntegrationsAccountingSetupPage = withIntegrationAccounting(
  IntegrationsAccountingSetupContent,
);
