import { FormField, TextInput } from '@dev-spendesk/grapes';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

type Props = {
  cardSupplierCode: string;
  invoiceSupplierCode: string;
  onCardSupplierCodeChange: (code: string) => void;
  onInvoiceSupplierCodeChange: (code: string) => void;
};

export const DefaultSupplierAccountsForm = ({
  cardSupplierCode,
  invoiceSupplierCode,
  onCardSupplierCodeChange,
  onInvoiceSupplierCodeChange,
}: Props) => {
  const { t } = useTranslation('global');

  return (
    <div className="flex flex-col gap-s">
      <div className="rounded-xxs bg-page-background px-m py-s">
        <FormField
          label={t('setupHub.supplierAccounts.cardSupplier.label')}
          description={t('setupHub.supplierAccounts.cardSupplier.description')}
          hint={t('misc.optional')}
        >
          <TextInput
            onChange={(event) => onCardSupplierCodeChange(event.target.value)}
            placeholder={t(
              'setupHub.supplierAccounts.cardSupplier.placeholder',
            )}
            value={cardSupplierCode}
          />
        </FormField>
      </div>
      <div className="flex items-center gap-s">
        <div className="separator bg-neutral-light" />
        <p className="uppercase text-complementary body-l">{t('misc.and')}</p>
        <div className="separator bg-neutral-light" />
      </div>
      <div className="rounded-xxs bg-page-background px-m py-s">
        <FormField
          label={t('setupHub.supplierAccounts.invoiceSupplier.label')}
          description={t(
            'setupHub.supplierAccounts.invoiceSupplier.description',
          )}
          hint={t('misc.optional')}
        >
          <TextInput
            onChange={(event) =>
              onInvoiceSupplierCodeChange(event.target.value)
            }
            placeholder={t(
              'setupHub.supplierAccounts.invoiceSupplier.placeholder',
            )}
            value={invoiceSupplierCode}
          />
        </FormField>
      </div>
    </div>
  );
};
