import {
  Callout,
  FormField,
  RadioField,
  RadioGroup,
  TextInput,
} from '@dev-spendesk/grapes';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { NotificationType, useNotifications } from 'modules/app/notifications';
import { useCostCentersQuery } from 'modules/budgets/apis';
import { MultipleCostCenterAutocomplete } from 'modules/company/cost-centers/components/MultipleCostCenterAutocomplete';
import { useCreateEcQuery } from 'modules/company/expense-categories/containers/EcFormModalContainer/hooks/useCreateEcQuery';
import { useUpdateCachedTaskStatus } from 'modules/onboarding/setup-hub/pages/SetupHubPage/hooks/useUpdateCachedTaskStatus';
import { unwrapQuery } from 'src/core/api/unwrapQuery';
import { routeFor } from 'src/core/constants/routes';
import { useExpenseCategoriesQuery } from 'src/core/modules/company/expense-categories/containers/EcContainer/hooks/useExpenseCategoriesQuery';

import { validate } from './form';
import { type FormValues } from './types';
import { SetupHubLayout } from '../../../components/SetupHubLayout';
import { VideoBlock } from '../../../components/VideoBlock';
import { routes } from '../../routes';

export const ControlSettingsNewExpenseCategory = () => {
  const { t } = useTranslation('global');
  const history = useHistory();
  const { pushNotif } = useNotifications();

  const companyId = useCompanyId();

  const updateCachedTaskStatus = useUpdateCachedTaskStatus();
  const costCentersQueryState = useCostCentersQuery();
  const costCenters = unwrapQuery(costCentersQueryState) || [];
  const [createExpenseCategory] = useCreateEcQuery();

  const expenseCategoriesQueryState = useExpenseCategoriesQuery();
  const expenseCategories = unwrapQuery(expenseCategoriesQueryState) || [];

  const [hasSubmittedOnce, setHasSubmittedOnce] = useState<boolean>(false);

  const goBackToHub = () => {
    const hubPath = routeFor(routes.SETUP_HUB_TASK_LIST.path, {
      company: companyId,
    });
    history.push(hubPath);
  };

  const { errors, values, handleChange, handleSubmit, setFieldValue } =
    useFormik<FormValues>({
      initialValues: {
        name: '',
        costCenterIds: [],
        assignToAllCostCenters: true,
      },
      validate: (valuesToValidate) =>
        validate({
          values: valuesToValidate,
          usedNames: expenseCategories.map(({ name }) => name),
          t,
        }),
      validateOnChange: hasSubmittedOnce,
      onSubmit: async (expenseCategoryValues) => {
        try {
          const payload = {
            name: expenseCategoryValues.name.trim(),
            costCenterIds: expenseCategoryValues.assignToAllCostCenters
              ? []
              : expenseCategoryValues.costCenterIds,
          };
          await createExpenseCategory(payload);

          updateCachedTaskStatus('expense_categories');
          const nextPagePath = routeFor(
            routes.CONTROL_SETTINGS_EXPENSE_CATEGORY_LIST.path,
            {
              company: companyId,
            },
          );
          history.push(nextPagePath);
        } catch {
          pushNotif({
            type: NotificationType.Danger,
            message: t('misc.errors.unknownError'),
          });
        }
      },
    });

  const onContinue = async () => {
    if (!hasSubmittedOnce) {
      setHasSubmittedOnce(true);
    }
    handleSubmit();
  };

  return (
    <SetupHubLayout
      onBack={goBackToHub}
      onNext={onContinue}
      onNextLabel={t('setupHub.newExpenseCategory.onNext')}
      rightContent={
        <VideoBlock
          id="expense_categories"
          title={t('setupHub.newExpenseCategory.video.title')}
          description={t('setupHub.newExpenseCategory.video.description')}
        />
      }
      scope="new-expense-category"
      stepIndex={0}
      stepTotal={2}
      title={t('setupHub.newExpenseCategory.title')}
    >
      <>
        <Callout
          className="mb-m"
          title={t('setupHub.newExpenseCategory.callout.title')}
        >
          <p>{t('setupHub.newExpenseCategory.callout.description')}</p>
        </Callout>
        <form onSubmit={handleSubmit}>
          <FormField
            className="mb-m"
            label={t('expenseCategories.form.name')}
            alertMessage={errors.name}
          >
            <TextInput
              name="name"
              onChange={handleChange}
              placeholder={t('controlRulesModal.namePlaceholder')}
              value={values.name}
            />
          </FormField>
          <FormField
            label={t('expenseCategories.form.costCenterAssignationFieldName')}
            description={t(
              'expenseCategories.form.costCenterAssignationFieldDescription',
            )}
          >
            <RadioGroup
              name="assignToAllCostCenters"
              value={values.assignToAllCostCenters ? 'true' : 'false'}
              onChange={(event) =>
                setFieldValue(
                  'assignToAllCostCenters',
                  event.target.value === 'true',
                )
              }
            >
              <div className="flex gap-m px-xs">
                <RadioField
                  value="true"
                  label={t('expenseCategories.form.costCenterAssignation.all')}
                />
                <RadioField
                  value="false"
                  label={t('expenseCategories.form.costCenterAssignation.some')}
                />
              </div>
            </RadioGroup>
          </FormField>
          {!values.assignToAllCostCenters && (
            <FormField
              className="mt-xs"
              label={t('misc.costCenter')}
              visuallyHideLabel
              alertMessage={errors.costCenterIds as string | undefined}
            >
              <MultipleCostCenterAutocomplete
                costCenters={costCenters}
                selectedCostCenterIds={values.costCenterIds}
                onSelect={(options) => {
                  setFieldValue(
                    'costCenterIds',
                    options.map((option) => option.key),
                  );
                }}
              />
            </FormField>
          )}
        </form>
      </>
    </SetupHubLayout>
  );
};
