import { type GetInboxInvoicesResponse } from '@dev-spendesk/spendesk-types';

import { useQuery } from 'src/core/api/hooks/useQuery';
import { useQueryStates } from 'src/core/api/hooks/useQueryStates';
import { type QueryState } from 'src/core/api/queryState';

import { type Filter } from '../../../inbox-invoices/apis/useInboxInvoicesInfiniteQuery';

type InboxInvoicesStats = {
  toAssign: number;
  awaiting: number;
};

export const useOrganisationEntityInboxInvoicesQuery = (
  companyId: string,
): QueryState<InboxInvoicesStats> => {
  return useQueryStates({
    states: {
      invoicesToAssign: useInboxInvoicesQuery(companyId, 'received'),
      invoicesAssigned: useInboxInvoicesQuery(companyId, 'assigned'),
    },
    reshapeData: ({ invoicesToAssign, invoicesAssigned }) => ({
      toAssign: invoicesToAssign.meta.pagination.total,
      awaiting: invoicesAssigned.meta.pagination.total,
    }),
  });
};

const useInboxInvoicesQuery = (
  companyId: string,
  filter: Filter,
): QueryState<GetInboxInvoicesResponse> => {
  return useQuery({
    key: ['organisation-reporting', companyId, 'inbox-invoices', filter],
    request: {
      type: 'rest',
      target: 'baseAPI',
      endpoint: `/${companyId}/inbox-invoices?status=${filter}`,
    },
  });
};
