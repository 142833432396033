import { useNotifications, NotificationType } from 'modules/app/notifications';
import { useCostCentersQuery } from 'modules/budgets/apis';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { useCostCenterReplacePreviewQuery } from './hooks/useCostCenterReplacePreviewQuery';
import { useDeleteCostCenterQuery } from './hooks/useDeleteCostCenterQuery';
import { useReplaceCostCenterQuery } from './hooks/useReplaceCostCenterQuery';
import { CostCenterDeletionModal } from '../../components/CostCenterDeletionModal/CostCenterDeletionModal';
import type { CostCenterWithBudgets } from '../../costCenter';

type Props = {
  costCenter: CostCenterWithBudgets;
  isOpen: boolean;
  onCloseModal(): void;
  onCancel(): void;
};

export const CostCenterDeletionModalContainer = ({
  costCenter,
  isOpen,
  onCloseModal,
  onCancel,
}: Props) => {
  const { pushNotif } = useNotifications();
  const { t } = useTranslation('global');
  const costCenterReplacePreviewQueryState = useCostCenterReplacePreviewQuery(
    costCenter.id,
  );
  const costCentersQueryState = useCostCentersQuery();
  const [replaceCostCenter] = useReplaceCostCenterQuery(costCenter.id);
  const [deleteCostCenter] = useDeleteCostCenterQuery(costCenter.id);

  if (
    costCentersQueryState.status === 'loading' ||
    costCenterReplacePreviewQueryState.status === 'loading'
  ) {
    // TODO: add loader
    return null;
  }

  if (
    costCentersQueryState.status === 'error' ||
    costCenterReplacePreviewQueryState.status === 'error'
  ) {
    pushNotif({
      type: NotificationType.Danger,
      message: t('costCenters.deletion.loadFailure'),
    });
    return null;
  }

  const availableSubstituteCostCenters = costCentersQueryState.data.filter(
    (cc) => cc.id !== costCenter.id,
  );

  const handleComplete = async (costCenterId: string | undefined) => {
    try {
      if (costCenterId) {
        await replaceCostCenter({ replacementId: costCenterId });
      } else {
        await deleteCostCenter();
      }
      pushNotif({
        type: NotificationType.Success,
        message: t('costCenters.deletion.notifSuccess', {
          name: costCenter.name,
        }),
        isClosable: true,
      });
      onCloseModal();
    } catch {
      pushNotif({
        type: NotificationType.Danger,
        message: t('costCenters.deletion.notifFailure', {
          name: costCenter.name,
        }),
      });
    }
  };

  return (
    <CostCenterDeletionModal
      isOpen={isOpen}
      costCenter={{
        name: costCenter.name,
        hasBudgets: costCenter.hasBudgets,
        replacePreview: costCenterReplacePreviewQueryState.data,
      }}
      substituteCostCenters={availableSubstituteCostCenters}
      onComplete={handleComplete}
      onCancel={onCancel}
    />
  );
};
