import {
  Panel,
  Accordion,
  AccordionItem,
  Icon,
  colors,
  type AccordionHandle,
  Button,
  Tooltip,
  Callout,
} from '@dev-spendesk/grapes';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { type I18nKey, useTranslation } from 'common/hooks/useTranslation';
import { useUser } from 'modules/app/hooks/useUser';
import { CompanySetupTask } from 'modules/onboarding/onboarding-hub/pages/OnboardingHub/tasks/CompanySetupTask';
import { KycTask } from 'modules/onboarding/onboarding-hub/pages/OnboardingHub/tasks/KycTask';
import { MemberInviteTask } from 'modules/onboarding/onboarding-hub/pages/OnboardingHub/tasks/MemberInviteTask';
import { WalletFundingTask } from 'modules/onboarding/onboarding-hub/pages/OnboardingHub/tasks/WalletFundingTask';
import { HubTaskItem } from 'modules/onboarding/setup-hub/pages/SetupHubPage/tasks/HubTaskItem';
import { UserTaskItem } from 'modules/onboarding/setup-hub/pages/SetupHubPage/tasks/UserTaskItem';
import type { UserTask } from 'modules/onboarding/setup-hub/types/userTask';
import { type HubSection } from 'modules/onboarding/types/hubSection';
import type { HubTask } from 'modules/onboarding/types/hubTask';

import { useMemberQuery } from './hooks/useMemberQuery';
import { TransferSectionOwnershipModal } from '../../../../onboarding-hub/components/TransferSectionOwnershipModal';

type Props = {
  section: HubSection<HubTask | UserTask>;
  title: I18nKey;
  isOpen?: boolean;
};

export const HubSectionWrapper = ({ section, title, isOpen }: Props) => {
  const { t } = useTranslation('global');
  const user = useUser();

  const accordionReference = useRef<AccordionHandle | null>(null);

  const completedTasksNumber = useMemo(
    () => section.tasks.filter((task) => task.status === 'completed').length,
    [section.tasks],
  );
  const isCompleted = completedTasksNumber === section.tasks.length;

  const [isTransferModalOpened, setIsTransferModalOpened] = useState(false);

  const assigneeId = useMemo(
    () =>
      (
        section.tasks.find(
          (task) => 'assignedUserId' in task && task.assignedUserId,
        ) as HubTask | null
      )?.assignedUserId ?? null,
    [section],
  );
  const memberQuery = useMemberQuery(assigneeId);
  const assigneeEmail = useMemo(() => {
    return memberQuery.status === 'success' ? memberQuery.data.email : null;
  }, [assigneeId, memberQuery]);

  const areAllTasksDisabled = section.tasks.every(
    (task) => task.status === 'disabled',
  );

  const isTransferEnabled =
    section.isTransferable &&
    !areAllTasksDisabled &&
    !completedTasksNumber &&
    (!assigneeId || (user.is_account_owner && assigneeId === user.id));

  useEffect(() => {
    if (isOpen && accordionReference.current) {
      accordionReference.current.openAccordion(new Set([0]));
    }
  }, [accordionReference, isOpen]);

  return (
    <>
      <Panel className="w-full">
        <Accordion ref={accordionReference}>
          <AccordionItem
            renderHeader={() => (
              <div className="mr-s flex w-full flex-row items-center justify-between">
                <div className="flex items-center gap-xs">
                  {isCompleted && (
                    <Icon name="success" size="l" color={colors.success} />
                  )}
                  <h2 className="title-xl">{t(title)}</h2>
                </div>
                {section.isTransferable && (
                  <Tooltip
                    content={
                      !isTransferEnabled && completedTasksNumber >= 1
                        ? t('onboardingHub.misc.taskTransferDisabledMessage')
                        : ''
                    }
                    triggerAsChild
                  >
                    <Button
                      text={t('onboardingHub.misc.taskTransferCta')}
                      variant="secondary"
                      isDisabled={!isTransferEnabled}
                      onClick={() => setIsTransferModalOpened(true)}
                    />
                  </Tooltip>
                )}

                {!section.isTransferable && section.tasks.length > 1 && (
                  <span className="body-l">
                    {t('onboardingHub.misc.completedTasks', {
                      count: completedTasksNumber,
                      totalNumber: section.tasks.length,
                    })}
                  </span>
                )}
              </div>
            )}
          >
            <div className="flex flex-col gap-m px-m pb-s">
              {section.tasks.map((task) => {
                if (
                  ['control_settings', 'accounting'].includes(
                    (task as HubTask).category,
                  )
                ) {
                  return (
                    <HubTaskItem
                      key={task.id}
                      task={task as HubTask}
                      sectionId={section.id}
                    />
                  );
                }
                switch (task?.type) {
                  case 'kyc_review':
                    return <KycTask key={task.id} task={task} />;
                  case 'wallet_funding':
                    return <WalletFundingTask key={task.id} task={task} />;
                  case 'company_setup':
                    return <CompanySetupTask key={task.id} task={task} />;
                  case 'member_invite':
                    return <MemberInviteTask key={task.id} task={task} />;
                  case 'download_app':
                  case 'subscription':
                  case 'single_use':
                  case 'expense_claim':
                  case 'upload_receipt':
                  case 'learn_to_approve':
                    return (
                      <UserTaskItem
                        key={task.id}
                        task={task}
                        sectionId={section.id}
                      />
                    );
                  default:
                    return null;
                }
              })}
            </div>
          </AccordionItem>
        </Accordion>
      </Panel>
      {assigneeId && assigneeId !== user.id && (
        <Callout
          title={t('onboardingHub.misc.taskTransferredToInfo', {
            email: assigneeEmail,
          })}
        />
      )}
      {isTransferEnabled && (
        <TransferSectionOwnershipModal
          section={section as HubSection<HubTask>}
          isOpen={isTransferModalOpened}
          onClose={() => setIsTransferModalOpened(false)}
        />
      )}
    </>
  );
};
