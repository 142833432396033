const buildAnchorElement = (
  url: string,
  filename: string,
): HTMLAnchorElement => {
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = filename;
  return a;
};

const triggerDownload = (a: HTMLAnchorElement): void => {
  document.body.append(a);
  a.click();
};

export const downloadFromBlob = (blob: Blob, filename: string = ''): void => {
  const url = URL.createObjectURL(blob);
  const a = buildAnchorElement(url, filename);
  triggerDownload(a);
  a.remove();
  window.URL.revokeObjectURL(url);
};

export const downloadFromUrl = (url: string, filename: string = ''): void => {
  const a = buildAnchorElement(url, filename);
  triggerDownload(a);
  a.remove();
};
