import { useFormik } from 'formik';

import { useCompany } from 'modules/app/hooks/useCompany';
import { NotificationType, useNotifications } from 'modules/app/notifications';
import {
  type BankDetailsFormValues,
  type SupplierDetails,
} from 'modules/bookkeep/accounts-payable/types';
import { PanelItemsSection } from 'src/core/common/components/Panel';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { COUNTRIES } from 'src/core/config/country';
import { BankFields } from 'src/core/utils/bankInfoFormats';
import { useBankInfoFieldsCombo } from 'src/core/utils/useBankInfo';

import {
  AccountPayableSuppliersBankFields,
  validateBankDetailsForm,
} from '../../../components/AccountPayableSuppliersBankFields';
import { useUpdateSupplierDetailsMutation } from '../../../hooks/useUpdateSupplierDetailsMutation';

type Props = {
  supplier: SupplierDetails;
};

export const AccountPayableSuppliersPanelBankDetailsSection = ({
  supplier,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
Props) => {
  const { t } = useTranslation('global');
  const company = useCompany();
  const { pushNotif } = useNotifications();

  const [updateSupplierDetailsMutation] = useUpdateSupplierDetailsMutation();

  const getBankInfoFields = useBankInfoFieldsCombo();
  const bankInfoFields = getBankInfoFields(
    supplier.bankDetails?.bankCountry || null,
  );

  const initialValues = {
    bankCountry: supplier.bankDetails?.bankCountry ?? '',
    iban: supplier.bankDetails?.iban ?? '',
    bicSwift: supplier.bankDetails?.bicSwift ?? '',
    sortCode: supplier.bankDetails?.sortCode ?? '',
    routingNumber: supplier.bankDetails?.routingNumber ?? '',
    accountNumber: supplier.bankDetails?.accountNumber ?? '',
    accountCode: supplier.bankDetails?.accountCode ?? '',
    accountHolderName: supplier.accountHolderName ?? '',
  };

  const formikProps = useFormik<BankDetailsFormValues>({
    enableReinitialize: true,
    initialValues,
    validateOnChange: false,
    validate: (values: BankDetailsFormValues) => {
      return validateBankDetailsForm(company, values);
    },
    // eslint-disable-next-line sonarjs/cognitive-complexity
    onSubmit: async (values: BankDetailsFormValues) => {
      const submittedBankInfoFields = getBankInfoFields(
        values.bankCountry || null,
      );

      const updateSupplierDetailsMutationResult =
        await updateSupplierDetailsMutation({
          supplierId: supplier.id,
          accountHolderName:
            (submittedBankInfoFields.includes(BankFields.AccountHolderName) &&
              values.accountHolderName) ||
            '',
          bankDetails: {
            bankCountry: values.bankCountry,
            iban:
              (submittedBankInfoFields.includes(BankFields.Iban) &&
                values.iban) ||
              '',
            bicSwift:
              (submittedBankInfoFields.includes(BankFields.BicSwift) &&
                values.bicSwift) ||
              '',
            sortCode:
              (submittedBankInfoFields.includes(BankFields.SortCode) &&
                values.sortCode) ||
              '',
            routingNumber:
              (submittedBankInfoFields.includes(BankFields.RoutingNumber) &&
                values.routingNumber) ||
              '',
            accountNumber:
              (submittedBankInfoFields.includes(BankFields.AccountNumber) &&
                values.accountNumber) ||
              '',
            accountCode:
              (submittedBankInfoFields.includes(BankFields.AccountCode) &&
                values.accountCode) ||
              '',
          },
          legalDetails: supplier.legalDetails,
        });

      if (updateSupplierDetailsMutationResult.updateSupplierDetails.reason) {
        pushNotif({
          type: NotificationType.Danger,
          message: t(
            'bookkeep.accountsPayable.panel.bankDetailsSection.updateErrorNotification',
          ),
        });
      } else {
        pushNotif({
          type: NotificationType.Success,
          message: t(
            'bookkeep.accountsPayable.panel.bankDetailsSection.updateSuccessNotification',
          ),
        });
      }
    },
  });

  return (
    <PanelItemsSection
      data-testid="bank-details-section"
      title={t('bookkeep.accountsPayable.panel.bankDetailsSection.title')}
      items={[
        ...(bankInfoFields.includes(BankFields.AccountHolderName)
          ? [
              {
                label: t(
                  'bookkeep.accountsPayable.panel.bankDetailsSection.accountHolderNameLabel',
                ),
                value: supplier.accountHolderName || '-',
              },
            ]
          : []),
        {
          label: t(
            'bookkeep.accountsPayable.panel.bankDetailsSection.bankCountryLabel',
          ),
          value:
            supplier.bankDetails?.bankCountry &&
            COUNTRIES[supplier.bankDetails?.bankCountry]
              ? t(COUNTRIES[supplier.bankDetails?.bankCountry].translationKey)
              : '-',
        },
        ...(bankInfoFields.includes(BankFields.AccountNumber)
          ? [
              {
                label: t(
                  'bookkeep.accountsPayable.panel.bankDetailsSection.accountNumberLabel',
                ),
                value: supplier.bankDetails?.accountNumber || '-',
              },
            ]
          : []),
        ...(bankInfoFields.includes(BankFields.AccountCode)
          ? [
              {
                label: t(
                  'bookkeep.accountsPayable.panel.bankDetailsSection.accountCodeLabel',
                ),
                value: supplier.bankDetails?.accountCode || '-',
              },
            ]
          : []),
        ...(bankInfoFields.includes(BankFields.Iban)
          ? [
              {
                label: t(
                  'bookkeep.accountsPayable.panel.bankDetailsSection.ibanLabel',
                ),
                value: supplier.bankDetails?.iban || '-',
              },
            ]
          : []),
        ...(bankInfoFields.includes(BankFields.BicSwift)
          ? [
              {
                label: t(
                  'bookkeep.accountsPayable.panel.bankDetailsSection.bicSwiftLabel',
                ),
                value: supplier.bankDetails?.bicSwift || '-',
              },
            ]
          : []),
        ...(bankInfoFields.includes(BankFields.SortCode)
          ? [
              {
                label: t(
                  'bookkeep.accountsPayable.panel.bankDetailsSection.sortCodeLabel',
                ),
                value: supplier.bankDetails?.sortCode || '-',
              },
            ]
          : []),
        ...(bankInfoFields.includes(BankFields.RoutingNumber)
          ? [
              {
                label: t(
                  'bookkeep.accountsPayable.panel.bankDetailsSection.routingNumberLabel',
                ),
                value: supplier.bankDetails?.routingNumber || '-',
              },
            ]
          : []),
      ]}
      isEditable
      cancelTranslation={t('misc.cancel')}
      saveTranslation={t('misc.saveChanges')}
      editSection={
        <form className="AccountPayableSuppliersPanelBankDetailsForm">
          <AccountPayableSuppliersBankFields {...formikProps} />
        </form>
      }
      onCancel={() => {
        formikProps.resetForm();
      }}
      onSave={async () => {
        // this will trigger 2 form validations
        // it is due to a formik bug that doesn't reject the promise when calling submitForm and there are validation errors
        // https://github.com/formium/formik/issues/1580
        const { submitForm, validateForm } = formikProps;
        const errors = await validateForm();
        const isValid = Object.keys(errors).length === 0;
        if (!isValid) {
          throw new Error('Validation error');
        }
        return submitForm();
      }}
    />
  );
};
