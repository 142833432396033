import isEmailAtSpendesk from './utils/isEmailAtSpendesk';
import { useCompany } from '../app/hooks/useCompany';
import { useSupervisor } from '../app/hooks/useSupervisor';
import { useUser } from '../app/hooks/useUser';

const DEFAULT_PORTAL_URL =
  'https://spendesk.atlassian.net/servicedesk/customer/portal/2/group/4/create/632';

// Fields names on jira's side ( it's the id of the input )
const jiraFieldsMap = {
  summary: 'summary',
  companyId: 'customfield_10049',
  companyName: 'customfield_10050',
  userEmail: 'customfield_10051',
  where: 'customfield_10048',
  bookkeep: 'customfield_10138',
  env: 'customfield_10243',
} as const;

type fieldKey = keyof typeof jiraFieldsMap;

function joinSSDPortalAndParams(
  portalURL: string,
  options: { [key in fieldKey]: string },
) {
  function generateKeyValue(key: fieldKey) {
    return `${jiraFieldsMap[key]}=${encodeURIComponent(options[key])}`;
  }

  const params = [
    generateKeyValue('summary'),
    generateKeyValue('companyId'),
    generateKeyValue('companyName'),
    generateKeyValue('userEmail'),
    generateKeyValue('where'),
    generateKeyValue('bookkeep'),
    generateKeyValue('env'),
  ];

  return `${portalURL}?${params.join('&')}`;
}

export const generateJiraLink = ({
  companyId,
  companyName,
  userEmail,
}: {
  companyId: string;
  companyName: string;
  userEmail: string;
}) => {
  return joinSSDPortalAndParams(DEFAULT_PORTAL_URL, {
    companyId,
    companyName,
    userEmail,
    where: window.location.href,
    summary: '',
    bookkeep: '10170', // value extracted from Jira's form. Inspect the dom, it's the input's ID
    env: appConfig.environmentName === 'production' ? '10663' : '10664',
  });
};

export const useJiraLink = () => {
  const user = useUser();
  const supervisor = useSupervisor();
  const company = useCompany();

  const realUser = supervisor ?? user;
  if (!isEmailAtSpendesk(realUser.email)) {
    return null;
  }

  return generateJiraLink({
    companyId: company.id,
    companyName: company.name,
    userEmail: user.email,
  });
};
