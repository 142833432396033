import { Button, Skeleton, SkeletonButton } from '@dev-spendesk/grapes';
import classnames from 'classnames';
import { useEffect } from 'react';

import { QuerySuspense } from 'src/core/common/components/QuerySuspense';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';
import { type useOrganisationEntityWalletSummaryQuery } from 'src/core/modules/organisation-reporting/hooks/data/useOrganisationEntityWalletSummaryQuery';
import { AnalyticEventName, track } from 'src/core/utils/analytics';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

import { WalletSummaryBreakdown } from './WalletSummaryBreakdown';
import { type EntityBasicInformation } from '../../../../types';
import { BreakdownGaugeSkeleton } from '../../../common/BreakdownGauge/BreakdownGaugeSkeleton';
import type { getEntityStatus, OnDataLoadedResult } from '../EntityListItem';
import { isEntityInactive } from '../types';

type Props = {
  entity: EntityBasicInformation;
  entityStatus: ReturnType<typeof getEntityStatus>;
  entityWalletSummaryQueryState: ReturnType<
    typeof useOrganisationEntityWalletSummaryQuery
  >;
  onWalletSummaryLoaded: (result: OnDataLoadedResult) => void;
};

export const EntityItemWalletSummary = ({
  entity,
  entityStatus,
  entityWalletSummaryQueryState,
  onWalletSummaryLoaded,
}: Props) => {
  const { t } = useTranslation('global');

  useEffect(() => {
    if (entityWalletSummaryQueryState.status === 'error') {
      onWalletSummaryLoaded({ entityData: entity, status: 'error' });
    } else if (entityWalletSummaryQueryState.status === 'success') {
      onWalletSummaryLoaded({
        entityData: { ...entity, ...entityWalletSummaryQueryState.data },
        status: 'success',
      });
    }
  }, [entityWalletSummaryQueryState.status]);

  return (
    <QuerySuspense
      queryState={entityWalletSummaryQueryState}
      loading={
        <div className="flex grow flex-col content-stretch gap-m">
          <div className="flex items-center justify-between">
            <div className="flex flex-col gap-xs">
              <p className="text-neutral-dark body-m">
                {t('organisation.reporting.page.entities.entity.walletBalance')}
              </p>
              <Skeleton width="120px" height="24px" />
            </div>
            <SkeletonButton />
          </div>
          <BreakdownGaugeSkeleton legendMatrix={[2, 2, 2]} />
        </div>
      }
      fallback={() => null}
    >
      {(entityWalletSummary) => {
        // TODO: Remove this when we want to display churned entities
        // See https://spendesk.atlassian.net/browse/ECO-395
        if (entity.hasChurned) {
          return null;
        }

        const isEntityActive = entityStatus === null;

        const isAvailableFundsLow =
          entityWalletSummary.breakdown.trend === 'warning';

        const isInactive = isEntityInactive(entityStatus);

        const onClick = () => {
          window.open(
            routeFor(routes.COMPANY_BANK_FUNDING.path, { company: entity.id }),
            '_blank',
          );
          track(
            AnalyticEventName.ORGANISATION_REPORTING_ADD_FUNDS_BUTTON_CLICKED,
          );
        };

        return (
          <div className="flex grow flex-col content-stretch gap-m">
            <div className="flex items-center justify-between">
              <div className="flex flex-col gap-xs">
                <p className="text-neutral-dark body-m">
                  {t(
                    'organisation.reporting.page.entities.entity.walletBalance',
                  )}
                </p>
                <p
                  className={classnames(
                    'leading-[24px] title-xl',
                    isInactive ? 'text-neutral' : 'text-complementary',
                  )}
                >
                  {formatMonetaryValue({
                    amount: entityWalletSummary.walletBalance,
                    currency: entity.currency,
                    precision: 2,
                  })}
                </p>
              </div>
              {isEntityActive && (
                <Button
                  className="inline-block text-nowrap"
                  variant={isAvailableFundsLow ? 'warning' : 'contrasted'}
                  isDisabled={isInactive}
                  text={t(
                    'organisation.reporting.page.entities.entity.walletBalanceAddFunds',
                  )}
                  onClick={onClick}
                />
              )}
            </div>
            <WalletSummaryBreakdown
              currency={entity.currency}
              breakdown={entityWalletSummary.breakdown}
              isInactive={isInactive && entityStatus !== 'churning'}
            />
          </div>
        );
      }}
    </QuerySuspense>
  );
};
