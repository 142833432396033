import { FormField, TextInput } from '@dev-spendesk/grapes';
import { type Dispatch, type SetStateAction } from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { type ModalState } from '../ModalState';
import styles from '../TaxAccountLocalOnlySection.module.css';

interface Props {
  formState: ModalState;
  setFormState: Dispatch<SetStateAction<ModalState>>;
}

export const SingleEntryReverseChargeAddEditForm = ({
  formState,
  setFormState,
}: Props) => {
  const { t } = useTranslation('global');

  return (
    <>
      {formState.kind === 'addReverseCharge' && (
        <>
          <FormField
            label={t(
              'bookkeep.integrations.settings.reverseChargeAccountsTable.accountName',
            )}
            className={styles.formField}
          >
            <TextInput
              value={formState.name}
              onChange={(e) =>
                setFormState({ ...formState, name: e.target.value })
              }
            />
          </FormField>
          <FormField
            label={t(
              'bookkeep.integrations.settings.reverseChargeAccountsTable.accountCode',
            )}
            className={styles.formField}
          >
            <TextInput
              value={formState.codeDebit}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  codeDebit: e.target.value,
                  codeCredit: e.target.value,
                  inputChanged: true,
                })
              }
            />
          </FormField>
        </>
      )}
    </>
  );
};
