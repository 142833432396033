import type { UserTaskType } from 'modules/onboarding/setup-hub/types/userTask';
import type { HubTaskType } from 'modules/onboarding/types/hubTask';

export const READY_TO_SPEND_TASKS: UserTaskType[] = [
  'subscription',
  'single_use',
  'expense_claim',
  'upload_receipt',
  'learn_to_approve',
  'download_app',
];

export const CONTROL_SETTINGS_TASKS: HubTaskType[] = [
  'physical_cards',
  'expense_categories',
  'analytical_fields',
  'control_rules',
];

export const INIT_ACCOUNTING_TASKS: HubTaskType[] = ['accounting_software'];

export const CHART_OF_ACCOUNTS_TASKS: HubTaskType[] = [
  'bank_account_codes',
  'expense_accounts_codes',
  'vat_codes',
  'employee_account_codes',
  'account_payable_codes',
];

export const EXPORTS_SETTINGS_TASKS: HubTaskType[] = [
  'journal_export',
  'receipt_file_name',
];

export const SETUP_HUB_TASKS = [
  ...READY_TO_SPEND_TASKS,
  ...CONTROL_SETTINGS_TASKS,
  ...CHART_OF_ACCOUNTS_TASKS,
  ...EXPORTS_SETTINGS_TASKS,
  ...INIT_ACCOUNTING_TASKS,
];
