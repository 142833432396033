import React, { useState } from 'react';

import { NotificationType, useNotifications } from 'modules/app/notifications';
import {
  type BankAccountDetailsFile,
  type SupplierDetails,
} from 'modules/bookkeep/accounts-payable/types';
import { PanelPreviewsSection } from 'src/core/common/components/Panel';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { fileToBase64, srcFromFile } from 'src/core/utils/files';

import { AccountPayableSuppliersFile } from '../../../components/AccountPayableSupplierFile';
import { useUpdateSupplierDetailsMutation } from '../../../hooks/useUpdateSupplierDetailsMutation';

type Props = {
  supplier: SupplierDetails;
};

export const AccountPayableSuppliersPanelBankProofSection = ({
  supplier,
}: Props) => {
  const { t } = useTranslation('global');
  const { pushNotif } = useNotifications();

  const [bankAccountDetailsFile, setBankAccountDetailsFile] =
    useState<BankAccountDetailsFile>(null);

  const [updateSupplierDetailsMutation] = useUpdateSupplierDetailsMutation();

  const getFileUrl = () => {
    if (bankAccountDetailsFile !== null) {
      if (bankAccountDetailsFile.action === 'delete') {
        return null;
      }
      if (bankAccountDetailsFile.action === 'upload') {
        return bankAccountDetailsFile.fileUrl;
      }
    }
    return supplier?.bankDetails?.bankAccountDetailsFileUrl;
  };

  const getFileMediaType = () => {
    if (bankAccountDetailsFile !== null) {
      if (bankAccountDetailsFile.action === 'delete') {
        return null;
      }
      if (bankAccountDetailsFile.action === 'upload') {
        return bankAccountDetailsFile.fileMediaType;
      }
    }
    return supplier?.bankDetails?.bankAccountDetailsMediaType;
  };

  const handleUploadBankAccountDetailsFile = (file: File) => {
    if (!file) {
      return;
    }
    setBankAccountDetailsFile({
      file,
      fileUrl: srcFromFile(file) ?? '',
      fileMediaType: file.type,
      action: 'upload',
    });
  };

  const handleDeleteBankAccountDetailsFile = () => {
    setBankAccountDetailsFile({
      action: 'delete',
    });
  };

  const submit = async () => {
    let bankAccountDetailsFileToUpload;
    if (
      bankAccountDetailsFile !== null &&
      bankAccountDetailsFile.action === 'upload'
    ) {
      bankAccountDetailsFileToUpload = await fileToBase64(
        bankAccountDetailsFile.file,
      );
    }

    if (bankAccountDetailsFile?.action === 'delete') {
      bankAccountDetailsFileToUpload = null;
    }

    const updateSupplierDetailsMutationResult =
      await updateSupplierDetailsMutation({
        supplierId: supplier.id,
        bankDetails: {
          bankCountry: supplier.bankDetails?.bankCountry,
          bankAccountDetailsFile: bankAccountDetailsFileToUpload,
        },
      });

    if (updateSupplierDetailsMutationResult.updateSupplierDetails.reason) {
      pushNotif({
        type: NotificationType.Danger,
        message: t(
          'bookkeep.accountsPayable.panel.bankProofSection.updateErrorNotification',
        ),
      });
    } else {
      pushNotif({
        type: NotificationType.Success,
        message: t(
          'bookkeep.accountsPayable.panel.bankProofSection.updateSuccessNotification',
        ),
      });
    }
  };

  return (
    <PanelPreviewsSection
      title={t('bookkeep.accountsPayable.panel.bankProofSection.title')}
      previews={
        supplier.bankDetails?.bankAccountDetailsFileUrl
          ? [
              <AccountPayableSuppliersFile
                key="bankProofFile"
                isEditing={false}
                previewName={t(
                  'bookkeep.accountsPayable.panel.bankProofSection.title',
                )}
                fileUrl={supplier.bankDetails?.bankAccountDetailsFileUrl}
                fileMediaType={
                  supplier.bankDetails?.bankAccountDetailsMediaType
                }
              />,
            ]
          : []
      }
      noPreviewContent={t(
        'bookkeep.accountsPayable.panel.bankProofSection.noBankProof',
      )}
      isEditable
      cancelTranslation={t('misc.cancel')}
      saveTranslation={t('misc.saveChanges')}
      editSection={
        <form className="AccountPayableSuppliersPanelBankProofForm">
          <AccountPayableSuppliersFile
            isEditing
            previewName={t(
              'bookkeep.accountsPayable.panel.bankProofSection.title',
            )}
            fileUrl={getFileUrl()}
            fileMediaType={getFileMediaType()}
            onUpload={(files: File[]) =>
              handleUploadBankAccountDetailsFile(files[0])
            }
            onDelete={handleDeleteBankAccountDetailsFile}
          />
        </form>
      }
      onCancel={() => {}}
      onSave={async () => {
        return submit();
      }}
    />
  );
};
