import { CheckboxField, RadioField, RadioGroup } from '@dev-spendesk/grapes';

import {
  type I18nKey,
  useTranslation,
} from 'src/core/common/hooks/useTranslation';

import {
  type AnalyticalLineOption,
  type RowDisplayOption,
  type ColumnDisplayOptions,
  analyticalLineOptions,
} from '../../../export';

type Props = {
  onDisplayOptionChange: (displayOptions: ColumnDisplayOptions) => void;
  isAnalyticalColumn: boolean;
  displayOptions?: ColumnDisplayOptions;
  rowDisplayOptions: RowDisplayOption[];
};

export const ColumnDisplayOptionsContainer = ({
  onDisplayOptionChange,
  isAnalyticalColumn,
  displayOptions,
  rowDisplayOptions,
}: Props) => {
  const { t } = useTranslation('global');

  if (!displayOptions) {
    displayOptions = {
      expense: { active: true },
      analytical: { active: !isAnalyticalColumn },
      vat: { active: true },
      supplier: { active: true },
    };
    onDisplayOptionChange(displayOptions);
  }

  return (
    <div className="flex flex-col">
      {rowDisplayOptions
        .filter((r) => r.active)
        .map(({ group, analyticalCommonLine }) => {
          const isChecked = !!(
            displayOptions &&
            displayOptions[group] &&
            displayOptions[group].active
          );

          const isAnalyticalColumnActiveForAnalyticalColumn =
            isAnalyticalColumn &&
            group === 'analytical' &&
            displayOptions?.analytical.active;

          // If analytical column is active and no selection, set first item as selected
          if (
            isAnalyticalColumnActiveForAnalyticalColumn &&
            !displayOptions?.analytical.analyticalOption &&
            displayOptions
          ) {
            displayOptions.analytical.analyticalOption =
              analyticalLineOptions[0];
            onDisplayOptionChange(displayOptions);
          }

          return (
            <>
              <CheckboxField
                isChecked={isChecked}
                key={group}
                onChange={(e) => {
                  if (!displayOptions) {
                    return;
                  }
                  const displayOption = displayOptions[group];
                  if (displayOption) {
                    displayOption.active = e.target.checked;
                  }
                  onDisplayOptionChange(displayOptions);
                }}
                label={t(groupToI18nKey[group])}
                className="AutoCompleteMulti__list__option__checkbox w-[436px]"
              />
              {isAnalyticalColumnActiveForAnalyticalColumn && (
                <RadioGroup
                  name="analyticalColumnDisplayOptions"
                  value={
                    displayOptions?.analytical.analyticalOption ||
                    analyticalLineOptions[0]
                  }
                  onChange={(e) => {
                    if (!displayOptions) {
                      return;
                    }
                    displayOptions.analytical.analyticalOption = e.target
                      .value as AnalyticalLineOption;

                    onDisplayOptionChange(displayOptions);
                  }}
                  direction="column"
                  className="pl-s"
                >
                  {analyticalLineOptions
                    .filter((option) => {
                      if (!analyticalCommonLine?.active) {
                        return option !== 'common';
                      }
                      return true;
                    })
                    .map((option) => (
                      <RadioField
                        value={option}
                        key={option}
                        label={t(analyticalOptionsToI18nKey[option])}
                      />
                    ))}
                </RadioGroup>
              )}
            </>
          );
        })}
    </div>
  );
};

const groupToI18nKey: Record<RowDisplayOption['group'], I18nKey> = {
  expense: 'bookkeep.settings.export.columnDisplayOptions.expense',
  analytical: 'bookkeep.settings.export.columnDisplayOptions.analytical',
  vat: 'bookkeep.settings.export.columnDisplayOptions.vat',
  supplier: 'bookkeep.settings.export.columnDisplayOptions.supplier',
};

const analyticalOptionsToI18nKey: Record<'new' | 'all' | 'common', I18nKey> = {
  new: 'bookkeep.settings.export.columnDisplayOptions.analyticalOptions.new',
  all: 'bookkeep.settings.export.columnDisplayOptions.analyticalOptions.all',
  common:
    'bookkeep.settings.export.columnDisplayOptions.analyticalOptions.common',
};
