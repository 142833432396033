import * as R from '@dev-spendesk/general-type-helpers/Result';
import { Callout, IconButton, InfoTip, colors } from '@dev-spendesk/grapes';
import cx from 'classnames';
import isEqual from 'lodash/isEqual';
import throttle from 'lodash/throttle';
import React, { useRef, useState, useEffect } from 'react';
import Reorder from 'react-reorder';
import { usePrevious } from 'react-use';

import { SearchFilter } from 'common/components/SearchFilter';
import { Spinner } from 'common/components/Spinner/Spinner';
import { useTranslation } from 'common/hooks/useTranslation';
import { ActiveAccountingIntegrationApi } from 'modules/bookkeep/hooks';
import {
  type Column,
  type DefinedColumn,
  type CustomFieldColumn,
  type CompositeColumn,
  type BaseColumn,
  type CompositeOption,
  type CompositePatternPart,
  type JournalCodeColumn,
  type DirectionColumn,
  type TypeColumn,
  isCompositeColumn,
  getCompositePatternValue,
  customContentValueToCompositeOption,
  customContentValueToCompositePatternPart,
  type PreviewRow,
  type RowDisplayOption,
  type ColumnDisplayOptions,
} from 'modules/bookkeep/settings/export';
import { removeAt, replaceAt, insertAt, swap } from 'src/core/utils/toolbelt';

import plusSignIconSource from './plus-sign.svg';
import { type CustomField } from '../../../export/customField';
// eslint-disable-next-line import/order
import {
  JournalCodeColumnEditModalContainer,
  ColumnEditModalContainer,
} from '../ColumnEditModal';

import './TemplateBuilder.css';
import { DirectionColumnEditModalContainer } from '../ColumnEditModal/DirectionColumnEditModalContainer';
import { TypeColumnEditModalContainer } from '../ColumnEditModal/TypeColumnEditModalContainer';

type Props = {
  columns: Column[];
  rows: (PreviewRow | null)[];
  compositePatternPartOptions: CompositeOption[];
  isLoading: boolean;
  totalFixtureLines: number;
  style?: React.CSSProperties;
  onChange: (columns: Column[]) => void;
  searchAvailableColumns: (columns: Column[], query: string) => Column[];
  setCannotReorderColumn: (column: InternalColumn | null) => void;
  customFields: CustomField[];
  expenseCategoryCustomFieldId: CustomField['id'] | undefined;
  rowDisplayOptions?: RowDisplayOption[];
};

interface NewColumn extends BaseColumn {
  type: 'new';
}

const isColumn = (column: InternalColumn): column is Column => {
  return column.type !== 'new';
};

export type InternalColumn =
  | DefinedColumn
  | CustomFieldColumn
  | CompositeColumn
  | JournalCodeColumn
  | NewColumn
  | DirectionColumn
  | TypeColumn;

export const TemplateBuilderV2 = ({
  columns,
  rows,
  compositePatternPartOptions,
  isLoading,
  totalFixtureLines,
  style,
  onChange,
  searchAvailableColumns,
  setCannotReorderColumn,
  customFields,
  expenseCategoryCustomFieldId,
  rowDisplayOptions,
}: Props) => {
  const { t } = useTranslation('global');
  const chooseColumnReference = useRef<HTMLDivElement>(null);

  // The component maintains in sync an internal copy of the columns for adding
  // columns.
  const [internalColumns, setInternalColumns] =
    useState<InternalColumn[]>(columns);
  const [newColumnTypeSearch, setNewColumnTypeSearch] = useState('');
  const [selectedItem, setSelectedItem] = useState<number | null>(null);
  const [editingItem, setEditingItem] = useState<number | null>(null);
  const [isStickedToTheLeftSide, setIsStickedToTheLeftSide] = useState(true);
  const [isStickedToTheRightSide, setIsStickedToTheRightSide] = useState(false);

  const activeAccountingIntegration =
    ActiveAccountingIntegrationApi.useActiveAccountingIntegration();

  const isDoubleOrSingleEntry =
    activeAccountingIntegration.status === 'success' &&
    (activeAccountingIntegration.data.activeAccountingIntegration ===
      'SpendeskAccounting' ||
      activeAccountingIntegration.data.activeAccountingIntegration ===
        'SpendeskAccountingSingleEntry');

  const previousColumns = usePrevious(columns);

  useEffect(() => {
    if (!isEqual(columns, previousColumns)) {
      setInternalColumns(columns);
    }
  }, [columns]);

  useEffect(() => {
    onChange(internalColumns as Column[]);
  }, [internalColumns]);

  const onReorder = (
    _: MouseEvent,
    previousIndex: number,
    nextIndex: number,
    // eslint-disable-next-line sonarjs/cognitive-complexity
  ) => {
    if (isDoubleOrSingleEntry) {
      setSelectedItem(null);
      setInternalColumns(reorder(columns, previousIndex, nextIndex));
      return;
    }

    const newColumnsResult = reorderColumns({
      columns: internalColumns,
      customFields,
      expenseCategoryCustomFieldId,
      previousIndex,
      nextIndex,
    });

    if (R.isSuccess(newColumnsResult)) {
      setSelectedItem(null);
      setInternalColumns(newColumnsResult.value);
      setCannotReorderColumn(null);
    } else {
      setCannotReorderColumn(newColumnsResult.error);
    }
  };

  const handleColumnDataChange = (dep: {
    index: number;
    rawName: string;
    compositePattern?: CompositePatternPart[];
    journalCode?: {
      cardExpense: string;
      invoice: string;
      expenseClaim: string;
      subscription: string;
    };
    direction?: {
      debit: string;
      credit: string;
    };
    type?: {
      analytical: string;
      general: string;
    };
    maxLength?: number;
    displayOptions?: ColumnDisplayOptions;
  }) => {
    const {
      index,
      rawName,
      displayOptions,
      compositePattern,
      journalCode,
      direction,
      type,
      maxLength,
    } = dep;

    if (!rawName || !internalColumns[index]) {
      return;
    }

    const name = rawName.replaceAll(/<[\n.]*?>/gm, '');

    const nextColumns = replaceAt(internalColumns, index, {
      ...internalColumns[index],
      name,
      displayOptions,
      maxLength,
      ...(compositePattern
        ? {
            compositePattern,
            type: 'composite',
          }
        : {}),
      ...(journalCode
        ? {
            ...journalCode,
            type: 'journalCode',
          }
        : {}),
      ...(direction
        ? {
            ...direction,
            type: 'direction',
          }
        : {}),
      ...(type
        ? {
            ...type,
            type: 'type',
          }
        : {}),
    } as InternalColumn);

    setInternalColumns(nextColumns);
    setEditingItem(null);
  };

  const handleTrashColumn = (index: number) => {
    setInternalColumns(removeAt(internalColumns, index));
    setEditingItem(null);
  };

  const handleEditColumn = (index: number) => {
    setSelectedItem(null);
    setEditingItem(index);

    removeNewColumn();
  };

  const handleColumnEditModalClose = () => {
    setEditingItem(null);
  };

  const handleAddColumn = (index: number, position: 'left' | 'right') => {
    if (!['left', 'right'].includes(position)) {
      return;
    }

    // Filter out other new columns so we only add one at a time
    const newColumns = internalColumns.filter(
      (column) => column.type !== 'new',
    );
    const newColumn: NewColumn = {
      name: 'Add column',
      type: 'new',
      description: 'New column',
      isRequired: false,
      isReorderable: true,
    };
    const indexToAdd = position === 'left' ? index : index + 1;
    // @ts-expect-error: missing compositePattern
    const nextColumns = insertAt(newColumns, indexToAdd, newColumn);
    setInternalColumns(nextColumns);

    scrollAfterAddChooseColumn();
  };

  const scrollAfterAddChooseColumn = () => {
    chooseColumnReference.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const commitNewColumn = (column: InternalColumn) => {
    const nextColumns = swap(
      internalColumns,
      (previousColumn: InternalColumn) => previousColumn.type === 'new',
      column,
    );
    setInternalColumns(nextColumns);
    setNewColumnTypeSearch('');
  };

  const removeNewColumn = () => {
    setInternalColumns(
      internalColumns.filter((column) => column.type !== 'new'),
    );
    setNewColumnTypeSearch('');
  };

  // Update left/right scroll incitation overlay
  const handleHorizontalScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;

    throttle(() => {
      const offset = target.scrollLeft;
      const fullWidth = target.scrollWidth;

      if (isStickedToTheLeftSide) {
        setIsStickedToTheLeftSide(offset === 0);
        return;
      }

      if (isStickedToTheRightSide) {
        setIsStickedToTheRightSide(target.offsetWidth + offset === fullWidth);
        return;
      }

      // Remove class if needed
      if (isStickedToTheLeftSide || isStickedToTheRightSide) {
        setIsStickedToTheLeftSide(false);
        setIsStickedToTheRightSide(false);
      }
    }, 200)();
  };

  const getScrollOverflowClasses = () => {
    return {
      'stick-left': isStickedToTheLeftSide,
      'stick-right': isStickedToTheRightSide,
    };
  };

  /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
  const renderExistingColumn = (column: InternalColumn, index: number) => {
    return (
      <div
        className={cx('TemplateBuilder__column', {
          'is-selected': selectedItem === index,
        })}
        key={index}
      >
        <div
          className="TemplateBuilder__column-actions"
          onClick={() => {
            setSelectedItem(selectedItem === index ? null : index);
          }}
        >
          <div className="action-left">
            <img
              src={plusSignIconSource}
              className="plus-sign"
              onClick={() => handleAddColumn(index, 'left')}
              alt="Add column"
            />
            <span className="blue-separator" />
          </div>
          <div className="action-right">
            <img
              src={plusSignIconSource}
              className="plus-sign"
              onClick={() => handleAddColumn(index, 'right')}
              alt="Add column"
            />
            <span className="blue-separator" />
          </div>
        </div>
        <div className="TemplateBuilder__column-head">
          <div className="title">{column.name}</div>
          <IconButton
            className="TemplateBuilder__column-settings"
            iconName="settings"
            onClick={() => handleEditColumn(index)}
            iconColor={colors.neutralDark}
            aria-label={t('misc.setting_plural')}
          />
        </div>
        <div className="TemplateBuilder__column-rows">
          {rows.map((line, _index) => {
            if (line) {
              return (
                <div
                  // eslint-disable-next-line react/no-array-index-key
                  key={_index}
                  className={cx('TemplateBuilder__column-rows-item', {
                    'is-empty': line[column.name] === '',
                  })}
                >
                  {line[column.name]}
                </div>
              );
            }

            return undefined;
          })}
        </div>
      </div>
    );
  };
  /* eslint-enable jsx-a11y/no-noninteractive-element-interactions */

  const renderNewColumn = () => {
    const query = newColumnTypeSearch.toLowerCase().trim();
    const columnsInUse = internalColumns.filter(isColumn);
    const columnSearchResults = searchAvailableColumns(columnsInUse, query);
    const matchingResults = columnSearchResults.length;

    return (
      <div className="TemplateBuilder__column new" key="new-column">
        <div className="TemplateBuilder__column-actions">
          <div className="action-left" />
          <div className="action-right" />
        </div>
        <div className="TemplateBuilder__column-head">
          <div className="title">{t('exports.addColumn')}</div>
          <div className="choose-column" ref={chooseColumnReference}>
            <div className="choose-column__search">
              <SearchFilter
                minLength={0}
                fit="parent"
                placeholder={t('exports.searchColumn')}
                search={newColumnTypeSearch}
                setSearch={setNewColumnTypeSearch}
              />
            </div>
            <div
              className={cx('choose-column__results', {
                'no-results': !matchingResults,
              })}
            >
              {matchingResults > 0 &&
                columnSearchResults.map((result, index) => (
                  <button
                    type="button"
                    /* eslint-disable-next-line react/no-array-index-key */
                    key={index}
                    className="choose-column__results-item"
                    onClick={() => commitNewColumn(result)}
                  >
                    <span>{result.name}</span>
                    <InfoTip content={result.description} />
                  </button>
                ))}
              {!matchingResults && (
                <Callout
                  title={
                    query === ''
                      ? t('exports.noColumnLeft')
                      : t('exports.noMatchingColumn')
                  }
                />
              )}
            </div>
          </div>
        </div>
        <div className="TemplateBuilder__column-rows">
          {Array.from({ length: totalFixtureLines }, (_, index) => (
            <div key={index} className="TemplateBuilder__column-rows-item">
              {' '}
              -{' '}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const hasNewColumn = internalColumns.some((column) => column.type === 'new');
  const isEditingColumn = typeof editingItem === 'number';
  // Casting as Column because we can't edit a "new" column
  const editingColumn = isEditingColumn
    ? (internalColumns[editingItem] as Column)
    : null;

  const journalCodeColumnEditModalContainer = isEditingColumn &&
    editingColumn &&
    editingColumn.type === 'journalCode' && (
      <JournalCodeColumnEditModalContainer
        isOpen={isEditingColumn && Boolean(editingColumn)}
        title={editingColumn.name}
        maxLength={
          editingColumn.maxLength ? String(editingColumn.maxLength) : undefined
        }
        cardExpense={editingColumn.cardExpense}
        expenseClaim={editingColumn.expenseClaim}
        invoice={editingColumn.invoice}
        subscription={editingColumn.subscription}
        description={editingColumn.description}
        isDeletable={!editingColumn.isRequired}
        onClose={handleColumnEditModalClose}
        displayOptions={editingColumn.displayOptions}
        rowDisplayOptions={rowDisplayOptions}
        onSaveColumn={({
          title,
          maxLength,
          displayOptions,
          cardExpense,
          invoice,
          expenseClaim,
          subscription,
        }: {
          title: string;
          maxLength?: string;
          displayOptions?: ColumnDisplayOptions;
          cardExpense: string;
          invoice: string;
          expenseClaim: string;
          subscription: string;
        }) =>
          handleColumnDataChange({
            index: editingItem,
            rawName: title,
            displayOptions,
            maxLength: maxLength ? Number.parseInt(maxLength) : undefined,
            journalCode: {
              cardExpense,
              invoice,
              expenseClaim,
              subscription,
            },
          })
        }
        onRemoveColumn={() => handleTrashColumn(editingItem)}
      />
    );

  const directionColumnEditModalContainer = isEditingColumn &&
    editingColumn &&
    editingColumn.type === 'direction' && (
      <DirectionColumnEditModalContainer
        isOpen={isEditingColumn && Boolean(editingColumn)}
        title={editingColumn.name}
        maxLength={
          editingColumn.maxLength ? String(editingColumn.maxLength) : undefined
        }
        debit={editingColumn.debit}
        credit={editingColumn.credit}
        description={editingColumn.description}
        isDeletable={!editingColumn.isRequired}
        onClose={handleColumnEditModalClose}
        displayOptions={editingColumn.displayOptions}
        rowDisplayOptions={rowDisplayOptions}
        onSaveColumn={({
          title,
          maxLength,
          displayOptions,
          debit,
          credit,
        }: {
          title: string;
          maxLength?: string;
          displayOptions?: ColumnDisplayOptions;
          debit: string;
          credit: string;
        }) =>
          handleColumnDataChange({
            index: editingItem,
            rawName: title,
            displayOptions,
            maxLength: maxLength ? Number.parseInt(maxLength) : undefined,
            direction: {
              debit,
              credit,
            },
          })
        }
        onRemoveColumn={() => handleTrashColumn(editingItem)}
      />
    );

  const typeColumnEditModalContainer = isEditingColumn &&
    editingColumn &&
    editingColumn.type === 'type' && (
      <TypeColumnEditModalContainer
        isOpen={isEditingColumn && Boolean(editingColumn)}
        title={editingColumn.name}
        general={editingColumn.general}
        maxLength={
          editingColumn.maxLength ? String(editingColumn.maxLength) : undefined
        }
        analytical={editingColumn.analytical}
        description={editingColumn.description}
        isDeletable={!editingColumn.isRequired}
        onClose={handleColumnEditModalClose}
        displayOptions={editingColumn.displayOptions}
        rowDisplayOptions={rowDisplayOptions}
        onSaveColumn={({
          title,
          displayOptions,
          maxLength,
          general,
          analytical,
        }: {
          title: string;
          displayOptions?: ColumnDisplayOptions;
          maxLength?: string;
          general: string;
          analytical: string;
        }) =>
          handleColumnDataChange({
            index: editingItem,
            rawName: title,
            displayOptions,
            maxLength: maxLength ? Number.parseInt(maxLength) : undefined,
            type: {
              general,
              analytical,
            },
          })
        }
        onRemoveColumn={() => handleTrashColumn(editingItem)}
      />
    );

  const isAnalyticalColumn =
    (editingColumn?.type === 'defined' &&
      editingColumn?.reference === 'costCenter') ||
    editingColumn?.type === 'customField' ||
    editingColumn?.type === 'composite';

  const columnEditModalContainer = isEditingColumn &&
    editingColumn &&
    ['defined', 'customField', 'composite'].includes(editingColumn.type) && (
      <ColumnEditModalContainer
        isOpen={isEditingColumn && Boolean(editingColumn)}
        title={editingColumn.name}
        description={editingColumn.description}
        customContent={
          isCompositeColumn(editingColumn)
            ? getCompositePatternValue(editingColumn.compositePattern)
            : null
        }
        isAnalyticalColumn={isAnalyticalColumn}
        maxLength={
          editingColumn.maxLength ? String(editingColumn.maxLength) : undefined
        }
        displayOptions={editingColumn.displayOptions}
        rowDisplayOptions={rowDisplayOptions}
        customContentOptions={compositePatternPartOptions}
        isDeletable={!editingColumn.isRequired}
        isStaticCustomContentValue={(value) =>
          customContentValueToCompositeOption(
            compositePatternPartOptions,
            value,
            t,
          ) === undefined
        }
        getCustomContentValueLabel={(value) => {
          const option = customContentValueToCompositeOption(
            compositePatternPartOptions,
            value,
            t,
          );
          return option ? option.name : value;
        }}
        onClose={handleColumnEditModalClose}
        onSaveColumn={({
          title,
          displayOptions,
          customContent,
          maxLength,
        }: {
          title: string;
          displayOptions?: ColumnDisplayOptions;
          customContent: string[] | null;
          maxLength?: string;
        }) => {
          const index = editingItem;

          if (customContent) {
            const compositePattern = customContent.map((value) =>
              customContentValueToCompositePatternPart(
                compositePatternPartOptions,
                value,
              ),
            );

            handleColumnDataChange({
              index,
              rawName: title,
              displayOptions,
              compositePattern,
              maxLength: maxLength ? Number.parseInt(maxLength) : undefined,
            });
          } else {
            handleColumnDataChange({
              index,
              rawName: title,
              displayOptions,
              maxLength: maxLength ? Number.parseInt(maxLength) : undefined,
            });
          }
        }}
        onRemoveColumn={() => handleTrashColumn(editingItem)}
      />
    );

  return (
    <div
      className={cx(
        'TemplateBuilderV2',
        { 'is-editing': isEditingColumn },
        getScrollOverflowClasses(),
      )}
      style={style}
    >
      {typeColumnEditModalContainer}
      {directionColumnEditModalContainer}
      {journalCodeColumnEditModalContainer}
      {columnEditModalContainer}
      {isLoading && (
        <Spinner
          className="TemplateBuilder__loader grey"
          width={60}
          height={60}
        />
      )}
      <div
        onScroll={handleHorizontalScroll}
        className="TemplateBuilder-wrapper"
      >
        <Reorder
          reorderId="exports-preview"
          className={cx('TemplateBuilder', {
            'TemplateBuilder--is-loading': isLoading,
          })}
          draggedClassName="is-dragged"
          lock="vertical"
          holdTime={100}
          touchHoldTime={500}
          onReorder={onReorder}
          disabled={hasNewColumn || isLoading}
          disableContextMenus
          autoScroll
        >
          {internalColumns.map((column, index) =>
            column.type === 'new'
              ? renderNewColumn()
              : renderExistingColumn(column, index),
          )}
        </Reorder>
      </div>
    </div>
  );
};

function reorder<T>(array: T[], from: number, to: number): T[] {
  const newArray = [...array];
  newArray.splice(to, 0, newArray.splice(from, 1)[0]);
  return newArray;
}

export const reorderColumns = ({
  columns,
  customFields,
  expenseCategoryCustomFieldId,
  previousIndex,
  nextIndex,
}: {
  columns: InternalColumn[];
  customFields: CustomField[];
  expenseCategoryCustomFieldId: CustomField['id'] | undefined;
  previousIndex: number;
  nextIndex: number;
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const reorderingColumn = columns[previousIndex];

  if (!reorderingColumn.isReorderable) {
    return R.toFailure(reorderingColumn);
  }

  // Reorder target column
  let newColumns = reorder(columns, previousIndex, nextIndex);

  if (
    reorderingColumn.type === 'defined' &&
    reorderingColumn.reference === 'costCenter'
  ) {
    let splittableCustomFieldColumnCount = previousIndex > nextIndex ? 1 : 0;

    // Reorder expense category column
    const expenseCategoryCustomFieldIndex = newColumns.findIndex(
      (column) =>
        column.type === 'customField' &&
        column.customFieldId === expenseCategoryCustomFieldId,
    );

    if (expenseCategoryCustomFieldIndex !== -1) {
      const cfNextIndex =
        newColumns.findIndex(
          (c) => c.type === 'defined' && c.reference === 'costCenter',
        ) +
        splittableCustomFieldColumnCount +
        (expenseCategoryCustomFieldIndex > nextIndex &&
        previousIndex < nextIndex
          ? 1
          : 0);

      newColumns = reorder(
        newColumns,
        expenseCategoryCustomFieldIndex,
        cfNextIndex,
      );

      splittableCustomFieldColumnCount += 1;
    }

    // Reorder splittable custom fields columns
    columns.forEach((column) => {
      if (
        column.type === 'customField' &&
        column.customFieldId !== expenseCategoryCustomFieldId
      ) {
        const customFieldIndex = customFields.findIndex(
          ({ id }) => id === column.customFieldId,
        );

        const customField = customFields[customFieldIndex];

        if (customField?.is_splittable) {
          const cfPreviousIndex = newColumns.findIndex(
            (c) =>
              c.type === 'customField' &&
              c.customFieldId === column.customFieldId,
          );

          const cfNextIndex =
            newColumns.findIndex(
              (c) => c.type === 'defined' && c.reference === 'costCenter',
            ) +
            splittableCustomFieldColumnCount +
            (cfPreviousIndex > nextIndex && previousIndex < nextIndex ? 1 : 0);

          newColumns = reorder(newColumns, cfPreviousIndex, cfNextIndex);

          splittableCustomFieldColumnCount += 1;
        }
      }
    });
  }

  return R.toSuccess(newColumns);
};
