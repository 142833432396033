import { type MouseEventHandler } from 'react';

import { PageEmptyState } from 'src/core/common/components/PageEmptyState';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import illustrationSourceDe from '../../assets/illustration_de.svg';
import illustrationSourceEn from '../../assets/illustration_en.svg';
import illustrationSourceFr from '../../assets/illustration_fr.svg';

type Props = {
  onCreateClick: MouseEventHandler<HTMLButtonElement>;
};

export const CostCenterEmptyState = ({ onCreateClick }: Props) => {
  const { t, activeLanguage } = useTranslation('global');

  let illustrationSource;
  switch (activeLanguage) {
    case 'de':
      illustrationSource = illustrationSourceDe;
      break;
    case 'en':
      illustrationSource = illustrationSourceEn;
      break;
    // eslint-disable-next-line unicorn/no-useless-switch-case
    case 'fr':
    default:
      illustrationSource = illustrationSourceFr;
      break;
  }

  return (
    <PageEmptyState
      illustrationSrc={illustrationSource}
      title={t('costCenters.emptyState.title')}
      description={t('costCenters.emptyState.description')}
      buttonLabel={t('costCenters.emptyState.button')}
      onClick={onCreateClick}
    />
  );
};
