/* eslint-disable jsx-a11y/control-has-associated-label */
import { colors, Icon, type IconName, Tooltip } from '@dev-spendesk/grapes';
import { useState } from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

type Props = {
  isPinned: boolean;
  togglePinnedEntity: () => void;
};

export const EntityItemHeaderPin = ({
  isPinned,
  togglePinnedEntity,
}: Props) => {
  const { t } = useTranslation('global');
  const [isHovered, setIsHovered] = useState(false);

  const iconName: IconName = isHovered || isPinned ? 'star' : 'star-outlined';

  return (
    <Tooltip
      content={
        isPinned
          ? t('organisation.reporting.page.entities.favorites.remove')
          : t('organisation.reporting.page.entities.favorites.add')
      }
      triggerAsChild
    >
      <button
        type="button"
        className="rounded-none text-inherit flex cursor-pointer items-center border-none no-underline outline-none"
        style={{ background: 'inherit' }}
        onClick={togglePinnedEntity}
        onMouseOver={() => setIsHovered(true)}
        onFocus={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Icon name={iconName} color={colors.neutral} />
      </button>
    </Tooltip>
  );
};
