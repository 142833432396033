import { useEffect, useState } from 'react';

import { Step1 } from './Step1/Step1';
import { Step2 } from './Step2/Step2';
import { type ApiCard } from '../../../card';

import './CardActivation.css';

const steps = [Step1, Step2];

type Props = {
  card: ApiCard;
  startStep?: number;
  onFinish: () => void;
  isRecard?: boolean;
};

export const CardActivation = ({
  card,
  startStep,
  onFinish,
  isRecard = false,
}: Props) => {
  const [stepIndex, setStepIndex] = useState(startStep ?? 0);

  useEffect(() => {
    const newStepIndex = startStep ?? 0;

    setStepIndex(newStepIndex);
  }, [card]);

  const goToNextStep = () => {
    const nextIndex = stepIndex + 1;

    if (nextIndex < steps.length) {
      setStepIndex(nextIndex);
    } else {
      onFinish();
    }
  };

  let Step: JSX.Element | null;

  switch (stepIndex) {
    case 0:
      Step = (
        <Step1 card={card} goToNextStep={goToNextStep} isRecard={isRecard} />
      );
      break;
    case 1:
      Step = (
        <Step2 card={card} goToNextStep={goToNextStep} onFinish={onFinish} />
      );
      break;
    default:
      Step = null;
  }

  return (
    <div className="CardActivation">
      <div>{Step}</div>
    </div>
  );
};
