import { type ApprovalRuleApi } from 'modules/company/structure/approval-flows';
import { apiUrl } from 'src/core/utils/api';

type CreationPayload = {
  owner: {
    type: 'costCenter';
    id: string;
  } | null;
  rules: ApprovalRuleApi[];
};

export const createApprovalScheme = async (
  payload: CreationPayload,
  companyId: string,
): Promise<{ id: string }> => {
  const response = await fetch(apiUrl('/approval-scheme', companyId), {
    method: 'POST',
    credentials: 'include',
    headers: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
  if (response.status === 200) {
    return response.json();
  }
  throw new Error('Impossible to create an approval scheme');
};
