import { format } from 'date-fns';
import compact from 'lodash/compact';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import map from 'lodash/map';

import { formatMonetaryValue } from 'src/core/utils/monetaryValue';
import { formatMoney } from 'src/core/utils/money';

import {
  type TGlobalFunctionTyped,
  type I18nKey,
} from '../common/hooks/useTranslation';

export const getPaymentCreationDetails = (
  // eslint-disable-next-line @typescript-eslint/ban-types
  t: Function,
  user: string,
  arePointOfSaleDetailsProvided: string,
  pointOfSale: string,
  is3DSecure: boolean | string,
  chipUsage: string,
): string => {
  if (
    !arePointOfSaleDetailsProvided ||
    !['physical', 'online'].includes(pointOfSale)
  ) {
    return t('paymentCreatedBy', { user });
  }

  if (pointOfSale === 'online') {
    if (is3DSecure === true) {
      return t('onlinePaymentMadeWith3DSecureBy', { user });
    }

    if (is3DSecure === false) {
      return t('onlinePaymentMadeWithout3DSecureBy', { user });
    }

    return t('onlinePaymentMadeBy', { user });
  }

  if (pointOfSale === 'physical' && chipUsage) {
    switch (chipUsage) {
      case 'contactless':
        return t('pointOfSalePaymentMadeContactlessBy', { user });
      case 'pin':
        return t('pointOfSalePaymentMadeWithPinBy', { user });
      case 'pinAndContactless':
        return t('pointOfSalePaymentMadeContactlessWithPinBy', { user });
      default:
        return t('pointOfSalePaymentMadeBy', { user });
    }
  }

  return t('pointOfSalePaymentMadeBy', { user });
};

type TimelineEvents =
  | 'submittedBy'
  | 'expenseMadeBy'
  | 'reimbursedOutsideSpendesk'
  | 'reimbursedFromSpendesk'
  | 'validatedBy'
  | 'paymentCreatedBy'
  | 'onlinePaymentMadeBy'
  | 'onlinePaymentMadeWith3DSecureBy'
  | 'onlinePaymentMadeWithout3DSecureBy'
  | 'pointOfSalePaymentMadeContactlessBy'
  | 'pointOfSalePaymentMadeWithPinBy'
  | 'pointOfSalePaymentMadeContactlessWithPinBy'
  | 'pointOfSalePaymentMadeBy'
  | 'unValidatedBy'
  | 'exportedBy'
  | 'attachedInvoiceBy'
  | 'amountRequested'
  | 'userAttachedReceipt'
  | 'amountEditedBy'
  | 'amountChanged'
  | 'amountToReimburse'
  | 'approvedBy'
  | 'deniedBy'
  | 'deniedByController'
  | 'validatedByController'
  | 'amountApproved'
  | 'schedulesUpdatedBy'
  | 'scheduleAddedBy'
  | 'invoiceUpdatedBy'
  | 'invoiceSupplierCreatedBy'
  | 'invoiceSupplierUpdatedBy'
  | 'invoiceSupplierBankInfoCreatedBy'
  | 'invoiceSupplierBankInfoUpdatedBy'
  | 'invoiceSupplierBankDetailsUploadedBy'
  | 'scheduledPaymentPaidExternally'
  | 'scheduledPaymentSentToPayment'
  | 'scheduledPaymentConfirmedByAo'
  | 'scheduledPaymentRejectedByAo'
  | 'scheduledPaymentCancelledByController'
  | 'scheduledPaymentPaidByWireTransferInternally'
  | 'wireTransferFailed'
  | 'wireTransferComplianceError'
  | 'wireTransferInvalidAmountGreaterThanLimitError'
  | 'wireTransferWrongBankInfo'
  | 'wireTransferConfirmed'
  | 'paymentScheduleUpdatedBy'
  | 'paymentScheduledOutsideSpendeskBy'
  | 'paymentScheduledSentForConfirmation'
  | 'paymentScheduledCancelled'
  | 'paidFromSpendesk'
  | 'cardLastFourDigits'
  | 'sentBackToReview';

const timelineEventToI18nKey: Record<TimelineEvents, I18nKey> = {
  submittedBy: 'requests.timeline.events.submittedBy',
  expenseMadeBy: 'requests.timeline.events.expenseMadeBy',
  reimbursedOutsideSpendesk:
    'requests.timeline.events.reimbursedOutsideSpendesk',
  reimbursedFromSpendesk: 'requests.timeline.events.reimbursedFromSpendesk',
  validatedBy: 'requests.timeline.events.validatedBy',
  paymentCreatedBy: 'requests.timeline.events.paymentCreatedBy',
  onlinePaymentMadeBy: 'requests.timeline.events.onlinePaymentMadeBy',
  onlinePaymentMadeWith3DSecureBy:
    'requests.timeline.events.onlinePaymentMadeWith3DSecureBy',
  onlinePaymentMadeWithout3DSecureBy:
    'requests.timeline.events.onlinePaymentMadeWithout3DSecureBy',
  pointOfSalePaymentMadeContactlessBy:
    'requests.timeline.events.pointOfSalePaymentMadeContactlessBy',
  pointOfSalePaymentMadeWithPinBy:
    'requests.timeline.events.pointOfSalePaymentMadeWithPinBy',
  pointOfSalePaymentMadeContactlessWithPinBy:
    'requests.timeline.events.pointOfSalePaymentMadeContactlessWithPinBy',
  pointOfSalePaymentMadeBy: 'requests.timeline.events.pointOfSalePaymentMadeBy',
  unValidatedBy: 'requests.timeline.events.unValidatedBy',
  exportedBy: 'requests.timeline.events.exportedBy',
  attachedInvoiceBy: 'requests.timeline.events.attachedInvoiceBy',
  amountRequested: 'requests.timeline.events.amountRequested',
  userAttachedReceipt: 'requests.timeline.events.userAttachedReceipt',
  amountEditedBy: 'requests.timeline.events.amountEditedBy',
  amountChanged: 'requests.timeline.events.amountChanged',
  amountToReimburse: 'requests.timeline.events.amountToReimburse',
  approvedBy: 'requests.timeline.events.approvedBy',
  deniedBy: 'requests.timeline.events.deniedBy',
  deniedByController: 'requests.timeline.events.deniedByController',
  validatedByController: 'requests.timeline.events.validatedByController',
  amountApproved: 'requests.timeline.events.amountApproved',
  schedulesUpdatedBy: 'requests.timeline.events.schedulesUpdatedBy',
  scheduleAddedBy: 'requests.timeline.events.scheduleAddedBy',
  invoiceUpdatedBy: 'requests.timeline.events.invoiceUpdatedBy',
  invoiceSupplierCreatedBy: 'requests.timeline.events.invoiceSupplierCreatedBy',
  invoiceSupplierUpdatedBy: 'requests.timeline.events.invoiceSupplierUpdatedBy',
  invoiceSupplierBankInfoCreatedBy:
    'requests.timeline.events.invoiceSupplierBankInfoCreatedBy',
  invoiceSupplierBankInfoUpdatedBy:
    'requests.timeline.events.invoiceSupplierBankInfoUpdatedBy',
  invoiceSupplierBankDetailsUploadedBy:
    'requests.timeline.events.invoiceSupplierBankDetailsUploadedBy',
  scheduledPaymentPaidExternally:
    'requests.timeline.events.scheduledPaymentPaidExternally',
  scheduledPaymentSentToPayment:
    'requests.timeline.events.scheduledPaymentSentToPayment',
  scheduledPaymentConfirmedByAo:
    'requests.timeline.events.scheduledPaymentConfirmedByAo',
  scheduledPaymentRejectedByAo:
    'requests.timeline.events.scheduledPaymentRejectedByAo',
  scheduledPaymentCancelledByController:
    'requests.timeline.events.scheduledPaymentCancelledByController',
  scheduledPaymentPaidByWireTransferInternally:
    'requests.timeline.events.scheduledPaymentPaidByWireTransferInternally',
  wireTransferFailed: 'requests.timeline.events.wireTransferFailed',
  wireTransferComplianceError:
    'requests.timeline.events.wireTransferComplianceError',
  wireTransferInvalidAmountGreaterThanLimitError:
    'requests.timeline.events.wireTransferInvalidAmountGreaterThanLimitError',
  wireTransferWrongBankInfo:
    'requests.timeline.events.wireTransferWrongBankInfo',
  wireTransferConfirmed: 'requests.timeline.events.wireTransferConfirmed',
  paymentScheduleUpdatedBy: 'requests.timeline.events.paymentScheduleUpdatedBy',
  paymentScheduledOutsideSpendeskBy:
    'requests.timeline.events.paymentScheduledOutsideSpendeskBy',

  paymentScheduledSentForConfirmation:
    'requests.timeline.events.paymentScheduledSentForConfirmation',
  paymentScheduledCancelled:
    'requests.timeline.events.paymentScheduledCancelled',
  paidFromSpendesk: 'requests.timeline.events.paidFromSpendesk',
  cardLastFourDigits: 'requests.timeline.events.cardLastFourDigits',
  sentBackToReview: 'requests.timeline.events.sentBackToReview',
};

export const formatTimelineEvents = (
  trans: TGlobalFunctionTyped,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  events: any[] | readonly any[],
  request: {
    amount_declared: number;
    amount_to_refund: number;
    currency_declared: string;
  } | null,
  companyCurrency: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  context: any,
) => {
  const t = (key: TimelineEvents, params = {}) =>
    trans(timelineEventToI18nKey[key], { ...params, ...context });

  return compact(
    // eslint-disable-next-line sonarjs/cognitive-complexity
    map(events, (e) => {
      if (isNil(e)) {
        return;
      }

      const user = get(e, 'user.fullname') ?? '';
      const arePointOfSaleDetailsProvided = get(e, 'pos') ?? false;
      const is3DSecure = get(e, 'pos.is3DSecure') ?? '';
      const pointOfSale = get(e, 'pos.pointOfSale') ?? '';
      const chipUsage = get(e, 'pos.chipUsage') ?? '';
      const { data } = e;

      let firstLine;
      let secondLine;
      switch (e.action_type) {
        case 'request.create': {
          firstLine = t('submittedBy', { user });
          secondLine = t('amountRequested', {
            amount: formatMoney(
              // make compatible with old event records
              data?.amountDeclared ?? request?.amount_declared,
              data?.currency ?? request?.currency_declared,
            ),
          });
          break;
        }
        case 'request.approve': {
          firstLine = t('approvedBy', { user });
          if (request?.amount_to_refund) {
            secondLine = t('amountApproved', {
              amount: formatMoney(
                // make compatible with old event records
                data?.amountApproved ??
                  data?.amountToRefund ??
                  request.amount_to_refund,
                data?.currency ?? companyCurrency,
              ),
            });
          }
          break;
        }
        case 'request.deny': {
          firstLine = t('deniedBy', { user });
          break;
        }
        case 'request.reject_by_controller': {
          firstLine = t('deniedByController', { user });
          break;
        }
        case 'request.validate_by_controller': {
          firstLine = t('validatedByController', { user });
          break;
        }
        case 'request.sent_back_to_review': {
          firstLine = t('sentBackToReview', { user });
          break;
        }
        case 'request.invoice': {
          firstLine = t('userAttachedReceipt', { user });
          break;
        }
        case 'request.updateAmount':
        case 'expense.updateAmount': {
          firstLine = t('amountEditedBy', { user });
          if (!isEmpty(data)) {
            const { toAmount, fromAmount, toCurrency, fromCurrency } = data;
            secondLine = t('amountChanged', {
              fromAmount: formatMoney(fromAmount, fromCurrency),
              toAmount: formatMoney(toAmount, toCurrency),
            });
          }
          break;
        }
        case 'expense.updateAmountToRefund': {
          firstLine = t('amountEditedBy', { user });
          if (data?.to) {
            secondLine = t('amountToReimburse', {
              amount: formatMoney(data.to, companyCurrency),
            });
          }
          break;
        }
        case 'expense.made': {
          firstLine = t('expenseMadeBy', { user });
          break;
        }
        case 'expense.reimbursed': {
          if (data && data.from === 'outside') {
            firstLine = t('reimbursedOutsideSpendesk');
            break;
          }
          if (data && data.from === 'spendesk') {
            firstLine = t('reimbursedFromSpendesk', context);
            break;
          }
          break;
        }
        case 'payment.create': {
          firstLine = getPaymentCreationDetails(
            t,
            user,
            arePointOfSaleDetailsProvided,
            pointOfSale,
            is3DSecure,
            chipUsage,
          );

          if (data?.cardLastFourDigits) {
            secondLine = t('cardLastFourDigits', {
              cardLastFourDigits: data.cardLastFourDigits,
            });
          }

          break;
        }
        case 'payment.invoice': {
          firstLine = t('attachedInvoiceBy', { user });
          break;
        }
        case 'payment.validate': {
          firstLine = t('validatedBy', { user });
          break;
        }
        case 'payment.undoValidate': {
          firstLine = t('unValidatedBy', { user });
          break;
        }
        case 'payment.export': {
          firstLine = t('exportedBy', { user });
          break;
        }
        case 'invoice.paidFromSpendesk': {
          const { amount, currency } = data;
          firstLine = t('paidFromSpendesk', {
            amount: formatMoney(amount, currency),
          });
          break;
        }
        case 'invoice_request.updated': {
          firstLine = t('invoiceUpdatedBy', { user });
          if (data?.from && data?.to) {
            const {
              amount_declared: fromAmount,
              currency_declared: fromCurrency,
            } = get(data, 'from', {});
            const { amount_declared: toAmount, currency_declared: toCurrency } =
              get(data, 'to', {});
            if (fromAmount !== toAmount || fromCurrency !== toCurrency) {
              secondLine = t('amountChanged', {
                fromAmount: formatMoney(fromAmount, fromCurrency),
                toAmount: formatMoney(toAmount, toCurrency),
              });
            }
          }
          break;
        }
        case 'invoice_request.updateSchedules': {
          firstLine = t('schedulesUpdatedBy', { user });
          break;
        }
        case 'invoice_request.addSchedule': {
          firstLine = t('scheduleAddedBy', { user });
          break;
        }
        case 'invoice_request.transferScheduling.paymentScheduleUpdated': {
          firstLine = t('paymentScheduleUpdatedBy', {
            user,
          });
          break;
        }
        case 'invoice_request.transferScheduling.payment.scheduled': {
          if (!data || !data.scheduledAmount || !data.paymentMethod) {
            return null;
          }

          const { scheduledAmount, paymentMethod } = data;
          const formattedScheduledAmount = formatMonetaryValue(scheduledAmount);
          const executionDate = format(new Date(data.executionDate), 'PP');

          if (data.from === 'outside') {
            firstLine = t('paymentScheduledOutsideSpendeskBy', {
              scheduledAmount: formattedScheduledAmount,
              user,
              executionDate,
              context: paymentMethod,
            });
          } else if (data.from === 'spendesk') {
            firstLine = t('paymentScheduledSentForConfirmation', {
              scheduledAmount: formattedScheduledAmount,
              user,
              executionDate,
              context: paymentMethod,
            });
          }
          break;
        }
        case 'invoice_request.transferScheduling.payment.cancelled': {
          firstLine = t('paymentScheduledCancelled', {
            user,
            scheduledAmount: formatMonetaryValue(data.scheduledAmount),
          });
          break;
        }
        case 'invoice_supplier.create': {
          firstLine = t('invoiceSupplierCreatedBy', { user });
          break;
        }
        case 'invoice_supplier.update': {
          firstLine = t('invoiceSupplierUpdatedBy', { user });
          break;
        }
        case 'invoice_supplier_bank_info.create': {
          firstLine = t('invoiceSupplierBankInfoCreatedBy', { user });
          break;
        }
        case 'invoice_supplier_bank_info.update': {
          firstLine = t('invoiceSupplierBankInfoUpdatedBy', { user });
          break;
        }
        case 'invoice_supplier.uploadBankDetails': {
          firstLine = t('invoiceSupplierBankDetailsUploadedBy', { user });
          break;
        }
        case 'scheduled_payment.paid_externally': {
          firstLine = t('scheduledPaymentPaidExternally');
          break;
        }
        case 'request.scheduled_payment.sent_to_payment':
        case 'scheduled_payment.sent_to_payment': {
          firstLine = t('scheduledPaymentSentToPayment', { user });
          break;
        }
        case 'request.wire_transfer.confirmed_by_ao':
        case 'scheduled_payment.confirmed_by_ao': {
          firstLine = t('scheduledPaymentConfirmedByAo', { user });
          break;
        }
        case 'scheduled_payment.rejected_by_ao': {
          firstLine = t('scheduledPaymentRejectedByAo', { user });
          break;
        }
        case 'request.wire_transfer.cancelled_by_controller':
        case 'scheduled_payment.cancelled_by_controller': {
          firstLine = t('scheduledPaymentCancelledByController', { user });
          break;
        }
        case 'transfer_scheduling_payment.paid_by_wire_transfer_internally':
        case 'scheduled_payment.paid_by_wire_transfer_internally': {
          firstLine = t('scheduledPaymentPaidByWireTransferInternally');
          break;
        }
        case 'request.wire_transfer.failed':
        case 'transfer_scheduling_payment.wire_transfer_failed':
        case 'invoice_request.transferScheduling.payment.wire_transfer_failed':
        case 'scheduled_payment.wire_transfer_failed': {
          firstLine = t('wireTransferFailed');
          if (data) {
            if (
              [data.error, data.errorMessage].includes(
                'PaymentComplianceFailure',
              )
            ) {
              secondLine = t('wireTransferComplianceError');
            }
            if (data.error === 'InvalidAmountGreaterThanLimit') {
              secondLine = t('wireTransferInvalidAmountGreaterThanLimitError');
            }
          }
          break;
        }
        case 'invoice_request.transferScheduling.payment.wrong_bank_info':
        case 'scheduled_payment.wrong_bank_info': {
          firstLine = t('wireTransferFailed');
          if (data?.errorCode) {
            secondLine = t('wireTransferWrongBankInfo', {
              errorCode: data.errorCode,
            });
          }
          break;
        }

        case 'invoice_request.transferScheduling.payment.confirmed': {
          firstLine = t('wireTransferConfirmed', {
            user,
            amount: formatMonetaryValue(data.scheduledAmount),
          });
          break;
        }

        default:
          return null;
      }
      if (!firstLine) {
        return null;
      }

      return {
        id: e.id,
        firstLine,
        secondLine,
        occuredAt: e.occuredAt ?? e.occured_at,
      };
    }),
  );
};
