import { Button, Callout } from '@dev-spendesk/grapes';
import { useState } from 'react';

import { unwrapQuery } from 'src/core/api/unwrapQuery';
import { useTranslation } from 'src/core/common/hooks/useTranslation';


import { BulkEditManagersModal } from './BulkEditManagersModal';
import { useGetCompanyManagedByKomboHrIntegrationQuery } from '../../hooks/useGetCompanyManagedByKomboHrIntegrationQuery';
import { type Member } from '../../models/member';

type Props = {
  membersWithMissingManagers: Member[];
};

export const ManagersPrimaryCallout = ({
  membersWithMissingManagers,
}: Props) => {
  const { t } = useTranslation('global');

  const [isModalOpen, setIsModalOpen] = useState(false);

  const isCompanyManagedByIntegrationQueryState =
    useGetCompanyManagedByKomboHrIntegrationQuery();

  const isCompanyManagedByIntegrationResult = unwrapQuery(
    isCompanyManagedByIntegrationQueryState,
  );
  const featureSet = isCompanyManagedByIntegrationResult?.featureSet ?? [];
  const includesReportingManagers = featureSet.includes(
    'includesReportingManagers',
  );

  return (
    <Callout
      data-testid="MissingManagersCallout"
      className="mb-s"
      iconName="sparkles"
      variant="primary"
      title={t(
        'members.reportingManagers.missingManagersCallout.titleMissingApprovalScheme',
        {
          count: membersWithMissingManagers.length,
        },
      )}
    >
      {t(
        'members.reportingManagers.missingManagersCallout.description.missingApprovalScheme',
      )}
      <div className="mt-s flex">
        {!includesReportingManagers && (
          <Button
            className="mr-s"
            text={t('members.reportingManagers.missingManagersCallout.cta')}
            variant="contrasted"
            onClick={() => setIsModalOpen(true)}
          />
        )}
        <Button
          text={t('misc.learnMore')}
          variant="ghost"
          component="a"
          target="_blank"
          href="https://helpcenter.spendesk.com/articles/9910293-approval-workflows"
        />
      </div>
      <BulkEditManagersModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        membersWithMissingManagers={membersWithMissingManagers}
      />
    </Callout>
  );
};
