import { apiV2Url } from 'src/core/utils/api';

type Payload = {
  customFieldValueId: string;
  amount: number;
  period: 'day' | 'month';
  id: string;
};

export const editSpendLimit = async ({
  payload,
  companyId,
  spendLimitId,
}: {
  payload: Payload;
  companyId: string;
  spendLimitId: string;
}): Promise<void> => {
  const response = await fetch(
    apiV2Url(`/spend-limits/${spendLimitId}`, companyId),
    {
      method: 'PUT',
      credentials: 'include',
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    },
  );
  if (!response.ok) {
    throw new Error('Impossible to edit an approval scheme');
  }
};
