import { Callout, FormField } from '@dev-spendesk/grapes';
import truncate from 'lodash/truncate';
import React from 'react';

import { AutocompleteMultipleSearch } from 'src/core/common/components/AutocompleteSearch';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { type Member } from '../../member';
import { ListMembers } from '../ListMembers/ListMembers';

import './RuleMembers.css';

type Props = {
  selectedMemberIds: string[];
  members: Member[];
  warningContent?: React.ReactNode;
  onChange(memberIds: string[]): void;
};

const toMembersById = (members: Member[]) => {
  const result: { [id: string]: Member } = {};
  for (const member of members) {
    result[member.id] = member;
  }
  return result;
};

export const RuleMembers = ({
  selectedMemberIds,
  members,
  warningContent,
  onChange,
}: Props) => {
  const { t } = useTranslation('global');
  const membersById = toMembersById(members);
  const membersInRule = selectedMemberIds
    .map((memberId) => membersById[memberId])
    .filter(Boolean);

  return (
    <div className="RuleMembers">
      <FormField
        className="RuleMembers__select"
        label={t('controlRulesModal.assignMembersToControlRule')}
      >
        <AutocompleteMultipleSearch
          fit="parent"
          placeholder={t('controlRulesModal.members.search')}
          translations={{
            selectAll: t('controlRulesModal.members.selectAll', {
              count: members.length,
            }),
            selected: t('controlRulesModal.members.search'),
          }}
          // eslint-disable-next-line @typescript-eslint/no-shadow
          onSelect={(members) => onChange(members.map((member) => member.key))}
          options={members.map((member) => {
            const memberName = member.pending ? member.email : member.fullname;
            const teamNamesString =
              member.teamNames.length > 0
                ? `(${truncate(member.teamNames.join(', '), { length: 40 })})`
                : '';
            return {
              label: `${memberName} ${teamNamesString}`,
              key: member.id,
            };
          })}
          values={membersInRule.map((member) => ({
            key: member.id,
            label: member.fullname,
          }))}
        />
      </FormField>
      <FormField
        label={t('controlRulesModal.members.counter', {
          count: membersInRule.length,
        })}
        // without the htmlFor set to a non existing id, clicking on the label will trigger the Trash button & delete the member from the list
        htmlFor="prevent-click-on-trash-button"
      >
        <ListMembers
          members={membersInRule}
          onClick={(clickedMember) => {
            onChange(
              selectedMemberIds.filter(
                (memberId) => memberId !== clickedMember.id,
              ),
            );
          }}
        />
      </FormField>
      {warningContent && (
        <Callout
          variant="info"
          title={warningContent}
          className="RuleMembers__flash"
        />
      )}
    </div>
  );
};
