import {
  Callout,
  DropdownItem,
  Icon,
  Tooltip,
  colors,
} from '@dev-spendesk/grapes';

import { useNotifications } from 'modules/app/notifications';
import { PayableInputComponent } from 'modules/payable/components/PayableInputComponent';
import {
  type AnalyticalFieldAssociation,
  type AnalyticalFieldAssociationAutomation,
} from 'modules/payable/models';
import {
  type TGlobalFunctionTyped,
  useTranslation,
} from 'src/core/common/hooks/useTranslation';

import { type CustomFieldValue } from '../../hooks/useValuesOfCustomFieldsQuery';
import {
  type InputValueEntry,
  type PreparePayablesInputConfig,
} from '../../panelInputConfig';

const getCustomFieldWarningMessage = (
  t: TGlobalFunctionTyped,
  inputConfig: PreparePayablesInputConfig,
  fieldEntityValueId?: string,
) => {
  if (inputConfig.type === 'select') {
    const selectedCustomFieldValue = inputConfig.values.find(
      (value) => value.key === fieldEntityValueId,
    );

    if (selectedCustomFieldValue?.archiveDate) {
      return t('expenseInbox.expenseEditor.customFieldDeleted');
    }
  }
};

export const AutomationIcon = ({
  automationKind,
  t,
}: {
  automationKind?: string;
  t: TGlobalFunctionTyped;
}) => {
  if (!automationKind) {
    return null;
  }

  return (
    <Tooltip
      content={t(
        'expenseInbox.expenseEditor.analyticalFieldAssociationPrefilledByPrediction',
      )}
      triggerAsChild
    >
      <Icon data-testid="automation-icon" color={colors.primary} name="robot" />
    </Tooltip>
  );
};

export const PreparePayablesAnalyticalFieldAssociation = ({
  inputConfig,
  analyticalFieldAssociations,
  customFieldsValues,
  errors,
  onChange,
  analyticalFieldAssociationAutomation,
}: {
  inputConfig: PreparePayablesInputConfig;
  analyticalFieldAssociations: AnalyticalFieldAssociation[];
  customFieldsValues: Map<string, CustomFieldValue>;
  errors?: { [id: string]: string };
  onChange: (analyticalFieldAssociations: AnalyticalFieldAssociation[]) => void;
  analyticalFieldAssociationAutomation?: AnalyticalFieldAssociationAutomation[];
}) => {
  const { t } = useTranslation('global');
  const { dangerNotif } = useNotifications();

  const existingValue = analyticalFieldAssociations?.find(
    ({ fieldEntityId }) => fieldEntityId === inputConfig.id,
  );

  const automation = analyticalFieldAssociationAutomation?.find(
    ({ fieldEntityId }) => fieldEntityId === inputConfig.id,
  );

  const fieldEntityValueId = existingValue?.fieldEntityValueId || '';

  const handleChange = (value: string | boolean | null) => {
    if (value === null) {
      // setting no value on an optional field should remove association
      onChange(
        analyticalFieldAssociations.filter(
          ({ fieldEntityId }) => fieldEntityId !== inputConfig.id,
        ),
      );
      return;
    }
    if (typeof value === 'boolean') {
      // todo
      return;
    }

    if (!existingValue) {
      onChange([
        ...analyticalFieldAssociations,
        {
          fieldKind: 'customField',
          fieldEntityId: inputConfig.id,
          fieldEntityValueId: value,
        },
      ]);
      return;
    }

    const newAnalyticalFieldAssociations = analyticalFieldAssociations.map(
      (afa) => {
        if (afa.fieldEntityId === inputConfig.id) {
          return {
            ...afa,
            fieldEntityValueId: value,
          };
        }
        return afa;
      },
    );

    onChange(newAnalyticalFieldAssociations);
  };

  const isCurrentlyAutomatedValue =
    automation?.isAppliedOnPayable &&
    fieldEntityValueId &&
    automation?.fieldEntityValueId === fieldEntityValueId;

  return (
    <>
      <PayableInputComponent
        className="!mb-0 !mt-xs"
        input={{
          ...inputConfig,
          placeholder: inputConfig.placeholder || inputConfig.name,
          'data-testid': `split-${inputConfig.id}`,
          inputVariant: isCurrentlyAutomatedValue ? 'magicGradient' : 'default',
          renderPrefix: () =>
            isCurrentlyAutomatedValue && (
              <AutomationIcon automationKind={automation?.kind} t={t} />
            ),

          renderOption: (
            option: InputValueEntry,
            state: { isSelected: boolean; isHighlighted: boolean },
          ) => {
            return (
              <DropdownItem
                key={option.key}
                label={option.label}
                isSelected={state.isSelected && !option.archiveDate}
                isHighlighted={state.isHighlighted}
                isDisabled={!!option.archiveDate}
                suffix={
                  option.archiveDate
                    ? t('misc.deleted')
                    : option.key === automation?.fieldEntityValueId && (
                        <AutomationIcon
                          automationKind={automation?.kind}
                          t={t}
                        />
                      )
                }
              />
            );
          },
        }}
        allowNewValue={inputConfig.allowNewValue}
        error={errors?.[inputConfig.id]}
        value={fieldEntityValueId}
        warningMessage={getCustomFieldWarningMessage(
          t,
          inputConfig,
          fieldEntityValueId,
        )}
        onChange={handleChange}
        onCreateNewValue={async (newValue) => {
          if (!inputConfig.onCreateNewValue) {
            return '';
          }
          try {
            return await inputConfig.onCreateNewValue(newValue);
          } catch (error) {
            dangerNotif(
              t('expenseInbox.errors.customFieldValueCreationError', {
                value: newValue,
                field: inputConfig.name,
              }),
            );

            throw error;
          }
        }}
      />
      {customFieldsValues.get(fieldEntityValueId as string)?.archiveDate && (
        <Callout
          variant="warning"
          className="my-xs"
          title={t('expenseInbox.expenseEditor.customFieldValueDeleted', {
            value: customFieldsValues.get(fieldEntityValueId as string)?.name,
          })}
        />
      )}
    </>
  );
};
