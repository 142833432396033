import { Button, Modal } from '@dev-spendesk/grapes';
import { type Dispatch, type SetStateAction } from 'react';

import {
  type AccountingSoftware,
  hasIntegrationFileBasedExport,
} from 'modules/bookkeep/integration/status';
import { type IntegrationReverseChargeAccount } from 'modules/bookkeep/settings/accounting';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { type ModalState } from '../ModalState';

interface Props {
  integration: AccountingSoftware;
  onDelete: (taxAccount: IntegrationReverseChargeAccount) => Promise<void>;
  formState: ModalState;
  setFormState: Dispatch<SetStateAction<ModalState>>;
}

export const ReverseChargeDeleteModal = ({
  integration,
  onDelete,
  formState,
  setFormState,
}: Props) => {
  const { t } = useTranslation('global');

  return (
    <Modal
      title={
        formState.kind === 'confirmDeleteReverseCharge'
          ? t(
              'bookkeep.integrations.settings.reverseChargeAccountsTable.confirmDeleteTaxAccountTitle',
              { accountName: formState.name },
            )
          : ''
      }
      subtitle={
        hasIntegrationFileBasedExport(integration)
          ? t('bookkeep.integrations.fileBased.confirmDeleteDescription')
          : t(
              'bookkeep.integrations.settings.reverseChargeAccountsTable.confirmSendBackToPrepareSubtitle',
            )
      }
      isOpen={formState.kind === 'confirmDeleteReverseCharge'}
      iconVariant="alert"
      iconName="trash"
      actions={[
        <Button
          key="cancel"
          onClick={() => setFormState({ kind: 'closed' })}
          text={t('misc.cancel')}
          variant="secondary"
        />,
        <Button
          key="yes"
          onClick={() => {
            if (formState.kind === 'confirmDeleteReverseCharge') {
              onDelete({
                id: formState.id,
                name: formState.name,
                codeDebit: formState.codeDebit,
                codeCredit: formState.codeCredit,
                type: 'reverseCharge',
              });
            }

            setFormState({ kind: 'closed' });
          }}
          text={t(
            'bookkeep.integrations.settings.reverseChargeAccountsTable.confirmDeletion',
          )}
          variant="alert"
        />,
      ]}
    />
  );
};
