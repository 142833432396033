import { apiV2Url } from 'src/core/utils/api';

type CreationPayload = {
  approvalSchemeId: string;
};

export const createApprovalWorkflow = async (
  payload: CreationPayload,
  companyId: string,
): Promise<Response> => {
  const response = await fetch(apiV2Url('/approval-workflows', companyId), {
    method: 'POST',
    credentials: 'include',
    headers: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
  if (response.status === 200) {
    return response.json();
  }
  throw new Error('Impossible to create an approval scheme');
};
