import { SwitchField } from '@dev-spendesk/grapes';

import { useNotifications, NotificationType } from 'modules/app/notifications';
import {
  type I18nKey,
  useTranslation,
} from 'src/core/common/hooks/useTranslation';

import {
  AUTO_REMINDERS_DEFAULT_VALUE,
  type Notification,
} from '../../../../models';
import { useUpdateNotificationMutation } from '../../hooks/useUpdateNotificationMutation';
import { NotificationReceiptRemindersContainer } from '../NotificationReceiptReminders';

type DefinedCode =
  | 'walletRefilled'
  | 'notifyApprovalRightsApprover'
  | 'autoReminders';

type Props = {
  notification: Notification & {
    notifCode: DefinedCode;
  };
};

export const NotificationSetting = ({ notification }: Props) => {
  const { t } = useTranslation('global');
  const { pushNotif } = useNotifications();

  const [updateNotification] = useUpdateNotificationMutation();

  return (
    <>
      <SwitchField
        id={notification.notifCode}
        fit="parent"
        isChecked={notification.checked}
        label={t(notificationsTitleToI18nKey[notification.notifCode])}
        helpText={t(notificationsCompanyToI18nKey[notification.notifCode])}
        onChange={async (e) => {
          try {
            await updateNotification({
              notif_code: notification.notifCode,
              checked: e.target.checked,
              value:
                notification.notifCode === 'autoReminders'
                  ? AUTO_REMINDERS_DEFAULT_VALUE
                  : null,
            });
          } catch {
            pushNotif({
              type: NotificationType.Danger,
              message: t(
                'generalSettings.notifications.editFailureNotification',
              ),
            });
            return;
          }

          pushNotif({
            type: NotificationType.Success,
            message: t('generalSettings.notifications.editSuccessNotification'),
          });
        }}
      />
      {notification.notifCode === 'autoReminders' && notification.checked && (
        <NotificationReceiptRemindersContainer notification={notification} />
      )}
    </>
  );
};

const notificationsTitleToI18nKey: Record<DefinedCode, I18nKey> = {
  walletRefilled: 'generalSettings.notifications.walletRefilled.title',
  notifyApprovalRightsApprover:
    'generalSettings.notifications.notifyApprovalRightsApprover.title',
  autoReminders: 'generalSettings.notifications.autoReminders.title',
};

const notificationsCompanyToI18nKey: Record<DefinedCode, I18nKey> = {
  walletRefilled: 'generalSettings.notifications.walletRefilled.company',
  notifyApprovalRightsApprover:
    'generalSettings.notifications.notifyApprovalRightsApprover.company',
  autoReminders: 'generalSettings.notifications.autoReminders.company',
};
