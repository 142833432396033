import {
  type PartnerIntegration,
  partnerIntegrationsSchema,
} from '../types/partnerIntegration';

export const useGetPartnerIntegrations = (): PartnerIntegration[] => {
  const partnerIntegrations = [
    {
      description: 'integration.spendesk-partner-corma.description',
      helpLink:
        'http://helpcenter.spendesk.com/en/articles/10146151-connect-spendesk-to-corma',
      id: 'spendesk-partner-corma',
      logo: 'corma',
      name: 'Corma',
      website: 'https://app.corma.io/',
    },
    {
      description: 'integration.spendesk-partner-fygr.description',
      helpLink:
        'http://helpcenter.spendesk.com/en/articles/9620560-connect-spendesk-and-fygr',
      id: 'spendesk-partner-fygr',
      logo: 'fygr',
      name: 'Fygr',
      website: 'https://app.fygr.io',
    },
    {
      description: 'integration.spendesk-partner-licenceone.description',
      helpLink:
        'http://helpcenter.spendesk.com/en/articles/10146012-connect-spendesk-to-licenceone',
      id: 'spendesk-partner-licenceone',
      logo: 'licenceOne',
      name: 'LicenceOne',
      website: 'https://app.licence.one/',
    },
    {
      description: 'integration.spendesk-partner-payflows.description',
      helpLink:
        'http://helpcenter.spendesk.com/en/articles/10138805-connect-spendesk-and-payflows ',
      id: 'spendesk-partner-payflows',
      logo: 'payflows',
      name: 'Payflows',
      website: 'http://app.payflows.io',
    },
    {
      description: 'integration.spendesk-partner-trelica.description',
      helpLink:
        'http://helpcenter.spendesk.com/en/articles/10146089-connect-spendesk-to-trelica',
      id: 'spendesk-partner-trelica',
      logo: 'trelica',
      name: 'Trelica',
      website: 'https://app.trelica.com/',
    },
  ];

  return partnerIntegrationsSchema.parse(partnerIntegrations);
};
