import { FormField, OptionGroup, TextInput } from '@dev-spendesk/grapes';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useNotifications } from 'modules/app/notifications';
import { useGetDefaultExpenseAccountQuery } from 'modules/bookkeep/settings/integrations/hooks/useGetDefaultExpenseAccountQuery';
import { useSetDefaultExpenseAccountMutation } from 'modules/bookkeep/settings/integrations/hooks/useSetDefaultExpenseAccountMutation';
import { BulkImportExpenseAccountForm } from 'modules/bookkeep/settings/integrations/pages/LegacyIntegrationsAccountingPage/sections/ExpenseAccountsSection/BulkImportExpenseAccountForm';
import { ExpenseAccountErrorModal } from 'modules/bookkeep/settings/integrations/pages/LegacyIntegrationsAccountingPage/sections/ExpenseAccountsSection/ExpenseAccountErrorModal';
import {
  reshapeToFormData,
  useExpenseAccountsMutation,
} from 'modules/bookkeep/settings/integrations/pages/LegacyIntegrationsAccountingPage/sections/ExpenseAccountsSection/useImportExpenseAccountsMutation';
import { TaskStepLayout } from 'modules/onboarding/components/TaskStepLayout';
import { unwrapQuery } from 'src/core/api/unwrapQuery';
import { routeFor } from 'src/core/constants/routes';

import { type FormValues } from './form';
import { useUpdateCachedTaskStatus } from '../../SetupHubPage/hooks/useUpdateCachedTaskStatus';
import { routes } from '../../routes';
import { ExpenseAccountTableAndModals } from '../components/ExpenseAccountTableAndModals';

export const AccountingExpenseAccounts = () => {
  const history = useHistory();

  const { t } = useTranslation('global');
  const { dangerNotif } = useNotifications();

  const companyId = useCompanyId();

  const [
    importExpenseAccounts,
    importExpenseAccountsState,
    resetImportExpenseAccountsState,
  ] = useExpenseAccountsMutation();
  const importErrors =
    importExpenseAccountsState.status === 'error' &&
    importExpenseAccountsState.error.type === 'RequestError'
      ? importExpenseAccountsState.error.data.errors
      : [];

  const defaultExpenseAccountQueryState = useGetDefaultExpenseAccountQuery();
  const defaultExpenseAccount = unwrapQuery(defaultExpenseAccountQueryState);
  const [setDefaultExpenseAccount] = useSetDefaultExpenseAccountMutation();

  const updateCachedTaskStatus = useUpdateCachedTaskStatus();

  const updateDefaultExpenseAccount = async (
    newDefaultExpenseAccountCode: string,
  ) => {
    if (!defaultExpenseAccount) {
      return { outcome: 'notSet', reason: 'accountNotFound' };
    }

    const newDefaultExpenseAccount =
      newDefaultExpenseAccountCode !== ''
        ? {
            ...defaultExpenseAccount,
            code: newDefaultExpenseAccountCode,
            isArchived: false,
          }
        : { ...defaultExpenseAccount, isArchived: true };

    const result = await setDefaultExpenseAccount(newDefaultExpenseAccount);
    if (result.outcome === 'notSet') {
      dangerNotif(
        result.reason === 'codeAlreadyExists'
          ? t(
              'bookkeep.integrations.settings.defaultExpenseAccountForm.codeAlreadyExistsError',
            )
          : t('misc.errors.unknownError'),
      );
    }
    return result;
  };

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldError,
    submitCount,
  } = useFormik<FormValues>({
    initialValues: {
      file: undefined,
      prefixNameWithCode: true,
      defaultExpenseAccountCode: defaultExpenseAccount?.isArchived
        ? ''
        : (defaultExpenseAccount?.code ?? ''),
      tab: 'import',
    },
    enableReinitialize: true,
    validate: (newValues) => {
      if (newValues.tab === 'import' && !newValues.file) {
        return { file: 'missing_file' };
      }
    },
    onSubmit: async (newValues) => {
      if (newValues.tab === 'import') {
        if (!newValues.file) {
          return;
        }
        const payload = reshapeToFormData(
          newValues.file,
          newValues.prefixNameWithCode,
        );
        try {
          await importExpenseAccounts(payload);

          const nextPath = routeFor(
            routes.ACCOUNTING_EXPENSE_ACCOUNTS_LIST.path,
            { company: companyId },
          );
          history.push(nextPath);
        } catch {
          // Not doing anything here
          // because errors are handled through the mutation state
        }
      }

      if (newValues.tab === 'manual') {
        try {
          const result = await updateDefaultExpenseAccount(
            newValues.defaultExpenseAccountCode,
          );
          if (result.outcome === 'set') {
            updateCachedTaskStatus('expense_accounts_codes');
            const nextPath = routeFor(routes.SETUP_HUB_TASK_LIST.path, {
              company: companyId,
            });
            history.push(nextPath);
          }
        } catch {
          return dangerNotif(t('misc.errors.unknownError'));
        }
      }
    },
  });

  const goBack = () => {
    const setupHubPath = routeFor(routes.SETUP_HUB_TASK_LIST.path, {
      company: companyId,
    });
    history.push(setupHubPath);
  };

  return (
    <div className="page__container">
      <TaskStepLayout
        description={t('setupHub.expenseAccounts.description')}
        extraDescription={
          <p className="text-neutral-dark body-l">
            {t('setupHub.expenseAccounts.extraDescription')}
          </p>
        }
        onBack={goBack}
        onNext={handleSubmit}
        title={t('setupHub.expenseAccounts.title')}
        video={{
          // TODO(GROW-1484): Replace with localized video URL
          url: '',
          title: t('setupHub.expenseAccounts.helpTitle'),
        }}
      >
        <FormField
          className="mb-s"
          label={t('setupHub.expenseAccounts.tabs.label')}
        >
          <OptionGroup
            name="tab"
            options={[
              {
                value: 'import',
                label: t('setupHub.expenseAccounts.tabs.importOption'),
              },
              {
                value: 'manual',
                label: t('setupHub.expenseAccounts.tabs.manualOption'),
              },
            ]}
            value={values.tab}
            onChange={(event) =>
              setFieldValue(event.target.name, event.target.value)
            }
          />
        </FormField>
        {values.tab === 'import' && (
          <>
            <div className="rounded-xs bg-page-background px-m py-s">
              <BulkImportExpenseAccountForm
                templateButtonVariant="contrasted"
                file={values.file}
                hasMissingFileError={submitCount > 0 && !!errors.file}
                prefixNameWithCode={values.prefixNameWithCode}
                setFile={(file) => setFieldValue('file', file)}
                setHasMissingFileError={(hasError) =>
                  setFieldError('file', hasError ? 'missing_file' : undefined)
                }
                setPrefixNameWithCode={(prefixNameWithCode) =>
                  setFieldValue('prefixNameWithCode', prefixNameWithCode)
                }
              />
            </div>
            <ExpenseAccountErrorModal
              action={resetImportExpenseAccountsState}
              errors={importErrors}
              isOpen={importErrors.length > 0}
            />
          </>
        )}
        {values.tab === 'manual' && (
          <div className="flex flex-col gap-s">
            <ExpenseAccountTableAndModals />
            <div className="flex items-center gap-s">
              <div className="separator" />
              <p className="lize uppercase text-complementary body-l">
                {t('misc.or')}
              </p>
              <div className="separator" />
            </div>
            <div className="rounded-xs bg-page-background px-m py-s">
              <FormField
                description={t(
                  'bookkeep.integrations.settings.defaultExpenseAccountForm.description',
                )}
                label={t(
                  'bookkeep.integrations.settings.defaultExpenseAccountForm.name',
                )}
              >
                <TextInput
                  name="defaultExpenseAccountCode"
                  onChange={handleChange}
                  placeholder={t(
                    'bookkeep.integrations.settings.defaultExpenseAccountForm.placeholder',
                  )}
                  value={values.defaultExpenseAccountCode}
                />
              </FormField>
            </div>
          </div>
        )}
      </TaskStepLayout>
    </div>
  );
};
