import { Button, Modal } from '@dev-spendesk/grapes';

import {
  type I18nKey,
  type TGlobalFunctionTyped,
  useTranslation,
} from 'src/core/common/hooks/useTranslation';

import styles from './ExpenseAccountErrorModal.module.css';

type ExpenseAccountWorksheetErrorPosition = {
  column: number;
  row: number;
};

type ExpenseAccountImportError = {
  position?: ExpenseAccountWorksheetErrorPosition;
  errorCode: string;
  message: string;
};

type ExpenseAccountErrorModalProps = {
  errors: ExpenseAccountImportError[];
  isOpen: boolean;
  action: () => void;
  close?: () => void;
};

type ErrorBodyProps = {
  title: string;
  description: string;
  translate: TGlobalFunctionTyped;
  error: ExpenseAccountImportError;
};

type BodyPropsByCode = {
  [key: string]: {
    title: I18nKey;
    description?: I18nKey;
  };
};

const ErrorBody = function ({
  title,
  description,
  translate,
  error,
}: ErrorBodyProps) {
  const getRowText = function (row: number) {
    const rowNumber = row + 1;
    return translate('accounting.expenseAccounts.errors.rowPositionError', {
      row: rowNumber,
    });
  };

  return (
    <div className={styles.ExpenseAccountImportError}>
      <h3>&quot;{title}&quot;</h3>
      <p>{description}</p>
      {error.position !== undefined ? getRowText(error.position.row) : null}
    </div>
  );
};

const bodyPropsByCode: BodyPropsByCode = {
  empty_expense_account_name: {
    title: 'accounting.expenseAccounts.errors.emptyExpenseAccountName.title',
    description:
      'accounting.expenseAccounts.errors.emptyExpenseAccountName.description',
  },
  empty_expense_account_code: {
    title: 'accounting.expenseAccounts.errors.emptyExpenseAccountCode.title',
    description:
      'accounting.expenseAccounts.errors.emptyExpenseAccountCode.description',
  },
  duplicate_expense_account_name: {
    title:
      'accounting.expenseAccounts.errors.duplicateExpenseAccountName.title',
    description:
      'accounting.expenseAccounts.errors.duplicateExpenseAccountName.description',
  },
  duplicate_expense_account_code: {
    title:
      'accounting.expenseAccounts.errors.duplicateExpenseAccountCode.title',
    description:
      'accounting.expenseAccounts.errors.duplicateExpenseAccountCode.description',
  },
  invalid_file_format: {
    title: 'accounting.expenseAccounts.errors.invalidFileFormat.title',
    description:
      'accounting.expenseAccounts.errors.invalidFileFormat.description',
  },
  invalid_file_type: {
    title: 'accounting.expenseAccounts.errors.invalidFileType.title',
    description:
      'accounting.expenseAccounts.errors.invalidFileType.description',
  },
  empty_expense_accounts: {
    title: 'accounting.expenseAccounts.errors.emptyExpenseAccounts.title',
    description:
      'accounting.expenseAccounts.errors.emptyExpenseAccounts.description',
  },
  invalid_form_data: {
    title: 'accounting.expenseAccounts.errors.invalidFormData.title',
    description:
      'accounting.expenseAccounts.errors.invalidFormData.description',
  },
  default: {
    title: 'accounting.expenseAccounts.errors.default.title',
  },
};

const createUniqueKeyFromError = (error: ExpenseAccountImportError): string => {
  if (!error.position) {
    return error.errorCode;
  }

  return `${error.errorCode}-${error.position.row}`;
};

export const ExpenseAccountErrorModal = function ({
  errors,
  action,
  isOpen,
  close,
}: ExpenseAccountErrorModalProps) {
  const { t } = useTranslation('global');

  return (
    <Modal
      isOpen={isOpen}
      title={t('accounting.expenseAccounts.errors.modalTitle')}
      iconName="failure"
      iconVariant="alert"
      actions={[
        close && (
          <Button
            key="cancel"
            text={t('accounting.expenseAccounts.errors.cancel')}
            variant="secondary"
            onClick={close}
          />
        ),
        <Button
          key="try_again"
          text={t('accounting.expenseAccounts.errors.tryAgain')}
          onClick={action}
        />,
      ]}
    >
      {errors.map((error) => {
        const { description, title } =
          bodyPropsByCode[error.errorCode] ?? bodyPropsByCode.default;
        const descriptionText = description ? t(description) : error.message;
        return (
          <ErrorBody
            key={createUniqueKeyFromError(error)}
            error={error}
            translate={t}
            title={t(title)}
            description={descriptionText}
          />
        );
      })}
    </Modal>
  );
};
