import { useHistory, useParams, useRouteMatch } from 'react-router-dom';

import { routes, routeFor } from 'src/core/constants/routes';

export const useExpensesClaimsRouter = () => {
  const history = useHistory();
  const { company: companyId } = useParams();
  const { path: currentPath } = useRouteMatch();

  const goToPay = (userId: string = '') => {
    history.push(
      routeFor(routes.EXPENSE_CLAIMS_PAY.path, {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        company: companyId!,
        userId,
      }),
    );
  };

  const goToReview = () =>
    history.push(
      routeFor(routes.EXPENSE_CLAIMS_REVIEW.path, {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        company: companyId!,
      }),
    );

  const goToHistory = (paymentId?: string) => {
    history.push(
      routeFor(routes.EXPENSE_CLAIMS_HISTORY.path, {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        company: companyId!,
        paymentId,
      }),
    );
  };

  const goToConfirm = () => {
    history.push(
      routeFor(routes.EXPENSE_CLAIMS_CONFIRM.path, {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        company: companyId!,
      }),
    );
  };

  const goToRelativeScheduledPaymentDetails = (paymentId: string) => {
    history.push(
      routeFor(currentPath, {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        company: companyId!,
        paymentId,
      }),
    );
  };

  return {
    goToPay,
    goToReview,
    goToHistory,
    goToConfirm,
    goToRelativeScheduledPaymentDetails,
  };
};
