import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// TODO Should be moved to common components folder
export const FilteredListBySearchWrapper = <T extends object>({
  items,
  searchableFields,
  isSearchEnabled,
  isSyncedWithUrl = false,
  children,
}: {
  items: T[];
  searchableFields: (keyof T)[];
  isSearchEnabled: boolean;
  isSyncedWithUrl?: boolean;
  children: ({
    search,
    setSearch,
    filteredItems,
  }: {
    search: string;
    setSearch: (search: string) => void;
    filteredItems: T[];
  }) => React.ReactNode;
}) => {
  const [search, setSearch] = useState<string>('');

  const sanitizedTerm = search.trim().toLowerCase();
  const filterBySearchTerm = (item: T) => {
    return searchableFields
      .map((field) => String(item[field]).toLowerCase())
      .some((itemField) => itemField.includes(sanitizedTerm));
  };

  const querySearch = useLocation().search.toString();
  useEffect(() => {
    if (isSyncedWithUrl && isSearchEnabled) {
      const searchTermFromUrl = new URLSearchParams(querySearch).get('q');
      if (searchTermFromUrl) {
        setSearch(searchTermFromUrl);
      }
    }
  }, [isSearchEnabled]);

  const history = useHistory();
  useEffect(() => {
    if (isSyncedWithUrl && isSearchEnabled) {
      const searchParams = new URLSearchParams(querySearch);
      if (sanitizedTerm.length > 0) {
        searchParams.set('q', sanitizedTerm);
      } else {
        searchParams.delete('q');
      }

      history.push({
        search: searchParams.toString(),
      });
    }
  }, [sanitizedTerm, isSearchEnabled]);

  return children({
    search,
    setSearch,
    filteredItems: items.filter((entity) => filterBySearchTerm(entity)),
  });
};
