import { useBookkeepingSettings } from 'modules/bookkeep/apis/useBookkeepingSettings';
import {
  getIsPayableExported,
  isSpanishDPRPayable as getIsSpanishDPRPayable,
  type PayableState,
} from 'modules/bookkeep/payables/models/payable';
import { type Payable } from 'modules/bookkeep/prepare-payables/models';
import { unwrapQuery } from 'src/core/api/unwrapQuery';

import { useIsLockedPayable } from './useIsLockedPayable';

export const useIsEditablePayable = (
  payable?: {
    creationDate: Date;
    state: PayableState;
  } & Pick<Payable, 'documentaryEvidence'>,
): boolean => {
  const bookkeepingSettingsQueryResult = useBookkeepingSettings({
    isEnabled: true,
  });

  const bookkeepingSettings = unwrapQuery(bookkeepingSettingsQueryResult);

  const isLockedPayable = useIsLockedPayable(payable);

  if (!payable) {
    return false;
  }

  const isPayableExported = getIsPayableExported(payable);

  const isSpanishDPRPayable = getIsSpanishDPRPayable(payable);

  if (!isPayableExported) {
    return true;
  }

  if (isPayableExported && isSpanishDPRPayable) {
    return false;
  }

  return !!(
    bookkeepingSettings?.editPayablesAfterExport?.hasOptedIn && !isLockedPayable
  );
};
