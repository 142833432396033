import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

type TransferSchedulingResponse = {
  paymentsToSchedule: number;
  submittedCreditNotesToAssess: number;
  submittedInvoicesToAssess: number;
  transfersToConfirm: number;
};

type InvoiceStats = {
  toReview: number;
  toSchedule: number;
  toConfirm: number;
};

export const useOrganisationEntityTransferSchedulingQuery = (
  companyId: string,
  options: {
    isEnabled: boolean;
  } = {
    isEnabled: true,
  },
): QueryState<InvoiceStats> => {
  return useQuery<InvoiceStats, TransferSchedulingResponse>({
    key: ['organisation-reporting', companyId, 'invoices-to-pay'],
    request: {
      type: 'rest',
      target: 'baseAPI',
      endpoint: `/${companyId}/transfer-scheduling/counts?type=invoice`,
    },
    isEnabled: options.isEnabled,
    reshapeData: (stats) => ({
      toReview:
        stats.submittedCreditNotesToAssess + stats.submittedInvoicesToAssess,
      toSchedule: stats.paymentsToSchedule,
      toConfirm: stats.transfersToConfirm,
    }),
  });
};
