import { EmptyState } from '@dev-spendesk/grapes';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

export const EntityListEmptyState = () => {
  const { t } = useTranslation('global');

  return (
    <EmptyState
      iconName="search"
      title={t('organisation.reporting.page.entities.emptyState.title')}
      subtitle={t('organisation.reporting.page.entities.emptyState.subtitle')}
    />
  );
};
