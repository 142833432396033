import { ModalSlideshow } from '@dev-spendesk/grapes';
import { Trans } from 'react-i18next';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import illustrationActionsEn from './assets/multi-entity-hub-actions-en.webp';
import illustrationActionsFr from './assets/multi-entity-hub-actions-fr.webp';
import illustrationEntitiesEn from './assets/multi-entity-hub-entities-en.webp';
import illustrationEntitiesFr from './assets/multi-entity-hub-entities-fr.webp';
import illustrationHomepageEn from './assets/multi-entity-hub-homepage-en.webp';
import illustrationHomepageFr from './assets/multi-entity-hub-homepage-fr.webp';

type Props = {
  onModalHidden: () => void;
};

export const IntroModal = ({ onModalHidden }: Props) => {
  const { t, activeLanguage } = useTranslation('global');

  const getLocalizedImage = (slide: 'homepage' | 'entities' | 'actions') => {
    switch (activeLanguage) {
      case 'fr':
        if (slide === 'actions') {
          return illustrationActionsFr;
        }
        if (slide === 'entities') {
          return illustrationEntitiesFr;
        }
        return illustrationHomepageFr;

      default: {
        if (slide === 'actions') {
          return illustrationActionsEn;
        }
        if (slide === 'entities') {
          return illustrationEntitiesEn;
        }
        return illustrationHomepageEn;
      }
    }
  };

  return (
    <ModalSlideshow
      isOpen
      slides={[
        {
          title: t('organisation.reporting.introModal.slides.slide1.title'),
          content: (
            <p className="leading-[18px] text-neutral-dark body-m">
              <Trans i18nKey="organisation.reporting.introModal.slides.slide1.description" />
            </p>
          ),
          illustration: (
            <div className="h-full w-full bg-primary-lightest pb-[82px] pl-l pt-[128px]">
              <img
                src={getLocalizedImage('homepage')}
                alt="Multi entity hub homepage"
                className="absolute right-0"
                height={350}
              />
            </div>
          ),
        },
        {
          title: t('organisation.reporting.introModal.slides.slide2.title'),
          content: (
            <p className="leading-[18px] text-neutral-dark body-m">
              <Trans i18nKey="organisation.reporting.introModal.slides.slide2.description" />
            </p>
          ),
          illustration: (
            <div className="relative flex h-full w-full items-center bg-primary-lightest pl-[30px]">
              <img
                src={getLocalizedImage('entities')}
                alt="Key spend metrics"
                className="absolute right-0"
                height={257}
              />
            </div>
          ),
        },
        {
          title: t('organisation.reporting.introModal.slides.slide3.title'),
          content: (
            <p className="leading-[18px] text-neutral-dark body-m">
              <Trans i18nKey="organisation.reporting.introModal.slides.slide3.description" />
            </p>
          ),
          illustration: (
            <div className="relative flex h-full w-full items-center bg-primary-lightest pr-[30px]">
              <img
                src={getLocalizedImage('actions')}
                alt="Outstanding actions"
                className="absolute left-0"
                height={236}
              />
            </div>
          ),
        },
      ]}
      translations={{
        cancel: t('organisation.reporting.introModal.ctas.cancel'),
        previous: t('organisation.reporting.introModal.ctas.previous'),
        next: t('organisation.reporting.introModal.ctas.next'),
        done: t('organisation.reporting.introModal.ctas.done'),
      }}
      onCancel={onModalHidden}
      onClose={onModalHidden}
      onDone={onModalHidden}
    />
  );
};
