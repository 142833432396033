import { Badge } from '@dev-spendesk/grapes';
import classnames from 'classnames';

import { EllipsisTooltip } from 'src/core/common/components/EllipsisTooltip';

type Props = {
  label: string;
  count: number;
  href: string;
  callback?: () => void;
  isInactive?: boolean;
};

export const EntityItemActionItem = ({
  label,
  count,
  href,
  callback,
  isInactive = false,
}: Props) => (
  <button
    type="button"
    disabled={isInactive}
    className={classnames(
      'flex w-full items-center justify-between gap-xxs rounded-[8px] border border-solid border-neutral-light p-xs',
      isInactive
        ? 'bg-neutral-lightest'
        : 'cursor-pointer bg-white hover:border-primary-light hover:bg-primary-lightest',
    )}
    onClick={() => {
      window.open(href, '_blank');
      callback?.();
    }}
  >
    <EllipsisTooltip text={label} className="text-complementary body-m" />
    <Badge
      className={classnames(
        'border border-solid',
        isInactive ? 'border-neutral-light' : 'border-primary-lighter',
      )}
      variant={isInactive ? 'secondary' : 'primary'}
    >
      {count}
    </Badge>
  </button>
);
