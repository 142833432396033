import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

import { type ExpenseClaimsCounts } from '../../../reimbursements/types';

type ExpenseClaimsStats = {
  toReview: number | undefined;
  toReimburse: number | undefined;
  toConfirm: number | undefined;
};

export const useOrganisationEntityExpenseClaimsQuery = (
  companyId: string,
  options: {
    isEnabled: boolean;
  } = {
    isEnabled: true,
  },
): QueryState<ExpenseClaimsStats> => {
  return useQuery<ExpenseClaimsStats, ExpenseClaimsCounts>({
    key: ['organisation-reporting', companyId, 'expense-claims'],
    request: {
      type: 'rest',
      target: 'baseAPI',
      endpoint: `/${companyId}/scheduled_payments/expense-claim/counts`,
    },
    isEnabled: options.isEnabled,
    reshapeData: ({ expensesToValidate, paymentsToConfirm, usersToPay }) => ({
      toReview: expensesToValidate,
      toReimburse: usersToPay,
      toConfirm: paymentsToConfirm,
    }),
  });
};
