import { useContext } from 'react';

import { notificationsContext } from '../contexts/notificationsContext';
import {
  NotificationType,
  type SuccessNotif,
  type PushNotif,
  type DangerNotif,
} from '../notification';

export { NotificationType } from 'modules/app/notifications/notification';

/**
 * @example
 * import { useNotifications, NotificationType } from 'modules/app/notifications';
 * const MyComponent = () => {
 *   const { pushNotif } = useNotifications();
 *   const onClick = () => {
 *     pushNotif({
 *       type: NotificationType.Success,
 *       message: 'Clicked!',
 *     });
 *   }
 *   return <button onClick={onClick}>Click</button>;
 * };
 */
export const useNotifications = (): {
  pushNotif: PushNotif;
  successNotif: SuccessNotif;
  dangerNotif: DangerNotif;
} => {
  const pushNotif = useContext(notificationsContext);

  if (pushNotif === undefined) {
    throw new Error(
      `Cannot useNotifications before it's initialized. Did you forget to set up <NotificationsContextProvider>? In tests you can use renderWithRedux + <NotificationsShell> or <AppShellContainer>`,
    );
  }

  return {
    pushNotif,
    successNotif: (
      message: React.ReactNode,
      description?: React.ReactNode,
    ): void =>
      pushNotif({ message, description, type: NotificationType.Success }),
    dangerNotif: (
      message: React.ReactNode,
      description?: React.ReactNode,
    ): void =>
      pushNotif({ message, description, type: NotificationType.Danger }),
  };
};
