import { SkeletonTable, SkeletonText } from '@dev-spendesk/grapes';

export const ExpenseAccountsSkeletonTable = () => (
  <SkeletonTable
    numberOfRows={2}
    columns={[
      {
        cell: <SkeletonText width="80%" />,
        header: <SkeletonText width="50%" />,
        width: '50%',
      },
      {
        cell: <SkeletonText width="80%" />,
        header: <SkeletonText width="50%" />,
        width: '50%',
      },
    ]}
    withColumnSeparator
    withHeader
  />
);
