import { Button, Link, Avatar } from '@dev-spendesk/grapes';
import React, { useId } from 'react';
import { Trans } from 'react-i18next';

import { useTranslation } from 'common/hooks/useTranslation';
import { getPartnerIntegrationLogoByName } from 'src/core/modules/integrations/utils';

import { type PartnerIntegration } from '../../../types/partnerIntegration';

type Props = {
  integration: PartnerIntegration;
};

export function PartnerIntegrationBox(props: Props) {
  const { t } = useTranslation('global');
  const titleId = useId();
  const { description, helpLink, name, website } = props.integration;
  const logo = getPartnerIntegrationLogoByName(props.integration.logo);

  return (
    <article className="box flex flex-col gap-s p-m" aria-labelledby={titleId}>
      <div className="flex flex-row items-center gap-xs">
        <Avatar variant="square" text={name} src={logo} size="m" />
        <h1 className="text-complementary title-l" id={titleId}>
          {name}
        </h1>
      </div>
      <div className="w-full grow text-neutral-dark">
        <>
          <Trans i18nKey={description}>
            text
            <Link
              className="text-complementary"
              isExternal
              href={helpLink}
              rel="noreferrer"
            >
              link
            </Link>
            text
          </Trans>
        </>
      </div>
      <div className="w-full">
        <Button
          variant="secondary"
          fit="parent"
          iconName="external"
          text={t('integration.view')}
          name="view.integration"
          onClick={() => {
            window.open(website);
          }}
        />
      </div>
    </article>
  );
}
