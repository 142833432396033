import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';
import { useParams } from 'src/core/common/hooks/useParams';
import { routes } from 'src/core/constants/routes';

import { GET_SUPPLIERS_WITHOUT_ACCOUNT_PAYABLE_LIST } from '../queries/getSuppliersWithoutAccountPayableList';
import { type SuppliersWithoutAccountPayableRow } from '../types';

type RawData = {
  company: {
    suppliers: {
      totalCount: number;
      edges: {
        node: {
          thumbnailUrl: string;
          name: string;
          id: string;
        };
      }[];
    };
  };
};

export const useGetSuppliersWithoutAccountPayableListQuery = (
  search: string,
  archivalStatus: 'current' | 'archived' | undefined,
): QueryState<SuppliersWithoutAccountPayableRow> => {
  const { company: companyId } = useParams(
    routes.COMPANY_ACCOUNTS_PAYABLE.path,
  );

  return useQuery<SuppliersWithoutAccountPayableRow, RawData>({
    key: [
      'useGetSuppliersWithoutAccountPayableListQuery',
      companyId,
      search,
      archivalStatus,
    ],
    request: {
      type: 'graphQL',
      target: 'v2',
      query: GET_SUPPLIERS_WITHOUT_ACCOUNT_PAYABLE_LIST,
      variables: {
        filter: {
          hasSupplierAccount: false,
          search,
          ...(archivalStatus === undefined
            ? {}
            : { isArchived: archivalStatus === 'archived' }),
        },
      },
    },
    reshapeData: (rawData) => {
      const { edges, totalCount } = rawData.company.suppliers;

      const logosUrls =
        totalCount === 1
          ? [edges[0]?.node?.thumbnailUrl]
          : [edges[0]?.node?.thumbnailUrl, edges[1]?.node?.thumbnailUrl];
      return {
        totalCount,
        logosUrls,
        suppliers: edges.map(({ node }) => ({
          id: node.id,
          name: node.name,
        })),
      };
    },
  });
};
