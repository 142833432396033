import { Button, FormField, Modal } from '@dev-spendesk/grapes';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';


import { CountryAutocomplete } from 'src/core/common/components/CountryAutocomplete';
import { PhoneNumberInput } from 'src/core/common/components/PhoneNumberInput';
import { getCountryFromNumber } from 'src/core/common/components/PhoneNumberInput/helpers';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import {
  FormSubmitButton,
  FormTextInput,
  useForm,
} from 'src/core/common/utils/forms';
import { useCompany } from 'src/core/modules/app/hooks/useCompany';
import { useNotifications } from 'src/core/modules/app/notifications';
import {
  allowedShippingCountriesForSfsEeaCardOrders,
  allowedShippingCountriesForSfsUkCardOrders,
  type allowedShippingCountriesForAllSfsCardOrders,
} from 'src/ts-rest-contract/src';

import type { ApiCard } from '../../../../../card';
import { useReorderNotReceivedCardMutation } from '../hooks/useReorderNotReceivedCardMutation';

type Props = {
  isOpen: boolean;
  onClose(): void;
  onFormSuccessfulSubmission(): void;
  notReceivedCard: ApiCard;
};

type FormValues = {
  addressLine1: string;
  addressLine2: string | null;
  city: string;
  zipcode: string;
  country: AllowedCountryCode;
  phoneNumber: string;
};

type FormErrors = {
  addressLine1?: boolean;
  city?: boolean | string;
  zipcode?: boolean | string;
  country?: boolean;
  phoneNumber?: boolean;
};

type AllowedCountryCode =
  (typeof allowedShippingCountriesForAllSfsCardOrders)[number];

export const ReorderNotReceivedFormModal = ({
  isOpen,
  onClose,
  onFormSuccessfulSubmission,
  notReceivedCard,
}: Props) => {
  const { t } = useTranslation('global');
  const { dangerNotif } = useNotifications();
  const company = useCompany();
  const [reorderNotReceivedCardMutation] = useReorderNotReceivedCardMutation();

  const isUkCompany = company.currency === 'GBP';
  const shippingCountriesList = isUkCompany
    ? allowedShippingCountriesForSfsUkCardOrders
    : allowedShippingCountriesForSfsEeaCardOrders;

  const {
    address: initialAddressLine1,
    address2: initialAddressLine2,
    country: initialCountry,
    mobile_ext: initialMobileExtension,
    mobile_no: initialMobileNo,
    ...rest
  } = notReceivedCard.cardOrder;

  const initialPhoneNumber =
    initialMobileExtension && initialMobileNo
      ? `+${initialMobileExtension}${initialMobileNo}`
      : undefined;

  const form = useForm<FormValues, FormErrors>({
    initialValues: {
      ...rest,
      addressLine1: initialAddressLine1,
      addressLine2: initialAddressLine2,
      // Casting here because we know this should only be reached by SFS
      // companies, for which the delivery country should be allowed.
      country: initialCountry as AllowedCountryCode,
      phoneNumber: initialPhoneNumber,
    },
    validate(values) {
      const errors: FormErrors = {};
      if (!values.addressLine1 || values.addressLine1.trim() === '') {
        errors.addressLine1 = true;
      }
      if (!values.city || values.city.trim() === '') {
        errors.city = true;
      }
      if (!values.zipcode || values.zipcode.trim() === '') {
        errors.zipcode = true;
      }
      if (values.zipcode.length > 10) {
        errors.zipcode = t(
          'reorderNotReceivedCard.addressModal.zipCodeTooLongError',
        );
      }

      // Check city length
      if (isUkCompany && values.city.length + values.zipcode.length > 39) {
        errors.city = t(
          'reorderNotReceivedCard.addressModal.cityTooLongError',
          {
            maxChar: 39 - values.zipcode.length,
          },
        );
      }

      if (
        !values.country ||
        values.country.trim() === '' ||
        // @ts-expect-error values.country can be GB in the typing, but this
        // value shouldn't reach this point.
        !shippingCountriesList.includes(values.country)
      ) {
        errors.country = true;
      }

      try {
        const parsedPhoneNumber = parsePhoneNumber(values.phoneNumber);
        const isValid = isValidPhoneNumber(parsedPhoneNumber.number);

        if (!isValid) {
          errors.phoneNumber = true;
        }
      } catch {
        errors.phoneNumber = true;
      }

      return errors;
    },
    onSubmit: async (values) => {
      const { phoneNumber, ...address } = values;
      try {
        const parsedPhoneNumber = parsePhoneNumber(values.phoneNumber);
        await reorderNotReceivedCardMutation({
          cards: [
            {
              recipient: notReceivedCard.user.fullname,
              ...address,
              addressLine2: address.addressLine2 ?? '',
              zipCode: address.zipcode,
              phoneNumber: {
                // eslint-disable-next-line camelcase
                mobile_ext: parsedPhoneNumber.countryCallingCode,
                // eslint-disable-next-line camelcase
                mobile_no: parsedPhoneNumber.nationalNumber,
              },
              owner: {
                id: notReceivedCard.user.id,
                firstName: notReceivedCard.user.first_name,
                lastName: notReceivedCard.user.last_name,
              },
              // Passing "recardingCardId" here is a workaround to avoid
              // isAdminOrRecardingOwnCard(...) (in the backend) to return false
              // for non-admins, leading to a 403 error. Though I believe this
              // should not be needed, as the user should be able to order a new
              // physical card for themselves in 2 cases:
              // 1. their company is on SFS and re-ordering a non-received
              //    non-activarted card
              // 2. they're re-ordering a soon be to be expired activated card

              // Both cases don't need to know the ID of the card to be
              // replaced.
              recardingCardId: notReceivedCard.id,
              // Using "available" here as it's giving the right budget whatever
              // if the card is on the indefinite or the monthly limit system, as
              // the non-activated card would not be used yet ("monthly_budget"
              // is defined only when the card is on the monthly limit system).
              budget: notReceivedCard.available,
              setUpMonthlyReload: Boolean(notReceivedCard.monthly_budget),
              enableCashWithdrawal: notReceivedCard.cash_withdrawal_allowed,
            },
          ],
        });

        onFormSuccessfulSubmission();
      } catch {
        dangerNotif(
          t('reorderNotReceivedCard.addressModal.updateAddressError'),
        );
      }
    },
  });

  return (
    <Modal
      iconName="pen"
      iconVariant="primary"
      isOpen={isOpen}
      title={t('myCardOrderBanner.orderFormModalTitle')}
      onClose={onClose}
      actions={
        <>
          <Button
            text={t('reorderNotReceivedCard.addressModal.cancel')}
            variant="secondary"
            onClick={onClose}
            isDisabled={form.isSubmitting}
          />
          <FormSubmitButton
            text={t('reorderNotReceivedCard.addressModal.save')}
            form={form}
          />
        </>
      }
    >
      <div>
        <form
          className="mx-auto flex w-[500px] flex-col gap-s p-s"
          onSubmit={form.handleSubmit}
        >
          <FormTextInput
            fit="parent"
            label={t('reorderNotReceivedCard.addressModal.addressLabel')}
            placeholder={t(
              'reorderNotReceivedCard.addressModal.addressPlaceholder',
            )}
            form={form}
            name="addressLine1"
          />
          <FormTextInput
            fit="parent"
            label={t('reorderNotReceivedCard.addressModal.addressLabel2')}
            placeholder={t(
              'reorderNotReceivedCard.addressModal.addressPlaceholder',
            )}
            form={form}
            name="addressLine2"
            hint={t('reorderNotReceivedCard.addressModal.optionalHint')}
          />
          <FormTextInput
            fit="parent"
            label={t('reorderNotReceivedCard.addressModal.cityLabel')}
            placeholder={t(
              'reorderNotReceivedCard.addressModal.cityPlaceholder',
            )}
            form={form}
            name="city"
            maxLength={isUkCompany ? 39 - form.values.zipcode.length : 40}
          />
          <div className="flex gap-s">
            <div className="flex-1">
              <FormTextInput
                fit="parent"
                label={t('reorderNotReceivedCard.addressModal.zipLabel')}
                placeholder={t(
                  'reorderNotReceivedCard.addressModal.zipPlaceholder',
                )}
                form={form}
                maxLength={10}
                name="zipcode"
              />
            </div>
            <div className="flex-1">
              <FormField
                label={t('reorderNotReceivedCard.addressModal.countryLabel')}
              >
                <CountryAutocomplete
                  className="w-full"
                  countryCode={form.values.country}
                  onSelect={(countryCode: AllowedCountryCode) => {
                    form.setFieldValue('country', countryCode);
                  }}
                  countryFilter={(countries) => {
                    return countries.filter((country) => {
                      return shippingCountriesList.includes(
                        // @ts-expect-error country.alpha2 should be respecting
                        // the typing.
                        country.alpha2,
                      );
                    });
                  }}
                />
              </FormField>
            </div>
          </div>
          <div className="flex">
            <FormField
              className="mb-m w-full"
              label={t('reorderNotReceivedCard.addressModal.phoneNumberLabel')}
              alertMessage={
                form.errors.phoneNumber
                  ? t('reorderNotReceivedCard.addressModal.phoneNumberError')
                  : ''
              }
            >
              <PhoneNumberInput
                value={form.values.phoneNumber}
                onChange={(phoneNumber) => {
                  form.setFieldValue('phoneNumber', phoneNumber ?? '');
                }}
                name="phoneNumber"
                placeholder={t(
                  'reorderNotReceivedCard.addressModal.phoneNumberPlaceholder',
                )}
                initialCountry={getCountryFromNumber(form.values.phoneNumber)}
                className="w-full"
              />
            </FormField>
          </div>
        </form>
      </div>
    </Modal>
  );
};
