import { type TGlobalFunctionTyped } from 'common/hooks/useTranslation';

export type InvoiceStatus =
  | 'paid'
  | 'posted'
  | 'voided'
  | 'pending'
  | 'paymentDue'
  | 'notPaid'
  | 'adjusted'
  | 'refunded'
  | 'refundDue';

export type Invoice = {
  createdAt: string;
  id: string;
  number: string;
  paymentInvoice?: {
    url: string;
  };
  status: InvoiceStatus;
  total: number;
};

export const formatInvoiceStatus = (
  status: InvoiceStatus,
  t: TGlobalFunctionTyped,
): string => {
  switch (status) {
    case 'paid':
      return t('billing.invoicesList.statuses.paid');
    case 'posted':
      return t('billing.invoicesList.statuses.posted');
    case 'voided':
      return t('billing.invoicesList.statuses.voided');
    case 'pending':
      return t('billing.invoicesList.statuses.pending');
    case 'paymentDue':
      return t('billing.invoicesList.statuses.paymentDue');
    case 'notPaid':
      return t('billing.invoicesList.statuses.notPaid');
    case 'adjusted':
      return t('billing.invoicesList.statuses.adjusted');
    case 'refunded':
      return t('billing.invoicesList.statuses.refunded');
    case 'refundDue':
      return t('billing.invoicesList.statuses.refundDue');
    default:
      return t('billing.invoicesList.statuses.notAvailable');
  }
};
