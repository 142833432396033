import history from 'src/core/history';

import { useTransferRoute } from './useTransferRoute';
import { type TransferId } from '../../models';

type Params = {
  type: 'invoice' | 'expense';
  paymentId?: TransferId;
};

export const useGoToTransfer = () => {
  const getTransferRoute = useTransferRoute();

  return (params: Params) => {
    const route = getTransferRoute(params);
    history.push(route);
  };
};
