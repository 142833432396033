/* eslint-disable @typescript-eslint/naming-convention */
import { Icon } from '@dev-spendesk/grapes';
import cx from 'classnames';
import React, { type ReactElement } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

export type SimpleItem = {
  error?: JSX.Element;
  label: string;
  deleted?: boolean;
  valueDeleted?: boolean;
  value: string | ReactElement;
  values?: never;
  component?: never;
  className?: string;
};

const SimplePanelItem = ({
  item,
  className,
}: {
  item: SimpleItem;
  className?: string;
}) => {
  const { t } = useTranslation('global');
  const isDeleted = item.deleted || item.valueDeleted;

  return (
    <>
      <li
        key={item.label}
        className={cx(
          'my-s flex h-full w-full flex-row body-m',
          className,
          isDeleted && '-mx-xs border-0 border-l border-solid border-l-warning',
        )}
      >
        <div
          className={cx(
            'flex w-full flex-col space-y-xxs',
            isDeleted && '-mr-xs ml-xs',
          )}
        >
          <div className="flex w-full flex-row items-center justify-between">
            <span
              className={item.deleted ? 'text-neutral' : 'text-neutral-dark'}
            >
              {item.label}
            </span>
            <span
              className={cx(
                'max-w-[60%] overflow-hidden text-ellipsis whitespace-nowrap',
                {
                  'font-medium text-alert': item.error,
                  'text-neutral': isDeleted,
                  'text-complementary': !(item.error || item.valueDeleted),
                },
              )}
              title={typeof item.value === 'string' ? item.value : ''}
            >
              {item.value}
            </span>
          </div>

          {isDeleted && (
            <div className="flex flex-row items-center space-x-xxs text-warning">
              <Icon name="warning" size="s" />
              <span>
                {t('common.panelItemsSection.removed', {
                  item: item.deleted ? item.label : item.value,
                })}
              </span>
            </div>
          )}
        </div>
      </li>
      {item.error && (
        <div className="-ml-xs -mr-xs px-xs body-m">{item.error}</div>
      )}
    </>
  );
};

export default SimplePanelItem;
