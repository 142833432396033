import { PanelSection } from '@dev-spendesk/grapes';
import { format } from 'date-fns';
import * as Money from 'ezmoney';
import { type FormikErrors, setIn, useFormik } from 'formik';
import { useDispatch } from 'react-redux';

import { useTranslation } from 'common/hooks/useTranslation';
import { sortByDateAsc } from 'common/utils/sortByDateAsc';

import { SchedulingProcessView } from './SchedulingProcessView';
import {
  type SchedulingProcess as TSchedulingProcess,
  type Bill,
  computeAmountScheduled,
  computeAmountToSchedule,
} from '../../models';
import * as invoicesThunks from '../../redux/thunks';
import { SchedulingProcessForm } from '../SchedulingProcessForm';
import { type FormValues } from '../SchedulingProcessForm/formValues';

type Props = {
  className?: string;
  schedulingProcess: TSchedulingProcess;
  bill: Bill;
  activeItemId: string;
  isEditable: boolean;
  isCollapsible?: boolean;
};

export const SchedulingProcess = ({
  className,
  schedulingProcess,
  bill,
  activeItemId,
  isEditable,
  isCollapsible = false,
}: Props) => {
  const { t } = useTranslation('global');

  const dispatch = useDispatch();

  const billAmount = bill.amount;
  const amountToSchedule = computeAmountToSchedule(
    schedulingProcess,
    billAmount,
  );
  const formikProps = useFormik<FormValues>({
    initialValues: {
      payments: sortByDateAsc(schedulingProcess.paymentsToSchedule).map(
        (item) => ({
          id: item.id,
          amount: Money.toNumber(item.amount),
          date: new Date(item.date),
        }),
      ),
    },
    validateOnChange: false,
    validate: (values) => {
      let errors: FormikErrors<FormValues> = {};

      values.payments.forEach((payment, index) => {
        if (!payment.amount) {
          errors = setIn(errors, `payments[${index}].amount`, true);
        }

        if (!payment.date) {
          errors = setIn(errors, `payments[${index}].date`, true);
        }
      });

      if (!errors.payments) {
        const amountScheduled = computeAmountScheduled(
          values.payments,
          billAmount.currency,
        );
        const comparison = Money.compare(amountScheduled, amountToSchedule);

        if (comparison === -1) {
          errors.payments =
            'invoices.schedulingProcessForm.notEnoughCommittedError';
        }

        if (comparison === 1) {
          errors.payments =
            'invoices.schedulingProcessForm.tooMuchCommittedError';
        }
      }

      return errors;
    },
    onSubmit: async (values) => {
      const apiPayments = values.payments.map((payment) => {
        // Casting non-null types because we know values can't be
        // null since the validate function already checks for it before
        const date = payment.date as Date;
        const amount = payment.amount as number;
        return {
          date: format(date, 'yyyy-MM-dd'),
          amount: Money.fromNumber(amount, billAmount.currency, 2),
        };
      });

      dispatch(
        // @ts-expect-error: Not an helpful comment
        invoicesThunks.updatePaymentSchedule({
          billId: bill.id,
          payments: apiPayments,
        }),
      );
    },
  });

  return (
    <PanelSection
      title={t('invoices.paymentBillDetails.payments')}
      {...(isEditable
        ? {
            isEditable: true,
            editSection: (
              <SchedulingProcessForm
                currency={billAmount.currency}
                amountToSchedule={amountToSchedule}
                {...formikProps}
              />
            ),
            cancelTranslation: t('misc.cancel'),
            saveTranslation: t('misc.saveChanges'),
            onSave: async () => {
              const errors = await formikProps.validateForm();
              if (Object.keys(errors).length > 0) {
                throw new Error('invalid');
              }
              await formikProps.submitForm();
            },
            onCancel: () => {
              formikProps.resetForm();
            },
          }
        : {
            className,
            isEditable: false,
            isCollapsible,
          })}
    >
      <SchedulingProcessView
        className={isCollapsible ? 'mt-m' : ''}
        schedulingProcess={schedulingProcess}
        activeItemId={activeItemId}
        bill={bill}
      />
    </PanelSection>
  );
};
