import { type Node, type Edge } from '@xyflow/react';
import React, { useState, useCallback, type ReactNode } from 'react';
import { v4 as uuid } from 'uuid';

import { WorkflowContext } from './WorkflowContext';
import { makeTreeLayout } from './hooks';
import { initialNodes, initialEdges } from './initialNodes';
import { type CustomNode } from './node';

export const WorkflowProvider = ({ children }: { children: ReactNode }) => {
  const [nodes, setNodesState] = useState<CustomNode[]>(initialNodes);
  const [edges, setEdgesState] = useState<Edge[]>(initialEdges);
  const [selectedNodeId, setSelectedNodeIdState] = useState<string | null>(
    null,
  );

  const setNodes = useCallback((ns: Node[]) => {
    setNodesState(ns as CustomNode[]);
  }, []);

  const setEdges = useCallback((es: Edge[]) => {
    setEdgesState(es);
  }, []);

  const setSelectedNodeId = useCallback((nodeId: string | null) => {
    setSelectedNodeIdState(nodeId);
  }, []);

  const createAndConnectNode = useCallback(({
    sourceNodeId,
    shouldSelectNewNode = true,
  }: {
    sourceNodeId: string;
    shouldSelectNewNode?: boolean;
  }) => {
    const newNode: CustomNode = {
      id: `node-${uuid()}`,
      type: 'baseNode',
      data: {
        schemeId: undefined,
      },
      position: { x: 0, y: 0 },
    };

    setNodesState((nds) => {
      const updatedNodes = [...nds, newNode];
      setEdgesState((eds) => [
        ...eds,
        {
          id: `edge-${uuid()}`,
          source: sourceNodeId,
          target: newNode.id,
          type: 'labelEdge',
          data: {
            labelText: 'If',
            labelVariant: 'primary',
          },
        },
      ]);
      return updatedNodes;
    });

    if (shouldSelectNewNode) {
      setSelectedNodeIdState(newNode.id);
    }
  }, []);

  const { layoutedEdges, layoutedNodes } = makeTreeLayout(nodes, edges);

  return (
    <WorkflowContext.Provider
      value={{
        nodes: layoutedNodes,
        edges: layoutedEdges,
        selectedNodeId,
        setNodes,
        setEdges,
        setSelectedNodeId,
        createAndConnectNode,
      }}
    >
      {children}
    </WorkflowContext.Provider>
  );
};
