import get from 'lodash/get';
import { useEffect, useState } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { NotificationType, useNotifications } from 'modules/app/notifications';
import { type CurrenciesKey } from 'src/core/config/money';

import { SupplierFormContainer } from './SupplierForm';
import { SupplierView } from './SupplierView';
import { reshapeSupplier, toApiSupplierDetails } from './reshaper';
import {
  type ApiSupplier,
  type Supplier,
  type UpdatableSupplierDetails,
} from './supplier';

type Props = {
  company: {
    id: string;
    currency: CurrenciesKey;
    country: string;
  };
  supplier?: ApiSupplier;
  warnForUnsavedChanges?: boolean;
  isBankProofFileDisplayed: boolean;
  onSave: (
    supplierId: string | undefined,
    payload: Partial<ApiSupplier>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => Promise<{ outcome: 'success' } | { outcome: 'failure'; error: any }>;
  onShowBankProofFile: (
    bankProofFile?: Supplier['details']['bankProofFile'],
  ) => void;
  onHideBankProofFile: () => void;
  onTrackSupplierSave?: (suggestionContext: {
    isIbanDisplayed: boolean;
    suggestedIban: string | null;
    isBicDisplayed: boolean;
    suggestedBic: string | null;
  }) => void;
  showBankProofFileInline?: boolean;
};

export const SupplierEditor = ({
  company,
  supplier,
  warnForUnsavedChanges = false,
  isBankProofFileDisplayed = false,
  showBankProofFileInline = false,
  onSave,
  onShowBankProofFile,
  onHideBankProofFile,
  onTrackSupplierSave,
}: Props) => {
  const { t } = useTranslation('global');
  const { pushNotif } = useNotifications();

  // FIXME: move this reshaping logic into redux actions
  const supplierReshaped = supplier ? reshapeSupplier(supplier) : undefined;
  const supplierId = supplierReshaped ? supplierReshaped.id : undefined;
  const mode =
    supplierReshaped && supplierReshaped.validatedAt ? 'edition' : 'creation';
  const [isFormPanelDisplayed, setIsFormPanelDisplayed] = useState(
    mode === 'creation',
  );

  // It's really important to only depends on the supplier ID here to avoid
  // unexpected behavior due to unexpected re-rendering caused by the parent
  // props changes
  useEffect(() => {
    setIsFormPanelDisplayed(mode === 'creation');
    return () => onHideBankProofFile();
  }, [supplierId]);

  const handleToggleBankProofFileDisplay = (
    bankProofFile?: Supplier['details']['bankProofFile'],
  ) => {
    if (isBankProofFileDisplayed) {
      return onHideBankProofFile();
    }

    onShowBankProofFile(bankProofFile);
  };

  const handleSaveSupplier = async (
    supplierAccountCode: string | null,
    supplierDetails: UpdatableSupplierDetails,
    bankProofFile?:
      | {
          action: 'upload';
          base64: string;
        }
      | { action: 'delete' },
  ): Promise<{
    outcome: 'success' | 'ibanError' | 'bankProofError' | 'unknownError';
  }> => {
    const onSaveResult = await onSave(supplierId, {
      name: supplier?.name,
      supplierAccountCode: supplierAccountCode?.trim() ?? undefined,
      supplier_details: toApiSupplierDetails(supplierDetails, bankProofFile),
    });

    if (onSaveResult.outcome === 'failure') {
      // Try to check if the failure is due to incorrect IBAN info
      const isIbanError = get(onSaveResult, 'invalidFields.iban');

      if (isIbanError) {
        pushNotif({
          type: NotificationType.Danger,
          message: t('submitMyInvoice.suppliers.invalidBankInfo'),
        });
        return { outcome: 'ibanError' };
      }

      const isBankProofMimeTypeError =
        onSaveResult.error === 'mimeTypeNotSupported';
      if (isBankProofMimeTypeError) {
        pushNotif({
          type: NotificationType.Danger,
          message: t('submitMyInvoice.suppliers.invalidMimeTypeForBankProof', {
            uploadedMimeType: get(
              onSaveResult,
              'errorDetails.uploadedMimeType',
            ),
            allowedMimeTypes: get(
              onSaveResult,
              'errorDetails.allowedMimeTypes',
              [],
            ).join(','),
          }),
        });
        return { outcome: 'bankProofError' };
      }

      pushNotif({
        type: NotificationType.Danger,
        message: t('submitMyInvoice.suppliers.saveFailure'),
      });
      return { outcome: 'unknownError' };
    }

    setIsFormPanelDisplayed(false);

    if (mode === 'creation') {
      pushNotif({ message: t('submitMyInvoice.suppliers.createSuccess') });
    } else {
      pushNotif({ message: t('submitMyInvoice.suppliers.saveSuccess') });
    }

    return { outcome: 'success' };
  };

  return supplierReshaped && !isFormPanelDisplayed ? (
    <SupplierView
      company={company}
      supplier={supplierReshaped}
      onEditSupplierClicked={() => setIsFormPanelDisplayed(true)}
    />
  ) : (
    <SupplierFormContainer
      mode={mode}
      company={company}
      supplier={supplierReshaped}
      isBankProofFileDisplayed={isBankProofFileDisplayed}
      warnForUnsavedChanges={warnForUnsavedChanges}
      onShowBankProofFile={handleToggleBankProofFileDisplay}
      onSaveSupplier={handleSaveSupplier}
      onTrackSupplierSave={onTrackSupplierSave}
      showBankProofFileInline={showBankProofFileInline}
    />
  );
};
