import { type MonetaryValue, multiply } from 'ezmoney';

import { useIntegrationStatusQuery } from 'modules/bookkeep/hooks';
import { reverseChargeApplicableCountries } from 'modules/bookkeep/utils/countries';
import { useQueryStates } from 'src/core/api/hooks/useQueryStates';

import { useReverseChargeTaxAccountRatesQuery } from './useReverseChargeTaxAccountRatesQuery';
import { type ReverseChargeTaxAccountRate } from '../../../models';

type Result =
  | {
      outcome: 'valid';
      reverseChargeRate: ReverseChargeTaxAccountRate;
      computeReverseChargeAmount: (value: MonetaryValue) => MonetaryValue;
    }
  | {
      outcome: 'notApplicable';
    }
  | {
      outcome: 'invalid';
      reason: 'dependencyNotFound' | 'applicableRateNotFound';
      details?: Error;
    };

export const useApplicableReverseChargeAccountRate = (
  expenseDate: Date | undefined,
): Result | undefined => {
  const integrationStatusQuery = useIntegrationStatusQuery();

  const integrationStatus =
    (integrationStatusQuery.status === 'success' &&
      integrationStatusQuery.data.integration !== 'noIntegration' &&
      integrationStatusQuery.data.integration !== 'switchInProgress' &&
      integrationStatusQuery.data) ||
    undefined;

  const hasTaxAccountsCapability =
    integrationStatus?.capabilities.taxAccounts !== undefined;

  const accountingCountry = integrationStatus?.accountingCountry;

  const shouldUseReverseChargeAccount = Boolean(
    expenseDate &&
      hasTaxAccountsCapability &&
      accountingCountry &&
      reverseChargeApplicableCountries.includes(accountingCountry),
  );

  const reverseChargeRatesQuery = useReverseChargeTaxAccountRatesQuery({
    isEnabled: shouldUseReverseChargeAccount,
  });

  const queries = useQueryStates({
    states: {
      integrationStatus: integrationStatusQuery,
      reverseChargeRates: reverseChargeRatesQuery,
    },
  });

  if (!shouldUseReverseChargeAccount) {
    return {
      outcome: 'notApplicable',
    };
  }

  if (queries.status === 'loading') {
    return;
  }

  if (queries.status === 'error') {
    return {
      outcome: 'invalid',
      reason: 'dependencyNotFound',
      details: queries.error,
    };
  }

  const { reverseChargeRates } = queries.data;

  const reverseChargeRate = [...reverseChargeRates]
    .sort((a, b) => b.validFrom.getTime() - a.validFrom.getTime())
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    .find(({ validFrom }) => validFrom < expenseDate!);

  if (!reverseChargeRate) {
    return {
      outcome: 'invalid',
      reason: 'applicableRateNotFound',
    };
  }

  // reverseChargeRate.rate is an amount like that 0.196, by moving the comma
  // 4 positions and denoting it with a precision of 4 we don't loose any digit
  const computeReverseChargeAmount = (value: MonetaryValue): MonetaryValue =>
    multiply(value, Math.round(reverseChargeRate.rate * 10_000), 4);

  return {
    outcome: 'valid',
    reverseChargeRate,
    computeReverseChargeAmount,
  };
};
