import { GridContainer, GridItem } from '@dev-spendesk/grapes';
import React from 'react';

import styles from './TransferActionsBar.module.css';
import { WithTransfersSelected } from './WithTransfersSelected';
import { WithoutTransfersSelected } from './WithoutTransfersSelected';
import { selectors, useSelectorWithContext } from '../../redux';

type Props = {
  canApproveTransfers: boolean;
};

export const TransferActionsBar = (props: Props) => {
  const transfersSelectedCount = useSelectorWithContext(
    selectors.selectTransfersSelectedCount,
  );

  return (
    <GridContainer className={styles.TransferActionsBar}>
      <GridItem columnSpan={10} columnStartLine={2}>
        {transfersSelectedCount > 0 ? (
          <WithTransfersSelected {...props} />
        ) : (
          <WithoutTransfersSelected {...props} />
        )}
      </GridItem>
    </GridContainer>
  );
};
