import {
  AVAILABLE_CURRENCIES_FOR_NEW_ACCOUNTS,
  CURRENCIES,
} from 'src/core/config/money';
import { type BankingProvider } from 'src/core/reducers/global';

const AVAILABLE_CURRENCIES_FOR_SFS_BRANCHES = Object.values(
  AVAILABLE_CURRENCIES_FOR_NEW_ACCOUNTS,
).filter(({ value }) => ['EUR', 'GBP', 'USD'].includes(value));

export const getCurrencyOptions = ({
  bankingProvider,
  forceAllCurrencies,
}: {
  bankingProvider: BankingProvider;
  forceAllCurrencies: boolean;
}) => {
  if (forceAllCurrencies) {
    return Object.values(CURRENCIES)
      .filter(({ stillInCirculation }) => stillInCirculation !== false)
      .map((currency) => ({
        key: currency.value,
        label: currency.label,
        symbol: currency.symbol,
        disabled: false,
      }));
  }

  if (bankingProvider === 'sfs') {
    return AVAILABLE_CURRENCIES_FOR_SFS_BRANCHES.map((currency) => ({
      key: currency.value,
      label: currency.label,
      symbol: currency.symbol,
      disabled: false,
    })).sort((currencyA, currencyB) => {
      if (currencyA.disabled && !currencyB.disabled) {
        return 1;
      }

      if (!currencyA.disabled && currencyB.disabled) {
        return -1;
      }

      return 0;
    });
  }

  return Object.values(AVAILABLE_CURRENCIES_FOR_NEW_ACCOUNTS).map(
    (currency) => ({
      key: currency.value,
      label: currency.label,
      symbol: currency.symbol,
      disabled: false,
    }),
  );
};
