import { FormField, TextInput } from '@dev-spendesk/grapes';
import { type FormikProps } from 'formik';
import React from 'react';

import { CountryAutocomplete } from 'src/core/common/components/CountryAutocomplete/CountryAutocomplete';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { type LegalDetailsFormValues } from '../../../types';

type Props = FormikProps<LegalDetailsFormValues>;

export const AccountPayableSuppliersPanelLegalDetailsForm = ({
  // Formik props
  values,
  handleChange,
  setFieldValue,
}: Props) => {
  const { t } = useTranslation('global');

  return (
    <form className="AccountPayableSuppliersPanelLegalDetailsForm">
      <FormField
        className="my-s"
        label={t(
          'bookkeep.accountsPayable.panel.legalDetailsSection.legalNameLabel',
        )}
      >
        <TextInput
          name="legalName"
          value={values.legalName}
          placeholder={t(
            'bookkeep.accountsPayable.panel.legalDetailsSection.legalNamePlaceholder',
          )}
          isDisabled
          onChange={handleChange}
        />
      </FormField>
      <FormField
        className="my-s"
        label={t(
          'bookkeep.accountsPayable.panel.legalDetailsSection.registrationNumberLabel',
        )}
        hint={t('bookkeep.accountsPayable.panel.optionalField')}
      >
        <TextInput
          name="registrationNumber"
          value={values.registrationNumber}
          placeholder={t(
            'bookkeep.accountsPayable.panel.legalDetailsSection.registrationNumberPlaceholder',
          )}
          onChange={handleChange}
        />
      </FormField>
      <FormField
        className="my-s"
        label={t(
          'bookkeep.accountsPayable.panel.legalDetailsSection.vatNumberLabel',
        )}
        hint={t('bookkeep.accountsPayable.panel.optionalField')}
      >
        <TextInput
          name="vatNumber"
          value={values.vatNumber}
          placeholder={t(
            'bookkeep.accountsPayable.panel.legalDetailsSection.vatNumberPlaceholder',
          )}
          onChange={handleChange}
        />
      </FormField>
      <FormField
        className="my-s"
        label={t(
          'bookkeep.accountsPayable.panel.legalDetailsSection.streetAddressLabel',
        )}
        hint={t('bookkeep.accountsPayable.panel.optionalField')}
      >
        <TextInput
          name="streetAddress"
          value={values.streetAddress}
          placeholder={t(
            'bookkeep.accountsPayable.panel.legalDetailsSection.streetAddressPlaceholder',
          )}
          onChange={handleChange}
        />
      </FormField>
      <FormField
        className="my-s"
        label={t(
          'bookkeep.accountsPayable.panel.legalDetailsSection.addressLocalityLabel',
        )}
        hint={t('bookkeep.accountsPayable.panel.optionalField')}
      >
        <TextInput
          name="addressLocality"
          value={values.addressLocality}
          placeholder={t(
            'bookkeep.accountsPayable.panel.legalDetailsSection.addressLocalityPlaceholder',
          )}
          onChange={handleChange}
        />
      </FormField>
      <FormField
        className="my-s"
        label={t(
          'bookkeep.accountsPayable.panel.legalDetailsSection.postalCodeLabel',
        )}
        hint={t('bookkeep.accountsPayable.panel.optionalField')}
      >
        <TextInput
          name="postalCode"
          value={values.postalCode}
          placeholder={t(
            'bookkeep.accountsPayable.panel.legalDetailsSection.postalCodePlaceholder',
          )}
          onChange={handleChange}
        />
      </FormField>
      <FormField
        className="my-s"
        label={t(
          'bookkeep.accountsPayable.panel.legalDetailsSection.addressCountryLabel',
        )}
        hint={t('bookkeep.accountsPayable.panel.optionalField')}
      >
        <CountryAutocomplete
          fit="parent"
          name="addressCountry"
          countryCode={values.addressCountry}
          placeholder={t(
            'bookkeep.accountsPayable.panel.legalDetailsSection.addressCountryPlaceholder',
          )}
          onSelect={(selectedKey: string | undefined) =>
            setFieldValue('addressCountry', selectedKey || '')
          }
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            if (e.target.value === '') {
              setFieldValue('addressCountry', '');
            }
          }}
        />
      </FormField>
    </form>
  );
};
