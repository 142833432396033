import {
  AutocompleteNoOptions,
  Avatar,
  colors,
  DropdownItem,
  Icon,
  SkeletonText,
  Tag,
} from '@dev-spendesk/grapes';
import { Trans } from 'react-i18next';

import { AutocompleteSearch } from 'src/core/common/components/AutocompleteSearch';
import { QueryError } from 'src/core/common/components/QueryError';
import { QuerySuspense } from 'src/core/common/components/QuerySuspense';
import { useFeature } from 'src/core/common/hooks/useFeature';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';
import { useMembersQuery } from 'src/core/modules/company/cost-centers/containers/CostCentersPageContainer/hooks/useMembersQuery';

import { type SelectedApprover } from '../../types';

type Props = {
  onSelect: (selectedApprover: SelectedApprover) => void;
  onBlur: () => void;
  currentApproverId?: string;
  className?: string;
};

export const SelectApproverDropdown = ({
  onSelect,
  onBlur,
  currentApproverId,
  className,
}: Props) => {
  const membersQueryState = useMembersQuery();
  const isReportingManagerFeatureEnabled = useFeature(
    FEATURES.REPORTING_MANAGERS,
  );
  const { t } = useTranslation('global');

  return (
    <QuerySuspense
      queryState={membersQueryState}
      loading={<SkeletonText />}
      fallback={(error) => (
        <QueryError queryError={error} componentType="Callout" />
      )}
    >
      {(members) => {
        let options = members
          .filter((member) => member.id !== currentApproverId)
          .map((member) => ({
            key: member.id,
            label: member.isPending ? member.email : member.fullname,
            avatar: member.avatar,
            isCostCenterOwner: false,
            isReportingManager: false,
          }))
          .sort(
            (a, b) => Number(b.isCostCenterOwner) - Number(a.isCostCenterOwner),
          );

        if (isReportingManagerFeatureEnabled) {
          options = [
            {
              key: 'reportingManager',
              label: t('teams.approvalFlows.reportingManager'),
              avatar: '',
              isCostCenterOwner: false,
              isReportingManager: true,
            },
            ...options,
          ];
        }

        // Add cost center owner abstract role
        options = [
          {
            key: 'costCenterOwner',
            label: t('teams.approvalFlows.costCenterOwner'),
            avatar: '',
            isCostCenterOwner: true,
            isReportingManager: false,
          },
          ...options,
        ];

        return (
          <AutocompleteSearch
            name="ownerId"
            className={className}
            fit="parent"
            // ref={searchReference} TODO: Implement ref for Autocomplete Grapes component
            options={options}
            isInputVisible
            isAutocompletePlace
            placeholder={t('teams.approvalFlows.changeApproverPlaceholder')}
            value={undefined}
            renderPrefix={() => <Icon name="search" />}
            onBlur={() => {
              onBlur();
            }}
            renderOption={(option, state) => {
              if (option.isCostCenterOwner) {
                return (
                  <DropdownItem
                    {...option}
                    {...state}
                    key={option.key}
                    label={
                      <div className="flex items-center gap-xs">
                        <span className="text-complementary title-m">
                          {t('teams.approvalFlows.costCenterOwner')}
                        </span>
                      </div>
                    }
                    prefix={
                      <div className="mt-xxs flex h-m w-m items-center justify-center rounded-[50%] bg-primary-lighter">
                        <Icon name="person" color={colors.white} />
                      </div>
                    }
                  />
                );
              }
              if (option.isReportingManager) {
                return (
                  <DropdownItem
                    {...option}
                    {...state}
                    key={`${option.key}-${option.isReportingManager}}`}
                    label={
                      <div>
                        <div className="flex items-center gap-xs">
                          <span className="text-complementary title-m">
                            {t('teams.approvalFlows.reportingManager')}
                          </span>
                          <Tag variant="primary">{t('misc.new')}</Tag>
                        </div>
                        <div className="-ml-xxs mt-xxs text-neutral-dark body-s">
                          {t('teams.approvalFlows.reportingManagerDescription')}
                        </div>
                      </div>
                    }
                    prefix={
                      <div className="mt-xxs flex h-m w-m items-center justify-center rounded-[50%] bg-primary-lighter">
                        <Icon name="person" color={colors.white} />
                      </div>
                    }
                  />
                );
              }
              return (
                <DropdownItem
                  {...option}
                  {...state}
                  prefix={
                    <Avatar
                      size="s"
                      src={option.avatar ?? ''}
                      text={option.label}
                    />
                  }
                />
              );
            }}
            onSelect={(option) => {
              if (option) {
                if (option.isCostCenterOwner) {
                  onSelect({ type: 'costCenterOwner' });
                  return;
                }
                if (option.isReportingManager) {
                  onSelect({ type: 'reportingManager' });
                  return;
                }
                onSelect({
                  id: option.key,
                  type: 'user',
                });
              }
            }}
            renderNoOptions={(rawValue) => (
              <AutocompleteNoOptions className="text-center body-m">
                <div>
                  <Trans
                    i18nKey="misc.noOptions"
                    values={{ account: rawValue }}
                    components={[
                      <span
                        key="complementary"
                        className="text-complementary"
                      />,
                    ]}
                  />
                </div>
              </AutocompleteNoOptions>
            )}
          />
        );
      }}
    </QuerySuspense>
  );
};
