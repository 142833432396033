import { type Location } from 'history';
import Cookies from 'js-cookie';
import includes from 'lodash/includes';
import isNil from 'lodash/isNil';
import values from 'lodash/values';

import appConfig from 'src/core/config';
import { type Route, routes as appRoutes } from 'src/core/constants/routes';
import { COOKIE_TRACKING_PREFERENCES_SEGMENT } from 'src/core/constants/storage';
import { logger } from 'src/utils/datadog-log-wrapper';

import {
  AnalyticEventName,
  type AnalyticEventPayloadDefinition,
} from './eventRegistry';
import { getUrlInfos } from '../urlParser';

type EventContext = {
  company_id?: string;
  user_id?: string;
  group_id?: string;
  group_wallet_id?: string;
  organisation_id?: string;
};

// TODO: move to shared domain definition file
type User = {
  id: string;
  intercom_hash: string;
  lang: string;
  fullname: string;
  email: string;
  is_account_owner: boolean;
  is_admin: boolean;
  is_controller: boolean;
  is_requester: boolean;
};

type SignUpUser = {
  id: string;
  email: string;
  name: string;
  firstName: string;
  lastName: string;
  phone: string;
  country: string;
};

// TODO: move to shared domain definition file
type Company = {
  id: string;
  type: string;
  created_from: string;
  organisation: {
    id: string;
  };
};

// Note: user, company and supervisor are optional because we also load
// analytics on our auth pages, where the user isn't necessarily logged in.
type AnalyticsLoadSettings = {
  user?: User;
  company?: Company;
  supervisor?: {
    name: string;
    email: string;
  };
};

export type IntegrationsSettings = {
  'Amplitude (Actions)'?: boolean;
  'Facebook Pixel'?: boolean;
  'Google Analytics'?: boolean;
  'LinkedIn Insight Tag'?: boolean;
  Amplitude?: { groups: { requestId?: string; payableId?: string } };
  FullStory?: boolean;
  HubSpot?: boolean;
  Intercom?: boolean | { hideDefaultLauncher: boolean }; // todo intercom: hideDefaultLauncher not needed for direct integration, remove hideDefaultLauncher (Intercom consent still set with Segment)
  Slack?: boolean;
  Wootric?: boolean;
  SatisMeter?: boolean;
};

const eventContext: EventContext = {};

const isE2EIntercomTests = (): boolean => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  return urlSearchParams.has('e2e-enable-intercom');
};

const areAnalyticsEnabled = (): boolean => {
  if (!appConfig.segment.key) {
    return false;
  }

  return appConfig.analyticsEnabled || isE2EIntercomTests();
};

export const hasReviewedCookiePolicy = (): boolean =>
  Boolean(Cookies.get(COOKIE_TRACKING_PREFERENCES_SEGMENT));

const getIntegrationsOptions = (
  integrationsSettings: IntegrationsSettings = {},
): IntegrationsSettings => {
  const defaultIntegrationsSettings: IntegrationsSettings = {
    Wootric: true,
    SatisMeter: true,
    Intercom: true,
    FullStory: true,
    'Amplitude (Actions)': true,
    'Google Analytics': true,
    Slack: false, // disabled by default
    HubSpot: false, // disabled by default as it sends too many events to HubSpot (and make it unusable)
    'Facebook Pixel': false, // disabled by default as only used for the conversion on the free product sign-up flow
    'LinkedIn Insight Tag': false, // disabled by default as only used for the conversion on the free product sign-up flow
  };
  let overrideIntegrationsSettings: IntegrationsSettings = {};

  const isProduction = appConfig.stage === 'production';
  // for non-prod env, we disable all integrations by default
  // this can be commented for testing purpose
  if (!isProduction) {
    overrideIntegrationsSettings = {
      Wootric: false,
      SatisMeter: false,
      Intercom: isE2EIntercomTests(),
      FullStory: false,
      'Amplitude (Actions)': false,
      'Google Analytics': false,
      Slack: false,
      HubSpot: false,
      'Facebook Pixel': false,
      'LinkedIn Insight Tag': false,
    };
  }

  return {
    ...defaultIntegrationsSettings,
    ...integrationsSettings,
    ...overrideIntegrationsSettings,
  };
};

export const loadAnalytics = () => {
  if (window.analytics && window.analytics.load) {
    window.analytics.load(appConfig.segment.key, {
      integrations: getIntegrationsOptions(),
    });
  }
};

export const signupUserIdentify = (user: SignUpUser): void => {
  if (!areAnalyticsEnabled()) {
    return;
  }

  if (window.analytics) {
    window.analytics.identify(
      user.id,
      {
        email: user.email,
        name: `${user.firstName} ${user.lastName}`,
        first_name: user.firstName,
        last_name: user.lastName,
        phone: user.phone,
        country: user.country,
      },
      {
        context: {
          active: true,
        },
      },
    );
  } else {
    // Sending warning to Datadog (error does not work as it's triggered a lot, likely due to ad blockers or exotic browser)
    logger.warn('Failed to identify user. window.analytics was not found', {
      scope: 'app',
      team: 'none',
      userId: user.id,
    });
  }
};

const userIdentify = (
  user: User,
  integrations?: IntegrationsSettings,
): void => {
  if (!areAnalyticsEnabled()) {
    return;
  }

  if (window.analytics) {
    window.analytics.identify(
      user.id,
      {
        lang: user.lang,
        language: user.lang,
        name: user.fullname,
        email: user.email,
        account_owner: user.is_account_owner,
        administrator: user.is_admin,
        controller: user.is_controller,
        requester: user.is_requester,
      },
      {
        integrations: getIntegrationsOptions(integrations),
      },
      () => {
        window.dispatchEvent(
          new Event('analytics-integrations-consent-loaded'),
        );
      },
    );
  } else {
    // Sending warning to Datadog (error does not work as it's triggered a lot, likely due to ad blockers or exotic browser)
    logger.warn('Failed to identify user. window.analytics was not found', {
      scope: 'app',
      team: 'none',
      userId: user.id,
    });
  }
};

export const updateUserIdentify = (
  userId: string | undefined,
  traits: object,
  integrations?: IntegrationsSettings,
): void => {
  if (!areAnalyticsEnabled()) {
    return;
  }

  const options = {
    integrations: getIntegrationsOptions(integrations),
  };

  if (window.analytics) {
    if (userId) {
      window.analytics.identify(userId, traits, options);
    } else {
      window.analytics.identify(traits, options);
    }
  }
};

export const initAnalyticsContext = async ({
  user,
  company,
  supervisor,
}: AnalyticsLoadSettings): Promise<void> => {
  if (!areAnalyticsEnabled() || !isNil(supervisor)) {
    return;
  }

  if (user) {
    eventContext.user_id = user.id;
  }

  if (company) {
    const entityId =
      company.type === 'branch_currency' ? company.created_from : company.id;
    const walletId = company.id;
    eventContext.company_id = company.id;
    eventContext.group_id = entityId;
    eventContext.group_wallet_id = walletId;
    eventContext.organisation_id = company.organisation.id;
  }

  if (user && company) {
    userIdentify(user, {
      Intercom: { hideDefaultLauncher: true }, // todo intercom: not needed for direct integration, remove
    });
  }
};

export const track = <EventName extends AnalyticEventName>(
  name: EventName,
  payload?: AnalyticEventPayloadDefinition[EventName],
  integrations?: IntegrationsSettings,
): void => {
  if (process.env.REACT_APP_LOG_TRACKING_EVENT) {
    // eslint-disable-next-line no-console
    console.log('tracking event:', name);
    // eslint-disable-next-line no-console
    console.log('payload:', payload);
    // eslint-disable-next-line no-console
    console.log('context', eventContext);
  }
  if (!areAnalyticsEnabled()) {
    return;
  }

  if (!includes(values(AnalyticEventName), name)) {
    console.error(`Unknown tracking event ${name}`); // eslint-disable-line no-console
  }

  window?.analytics?.track(
    name,
    {
      ...eventContext,
      ...payload,
    },
    {
      integrations: getIntegrationsOptions(integrations),
    },
  );
};

export const group = <EventName extends AnalyticEventName>(
  name: EventName,
  groupId: string,
  payload?: AnalyticEventPayloadDefinition[EventName],
  integrations?: IntegrationsSettings,
): void => {
  if (process.env.REACT_APP_LOG_TRACKING_EVENT) {
    // eslint-disable-next-line no-console
    console.log('tracking event:', name);
    // eslint-disable-next-line no-console
    console.log('payload:', payload);
  }
  if (!areAnalyticsEnabled()) {
    return;
  }

  if (!includes(values(AnalyticEventName), name)) {
    console.error(`Unknown tracking event ${name}`); // eslint-disable-line no-console
  }

  window.analytics.group(
    groupId,
    {
      eventName: name,
      ...eventContext,
      ...payload,
    },
    {
      integrations: getIntegrationsOptions(integrations),
    },
  );
};

type TrackPageOptions = {
  routes?: Record<string, Route>;
  location: Location;
  prevLocation: Location | undefined;
  company?: {
    id: string;
  } & (
    | {
        type: 'initial';
        created_from: null;
      }
    | {
        type: 'branch_entity' | 'branch_currency' | 'branch_expense_entity';
        created_from: string;
      }
  );
  hash?: string;
  project?: string;
};

export const trackPage = ({
  routes = appRoutes,
  location,
  prevLocation,
  company,
  hash,
  project = 'App',
}: TrackPageOptions): void => {
  if (!areAnalyticsEnabled()) {
    return;
  }

  const urlInfos = getUrlInfos({ routes, location, prevLocation, project });
  if (!urlInfos) {
    return;
  }

  const properties: {
    title: string;
    path: string;
    group_id?: string;
    group_wallet_id?: string;
    referrer: string | null;
  } = {
    ...urlInfos.properties,
    group_id:
      company?.type === 'branch_currency' ? company.created_from : company?.id,
    group_wallet_id: company?.id,
  };

  const name = hash ? `${urlInfos.name} - ${hash}` : urlInfos.name;
  const context = { ...eventContext, ...properties };

  if (window.analytics && window.analytics.page) {
    // IMPORTANT: be careful, Segment's `page()` method signature is super weird
    // @see: https://segment.com/docs/sources/website/analytics.js/#page
    window.analytics.page(urlInfos.category, name, context, {
      integrations: getIntegrationsOptions(urlInfos.integrations),
    });
  }
};

export {
  AnalyticEventName,
  type AnalyticEventPayloadDefinition,
} from './eventRegistry';
