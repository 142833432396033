import React from 'react';

import { PanelItemsSection } from 'common/components/Panel';
import { useTranslation } from 'common/hooks/useTranslation';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

import { type ExpenseBill } from '../../../../models';

type Props = {
  bill: ExpenseBill;
};

export const ExpenseDetailsSection = ({ bill }: Props) => {
  const { t } = useTranslation('global');

  return (
    <PanelItemsSection
      title={t('purchaseOrders.panel.details')}
      items={[
        {
          label: t('invoices.transfer.panel.globalAmount'),
          value: formatMonetaryValue(bill.amount),
        },
      ]}
    />
  );
};
