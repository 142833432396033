import { type Edge } from '@xyflow/react';
import { stratify, tree } from 'd3-hierarchy';

import { type CustomNode } from '../node';

const g = tree<CustomNode>();

export const makeTreeLayout = (
  nodes: CustomNode[],
  edges: Edge[],
): { layoutedNodes: CustomNode[]; layoutedEdges: Edge[] } => {
  // Filter out initial nodes because we need to apply different layouting rules to them
  const filteredNodes = nodes.filter(
    (node) => node.id !== 'initialStandardApprovalFlowNode',
  );
  if (nodes.length === 0 || edges.length === 0) {
    return { layoutedNodes: [], layoutedEdges: [] };
  }
  const { width, height } = { width: 400, height: 100 }; // TODO: define width and height (or get from DOM element)
  const hierarchy = stratify<CustomNode>()
    .id((node) => node.id)
    .parentId((node) => edges.find((edge) => edge.target === node.id)?.source);
  const root = hierarchy(filteredNodes);
  const layout = g.nodeSize([height * 2, width * 1.3])(root); // Swap height and width for horizontal layout
  const layoutedNodes = layout.descendants().map((node) => ({
    ...node.data,
    position: { x: node.y, y: node.x }, // Swap x and y for horizontal layout
  }));

  return {
    layoutedNodes: [
      ...layoutedNodes,
      ...nodes.filter((node) => node.id === 'initialStandardApprovalFlowNode'),
    ],
    layoutedEdges: edges,
  };
};
