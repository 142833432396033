import { colors, Icon, Tooltip } from '@dev-spendesk/grapes';

export const AutomationIcon = ({
  message,
  className = '',
  dataTestId = 'automation-icon',
}: {
  message: string;
  className?: string;
  dataTestId?: string;
}) => {
  return (
    <Tooltip content={message} triggerAsChild>
      <Icon
        data-testid={dataTestId}
        name="robot"
        color={colors.primary}
        className={className}
      />
    </Tooltip>
  );
};
