import { useNotifications } from 'modules/app/notifications';
import { DangerConfirmationModal } from 'src/core/common/components/DangerConfirmationModal';
import { useFeature } from 'src/core/common/hooks/useFeature';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';

import { useDeleteEcQuery } from './hooks/useDeleteEcQuery';
import { type ExpenseCategory } from '../../expenseCategory';

type Props = {
  expenseCategory: ExpenseCategory;
  isOpen: boolean;
  onComplete(): void;
  onClose(): void;
};

export const EcDeletionModalContainer = ({
  expenseCategory,
  isOpen,
  onComplete,
  onClose,
}: Props) => {
  const { t } = useTranslation('global');
  const isBudgetsFeatureEnabled = useFeature(FEATURES.BUDGETS);
  const descriptionKey = isBudgetsFeatureEnabled
    ? 'expenseCategories.deletion.modalSubtitleWithBudgetFeature'
    : 'expenseCategories.deletion.modalSubtitleWithoutBudgetFeature';
  const { dangerNotif, successNotif } = useNotifications();
  const [handleOnSubmit] = useDeleteEcQuery(expenseCategory.id);

  return (
    <DangerConfirmationModal
      isOpen={isOpen}
      title={t('expenseCategories.deletion.modalTitle')}
      description={t(descriptionKey, { name: expenseCategory.name })}
      actionText={t('expenseCategories.deletion.delete')}
      withRejectionMessage={false}
      onClose={onClose}
      onConfirm={async () => {
        try {
          await handleOnSubmit(expenseCategory);
          successNotif(t('expenseCategories.deletion.success'));
          onComplete();
        } catch {
          dangerNotif(t('expenseCategories.deletion.error'));
        }
      }}
    />
  );
};
