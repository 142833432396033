import React, { type Dispatch, type SetStateAction } from 'react';

import { type AccountingSoftware } from 'modules/bookkeep/integration/status';
import { rejectUnexpectedValue } from 'src/core/utils/switchGuard';

import { type ModalState } from '../ModalState';
import { DatevReverseChargeAddEditForm } from '../datev/DatevReverseChargeAddEditForm';
import { DatevAddEditForm } from '../datev/DatevVatAddEditForm';
import { DoubleEntryReverseChargeAddEditForm } from '../file-based/DoubleEntryReverseChargeAddEditForm';
import { SingleEntryReverseChargeAddEditForm } from '../file-based/SingleEntryReverseChargeAddEditForm';
import { VatAddEditForm } from '../file-based/VatAddEditForm';

export function renderVatAddEditForm(
  integration: AccountingSoftware,
  formState: ModalState,
  setFormState: Dispatch<SetStateAction<ModalState>>,
) {
  switch (integration) {
    case 'Datev':
      return (
        <DatevAddEditForm formState={formState} setFormState={setFormState} />
      );
    case 'SpendeskAccounting':
    case 'SpendeskAccountingSingleEntry':
    case 'Sage':
    case 'Cegid':
      return (
        <VatAddEditForm formState={formState} setFormState={setFormState} />
      );
    case 'Netsuite':
    case 'Xero':
    case 'Quickbooks':
    case 'Sage100':
    case 'ACD':
    case 'Odoo':
    case 'ExactOnlineFr':
      return <>TO DO</>;
    default:
      rejectUnexpectedValue('render reverse charge table', integration);
  }
}

export function renderReverseChargeAddEditForm(
  integration: AccountingSoftware,
  formState: ModalState,
  setFormState: Dispatch<SetStateAction<ModalState>>,
) {
  switch (integration) {
    case 'Datev':
      return (
        <DatevReverseChargeAddEditForm
          formState={formState}
          setFormState={setFormState}
        />
      );
    case 'SpendeskAccounting':
    case 'Sage':
    case 'Cegid':
      return (
        <DoubleEntryReverseChargeAddEditForm
          formState={formState}
          setFormState={setFormState}
        />
      );
    case 'SpendeskAccountingSingleEntry':
      return (
        <SingleEntryReverseChargeAddEditForm
          formState={formState}
          setFormState={setFormState}
        />
      );
    case 'Xero':
    case 'Netsuite':
    case 'Quickbooks':
    case 'Sage100':
    case 'ACD':
    case 'Odoo':
    case 'ExactOnlineFr':
      return <>TO DO</>;
    default:
      rejectUnexpectedValue('render reverse charge table', integration);
  }
}
