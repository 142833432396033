import { Avatar, FormField, TextInput } from '@dev-spendesk/grapes';
import { type FormikProps } from 'formik';

import { type PayableType } from 'modules/bookkeep/prepare-payables/models';
import {
  PayableAccountingDateField,
  PayableCreationDateField,
  PayableInvoiceNumberFormField,
  shouldDisplayInvoiceNumber,
} from 'modules/payable/components';
import { PayableAllocationPeriodField } from 'modules/payable/components/PayableAllocationPeriodField/PayableAllocationPeriodField';

import { type Payable } from '../../PayablePanelContainer';
import { type DetailsFormValues } from '../hooks/usePayableDetailsEditForm';

type Props = {
  payable: Payable;
  items: {
    label: string;
    value?: string;
    type?: string;
  }[];
  context: {
    hasInvoiceNumberFeature: boolean;
    hasSpanishDPR: boolean;
    hasAccountingDateEnabled: boolean;
  };
};

export const PayableDetailsEditSection = ({
  payable,
  items,
  values,
  errors,
  setFieldValue,
  context: { hasInvoiceNumberFeature, hasSpanishDPR },
}: Props & FormikProps<DetailsFormValues>) => {
  return (
    <div className="PayableDetailsEditSection">
      {items.map((item) => {
        const defaultField = (
          <div className="mb-s flex flex-col" key={item.label}>
            <FormField label={item.label}>
              <TextInput
                fit="parent"
                isInvalid={false}
                value={item.value}
                isDisabled
              />
            </FormField>
          </div>
        );

        switch (item.type) {
          case 'requester':
            return (
              <div className="mb-s flex flex-col" key={item.label}>
                <FormField label={item.label}>
                  <TextInput
                    fit="parent"
                    isInvalid={false}
                    value={item.value}
                    isDisabled
                    leftAddon={
                      <Avatar
                        className="p-xxs"
                        variant="circle"
                        src={
                          payable.member?.type !== 'invitee'
                            ? payable.member?.avatar
                            : undefined
                        }
                        text={item.value || ''}
                        /* @ts-expect-error Grapes hacking time: Accessibility hack */
                        role="button"
                      />
                    }
                  />
                </FormField>
              </div>
            );

          case 'invoiceNumber':
            if (
              !shouldDisplayInvoiceNumber(
                {
                  integrationHasInvoiceNumberCapability:
                    hasInvoiceNumberFeature,
                  hasSpanishDPR,
                },
                payable,
              )
            ) {
              return <></>;
            }
            return (
              <div className="mb-s flex flex-col" key={item.label}>
                <PayableInvoiceNumberFormField
                  payable={reshapePayableForField(payable)}
                  value={values.invoiceNumber}
                  onChange={(value) => setFieldValue('invoiceNumber', value)}
                  error={errors.invoiceNumber}
                />
              </div>
            );

          case 'creationDate':
            return (
              <div className="mb-s flex flex-col" key={item.label}>
                <PayableCreationDateField
                  label={item.label}
                  value={values.creationDate}
                  setFieldValue={setFieldValue}
                  error={errors.creationDate}
                />
              </div>
            );

          case 'accountingDate':
            return (
              <div className="mb-s flex flex-col" key={item.label}>
                <PayableAccountingDateField
                  label={item.label}
                  setFieldValue={setFieldValue}
                  value={values.accountingDate}
                  error={errors.accountingDate}
                />
              </div>
            );

          case 'allocationPeriod':
            return (
              !!values.allocationPeriod && (
                <div className="mb-s flex flex-col" key={item.label}>
                  <PayableAllocationPeriodField
                    setFieldValue={setFieldValue}
                    value={values.allocationPeriod}
                  />
                </div>
              )
            );

          default:
            return defaultField;
        }
      })}
    </div>
  );
};

/**
 * Helpers
 */

export const reshapePayableForField = (payable: Payable) => {
  const reshapePayableType = (rawType: string): PayableType => {
    switch (rawType) {
      case 'cardPurchase':
        return 'card_purchase';
      case 'invoicePurchase':
        return 'invoice_purchase';
      case 'claimedBill':
        return 'expense_claim';
      case 'mileageAllowance':
        return 'mileage_allowance';
      case 'perDiemAllowance':
        return 'per_diem_allowance';
      case 'reversal':
        return 'reversal';

      default:
        throw new Error(`Unknown payable type ${rawType}`);
    }
  };

  return {
    ...payable,
    type: reshapePayableType(payable.type),
    subType: payable.subtype,
    originalAmount: payable.grossAmount,
    amount: payable.functionalAmount,
    exchangeRate: payable.functionalExchangeRate,
    createdAt: payable.creationDate.toISOString(),
  };
};
