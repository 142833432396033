import { useQueryClient } from 'react-query';

import {
  type HubTask,
  type HubTaskType,
} from 'modules/onboarding/types/hubTask';

export const useUpdateCachedTaskStatus = () => {
  const queryClient = useQueryClient();

  return (taskType: HubTaskType) =>
    queryClient.setQueryData<HubTask[]>(
      ['onboarding-hub', 'tasks', 'setup_hub'],
      (tasks) => {
        if (!tasks) {
          return [];
        }
        return tasks.map((task) => {
          if (task.type === taskType) {
            return {
              ...task,
              status: 'completed',
            };
          }
          return task;
        });
      },
    );
};
