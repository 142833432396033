import { DATE_FORMAT } from '@dev-spendesk/grapes';
import React, { useMemo } from 'react';

import { Timeline, type TimelineEvent } from 'common/components/Timeline';
import { useTranslation } from 'common/hooks/useTranslation';

import { SchedulingProcessViewEntry } from './SchedulingProcessViewEntry';
import {
  type SchedulingProcess,
  type SchedulingProcessEntry,
  type Bill,
  getSchedulingProcessSortedByDateWithStatus,
} from '../../../models';

type Props = {
  className?: string;
  activeItemId: string;
  schedulingProcess: SchedulingProcess;
  bill: Bill;
};

export const SchedulingProcessView = ({
  className,
  activeItemId,
  schedulingProcess,
  bill,
}: Props) => {
  const { t, localeFormat } = useTranslation('global');

  const timelineEvents: TimelineEvent[] = useMemo(() => {
    const sortedSchedulingProcess =
      getSchedulingProcessSortedByDateWithStatus(schedulingProcess);

    return sortedSchedulingProcess.map((schedulingProcessEntry) => ({
      key: schedulingProcessEntry.id,
      date: new Date(schedulingProcessEntry.date),
      content: (
        <SchedulingProcessViewEntry
          bill={bill}
          schedulingProcessEntry={schedulingProcessEntry}
          isActive={activeItemId === schedulingProcessEntry.id}
        />
      ),
      status: mapSchedulingProcessViewEntryStatusToTimelineEventStatus(
        schedulingProcessEntry,
      ),
    }));
  }, [schedulingProcess]);

  const renderDate = (date: Date): string =>
    t('invoices.schedulingProcess.date', {
      date: localeFormat(date, DATE_FORMAT.MEDIUM),
    });

  return (
    <Timeline
      className={className}
      events={timelineEvents}
      renderDate={renderDate}
      fit="parent"
    />
  );
};

const mapSchedulingProcessViewEntryStatusToTimelineEventStatus = (
  schedulingProcessEntry: SchedulingProcessEntry,
): TimelineEvent['status'] => {
  switch (schedulingProcessEntry.status) {
    case 'failed':
      return 'alert';

    case 'success':
      return 'success';

    default:
      return 'pending';
  }
};
