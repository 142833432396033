import { format } from 'date-fns';
import { type FormikErrors } from 'formik';

import { useNotifications } from 'modules/app/notifications';
import {
  checkIfAccountingDateIsInvalid,
  checkIfInvoiceNumberIsInvalid,
} from 'modules/bookkeep/prepare-payables/components/PreparePayablesInbox/components/PreparePayablesEditorForm/helpers';
import {
  useUpdatePayable,
  useUpdatePayableErrorMessage,
} from 'modules/payable/hooks';
import { useFormikWithValidationStrategy } from 'src/core/common/hooks/useFormikWithValidationStrategy';
import {
  type I18nKey,
  useTranslation,
} from 'src/core/common/hooks/useTranslation';
import { type AllocationPeriod } from 'src/core/modules/budgets/containers/AllocationPeriodContainer/AllocationPeriodContainer';
import { useAllocationPeriodMutation } from 'src/core/modules/budgets/containers/AllocationPeriodContainer/hooks/useAllocationPeriodMutation';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

import { getEditedFields } from '../../../components/PayablePanel/helpers';
import {
  type SpendingAllocationPeriod,
  type Payable,
} from '../../PayablePanelContainer';
import { useUpdatePayableQueryCache } from '../../PayablePanelContainer/hooks';

export type DetailsFormValues = {
  creationDate: string;
  accountingDate?: string;
  invoiceNumber?: string;
  allocationPeriod?: AllocationPeriod;
};

type PayableDetailsUpdate = {
  creationDate: string;
  accountingDate?: string;
  invoiceNumber?: string;
};

type Context = {
  hasInvoiceNumberFeature: boolean;
  hasSpanishDPR: boolean;
  hasAccountingDateEnabled: boolean;
};

export const usePayableDetailsEditForm = ({
  payable,
  context,
}: {
  payable: Payable;
  context: Context;
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const { t } = useTranslation('global');
  const [updatePayable] = useUpdatePayable(payable.id);
  const getUpdatePayableErrorMessage = useUpdatePayableErrorMessage();
  const updatePayableQueryCache = useUpdatePayableQueryCache();
  const { dangerNotif, successNotif } = useNotifications();
  const [submitAllocationPeriod] = useAllocationPeriodMutation(
    'payable',
    payable.id,
  );

  const { hasSpanishDPR, hasInvoiceNumberFeature } = context;

  const handleOnSectionCancelEdit = () => formik.resetForm();
  const handleOnSectionSaveEdit = async () => {
    const { submitForm, validateForm } = formik;
    const errors = await validateForm();
    const isValid = Object.keys(errors).length === 0;
    if (!isValid) {
      throw new Error('Validation error');
    }
    return submitForm();
  };

  const formik = useFormikWithValidationStrategy<DetailsFormValues>({
    initialValues: mapPayableToInitialValues(payable, context),
    enableReinitialize: true,
    validate: (values) => {
      const errors: FormikErrors<DetailsFormValues> = {};

      if (
        checkIfInvoiceNumberIsInvalid(
          hasInvoiceNumberFeature,
          hasSpanishDPR,
          values.invoiceNumber,
        )
      ) {
        errors.invoiceNumber = t(getInvoiceNumberErrorKey(payable));
      }

      if (
        checkIfAccountingDateIsInvalid(hasSpanishDPR, values.accountingDate)
      ) {
        errors.accountingDate = t(
          'payables.panel.detailsSection.validation.missingAccountingDate',
        );
      }

      return errors;
    },

    onSubmit: async (values) => {
      try {
        const payableUpdate = mapValuesToPayableUpdate(values, context);

        const { newPayableVersion } = await updatePayable({
          payableVersion: payable.version,
          update: payableUpdate,
        });

        const editedFields = getEditedFields({
          payable,
          update: payableUpdate,
        });

        if (values.allocationPeriod) {
          editedFields.push('allocation_period');
          await submitAllocationPeriod(
            values.allocationPeriod.type === 'date'
              ? {
                  type: values.allocationPeriod.type,
                  date: format(values.allocationPeriod.date, 'yyyy-MM-dd'),
                }
              : {
                  type: values.allocationPeriod.type,
                  period: {
                    from: format(
                      values.allocationPeriod.startDate,
                      'yyyy-MM-dd',
                    ),
                    to: format(values.allocationPeriod.endDate, 'yyyy-MM-dd'),
                  },
                },
          );
        }

        track(AnalyticEventName.BOOKKEEP_PAYABLE_ALL_PAYABLE_SAVED, {
          payableId: payable.id,
          editedFields,
          state: payable.state,
        });

        updatePayableQueryCache(payable.id, {
          version: newPayableVersion,
          ...payableUpdate,
        });

        successNotif(t('payables.panel.detailsSection.detailsUpdatedSuccess'));
      } catch (error) {
        const errorMessage = getUpdatePayableErrorMessage(error);
        dangerNotif(errorMessage);
        throw error;
      }
    },
  });

  return {
    formik,
    handleOnSectionCancelEdit,
    handleOnSectionSaveEdit,
  };
};

function getInvoiceNumberErrorKey(payable: Payable): I18nKey {
  if (payable.subtype === 'creditNote') {
    return 'payables.panel.detailsSection.validation.missingCreditNoteNumber';
  }

  switch (payable.type) {
    case 'claimedBill':
    case 'cardPurchase':
    case 'reversal':
      return 'payables.panel.detailsSection.validation.missingReceiptNumber';
    default:
      return 'payables.panel.detailsSection.validation.missingInvoiceNumber';
  }
}

function mapPayableToInitialValues(payable: Payable, context: Context) {
  const initialValues: DetailsFormValues = {
    creationDate: payable.creationDate.toISOString() || '',
    invoiceNumber:
      (payable.documentaryEvidence?.type === 'invoice' &&
        payable.documentaryEvidence?.invoiceNumber) ||
      (payable.documentaryEvidence?.type === 'creditNote' &&
        payable.documentaryEvidence?.creditNoteNumber) ||
      '',
    allocationPeriod: reshapeSpendingAllocationPeriod(
      payable.spendingAllocationPeriod,
    ),
  };

  if (context.hasAccountingDateEnabled) {
    initialValues.accountingDate =
      payable.accountingDate?.toISOString() ||
      payable.creationDate.toISOString();
  }

  return initialValues;
}

function mapValuesToPayableUpdate(values: DetailsFormValues, context: Context) {
  const payableUpdate: PayableDetailsUpdate = {
    creationDate: values.creationDate,
  };

  if (context.hasAccountingDateEnabled && values.accountingDate !== undefined) {
    payableUpdate.accountingDate = values.accountingDate;
  }

  if (values.invoiceNumber !== undefined) {
    payableUpdate.invoiceNumber = values.invoiceNumber;
  }

  return payableUpdate;
}

function reshapeSpendingAllocationPeriod(
  spendingAllocationPeriod: SpendingAllocationPeriod | undefined,
): AllocationPeriod | undefined {
  if (spendingAllocationPeriod === undefined) {
    return undefined;
  }

  return spendingAllocationPeriod.date
    ? {
        type: 'date',
        date: new Date(spendingAllocationPeriod.date),
      }
    : {
        type: 'period',
        startDate: new Date(spendingAllocationPeriod.period.from),
        endDate: new Date(spendingAllocationPeriod.period.to),
      };
}
