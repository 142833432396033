import classNames from 'classnames';
import React from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import { QueryError } from 'src/core/common/components/QueryError';
import { QuerySuspense } from 'src/core/common/components/QuerySuspense';
import { useCompany } from 'src/core/modules/app/hooks/useCompany';
import { useUser } from 'src/core/modules/app/hooks/useUser';
import {
  type ActionsCountsState,
  sumActionCounts,
} from 'src/core/modules/homepage/models/actionCounts';
import { type AppState } from 'src/core/reducers';
import { getIsWelcomeDashboardDismissed } from 'src/core/selectors/globalSelectors';
import { getUserPaymentCount } from 'src/core/selectors/users';

import { WelcomePanelContentSkeleton } from './WelcomePanelContentSkeleton';
import { getHeaderTitle } from '../../../../header';
import {
  useActionsCountsQuery,
  useAoActionsCountsQuery,
  useDisplayMode,
  useHomepageMode,
  useTitleState,
} from '../../../../hooks';
import { WelcomePanelActions } from '../WelcomePanelActions/WelcomePanelActions';

export const WelcomePanelContent = () => {
  const user = useUser();
  const displayMode = useDisplayMode();
  const isWelcomeDashboardDismissed = useSelector(
    getIsWelcomeDashboardDismissed,
  );
  const mode = useHomepageMode();
  const company = useCompany();
  const userPaymentCount = useSelector((state: AppState) =>
    getUserPaymentCount(state, company.id),
  );
  const controllerActionsCountsQueryState = useActionsCountsQuery(
    displayMode === 'controller' && !user.is_account_owner,
  );
  const aoActionsCountsQueryState = useAoActionsCountsQuery(
    displayMode === 'controller' && user.is_account_owner,
  );
  const actionsCountsQueryState = user.is_account_owner
    ? aoActionsCountsQueryState
    : controllerActionsCountsQueryState;

  const actionCountsSum =
    actionsCountsQueryState.status === 'success'
      ? sumActionCounts(actionsCountsQueryState.data)
      : 0;

  const actionsCountsState: ActionsCountsState =
    actionsCountsQueryState.status === 'success'
      ? {
          loading: false,
          counts: actionsCountsQueryState.data,
        }
      : { loading: true };

  const titleState = useTitleState({ displayMode, userPaymentCount });
  const subtitle = getHeaderTitle(
    titleState,
    isWelcomeDashboardDismissed,
    actionCountsSum,
  );
  const noCount =
    !actionsCountsState.loading &&
    sumActionCounts(actionsCountsState.counts) === 0;

  return (
    <div className="flex flex-row gap-xxs">
      <div
        className={classNames('flex flex-col justify-stretch', {
          'gap-m': actionsCountsQueryState.status === 'success',
        })}
      >
        <p
          className={classNames('leading-[26px] text-neutral-dark body-xl', {
            'text-alert': mode === 'danger',
          })}
        >
          <Trans i18nKey={subtitle} />
        </p>
        {displayMode === 'controller' &&
          isWelcomeDashboardDismissed &&
          !noCount && (
            <QuerySuspense
              queryState={actionsCountsQueryState}
              loading={<WelcomePanelContentSkeleton />}
              fallback={(error) => (
                <QueryError queryError={error} componentType="Callout" />
              )}
            >
              {(actionsCounts) => (
                <WelcomePanelActions actionsCounts={actionsCounts} />
              )}
            </QuerySuspense>
          )}
      </div>
    </div>
  );
};
