import * as R from '@dev-spendesk/general-type-helpers/Result';

import { type Option } from 'common/components/AutocompleteSearch/option';
import { useTranslation } from 'common/hooks/useTranslation';
import { useCreateOrUpdateAccountPayable } from 'modules/accounting-integration/apis';
import { useNotifications } from 'modules/app/notifications';
import { AccountPayableAutocompleteSearch } from 'modules/bookkeep/accounts-payable/components/AccountPayableAutocompleteSearch';
import { useGetSupplierAccountsQuery } from 'modules/bookkeep/accounts-payable/hooks/useGetSupplierAccountsQuery';
import { unwrapQuery } from 'src/core/api/unwrapQuery';

type Props = {
  error?: string;
  onChange: (value: string) => void;
  setError: (error: string | undefined) => void;
  value: string;
};

export const SupplierAccountAutocomplete = ({
  error,
  onChange,
  setError,
  value,
}: Props) => {
  const { t } = useTranslation('global');
  const { dangerNotif } = useNotifications();

  const supplierAccountsQueryState = useGetSupplierAccountsQuery({
    includeArchived: false,
  });
  const supplierAccounts = unwrapQuery(supplierAccountsQueryState);

  const [createSupplierAccount] =
    useCreateOrUpdateAccountPayable('supplierAccount');

  const onAddOption = async (code: string): Promise<Option> => {
    try {
      const createSupplierAccountResult = await createSupplierAccount({
        generalAccountCode: code,
        isArchived: false,
      });

      if (R.isFailure(createSupplierAccountResult)) {
        const errorMessage =
          createSupplierAccountResult.error.reason === 'codeAlreadyExists'
            ? t(
                'bookkeep.accountsPayable.panel.accountingSection.addOptionErrorCodeAlreadyExistsNotification',
              )
            : t(
                'bookkeep.accountsPayable.panel.accountingSection.addOptionErrorNotification',
              );
        dangerNotif(errorMessage);
        return { key: '', label: '' };
      }

      return {
        key: createSupplierAccountResult.value.accountPayableId,
        label: code,
      };
    } catch {
      dangerNotif(t('misc.errors.unknownError'));
      return { key: '', label: '' };
    }
  };

  return (
    <AccountPayableAutocompleteSearch
      accountPayables={supplierAccounts ?? []}
      error={error}
      onAddOption={onAddOption}
      onChange={onChange}
      setError={setError}
      value={value}
    />
  );
};
