import { Button, SkeletonTable, SkeletonText } from '@dev-spendesk/grapes';
import { useState } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import {
  filterIntegrationVatAccounts,
  useIntegrationStatusQuery,
} from 'modules/bookkeep';
import { canAccessTaxAccountsSettings } from 'modules/bookkeep/settings/integrations/helper/settings-access';
import { useTaxAccountsQuery } from 'modules/bookkeep/settings/integrations/hooks/useTaxAccountsQuery';
import { type ModalState } from 'modules/bookkeep/settings/integrations/pages/LegacyIntegrationsAccountingPage/sections/TaxAccountsSection/TaxAccountLocalOnlySection/ModalState';
import { VatAccountsTable } from 'modules/bookkeep/settings/integrations/pages/LegacyIntegrationsAccountingPage/sections/TaxAccountsSection/TaxAccountLocalOnlySection/VatAccountsTable';
import { VatAddModal } from 'modules/bookkeep/settings/integrations/pages/LegacyIntegrationsAccountingPage/sections/TaxAccountsSection/TaxAccountLocalOnlySection/modals/VatAddModal';
import { VatDeleteModal } from 'modules/bookkeep/settings/integrations/pages/LegacyIntegrationsAccountingPage/sections/TaxAccountsSection/TaxAccountLocalOnlySection/modals/VatDeleteModal';
import { unwrapQuery } from 'src/core/api/unwrapQuery';

export const VatAccountsTableAndModals = () => {
  const { t } = useTranslation('global');

  const [modalState, setModalState] = useState<ModalState>({
    kind: 'closed',
  });

  const integrationStatusQueryState = useIntegrationStatusQuery();

  const taxAccountsQueryState = useTaxAccountsQuery();
  const taxAccounts = unwrapQuery(taxAccountsQueryState) || [];
  const vatAccounts = filterIntegrationVatAccounts(taxAccounts);

  const isLoaded =
    taxAccountsQueryState.status === 'success' &&
    integrationStatusQueryState.status === 'success';

  if (
    !isLoaded ||
    !canAccessTaxAccountsSettings(integrationStatusQueryState.data)
  ) {
    return (
      <SkeletonTable
        numberOfRows={2}
        columns={[
          {
            cell: <SkeletonText width="80%" />,
            header: <SkeletonText width="50%" />,
          },
          {
            cell: <SkeletonText width="80%" />,
            header: <SkeletonText width="50%" />,
          },
          {
            cell: <SkeletonText width="80%" />,
            header: <SkeletonText width="50%" />,
          },
        ]}
        withColumnSeparator
        withHeader
      />
    );
  }

  return (
    <div>
      <VatAccountsTable
        vatAccounts={vatAccounts}
        integrationStatus={integrationStatusQueryState.data}
        setModalState={setModalState}
      />
      <Button
        className="mt-s self-start"
        iconName="plus"
        iconPosition="left"
        onClick={() =>
          setModalState({
            kind: 'addVat',
            id: '',
            rate: '',
            name: '',
            code: '',
            inputChanged: false,
          })
        }
        text={t(
          'bookkeep.integrations.settings.vatAccountsTable.addVatAccount',
        )}
        variant="ghost"
      />
      <VatAddModal
        setFormState={setModalState}
        vatAccounts={vatAccounts}
        formState={modalState}
        integration={integrationStatusQueryState.data.integration}
        hideErrorLink
      />
      <VatDeleteModal
        formState={modalState}
        hideSubtitle
        integration={integrationStatusQueryState.data.integration}
        setFormState={setModalState}
      />
    </div>
  );
};
