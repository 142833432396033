import { useFormik } from 'formik';
import { useEffect } from 'react';

import { useNotifications, NotificationType } from 'modules/app/notifications';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { NotificationReceiptReminders } from './NotificationReceiptReminders';
import {
  AUTO_REMINDERS_DEFAULT_VALUE,
  type Notification,
} from '../../../../models';
import { useGetControllersQuery } from '../../hooks/useGetControllersQuery';
import { useUpdateNotificationMutation } from '../../hooks/useUpdateNotificationMutation';

type Props = {
  notification: Notification;
};

export const NotificationReceiptRemindersContainer = ({
  notification,
}: Props) => {
  const { t } = useTranslation('global');
  const { pushNotif } = useNotifications();

  const controllersQuery = useGetControllersQuery();
  const [updateNotification] = useUpdateNotificationMutation();

  const formikProps = useFormik<{ schedulePattern?: string | null }>({
    initialValues: {
      schedulePattern: notification.value,
    },
    onSubmit: async (values) => {
      let value: string | null;

      if (notification.checked) {
        value = values.schedulePattern ?? AUTO_REMINDERS_DEFAULT_VALUE;
      } else {
        value = AUTO_REMINDERS_DEFAULT_VALUE;
      }

      try {
        await updateNotification({
          notif_code: notification.notifCode,
          checked: notification.checked,
          value,
        });
      } catch {
        pushNotif({
          type: NotificationType.Danger,
          message: t('generalSettings.notifications.editFailureNotification'),
        });
        return;
      }

      pushNotif({
        type: NotificationType.Success,
        message: t('generalSettings.notifications.editSuccessNotification'),
      });
    },
  });

  const { values, submitForm } = formikProps;

  useEffect(() => {
    // Submit form whenever the current values are different from the saved ones
    if (values.schedulePattern !== notification.value) {
      submitForm();
    }
  }, [values.schedulePattern, notification.value]);

  return (
    <NotificationReceiptReminders
      users={controllersQuery.status === 'success' ? controllersQuery.data : []}
      {...formikProps}
    />
  );
};
