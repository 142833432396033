import { Skeleton, SkeletonText } from '@dev-spendesk/grapes';
import React from 'react';

import styles from './IntegrationAccountingPageSkeleton.module.css';

export function IntegrationAccountingPageSkeleton() {
  return (
    <div className={styles.page}>
      <div className={styles.section}>
        <div className={styles.sectionDescriptionLine} />
        <div className={styles.wrapper}>
          <div className={styles.title}>
            <Skeleton
              className="mr-s"
              width="var(--sizing-l)"
              height="var(--sizing-l)"
            />
            <Skeleton className="mr-xs" width="150px" height="27px" />
            <Skeleton width="51px" height="var(--sizing-m)" />
          </div>
          <SkeletonText size="xl" className="mb-xxs" />
          <SkeletonText size="xl" className="mb-m" />
          <div className={styles.actions}>
            <SkeletonText width="175px" />
          </div>
        </div>
      </div>
    </div>
  );
}
