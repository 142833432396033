import {
  type HubTask,
  type HubTaskType,
} from 'modules/onboarding/types/hubTask';

import { unwrapQuery } from '../../../../api/unwrapQuery';
import { useSetupHubTasksQuery } from '../pages/SetupHubPage/hooks/useSetupHubTasksQuery';

const filterByType = <T extends HubTaskType>(
  task: HubTask,
  type: T,
): task is HubTask & { type: T } => task.type === type;

/**
 * @param type The type of the task to find
 * @returns
 *  undefined if the query is still loading or errored
 *  null if the task is not found
 *  the task if it is found
 */
export function useHubTaskByType<T extends HubTaskType>(
  type: T,
): (HubTask & { type: T }) | undefined | null {
  const hubTasksQueryState = useSetupHubTasksQuery();
  const hubTasks = unwrapQuery(hubTasksQueryState);

  if (!hubTasks) {
    return undefined;
  }

  const hubTask = hubTasks.find((task) => filterByType(task, type));
  return hubTask || null;
}
