import alexishrLogo from './alexishr.svg';
import bamboohrLogo from './bamboohr.svg';
import breathehrLogo from './breathehr.svg';
import cezannehrLogo from './cezannehr.svg';
import charliehrLogo from './charliehr.svg';
import cormaLogo from './corma.svg';
import deelLogo from './deel.svg';
import entraidLogo from './entraid.svg';
import eureciaLogo from './eurecia.svg';
import factorialLogo from './factorial.svg';
import fgryLogo from './fygr.svg';
import googleworkspaceLogo from './google-workspace.svg';
import hibobLogo from './hibob.svg';
import humaansLogo from './humaans.svg';
import kenjoLogo from './kenjo.svg';
import licenceOneLogo from './licenceone.svg';
import luccaLogo from './lucca.svg';
import officientLogo from './officient.svg';
import oktaLogo from './okta.svg';
import payfitLogo from './payfit.svg';
import payflowsLogo from './payflows.svg';
import personioLogo from './personio.svg';
import remotecomLogo from './remotecom.svg';
import sagehrLogo from './sage-hr.svg';
import successfactorsLogo from './sap-success-factors.svg';
import travelperkLogo from './travelperk.svg';
import trelicaLogo from './trelica.svg';
import workdayLogo from './workday.svg';

export const integrationLogo = {
  alexishr: alexishrLogo,
  bamboohr: bamboohrLogo,
  breathehr: breathehrLogo,
  cezannehr: cezannehrLogo,
  charliehr: charliehrLogo,
  corma: cormaLogo,
  deel: deelLogo,
  entraid: entraidLogo, // Microsoft Entra ID (Azure AD)
  eurecia: eureciaLogo,
  factorial: factorialLogo,
  fygr: fgryLogo,
  googleworkspace: googleworkspaceLogo,
  hibob: hibobLogo,
  humaans: humaansLogo,
  kenjo: kenjoLogo,
  licenceOne: licenceOneLogo,
  lucca: luccaLogo,
  officient: officientLogo,
  okta: oktaLogo,
  payfitpartner: payfitLogo,
  payflows: payflowsLogo,
  personio: personioLogo,
  remotecom: remotecomLogo,
  sagehr: sagehrLogo,
  successfactors: successfactorsLogo,
  travelperk: travelperkLogo,
  trelica: trelicaLogo,
  workday: workdayLogo,
};
