import { useCompanyCurrency } from 'modules/app/hooks/useCompanyCurrency';
import { useWalletTransactionForecastQuery } from 'modules/company';
import { PaymentContext } from 'modules/invoices/hooks';

import { useGetTransfersQueryData } from './api';
import {
  sumTransfersAmount,
  walletAmountAllocationToCheckForMissingFunds,
} from '../models';

export const useWalletTransactionForecastOnTransfersQueryData = () => {
  const { type } = PaymentContext.useContext();
  const getTransfers = useGetTransfersQueryData();
  const companyCurrency = useCompanyCurrency();
  const getWalletTransactionForecastQueryState =
    useWalletTransactionForecastQuery();

  return (): ReturnType<typeof getWalletTransactionForecastQueryState> => {
    const transfersAmountSum = sumTransfersAmount(
      getTransfers({ type }) ?? [],
      companyCurrency,
    );

    return getWalletTransactionForecastQueryState(
      transfersAmountSum,
      walletAmountAllocationToCheckForMissingFunds,
    );
  };
};
