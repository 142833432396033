import { type AppState } from 'src/core/reducers';

export const isValidatingMfaToken = (state: AppState) => {
  return state.mfaActivation.behavior.isValidatingMfaToken;
};

export const isRequestingMfaProcedure = (state: AppState) => {
  return state.mfaActivation.behavior.isRequestingMfaProcedure;
};

export const isActivatingWireTransfer = (state: AppState) => {
  return state.mfaActivation.behavior.isActivatingWireTransfer;
};

export const getMfaToken = (state: AppState) => {
  return {
    isValid: state.mfaActivation.token.isValid,
    isVerified: state.mfaActivation.token.isVerified,
  };
};

export const getIsMfaTokenVerified = (state: AppState) => {
  return state.mfaActivation.token.isVerified;
};

export const getIsMfaTokenValid = (state: AppState) => {
  const { token } = state.mfaActivation;
  return token.isValid;
};
