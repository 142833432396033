import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useNotifications } from 'modules/app/notifications';
import { getDefaultSupplierAccountName } from 'modules/bookkeep';
import { useGetDefaultSupplierAccountQuery } from 'modules/bookkeep/settings/integrations/hooks/useGetDefaultSupplierAccountQuery';
import { useSetDefaultSupplierAccountMutation } from 'modules/bookkeep/settings/integrations/hooks/useSetDefaultSupplierAccountMutation';
import { TaskStepLayout } from 'modules/onboarding/components/TaskStepLayout';
import { unwrapQuery } from 'src/core/api/unwrapQuery';
import { routeFor } from 'src/core/constants/routes';

import { type FormValues } from './form';
import { useMarkHubTaskAsDoneMutation } from '../../../../components/MarkAsDoneButton/hooks/useMarkHubTaskAsDoneMutation';
import { useHubTaskByType } from '../../../hooks/useHubTaskByType';
import { routes } from '../../routes';
import { DefaultSupplierAccountsForm } from '../components/DefaultSupplierAccountsForm';

export const AccountingSupplierAccountsDefault = () => {
  const history = useHistory();

  const { t } = useTranslation('global');
  const { dangerNotif } = useNotifications();

  const companyId = useCompanyId();

  const defaultSupplierAccountQueryState = useGetDefaultSupplierAccountQuery();
  const defaultSupplierAccount = unwrapQuery(defaultSupplierAccountQueryState);
  const defaultCardSupplierCode =
    defaultSupplierAccount?.supplierAccountForCard?.generalAccountCode ?? '';
  const defaultInvoiceSupplierCode =
    defaultSupplierAccount?.supplierAccountForInvoice?.generalAccountCode ?? '';

  const [setDefaultSupplierAccount] = useSetDefaultSupplierAccountMutation();

  const task = useHubTaskByType('account_payable_codes');
  const [markTaskAsDoneMutation] = useMarkHubTaskAsDoneMutation();

  const goBack = () => {
    const previousPath = routeFor(
      routes.ACCOUNTING_SUPPLIER_ACCOUNTS_LIST.path,
      {
        company: companyId,
      },
    );
    history.push(previousPath);
  };

  const { handleSubmit, setFieldValue, values } = useFormik<FormValues>({
    initialValues: {
      cardSupplierCode: defaultCardSupplierCode,
      invoiceSupplierCode: defaultInvoiceSupplierCode,
    },
    enableReinitialize: true,
    onSubmit: async (newValues) => {
      try {
        if (newValues.cardSupplierCode !== defaultCardSupplierCode) {
          await setDefaultSupplierAccount({
            generalAccountCode: newValues.cardSupplierCode,
            name: getDefaultSupplierAccountName('cardSupplier'),
            defaultFor: 'cardSupplier',
            isArchived: false,
          });
        }

        if (newValues.invoiceSupplierCode !== defaultInvoiceSupplierCode) {
          await setDefaultSupplierAccount({
            generalAccountCode: newValues.invoiceSupplierCode,
            name: getDefaultSupplierAccountName('invoiceSupplier'),
            defaultFor: 'invoiceSupplier',
            isArchived: false,
          });
        }

        if (task) {
          await markTaskAsDoneMutation({
            endpointParams: { taskId: task.id },
          });
        }

        const setupHubPath = routeFor(routes.SETUP_HUB_TASK_LIST.path, {
          company: companyId,
        });
        history.push(setupHubPath);
      } catch {
        dangerNotif(
          t(
            'bookkeep.integrations.settings.supplierAccountsTable.errorMessage',
          ),
        );
      }
    },
  });

  return (
    <div className="page__container">
      <TaskStepLayout
        description={t('setupHub.supplierAccounts.description')}
        isLastStep
        onBack={goBack}
        onNext={handleSubmit}
        title={t('setupHub.supplierAccounts.title')}
        video={{
          // TODO(GROW-1484): Replace with localized video URL
          url: '',
          title: t('setupHub.supplierAccounts.helpTitle'),
        }}
      >
        <DefaultSupplierAccountsForm
          cardSupplierCode={values.cardSupplierCode}
          invoiceSupplierCode={values.invoiceSupplierCode}
          onCardSupplierCodeChange={(code: string) =>
            setFieldValue('cardSupplierCode', code)
          }
          onInvoiceSupplierCodeChange={(code: string) =>
            setFieldValue('invoiceSupplierCode', code)
          }
        />
      </TaskStepLayout>
    </div>
  );
};
