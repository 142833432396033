import { FormField, Input } from '@dev-spendesk/grapes';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

type Props = {
  error?: string;
  onChange: (value: string) => void;
  value: string;
  visuallyHideLabel?: boolean;
};

export const DefaultTaxAccountRateFormField = ({
  error,
  onChange,
  value,
  visuallyHideLabel = false,
}: Props) => {
  const { t } = useTranslation('global');
  return (
    <FormField
      className="flex-1"
      label={t('bookkeep.integrations.settings.defaultTaxAccountForm.rateName')}
      htmlFor="set-account-rate-input"
      alertMessage={error}
      visuallyHideLabel={visuallyHideLabel}
    >
      <Input
        placeholder={t(
          'bookkeep.integrations.settings.defaultTaxAccountForm.ratePlaceholder',
        )}
        value={value ? Number.parseFloat(value) : ''}
        type="number"
        rightAddon={
          <p className="border-inherit flex h-full items-center border-0 border-l border-solid border-neutral-light px-s text-neutral-darker body-m">
            %
          </p>
        }
        min={0}
        max={100}
        step="0.01"
        onChange={(event) => onChange(event.target.value)}
        id="set-account-rate-input"
      />
    </FormField>
  );
};
