import { EnableTransfersForExpenses } from './EnableTransfersForExpenses';
import { EnableTransfersForInvoices } from './EnableTransfersForInvoices';

type Props = {
  type: 'invoice' | 'expense';
};

export const EnableTransfers = ({ type }: Props) => {
  return type === 'invoice' ? (
    <EnableTransfersForInvoices />
  ) : (
    <EnableTransfersForExpenses />
  );
};
