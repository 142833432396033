import Intercom from '@intercom/messenger-js-sdk';

import appConfig from 'src/core/config';

type User = {
  id: string;
  createdAt: string;
  displayName: string;
  email: string;
  intercomHash: string;
};

export const initIntercom = (
  user: User,
  chatWidgetLocation?: 'left' | 'right',
) => {
  if (
    window.analytics?.integrations?.Intercom &&
    appConfig.hasDirectIntercomIntegration &&
    appConfig.intercomAppId
  ) {
    const userCreatedAt = new Date(user.createdAt);
    Intercom({
      // eslint-disable-next-line camelcase
      app_id: appConfig.intercomAppId,
      // eslint-disable-next-line camelcase
      user_id: user.id,
      name: user.displayName,
      email: user.email,
      // eslint-disable-next-line camelcase
      created_at: userCreatedAt.getTime() / 1000,
      // eslint-disable-next-line camelcase
      user_hash: user.intercomHash,
      // eslint-disable-next-line camelcase
      hide_default_launcher: true,
      alignment: chatWidgetLocation || 'right',
      // eslint-disable-next-line camelcase
      custom_launcher_selector: '#intercom-message',
    });
  }
};
