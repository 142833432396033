import { useEffect } from 'react';
import { matchPath, useHistory, useLocation } from 'react-router-dom';

import { useFeature } from 'src/core/common/hooks/useFeature';
import FEATURES from 'src/core/constants/features';
import { routeFor, routes } from 'src/core/constants/routes';
import { useCompany } from 'src/core/modules/app/hooks/useCompany';

const underMaintenanceRoutes = [
  routes.EXPENSE_INBOX.path,
  routes.EXPENSE_INBOX_PREPARE.path,
  routes.EXPENSE_INBOX_EXPORT.path,
  routes.EXPENSE_INBOX_BANK_FEE_REVIEW_EXPORT.path,
  routes.EXPENSE_INBOX_EXPORT_HISTORY.path,
  routes.EXPENSE_INBOX_FAILED_BANK_FEES_REVIEW_EXPORT.path,
  routes.EXPENSE_INBOX_FAILED_SETTLEMENT_REVIEW_EXPORT.path,
  routes.EXPENSE_INBOX_REVIEW_EXPORT.path,
  routes.EXPENSE_INBOX_SETTLEMENT_REVIEW_EXPORT.path,
  routes.PAYMENTS_ALL.path,
  routes.PAYABLES_ALL.path,
  routes.COMPANY_ACCOUNTING_INTEGRATION.path,
  routes.COMPANY_ACCOUNTING_BANK_ACCOUNTS.path,
  routes.COMPANY_ACCOUNTING_ANALYTICAL_FIELDS.path,
  routes.COMPANY_ACCOUNTING_BOOKKEEPING_SETUP.path,
  routes.COMPANY_ACCOUNTING_EMPLOYEE_ACCOUNTS.path,
  routes.COMPANY_ACCOUNTING_EXPENSE_ACCOUNTS.path,
  routes.COMPANY_ACCOUNTING_SUPPLIER_ACCOUNTS.path,
  routes.COMPANY_ACCOUNTING_TAX_ACCOUNTS.path,
  routes.COMPANY_ACCOUNTS_PAYABLE.path,
  routes.COMPANY_ACCOUNTS_PAYABLE_SUPPLIERS.path,
  routes.COMPANY_ACCOUNTS_PAYABLE_SUPPLIERS_DETAIL.path,
  routes.COMPANY_EXPORTS.path,
];

export const useShouldRedirectMaintenencePage = () => {
  const location: Location = useLocation();
  const company = useCompany();
  const history = useHistory();

  const isPartialMaintenanceEnabled = useFeature(
    FEATURES.PARTIAL_MAINTENANCE_ENABLED,
  );

  useEffect(() => {
    if (!isPartialMaintenanceEnabled) {
      return;
    }

    const { pathname } = location;

    if (
      underMaintenanceRoutes.some((path) =>
        matchPath(pathname, {
          path,
          exact: false,
          strict: false,
        }),
      )
    ) {
      history.push(
        routeFor(routes.MAINTENANCE.path, {
          company: company.id,
        }),
      );
    }
  }, [location]);
};
