import { Callout, InfoTip } from '@dev-spendesk/grapes';
import React from 'react';

import { QuerySuspense } from 'common/components/QuerySuspense';
import { useFeature } from 'common/hooks/useFeature';
import { useTranslation } from 'common/hooks/useTranslation';
import { type AccountingSoftware } from 'modules/bookkeep/integration/status';
import { type ExpenseAccount } from 'modules/bookkeep/settings/accounting';
import { type InfiniteQueryState } from 'src/core/api/queryState';
import { SearchFilter } from 'src/core/common/components/SearchFilter';
import FEATURES from 'src/core/constants/features';

import { ExpenseAccountTable } from './ExpenseAccountTable';
import { ExpenseAccountsSkeletonTable } from './ExpenseAccountsSkeletonTable';
import { getExpenseAccountsDescriptionI18nKey } from './translations';

interface Props {
  search: string;
  expenseAccountQueryState: InfiniteQueryState<ExpenseAccount[]>;
  integration: AccountingSoftware;
  setSearch: (value: string) => void;
  handleToggleAvailabilityExpenseAccount: (
    expenseAccount: ExpenseAccount,
  ) => Promise<void>;
}

export const ExpenseAccountPullSection = ({
  expenseAccountQueryState,
  integration,
  search,
  setSearch,
  handleToggleAvailabilityExpenseAccount,
}: Props) => {
  const { t } = useTranslation('global');
  const hasHideAccountsFeature = useFeature(FEATURES.TMP_HIDE_EXPENSE_ACCOUNTS);

  const hasHideAccountsFeatureOrAvailableIntegration =
    hasHideAccountsFeature ||
    ['Xero', 'Netsuite', 'Sage100', 'ACD', 'Odoo', 'ExactOnlineFr'].includes(
      integration,
    );

  return (
    <div id="expense-accounts">
      <div className="flex items-center gap-xs">
        <div className="flex-grow">
          <h3 className="IntegrationAccountingPage__section-title title-xl">
            {t('bookkeep.integrations.settings.sectionExpenseAccounts')}
          </h3>
          <p className="IntegrationAccountingPage__section-description body-m">
            {t(getExpenseAccountsDescriptionI18nKey(integration))}
            {hasHideAccountsFeatureOrAvailableIntegration && (
              <InfoTip
                className="ml-xxs"
                content={t(
                  'bookkeep.integrations.settings.expenseAccountsDescriptionTooltip',
                )}
              />
            )}
          </p>
        </div>
        <div>
          <SearchFilter
            search={search}
            setSearch={(localSearch) => setSearch(localSearch)}
            placeholder={t(
              'bookkeep.integrations.settings.expenseAccountsTable.searchPlaceholder',
            )}
          />
        </div>
      </div>
      <QuerySuspense
        queryState={expenseAccountQueryState}
        loading={<ExpenseAccountsSkeletonTable />}
        fallback={() => <ExpenseAccountsErrorWarning />}
      >
        {(expenseAccounts) => (
          <ExpenseAccountTable
            expenseAccounts={expenseAccounts}
            fetchNextPage={expenseAccountQueryState.fetchNextPage}
            hasHideAccountsFeatureOrAvailableIntegration={
              hasHideAccountsFeatureOrAvailableIntegration
            }
            hasFooter={expenseAccountQueryState.hasNextPage}
            isLoading={
              expenseAccountQueryState.status === 'loading' &&
              !expenseAccountQueryState.isFetchingNextPage
            }
            isFetchingMore={expenseAccountQueryState.isFetchingNextPage}
            onSwitch={(row) => handleToggleAvailabilityExpenseAccount(row)}
          />
        )}
      </QuerySuspense>
    </div>
  );
};

const ExpenseAccountsErrorWarning = () => {
  const { t } = useTranslation('global');

  return (
    <Callout
      title={t('bookkeep.integrations.settings.errorLoading')}
      variant="alert"
    />
  );
};
