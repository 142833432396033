import { Select } from '@dev-spendesk/grapes';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

type Props = {
  value: number;
  onSelect(daysAmount: number): void;
};

export const DayDropdown = ({ value, onSelect }: Props) => {
  const { t } = useTranslation('global');

  const DAYS: {
    key: string;
    label: string;
  }[] = [...Array(30).keys()].map((index) => {
    // +2 is not to have '0 or 1 day'
    const day = index + 2;
    return {
      key: day.toString(),
      label: t('misc.day', { count: day }),
    };
  });

  return (
    <Select
      fit="parent"
      options={DAYS}
      value={DAYS.find((day) => day.key === value.toString())}
      onSelect={(dayOption) => {
        if (!dayOption) {
          return;
        }
        const dayOptionKey = Number.parseInt(dayOption?.key);
        if (!Number.isNaN(dayOptionKey)) {
          onSelect(dayOptionKey);
        }
      }}
    />
  );
};
