import {
  AmountInput,
  Button,
  Callout,
  DropdownItem,
  DropdownMenu,
  FormField,
  Modal,
  SkeletonText,
} from '@dev-spendesk/grapes';
import { type FormikErrors, useFormik } from 'formik';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';

import { QueryError } from 'src/core/common/components/QueryError';
import { QuerySuspense } from 'src/core/common/components/QuerySuspense';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { useCompanyCurrency } from 'src/core/modules/app/hooks/useCompanyCurrency';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';
import {
  NotificationType,
  useNotifications,
} from 'src/core/modules/app/notifications';
import { getCurrencyOptionByKey } from 'src/core/utils/money';

import { useSpendLimitQuery } from '../../hooks/useSpendLimitQuery';
import { createSpendLimit } from '../../mutations/createSpendLimit';
import { editSpendLimit } from '../../mutations/editSpendLimit';
import { ExpenseCategoryDropdown } from '../ExpenseCategoryDropdown';

type Props = {
  mode: 'create' | 'edit';
  spendLimitId?: string;
  isOpen: boolean;
  onClose(): void;
};

type SpendLimitFormProps = {
  expenseCategoryId: string | undefined;
  amount: number | undefined;
  period: 'day' | 'month' | undefined;
};

export const SpendLimitModal = ({
  mode,
  onClose,
  isOpen,
  spendLimitId,
}: Props) => {
  const { t } = useTranslation('global');
  const spendLimitQueryState = useSpendLimitQuery(spendLimitId);
  const companyCurrency = useCompanyCurrency();
  const companyId = useCompanyId();
  const { pushNotif } = useNotifications();
  const queryClient = useQueryClient();

  const expenseCategoryId =
    spendLimitQueryState.status === 'success' && mode === 'edit'
      ? spendLimitQueryState.data.customFieldValueId
      : undefined;
  const amount =
    spendLimitQueryState.status === 'success' && mode === 'edit'
      ? spendLimitQueryState.data.amount
      : undefined;
  const period =
    spendLimitQueryState.status === 'success' && mode === 'edit'
      ? spendLimitQueryState.data.period
      : 'day';

  const {
    handleSubmit,
    setFieldValue,
    resetForm,
    errors: formErrors,
    values: formValues,
  } = useFormik<SpendLimitFormProps>({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
      expenseCategoryId,
      amount,
      period,
    },
    validate: (values) => {
      const errors: FormikErrors<SpendLimitFormProps> = {};
      if (!values.expenseCategoryId || values.expenseCategoryId.trim() === '') {
        errors.expenseCategoryId = t('spendLimits.modal.expenseCategoryError');
      }
      if (!values.amount || values.amount === 0) {
        errors.amount = t('spendLimits.modal.spendLimitError');
      }
      return errors;
    },
    onSubmit: async (values) => {
      if (values.expenseCategoryId && values.amount && values.period) {
        try {
          if (mode === 'edit') {
            await editSpendLimit({
              payload: {
                customFieldValueId: values.expenseCategoryId,
                amount: values.amount,
                period: values.period,
                id: spendLimitId as string,
              },
              companyId,
              spendLimitId: spendLimitId as string,
            });
          } else {
            await createSpendLimit({
              payload: {
                customFieldValueId: values.expenseCategoryId,
                amount: values.amount,
                period: values.period,
              },
              companyId,
            });
          }
        } catch (error) {
          pushNotif({
            type: NotificationType.Danger,
            message: t('spendLimits.modal.creation.error'),
          });
          throw new Error('Failed to update approval flow', error);
        }
        onClose();
        queryClient.invalidateQueries(['spend-limits']);
        pushNotif({
          type: NotificationType.Success,
          message: t('spendLimits.modal.creation.success'),
        });
      }
    },
  });

  useEffect(() => {
    if (isOpen) {
      resetForm();
    }
  }, [isOpen, resetForm]);

  return (
    <Modal
      isOpen={isOpen}
      title={
        mode === 'create'
          ? t('spendLimits.modal.creation.title')
          : t('spendLimits.modal.edition.title')
      }
      iconName="plus-circle"
      actions={[
        <Button
          key="cancel-button"
          text={t('misc.cancel')}
          variant="secondary"
          onClick={onClose}
        />,
        <Button
          key="create-button"
          iconPosition="right"
          text={mode === 'create' ? t('misc.create') : t('misc.save')}
          onClick={() => {
            handleSubmit();
          }}
        />,
      ]}
    >
      <div className="-mt-s text-center text-neutral-dark body-m">
        {mode === 'create'
          ? t('spendLimits.modal.creation.description')
          : t('spendLimits.modal.edition.description')}
      </div>
      <QuerySuspense
        fallback={(error) => (
          <QueryError componentType="Callout" queryError={error} />
        )}
        loading={<SpendLimitModalSkeleton />}
        queryState={spendLimitQueryState}
      >
        {() => (
          <>
            <FormField
              label={t('spendLimits.modal.expenseCategoryLabel')}
              alertMessage={formErrors.expenseCategoryId}
              className="mt-m"
            >
              <ExpenseCategoryDropdown
                onChange={(id) => setFieldValue('expenseCategoryId', id)}
                value={formValues.expenseCategoryId}
              />
            </FormField>
            <FormField
              label={t('spendLimits.modal.spendLimitLabel')}
              alertMessage={formErrors.amount}
              className="mt-s"
            >
              <div className="flex items-center gap-xs">
                <span>{t('spendLimits.modal.spendLimitText')}</span>
                <AmountInput
                  value={formValues.amount ?? null}
                  currency={getCurrencyOptionByKey(companyCurrency)}
                  onChange={(_, value) => setFieldValue('amount', value)}
                  placeholder={t('spendLimits.modal.spendLimitPlaceholder')}
                />
                <DropdownMenu
                  options={[
                    { key: 'day', label: t('spendLimits.modal.creation.day') },
                    {
                      key: 'month',
                      label: t('spendLimits.modal.creation.month'),
                    },
                  ]}
                  onSelect={(option) => setFieldValue('period', option.key)}
                  renderOption={(option) => (
                    <DropdownItem label={option.label} />
                  )}
                  renderButton={(getToggleButtonProps) => (
                    <Button
                      {...getToggleButtonProps()}
                      text={
                        formValues.period === 'day'
                          ? t('spendLimits.modal.creation.day')
                          : t('spendLimits.modal.creation.month')
                      }
                      variant="secondary"
                    />
                  )}
                />
              </div>
            </FormField>
            <Callout
              title={
                formValues.period === 'day'
                  ? t('spendLimits.modal.calloutTextDay')
                  : t('spendLimits.modal.calloutTextMonth')
              }
              className="mt-s"
              variant="info"
            />
          </>
        )}
      </QuerySuspense>
    </Modal>
  );
};

const SpendLimitModalSkeleton = () => {
  return (
    <>
      <SkeletonText className="mt-m" />
      <SkeletonText className="mt-s" />
      <SkeletonText className="mt-s" />
      <SkeletonText className="mt-s" />
    </>
  );
};
