import { useState } from 'react';

import { ModalError } from 'common/components/ModalError';
import { ModalLoader } from 'common/components/ModalLoader';
import { QuerySuspense } from 'common/components/QuerySuspense';
import { useTranslation } from 'common/hooks/useTranslation';
import { useNotifications, NotificationType } from 'modules/app/notifications';
import { PreviewApprovalPolicyModal } from 'modules/company/members/components/ApprovalPolicies/components/PreviewApprovalPolicyModal';
import { useDeletePolicy } from 'modules/company/members/components/ApprovalPolicies/hooks/useDeletePolicy';
import { useQueryStates } from 'src/core/api/hooks/useQueryStates';

import { useEditUsersPolicyMutation } from './hooks/useEditUsersPolicyMutation';
import {
  type ApprovalPolicyFormValues,
  EditApprovalPolicyModal,
} from '../../components/EditApprovalPolicyModal';
import { useMembersQuery, usePoliciesQuery } from '../../hooks';
import { type Member } from '../../models/member';
import { type Policy } from '../../models/policy';

type Props = {
  policyId: string;
  onSuccess(): void;
  onCancel(): void;
};

export const EditApprovalPolicyModalContainer = ({
  policyId,
  onSuccess,
  onCancel,
}: Props) => {
  const { t } = useTranslation('global');
  const membersPoliciesQueryState = useQueryStates({
    states: {
      members: useMembersQuery(),
      policies: usePoliciesQuery(),
    },
  });

  return (
    <QuerySuspense
      queryState={membersPoliciesQueryState}
      loading={<ModalLoader />}
      fallback={() => (
        <ModalError message={t('members.table.error')} onClose={onCancel} />
      )}
    >
      {({ policies, members }) => (
        <EditApprovalPolicyModalContainerWithData
          policy={policies.find(({ id }) => id === policyId) as Policy}
          policies={policies}
          members={members}
          onCancel={onCancel}
          onSuccess={onSuccess}
        />
      )}
    </QuerySuspense>
  );
};

type PropsWithData = {
  policy: Policy;
  policies: Policy[];
  members: Member[];
  onSuccess(): void;
  onCancel(): void;
};

const EditApprovalPolicyModalContainerWithData = ({
  policy,
  policies,
  members,
  onSuccess,
  onCancel,
}: PropsWithData) => {
  const { t } = useTranslation('global');
  const { pushNotif } = useNotifications();
  const [editionView, setEditionView] = useState<'preview' | 'edition'>(
    'preview',
  );

  const policyMembers: Member[] = members.filter(
    (member) => member.policy?.id === policy.id,
  );

  const standardPolicy = policies.find(({ isDefault }) => isDefault);

  const [deletePolicy] = useDeletePolicy(policy.id);

  const [updateUsersPolicy] = useEditUsersPolicyMutation({
    policy,
    policyMembers,
    standardPolicyId: standardPolicy?.id,
  });

  const handleUpdatePolicy = async (values: ApprovalPolicyFormValues) => {
    try {
      await updateUsersPolicy(values);
      pushNotif({
        type: NotificationType.Success,
        message: t('approvalPolicy.errors.updateSuccessful'),
      });
      onSuccess();
    } catch {
      pushNotif({
        type: NotificationType.Danger,
        message: t('approvalPolicy.errors.cantUpdate'),
      });
    }
  };

  const handleDeletePolicy = async () => {
    try {
      await deletePolicy();
      onSuccess();
      pushNotif({
        type: NotificationType.Success,
        message: t('approvalPolicy.errors.deleteSuccessful'),
      });
    } catch {
      pushNotif({
        type: NotificationType.Danger,
        message: t('approvalPolicy.errors.cantDelete'),
      });
    }
  };

  if (editionView === 'edition') {
    return (
      <EditApprovalPolicyModal
        policy={policy}
        policyMembers={policyMembers}
        members={members}
        onSuccess={handleUpdatePolicy}
        onCancel={() => setEditionView('preview')}
      />
    );
  }

  return (
    <PreviewApprovalPolicyModal
      policy={policy}
      policyMembers={policyMembers}
      onEditPolicy={() => setEditionView('edition')}
      onDelete={handleDeletePolicy}
      onCancel={onCancel}
    />
  );
};
