import { Button, Icon } from '@dev-spendesk/grapes';
import React from 'react';

import { useUser } from 'modules/app/hooks/useUser';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { useCompany } from 'src/core/modules/app/hooks/useCompany';

import { useFetchCompanyBillingStatus } from '../../hooks/useFetchCompanyBillingStatus';
import './OverdueInvoiceNotification.css';

export const OverdueInvoiceNotification = () => {
  const { t } = useTranslation('global');
  const user = useUser();
  const company = useCompany();
  const fundWalletUrl = `/app/${company.id}/company/bank/funding`;

  const handleButtonClick = () => {
    window.location.href = fundWalletUrl;
  };

  const billingStatusQueryState = useFetchCompanyBillingStatus(company.id);

  if (billingStatusQueryState.status !== 'success') {
    return null;
  }

  if (!user.is_account_owner && !user.is_controller) {
    return null;
  }

  const billingStatus = billingStatusQueryState.data;

  const isCompanyAtRisk =
    billingStatus &&
    billingStatus.status?.billingInvoicePaymentStatus === 'atRisk';

  if (isCompanyAtRisk) {
    return (
      <section className="OverdueInvoiceNotification flex items-center justify-center bg-alert text-white">
        <div className="flex h-[36px] w-full items-center justify-center gap-m">
          <div className="flex h-m items-center gap-s">
            <span className="title-l">
              {t('overdueInvoice.notification.title')}
            </span>
            <div className="h-full w-[1px] bg-white opacity-50"></div>
            <span className="flex items-center gap-[6px] body-l">
              <Icon size="m" name="mail" />
              {t('overdueInvoice.notification.description')}
            </span>
          </div>
          <Button
            variant="contrasted"
            text={t('overdueInvoice.notification.cta')}
            onClick={handleButtonClick}
          />
        </div>
      </section>
    );
  }

  return null;
};
