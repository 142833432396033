import { Callout } from '@dev-spendesk/grapes';
import classNames from 'classnames';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import styles from './DatevAccountFormAdvice.module.css';

export const DatevAccountFormAdvice = ({
  accountLength,
  isAlert,
  title,
  className,
  isAccountsPayable = undefined,
}: {
  accountLength: number;
  isAlert: boolean;
  title?: string;
  className?: string;
  isAccountsPayable?: boolean;
}) => {
  const { t } = useTranslation('global');

  return (
    <Callout
      variant={isAlert ? 'alert' : 'info'}
      title={title ?? t('bookkeep.integrations.datev.accountCodeAdvice.title')}
      className={classNames(className, isAlert ? 'error' : 'tips')}
    >
      <ul className={styles.list}>
        <li>
          {t('bookkeep.integrations.datev.accountCodeAdvice.valuesRange', {
            lowestValue: isAccountsPayable
              ? '1'.padEnd(accountLength, '0')
              : '1'.padStart(accountLength, '0'),
            highestValue: '9'.repeat(accountLength),
          })}
        </li>
        <li>
          {t('bookkeep.integrations.datev.accountCodeAdvice.maximumLength', {
            maxCharacters: accountLength,
          })}
        </li>
        <li>
          {t('bookkeep.integrations.datev.accountCodeAdvice.contentType')}
        </li>
      </ul>
    </Callout>
  );
};
