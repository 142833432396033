import { Button } from '@dev-spendesk/grapes';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import pickBy from 'lodash/pickBy';
import values from 'lodash/values';
import { useState } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import {
  NewBranch,
  NewBranchButton,
} from 'modules/app/layout/components/NewBranch';

import {
  LegacySubscriptionAddonItem,
  LegacySubscriptionItems,
  LegacySubscriptionUsageItem,
} from './LegacySubscriptionItem';
import { Subscription } from './Subscription';
import {
  addIncludedExpensesToPrice,
  ADDON_NAMES,
  USAGE_UNITS,
} from './helpers';
import { type FusebillSubscription } from '../../../../models';

import './LegacyCompanyPlanSubscription.css';

type Props = {
  companySubscription: FusebillSubscription;
};

export const LegacyCompanyPlanSubscription = ({
  companySubscription,
}: Props) => {
  const { t } = useTranslation('global');
  const companyId = useCompanyId();
  const [showNewBranchFlow, setShowNewBranchFlow] = useState(false);

  const isSubscriptionActive =
    companySubscription.status === 'active' ||
    (companySubscription.version === 'v1' &&
      companySubscription.status === 'Active');

  if (!isSubscriptionActive) {
    return (
      <div className="LegacyCompanyPlanSubscription__TopInfo">
        {t('billing.notActivated')}
      </div>
    );
  }

  if (companyId !== companySubscription.responsibleCompany?.id) {
    return (
      <div className="LegacyCompanyPlanSubscription__TopInfo">
        {t('billing.centralized', {
          responsibleCompanyName: companySubscription.responsibleCompany?.name,
        })}
      </div>
    );
  }

  const subscriptionName =
    companySubscription.name || companySubscription.plan.name;

  const usages = pickBy(companySubscription.price.usage, (_, unit) =>
    Object.values(USAGE_UNITS).includes(unit),
  );
  const addons = pickBy(companySubscription.addons, (_, addonName) =>
    includes(values(ADDON_NAMES), addonName),
  );

  return (
    <>
      <div className="LegacyCompanyPlanSubscription__TopInfo">
        <Subscription
          name={subscriptionName}
          price={
            companySubscription.addons?.included_expenses
              ? addIncludedExpensesToPrice(
                  companySubscription.price.constant,
                  companySubscription.addons?.included_expenses.totalAmount,
                )
              : companySubscription.price.constant
          }
          discounts={companySubscription.price.discounts}
        />
        <LegacySubscriptionItems>
          {map(usages, (bracket, unit) => (
            <LegacySubscriptionUsageItem
              key={unit}
              unit={unit as keyof typeof USAGE_UNITS}
              usage={bracket}
            />
          ))}
        </LegacySubscriptionItems>
        {!isEmpty(addons) && (
          <LegacySubscriptionItems>
            {map(addons, (price, addonName) => (
              <LegacySubscriptionAddonItem
                key={addonName}
                // @ts-expect-error the TS is wrongly done here but I won't tackle it now :D
                name={addonName}
                // @ts-expect-error the TS is wrongly done here but I won't tackle it now :D
                price={price}
              />
            ))}
          </LegacySubscriptionItems>
        )}
        <button
          type="button"
          id="intercom-message"
          className="LegacyCompanyPlanSubscription__TopInfo-button"
        >
          {t('billing.changePlanCta')}
        </button>
      </div>
      <div className="LegacyCompanyPlanSubscription__BranchCta">
        <NewBranchButton>
          <Button
            variant="primary"
            text={t('billing.branchCta')}
            onClick={() => {
              setShowNewBranchFlow(true);
            }}
          />
        </NewBranchButton>
        {showNewBranchFlow && (
          <NewBranch onClose={() => setShowNewBranchFlow(false)} />
        )}
      </div>
    </>
  );
};
