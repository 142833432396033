import { type CurrenciesKey } from 'src/core/config/money';

export type EntityStatusFilterValue = 'active' | 'inactive';
export const isValidEntityStatusFilterValue = (
  status: string,
): status is EntityStatusFilterValue =>
  status === 'active' || status === 'inactive';
export type FundsStatusFilterValue = 'available' | 'shortfall';
export const isValidFundsStatusFilterValue = (
  status: string,
): status is FundsStatusFilterValue =>
  status === 'available' || status === 'shortfall';
export type EntityFilters = {
  currencyFilters: CurrenciesKey[];
  entityStatusFilters: EntityStatusFilterValue[];
  fundsStatusFilters: FundsStatusFilterValue[];
};
export enum EntityFilterQueryParameterKey {
  currencyFilters = 'currencies',
  entityStatusFilters = 'status',
  fundsStatusFilters = 'funds_status',
}

export const DEFAULT_ENTITY_FILTERS: EntityFilters = {
  currencyFilters: [],
  entityStatusFilters: [],
  fundsStatusFilters: [],
};
