import { useTranslation } from 'common/hooks/useTranslation';
import { useNotifications } from 'modules/app/notifications';
import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

import { type ApiCard } from '../card';

type Payload = {
  amount: number;
};

type Response = ApiCard;

export const useTopupCardMutation = (
  cardId: string,
): MutationState<Payload, Response> => {
  const { t } = useTranslation('global');
  const { successNotif, dangerNotif } = useNotifications();

  return useMutation<Payload, Response, Response>({
    request: {
      type: 'rest',
      method: 'put',
      target: 'companyAPI',
      endpoint: `/cards/${cardId}/topup`,
    },
    reshapeData: (data) => data,
    options: {
      onSuccess: () => {
        successNotif(t('cards.actions.successTopup'));
      },
      onError: () => {
        dangerNotif(t('errors:somethingWrong'));
      },
    },
  });
};
