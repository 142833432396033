import {
  colors,
  Button,
  Callout,
  IconButton,
  SkeletonTable,
  SkeletonText,
  Table,
} from '@dev-spendesk/grapes';
import { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { CustomFieldDeletionModalContainer } from 'modules/custom-fields/containers/CustomFieldDeletionModalContainer';
import { useCustomFieldsQuery } from 'modules/custom-fields/hooks/useCustomFieldsQuery';
import { useDesignatedCustomFieldQuery } from 'modules/custom-fields/hooks/useDesignatedCustomFieldQuery';
import type { CustomField } from 'modules/custom-fields/models/customField';
import { EllipsisTooltip } from 'src/core/common/components/EllipsisTooltip';
import { routeFor } from 'src/core/constants/routes';

import { CustomFieldFormModal } from './components/CustomFieldFormModal';
import { SetupHubLayout } from '../../../components/SetupHubLayout';
import { VideoBlock } from '../../../components/VideoBlock';
import { routes } from '../../routes';

export const ControlSettingsCustomFieldList = () => {
  const { t } = useTranslation('global');

  const history = useHistory();
  const companyId = useCompanyId();

  const [formModalMode, setFormModalMode] = useState<
    'create' | 'edit' | undefined
  >(undefined);
  const [editingCustomField, setEditingCustomField] = useState<
    CustomField | undefined
  >(undefined);
  const [deletingCustomField, setDeletingCustomField] =
    useState<null | CustomField>(null);

  const customFieldsQueryState = useCustomFieldsQuery({});
  const designatedCustomFieldQueryState = useDesignatedCustomFieldQuery();
  const customFields = useMemo(() => {
    if (
      customFieldsQueryState.status !== 'success' ||
      designatedCustomFieldQueryState.status !== 'success'
    ) {
      return [];
    }
    return customFieldsQueryState.data.filter(
      (customField) =>
        customField.id !== designatedCustomFieldQueryState.data.customFieldId,
    );
  }, [customFieldsQueryState, designatedCustomFieldQueryState]);

  const onContinue = () => {
    const setupHubPath = routeFor(routes.SETUP_HUB_TASK_LIST.path, {
      company: companyId,
    });
    history.push(setupHubPath);
  };

  const goBack = () => {
    const hubPath = routeFor(routes.CONTROL_SETTINGS_CUSTOM_FIELD_NAME.path, {
      company: companyId,
    });
    history.push(hubPath);
  };

  return (
    <SetupHubLayout
      onBack={goBack}
      onNext={onContinue}
      rightContent={
        <VideoBlock
          id="custom_fields"
          title={t('setupHub.customField.video.title')}
          description={t('setupHub.customField.video.description')}
        />
      }
      scope="custom-field-list"
      stepIndex={2}
      stepTotal={3}
      title={t('setupHub.customField.title')}
    >
      <Callout className="mb-m" title={t('setupHub.customField.callout.title')}>
        <p>{t('setupHub.customField.callout.description')}</p>
      </Callout>
      {customFieldsQueryState.status === 'success' ? (
        <>
          <Table
            columns={[
              {
                id: 'name',
                header: t('setupHub.customField.columns.name'),
                renderCell: ({ name }) => (
                  <div className="truncate">{name}</div>
                ),
              },
              {
                id: 'values',
                header: t('setupHub.customField.columns.values'),
                renderCell: (row, { isRowHovered }) => (
                  <div className="flex items-center justify-between">
                    <div className="truncate">
                      <EllipsisTooltip
                        text={row.values.map((value) => value.name).join(', ')}
                      />
                    </div>
                    {isRowHovered && (
                      <div className="flex gap-xxs">
                        <IconButton
                          variant="borderless"
                          iconName="pen"
                          iconColor={colors.complementary}
                          onClick={() => {
                            setFormModalMode('edit');
                            setEditingCustomField(row);
                          }}
                          aria-label={t('misc.edit')}
                        />
                        <IconButton
                          variant="borderless"
                          iconName="trash"
                          iconColor={colors.complementary}
                          onClick={() => {
                            setDeletingCustomField(row);
                          }}
                          aria-label={t('misc.delete')}
                        />
                      </div>
                    )}
                  </div>
                ),
              },
            ]}
            data={customFields}
            hasColumnSeparator
            emptyState={{
              title: t('setupHub.customField.emptyState.title'),
            }}
            getRowId={(row) => row.id}
          />
          <Button
            className="mt-s"
            onClick={() => {
              setFormModalMode('create');
            }}
            text={t('setupHub.customField.addCustomFieldCta')}
            variant="ghost"
            iconPosition="left"
            iconName="plus"
          />
        </>
      ) : (
        <SkeletonTable
          numberOfRows={1}
          columns={[
            { cell: <SkeletonText />, header: <SkeletonText /> },
            { cell: <SkeletonText />, header: <SkeletonText /> },
          ]}
          withColumnSeparator
          withHeader
        />
      )}
      {deletingCustomField && (
        <CustomFieldDeletionModalContainer
          customField={deletingCustomField}
          isOpen={!!deletingCustomField}
          onClose={() => setDeletingCustomField(null)}
        />
      )}
      <CustomFieldFormModal
        customField={editingCustomField}
        isOpen={!!formModalMode}
        mode={formModalMode}
        onClose={() => {
          if (formModalMode === 'edit') {
            setEditingCustomField(undefined);
          }
          setFormModalMode(undefined);
        }}
      />
    </SetupHubLayout>
  );
};
