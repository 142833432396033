import {
  Callout,
  type CalloutVariant,
  DATE_FORMAT,
  SwitchField,
} from '@dev-spendesk/grapes';
import { type PanelEditableSectionProps } from '@dev-spendesk/grapes/dist/components/Panel/PanelEditableSection';
import { add, fromNumber } from 'ezmoney';
import React, { useMemo } from 'react';

import {
  useAccountingIntegrationStatusQuery,
  useAmortisationSchemesQuery,
  useHasAccountingIntegrationCapability,
} from 'modules/accounting-integration/apis';
import { useNotifications } from 'modules/app/notifications';
import {
  useMustDisplayAccountsPayable,
  useUndoMarkPayableAsPrepared,
} from 'modules/bookkeep/hooks';
import {
  type AmortisationCapability,
  isIntegrationStatusWithIntegration,
} from 'modules/bookkeep/integration/status';
import { usePreparePayablesRouter } from 'modules/bookkeep/prepare-payables/hooks/usePreparePayablesRouter';
import { useCostCentersQuery } from 'modules/budgets/apis';
import {
  useGetPayableFieldValues,
  useIsEditablePayable,
} from 'modules/payable/hooks';
import { unwrapQuery } from 'src/core/api/unwrapQuery';
import { AutomationIcon } from 'src/core/common/components/AutomationIcon';
import { PanelItemsSection } from 'src/core/common/components/Panel';
import { type SimpleItem } from 'src/core/common/components/Panel/PanelItemsSection/SimplePanelItem';
import { useFeature } from 'src/core/common/hooks/useFeature';
import {
  type I18nKey,
  useTranslation,
} from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';
import { usePayableAccountPayableFieldAutomation } from 'src/core/modules/payable/components';
import { getCodeWithAuxiliaryAccounts } from 'src/core/utils/accountPayable';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';
import { TypeGuardError } from 'src/core/utils/switchGuard';

import { PayableAccountingEditSection } from './PayableAccountingEditSection/PayableAccountingEditSection';
import buildSplittableFieldPanelItems from './buildSplittableFieldPanelItems';
import { useCustomFieldsQuery } from './hooks/useCustomFieldsQuery';
import { useDesignatedCustomFieldQuery } from './hooks/useDesignatedCustomFieldQuery';
import { useExpenseCategoriesQuery } from './hooks/useExpenseCategoriesQuery';
import { usePayableAccountingEditForm } from './hooks/usePayableAccountingEditForm';
import { isAmortisationSchemesSupported } from '../../../utils/amortisation';
import { EditAfterExportEditCallout } from '../../components/PayablePanel/EditAfterExportEditCallout';
import {
  getIsPayableExported,
  getIsPayablePrepared,
} from '../../models/payable';
import type { Payable } from '../PayablePanelContainer';

type Props = {
  payable: Payable;
};

const hasNoSplitValues = (
  items: React.ComponentProps<typeof PanelItemsSection>['items'],
): items is SimpleItem[] => {
  return items.every((item) => item.values === undefined);
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const PayableAccountingSectionContainer = ({ payable }: Props) => {
  const { t, localeFormat } = useTranslation('global');
  const { dangerNotif } = useNotifications();

  const formatDate = (date: Date) => localeFormat(date, DATE_FORMAT.SHORT);

  const hasTaxAccountsCapability =
    useHasAccountingIntegrationCapability('taxAccounts');

  const hasCostCentersFeature = useFeature(FEATURES.COST_CENTERS_ACTIVATED);
  const costCentersQueryState = useCostCentersQuery({ withDeleted: true });
  const expenseCategoriesQueryState = useExpenseCategoriesQuery();
  const mustDisplayAccountCode = useMustDisplayAccountsPayable();
  const customFieldsQueryState = useCustomFieldsQuery(payable);
  const designatedCustomFieldQueryState = useDesignatedCustomFieldQuery();
  const customFieldIdForExpenseCategory =
    designatedCustomFieldQueryState.status === 'success'
      ? designatedCustomFieldQueryState.data.customFieldId
      : undefined;

  // Prefetch custom fields values for faster rendering when editing payable
  useGetPayableFieldValues();

  const preparePayableRouter = usePreparePayablesRouter();

  const [undoMarkPayableAsPrepared] = useUndoMarkPayableAsPrepared();

  const isEditable = useIsEditablePayable(payable);

  const integrationStatusQuery = useAccountingIntegrationStatusQuery();

  let amortisationCapability: AmortisationCapability | undefined;

  if (
    useFeature(FEATURES.BOOKKEEP_AMORTISATION) &&
    integrationStatusQuery.status === 'success' &&
    isIntegrationStatusWithIntegration(integrationStatusQuery.data)
  ) {
    amortisationCapability =
      integrationStatusQuery.data.capabilities.amortisation;
  }

  const amortisationSchemesQueryState = useAmortisationSchemesQuery({
    isEnabled: isAmortisationSchemesSupported(amortisationCapability),
  });

  const amortisationSchemes = unwrapQuery(amortisationSchemesQueryState) ?? [];

  const { formik, handleOnSectionCancelEdit, handleOnSectionSaveEdit } =
    usePayableAccountingEditForm({
      payable,
      amortisationCapability,
      hasCostCentersFeature,
    });

  const payableCurrency = payable.grossAmount.currency;

  const accountPayableAutomationMessage =
    usePayableAccountPayableFieldAutomation(
      payable,
      payable.accountPayable?.id,
    );

  const accountPayableAutomation = accountPayableAutomationMessage ? (
    <AutomationIcon
      message={accountPayableAutomationMessage}
      className="mr-xxs w-s shrink-0"
    />
  ) : null;

  const items = useMemo(
    // eslint-disable-next-line sonarjs/cognitive-complexity
    () => {
      const grossAmount = payable.itemLines.reduce(
        (total, itemLine) => add(total, itemLine.grossAmount),
        fromNumber(0, payableCurrency),
      );

      const netAmount = payable.itemLines.reduce(
        (total, itemLine) => add(total, itemLine.netAmount),
        fromNumber(0, payableCurrency),
      );

      const taxAmount = payable.itemLines.reduce(
        (total, itemLine) => add(total, itemLine.taxAmount),
        fromNumber(0, payableCurrency),
      );

      return [
        {
          label: t('payables.panel.totalGross'),
          value: formatMonetaryValue(grossAmount),
          type: 'amount',
          rawValue: grossAmount,
        },
        ...(hasTaxAccountsCapability
          ? [
              {
                label: t('payables.panel.totalNet'),
                value: formatMonetaryValue(netAmount),
                type: 'amount',
                rawValue: netAmount,
              },
              {
                label: t('payables.panel.totalVat'),
                value: formatMonetaryValue(taxAmount),
                type: 'amount',
                rawValue: taxAmount,
              },
            ]
          : []),
        ...(mustDisplayAccountCode
          ? [
              {
                label: t('payables.panel.accountPayable.label'),
                value: payable.accountPayable ? (
                  <div className="flex flex-row items-center">
                    {accountPayableAutomation}
                    <span className="overflow-hidden text-ellipsis whitespace-nowrap">
                      {getCodeWithAuxiliaryAccounts(payable.accountPayable)}
                    </span>
                  </div>
                ) : (
                  '-'
                ),
                type: 'accountPayable',
              },
            ]
          : []),
        ...buildSplittableFieldPanelItems({
          payable,
          showVATAccount: hasTaxAccountsCapability,
          hasCostCentersFeature,
          customFieldIdForExpenseCategory:
            customFieldIdForExpenseCategory || '',
          customFields:
            customFieldsQueryState.status === 'success'
              ? customFieldsQueryState.data
              : [],
          costCenters:
            costCentersQueryState.status === 'success'
              ? costCentersQueryState.data
              : [],
          t,
        }),
        ...(amortisationCapability
          ? [
              {
                label: t('payables.panel.amortisePayable'),
                value: (
                  <SwitchField
                    label=""
                    isDisabled
                    isChecked={!!payable.amortisation}
                    onChange={() => {
                      // Do nothing
                    }}
                  />
                ),
                type: 'amortisationSwitch',
              },
            ]
          : []),
        ...(amortisationCapability && payable.amortisation
          ? [
              {
                label: t('payables.panel.amortisationPeriod'),
                value: `${formatDate(payable.amortisation.date.from)} > ${formatDate(payable.amortisation.date.to)}`,
                type: 'amortisationDate',
              },
            ]
          : []),
        ...(isAmortisationSchemesSupported(amortisationCapability)
          ? [
              {
                label: t('payables.panel.amortisationTemplate'),
                value: payable.amortisation?.schemeId
                  ? `${payable.amortisation.schemeName || t('payables.panel.unknownAmortisationTemplate')}`
                  : '-',
                type: 'amortisationScheme',
                deleted: payable.amortisation?.schemeId
                  ? !amortisationSchemes?.find(
                      ({ id }) => id === payable.amortisation?.schemeId,
                    )
                  : false,
              },
            ]
          : []),
      ];
    },
    [
      payable,
      hasTaxAccountsCapability,
      costCentersQueryState,
      customFieldsQueryState,
      expenseCategoriesQueryState,
      hasCostCentersFeature,
      customFieldIdForExpenseCategory,
    ],
  ) as {
    label: string;
    value: string;
    type?: string;
    expenseCategoryValueId?: string;
    deleted?: boolean;
  }[];

  let editionProps:
    | { isEditable: false }
    | ({ isEditable: true } & Pick<
        PanelEditableSectionProps,
        | 'editSection'
        | 'cancelTranslation'
        | 'saveTranslation'
        | 'onSave'
        | 'onCancel'
      >)
    | object = {};
  if (
    costCentersQueryState.status === 'success' &&
    expenseCategoriesQueryState.status === 'success'
  ) {
    const isPayableExported = getIsPayableExported(payable);
    const isPayablePrepared = getIsPayablePrepared(payable);

    if (!hasNoSplitValues(items)) {
      const state = (() => {
        if (isPayableExported) {
          return 'exported';
        }
        if (isPayablePrepared) {
          return 'prepared';
        }
        return 'notPrepared';
      })();

      editionProps = {
        isEditable: true,
        editSection: (
          <Callout
            title={t(getSplitValueCalloutTitle(state))}
            variant={getSplitValueCalloutVariant(state)}
          />
        ),
        cancelTranslation: t('misc.cancel'),
        saveTranslation: t(getSplitValueCalloutButtonLabel(state)),
        onCancel: () => {},
        onSave: async () => {
          if (state === 'notPrepared') {
            preparePayableRouter.goToPreparePageWithPayable(payable.id);
          } else if (state === 'prepared') {
            try {
              await undoMarkPayableAsPrepared({
                payableId: payable.id,
                payableVersion: payable.version,
              });
              preparePayableRouter.goToPreparePageWithPayable(payable.id);
            } catch {
              dangerNotif(
                t(
                  'payables.panel.accountingDetailsSection.sendBackToPrepareFailure',
                ),
              );
            }
          }
        },
      };
    } else if (hasNoSplitValues(items) && isEditable) {
      editionProps = {
        editSection: (
          <>
            <EditAfterExportEditCallout payable={payable} />

            <PayableAccountingEditSection
              payable={payable}
              {...formik}
              items={[
                ...items,
                ...(amortisationCapability && !payable.amortisation
                  ? [
                      {
                        label: t('payables.panel.amortisationPeriod'),
                        type: 'amortisationDate',
                        value: '', // ignored
                      },
                    ]
                  : []),
              ]}
              costCenters={costCentersQueryState.data}
              amortisationSchemes={amortisationSchemes}
            />
          </>
        ),
        isEditable: true,
        cancelTranslation: t('misc.cancel'),
        saveTranslation: t('misc.saveChanges'),
        onSave: handleOnSectionSaveEdit,
        onCancel: handleOnSectionCancelEdit,
      };
    }
    // TODO@financeAccountant@CORE-5008 - Remove once the "EditAfterExportFeature" feature is rolled out
    else if (
      !isPayableExported &&
      !isPayablePrepared &&
      hasNoSplitValues(items)
    ) {
      editionProps = {
        editSection: (
          <>
            <EditAfterExportEditCallout payable={payable} />

            <PayableAccountingEditSection
              payable={payable}
              {...formik}
              items={[
                ...items,
                ...(amortisationCapability && !payable.amortisation
                  ? [
                      {
                        label: t('payables.panel.amortisationPeriod'),
                        type: 'amortisationDate',
                        value: '', // ignored
                      },
                    ]
                  : []),
              ]}
              costCenters={costCentersQueryState.data}
              amortisationSchemes={amortisationSchemes}
            />
          </>
        ),
        isEditable: true,
        cancelTranslation: t('misc.cancel'),
        saveTranslation: t('misc.saveChanges'),
        onSave: handleOnSectionSaveEdit,
        onCancel: handleOnSectionCancelEdit,
      };
    } else {
      editionProps = { isEditable: false };
    }
  }

  return (
    <PanelItemsSection
      title={t('payables.panel.accounting')}
      items={items}
      {...editionProps}
    />
  );
};

function getSplitValueCalloutTitle(
  state: 'prepared' | 'notPrepared' | 'exported',
): I18nKey {
  switch (state) {
    case 'notPrepared':
      return 'payables.panel.accountingDetailsSection.callouts.splitValuesNotPrepared';
    case 'prepared':
      return 'payables.panel.accountingDetailsSection.callouts.splitValuesPrepared';
    case 'exported':
      return 'payables.panel.accountingDetailsSection.callouts.splitValuesExported';
    default:
      throw new TypeGuardError(
        state,
        'Unable to determine split value callout title',
      );
  }
}

function getSplitValueCalloutButtonLabel(
  state: 'prepared' | 'notPrepared' | 'exported',
): I18nKey {
  switch (state) {
    case 'notPrepared':
      return 'payables.panel.accountingDetailsSection.buttons.splitValuesNotPrepared';
    case 'prepared':
      return 'payables.panel.accountingDetailsSection.buttons.splitValuesPrepared';
    case 'exported':
      return 'payables.panel.accountingDetailsSection.buttons.splitValuesExported';
    default:
      throw new TypeGuardError(
        state,
        'Unable to determine split value callout label',
      );
  }
}

function getSplitValueCalloutVariant(
  state: 'prepared' | 'notPrepared' | 'exported',
): CalloutVariant {
  switch (state) {
    case 'notPrepared':
    case 'prepared':
      return 'info';
    case 'exported':
      return 'neutral';
    default:
      throw new TypeGuardError(
        state,
        'Unable to determine split value callout variant',
      );
  }
}
