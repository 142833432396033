import {
  Button,
  DropdownMenu,
  IconButton,
  Popover,
  Select,
} from '@dev-spendesk/grapes';
import { useReducer, useState } from 'react';
import shortid from 'shortid';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

export const FilterBuilder = ({
  fields,
  savedFilters,
  applyFilter,
  renderSubfilterValue,
}: {
  fields: { key: string; label: string }[];
  savedFilters: SavedFilter[];
  applyFilter(filter: Filter): void;
  renderSubfilterValue(params: {
    subfilterValue: SubfilterValueWithId;
    updateSubfilterValue(newSubfilterValue: SubfilterValueWithId): void;
  }): React.ReactNode;
}) => {
  const { t } = useTranslation('global');

  const filterOperators = [
    { key: 'and' as const, label: t('common.filterBuilder.and') },
    { key: 'or' as const, label: t('common.filterBuilder.or') },
  ];

  const subfilterValueOperators = [
    { key: 'and' as const, label: t('common.filterBuilder.and') },
    { key: 'or' as const, label: t('common.filterBuilder.or') },
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedFilter, setSelectedFilter] = useState<Filter | undefined>(
    undefined,
  );

  const [{ filter }, dispatch] = useFilterBuilder(selectedFilter);

  const isFilterReady = checkIsFilterReady(filter);

  const subfilterGroups = filter.subfilters;

  const getCommonTriggerProps = (isOpen: boolean) => ({
    text: t('common.filterBuilder.title'),
    iconName: 'filters' as const,
    variant: 'ghost' as const,
    className: `font-normal ${isOpen ? 'bg-neutral-lightest text-neutral-darker' : 'text-neutral-dark'}`,
  });

  return (
    <Popover
      renderTrigger={({ isDropdown, ...triggerProps }, isPopoverOpen) => {
        return !savedFilters.length ? (
          <Button {...getCommonTriggerProps(isPopoverOpen)} {...triggerProps} />
        ) : (
          <DropdownMenu
            renderButton={(getTriggerProps, isOpen) => (
              <Button
                {...getCommonTriggerProps(isOpen || isPopoverOpen)}
                {...getTriggerProps()}
              />
            )}
            options={[
              {
                key: 'new-filter',
                label: t('common.filterBuilder.newFilter'),
              },
              {
                key: 'saved-filters',
                label: t('common.filterBuilder.savedFilters'),
                options: savedFilters.map(({ name }) => ({
                  key: name,
                  label: name,
                })),
              },
            ]}
            onSelect={({ key }) => {
              if (key === 'new-filter') {
                triggerProps.onClick();
                return;
              }

              const newFilter = savedFilters.find(
                ({ name }) => name === key,
              )?.filter;

              if (newFilter) {
                applyFilter(newFilter);
              }
            }}
          />
        );
      }}
    >
      {(closePopover) => {
        return (
          <div className="flex flex-col gap-s p-s">
            {/* Filter top toolbar */}
            <header className="flex flex-row items-center gap-xs">
              <h3 className="text-complementary title-l">
                {t('common.filterBuilder.title')}
              </h3>

              {isFilterReady && (
                <Button
                  text={t('common.filterBuilder.save')}
                  variant="ghost"
                  iconName="download"
                  className="ml-auto"
                />
              )}
            </header>

            {/* SubfilterGroups */}
            {subfilterGroups.map((subfilterGroup, subfilterGroupIndex) => {
              const subfilterGroupId = subfilterGroup.id;

              return (
                <div
                  key={subfilterGroupId}
                  className="flex w-[800px] flex-row gap-xs"
                >
                  {subfilterGroups.length > 1 && subfilterGroupIndex === 0 && (
                    <div className="mt-[22px] w-[70px] shrink-0 pl-xs text-complementary body-m">
                      {t('common.filterBuilder.where')}
                    </div>
                  )}

                  {subfilterGroups.length > 1 && subfilterGroupIndex === 1 && (
                    <div>
                      <Select
                        onSelect={({ key }) => {
                          dispatch({
                            type: 'updateFilter',
                            filterOperator: key,
                          });
                        }}
                        options={filterOperators}
                        value={{
                          key: filter.operator,
                          label: filter.operator,
                        }}
                        className="mt-s w-[70px]"
                      />
                    </div>
                  )}

                  {subfilterGroups.length > 1 && subfilterGroupIndex > 1 && (
                    <div className="mt-[22px] w-[70px] shrink-0 pl-xs text-complementary body-m">
                      {filter.operator}
                    </div>
                  )}

                  <div className="flex w-full flex-col gap-xs bg-page-background p-s pb-xs">
                    {/* SubfilterValues */}
                    {subfilterGroup.subfilters.map(
                      (subfilterValue, subfilterValueIndex) => (
                        <div
                          key={subfilterValue.id}
                          className="flex flex-row items-center gap-xs"
                        >
                          {subfilterValueIndex === 0 && (
                            <div className="w-[70px] shrink-0 pl-xs text-complementary body-m">
                              {t('common.filterBuilder.where')}
                            </div>
                          )}

                          {subfilterGroup.subfilters.length > 1 &&
                            subfilterValueIndex === 1 && (
                              <Select
                                onSelect={({ key }) => {
                                  dispatch({
                                    type: 'updateSubfilterValue',
                                    subfilterGroupId,
                                    subfilterOperator: key,
                                    subfilterValue: subfilterValue,
                                  });
                                }}
                                options={subfilterValueOperators}
                                value={{
                                  key: subfilterGroup.operator,
                                  label: subfilterGroup.operator,
                                }}
                                className="w-[70px]"
                              />
                            )}

                          {subfilterGroup.subfilters.length > 1 &&
                            subfilterValueIndex > 1 && (
                              <div className="w-[70px] shrink-0 pl-xs text-complementary body-m">
                                {subfilterGroup.operator}
                              </div>
                            )}

                          <Select
                            onSelect={({ key }) =>
                              dispatch({
                                type: 'updateSubfilterValue',
                                subfilterGroupId,
                                subfilterOperator: subfilterGroup.operator,
                                subfilterValue: {
                                  ...subfilterValue,
                                  field: key,
                                },
                              })
                            }
                            options={fields}
                            value={fields.find(
                              (field) => field.key === subfilterValue.field,
                            )}
                            className="w-[160px]"
                          />

                          {renderSubfilterValue({
                            subfilterValue: subfilterValue,
                            updateSubfilterValue: (newSubfilterValue) => {
                              dispatch({
                                type: 'updateSubfilterValue',
                                subfilterGroupId,
                                subfilterOperator: subfilterGroup.operator,
                                subfilterValue: newSubfilterValue,
                              });
                            },
                          })}

                          {!(
                            subfilterGroups.length === 1 &&
                            subfilterGroup.subfilters.length === 1 &&
                            (!subfilterValue.field || !subfilterValue.operator)
                          ) && (
                            <IconButton
                              iconName="trash"
                              aria-label={t(
                                'common.filterBuilder.removeFilter',
                              )}
                              className="flex-shrink-0"
                              onClick={() =>
                                dispatch({
                                  type: 'removeSubfilterValue',
                                  subfilterGroupId,
                                  subfilterValueId: subfilterValue.id,
                                })
                              }
                            />
                          )}
                        </div>
                      ),
                    )}

                    {/* SubfilterValues toolbar */}
                    <div className="mt-xs flex flex-row items-center gap-xs">
                      <Button
                        text={t('common.filterBuilder.addFilter')}
                        variant="ghost"
                        iconName="plus"
                        onClick={() =>
                          dispatch({
                            type: 'addSubfilterValue',
                            subfilterGroupId,
                          })
                        }
                      />
                      {subfilterGroup.subfilters.length > 1 && (
                        <Button
                          text={t('common.filterBuilder.clearAll')}
                          variant="ghost"
                          className="ml-auto"
                          onClick={() =>
                            dispatch({
                              type: 'removeSubfilterGroup',
                              subfilterGroupId,
                            })
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
              );
            })}

            {/* Filter groups bottom toolbar */}
            <footer className="flex flex-row items-center gap-xs">
              <Button
                text={t('common.filterBuilder.addFilterGroup')}
                variant="ghost"
                iconName="plus"
                onClick={() => dispatch({ type: 'addSubfilterGroup' })}
              />

              {isFilterReady && (
                <>
                  <Button
                    text={t('common.filterBuilder.reset')}
                    variant="secondary"
                    className="ml-auto"
                    onClick={() => dispatch({ type: 'reset' })}
                  />
                  <Button
                    text={t('common.filterBuilder.apply')}
                    variant="primary"
                    onClick={() => {
                      applyFilter(filter);
                      closePopover();
                    }}
                  />
                </>
              )}
            </footer>
          </div>
        );
      }}
    </Popover>
  );
};

/**
 * State handler hook
 */

type SavedFilter = {
  name: string;
  filter: Filter;
};

type Filter = { operator: 'and' | 'or'; subfilters: SubfilterGroup[] };

type SubfilterGroup = { operator: 'and' | 'or'; subfilters: SubfilterValue[] };

type SubfilterValue = {
  field: string | undefined;
  operator: string | undefined;
  value: [string, ...string[]] | 'all' | null | undefined;
};

export function getNonEmptyArraySubfilterValue(
  subfilterValue: string[] | 'all' | null | undefined,
): [string, ...string[]] | undefined {
  if (!Array.isArray(subfilterValue)) {
    return undefined;
  }
  return subfilterValue.length
    ? (subfilterValue as [string, ...string[]])
    : undefined;
}

type FilterWithId = {
  operator: 'and' | 'or';
  subfilters: SubfilterGroupWithId[];
};

type SubfilterGroupWithId = Omit<SubfilterGroup, 'subfilters'> & {
  id: string;
  subfilters: SubfilterValueWithId[];
};

type SubfilterValueWithId = SubfilterValue & {
  id: string;
};

type FilterBuilderState = {
  filter: FilterWithId;
};

type FilterBuilderAction =
  | { type: 'reset' }
  | {
      type: 'updateFilter';
      filterOperator: 'and' | 'or';
    }
  | { type: 'addSubfilterGroup' }
  | {
      type: 'removeSubfilterGroup';
      subfilterGroupId: string;
    }
  | {
      type: 'addSubfilterValue';
      subfilterGroupId: string;
    }
  | {
      type: 'updateSubfilterValue';
      subfilterGroupId: string;
      subfilterOperator: 'and' | 'or';
      subfilterValue: SubfilterValueWithId;
    }
  | {
      type: 'removeSubfilterValue';
      subfilterGroupId: string;
      subfilterValueId: string;
    };

const emptySubfilterValue = (): SubfilterValueWithId => ({
  id: shortid.generate(),
  field: undefined,
  operator: undefined,
  value: undefined,
});

const emptySubfilter = (): SubfilterGroupWithId => ({
  id: shortid.generate(),
  operator: 'and',
  subfilters: [emptySubfilterValue()],
});

const emptyFilter = (): FilterWithId => ({
  operator: 'and',
  subfilters: [emptySubfilter()],
});

const useFilterBuilder = (initialFilter: Filter = emptyFilter()) => {
  const initialState: FilterBuilderState = {
    filter: {
      ...initialFilter,
      subfilters: initialFilter.subfilters.map((subfilterGroup) => ({
        ...subfilterGroup,
        id: shortid.generate(),
        subfilters: subfilterGroup.subfilters.map((subfilterValue) => ({
          ...subfilterValue,
          id: shortid.generate(),
        })),
      })),
    },
  };

  return useReducer(
    (
      state: FilterBuilderState,
      action: FilterBuilderAction,
    ): FilterBuilderState => {
      switch (action.type) {
        case 'reset': {
          return {
            ...state,
            filter: emptyFilter(),
          };
        }
        case 'addSubfilterGroup': {
          return {
            ...state,
            filter: {
              ...state.filter,
              subfilters: state.filter.subfilters.concat(emptySubfilter()),
            },
          };
        }
        case 'updateFilter': {
          return {
            ...state,
            filter: {
              ...state.filter,
              operator: action.filterOperator,
            },
          };
        }
        case 'removeSubfilterGroup': {
          return {
            ...state,
            filter:
              state.filter.subfilters.length <= 1
                ? emptyFilter()
                : {
                    ...state.filter,
                    subfilters: state.filter.subfilters.filter(
                      (subfilterGroup) =>
                        subfilterGroup.id !== action.subfilterGroupId,
                    ),
                  },
          };
        }
        case 'addSubfilterValue': {
          return {
            ...state,
            filter: {
              ...state.filter,
              subfilters: state.filter.subfilters.map((subfilterGroup) => {
                if (subfilterGroup.id !== action.subfilterGroupId) {
                  return subfilterGroup;
                }

                return {
                  ...subfilterGroup,
                  subfilters: subfilterGroup.subfilters.concat(
                    emptySubfilterValue(),
                  ),
                };
              }),
            },
          };
        }
        case 'updateSubfilterValue': {
          return {
            ...state,
            filter: {
              ...state.filter,
              subfilters: state.filter.subfilters.map((subfilterGroup) => {
                if (subfilterGroup.id !== action.subfilterGroupId) {
                  return subfilterGroup;
                }

                return {
                  ...subfilterGroup,
                  operator: action.subfilterOperator,
                  subfilters: subfilterGroup.subfilters.map((subfilterValue) =>
                    subfilterValue.id === action.subfilterValue.id
                      ? action.subfilterValue
                      : subfilterValue,
                  ),
                };
              }),
            },
          };
        }
        case 'removeSubfilterValue': {
          return {
            ...state,
            filter: {
              ...state.filter,
              subfilters: state.filter.subfilters.flatMap((subfilterGroup) => {
                if (subfilterGroup.id !== action.subfilterGroupId) {
                  return subfilterGroup;
                }

                if (
                  state.filter.subfilters.length > 1 &&
                  subfilterGroup.subfilters.length <= 1
                ) {
                  return [];
                }

                return {
                  ...subfilterGroup,
                  subfilters:
                    subfilterGroup.subfilters.length <= 1
                      ? [emptySubfilterValue()]
                      : subfilterGroup.subfilters.filter(
                          (subfilterValue) =>
                            subfilterValue.id !== action.subfilterValueId,
                        ),
                };
              }),
            },
          };
        }
        default:
          throw new Error(`Unknown action type`);
      }
    },
    initialState,
  );
};

/**
 * Helpers
 */

const checkIsFilterReady = (filter: FilterWithId): boolean => {
  return filter.subfilters.some((subfilterGroup) =>
    subfilterGroup.subfilters.some(
      (subfilter) =>
        subfilter.field && subfilter.operator && subfilter.value !== undefined,
    ),
  );
};
