import { gql } from 'graphql-tag';

import { useQuery } from 'src/core/api/hooks/useQuery';
import { useQueryStates } from 'src/core/api/hooks/useQueryStates';
import { type QueryState } from 'src/core/api/queryState';

import { allPaymentsQuery } from '../../../payments/graphql/allPayments/query';

type PaymentLateReceiptsResponse = {
  company: {
    payments: {
      edges: unknown[];
    };
  };
};

type ReceiptsStats = {
  late: number;
  missing: number;
};

export const useOrganisationEntityReceiptsQuery = (
  companyId: string,
  options: {
    isEnabled: boolean;
  } = {
    isEnabled: true,
  },
): QueryState<ReceiptsStats> => {
  return useQueryStates({
    states: {
      lateReceipts: useQuery<PaymentLateReceiptsResponse>({
        key: ['organisation-reporting', companyId, 'payment-late-receipts'],
        request: {
          type: 'graphQL',
          target: 'v1',
          query: gql`
            ${allPaymentsQuery}
          `,
          variables: { companyId, filtersV1: ['LateReceipt'] },
        },
        isEnabled: options.isEnabled,
      }),
      missingReceipts: useQuery<{ total: number }>({
        key: ['organisation-reporting', companyId, 'payment-missing-receipts'],
        request: {
          type: 'graphQL',
          target: 'v1',
          query: gql`
            query PaymentsWithoutReceipt($companyId: String!) {
              company(id: $companyId) {
                payments(
                  filters_v2: [{ type: "invoice", value: ["missing"] }]
                ) {
                  total
                }
              }
            }
          `,
        },
        isEnabled: options.isEnabled,
      }),
    },
    reshapeData: ({ lateReceipts, missingReceipts }) => ({
      late: lateReceipts.company.payments.edges.length,
      missing: missingReceipts.total,
    }),
  });
};
