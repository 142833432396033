import { Button, Modal } from '@dev-spendesk/grapes';
import { type FormikErrors, useFormik } from 'formik';
import { useState } from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { CostCenterDeletionDetails } from './CostCenterDeletionDetails';
import {
  type CostCenterReplacePreview,
  isCostCenterReplacementRequired,
} from './costCenterReplacePreview';
import type { FormValues } from './formValues';
import { type CostCenter } from '../../costCenter';

type Props = {
  costCenter: {
    name: string;
    hasBudgets: boolean;
    replacePreview: CostCenterReplacePreview;
  };
  isOpen: boolean;
  substituteCostCenters: CostCenter[];
  onComplete(costCenterId?: string): void;
  onCancel(): void;
};

type Content = 'budgetAttachedWarning' | 'deletionDetails';

export const CostCenterDeletionModal = ({
  costCenter,
  substituteCostCenters,
  isOpen,
  onCancel,
  onComplete,
}: Props) => {
  const { t } = useTranslation('global');
  const [content, setContent] = useState<Content>(
    costCenter.hasBudgets ? 'budgetAttachedWarning' : 'deletionDetails',
  );

  const isReplacementRequired =
    substituteCostCenters.length > 0 &&
    isCostCenterReplacementRequired(costCenter.replacePreview);

  const formikProps = useFormik<FormValues>({
    initialValues: {
      costCenterId: undefined,
    },
    validate: (values) => {
      const errors: FormikErrors<FormValues> = {};
      if (!values.costCenterId) {
        errors.costCenterId = t('costCenters.deletion.noCostCenterSelected');
      }
      return errors;
    },
    onSubmit: (values) => {
      onComplete(values.costCenterId);
    },
  });

  const handleSubmit = () => {
    if (isReplacementRequired) {
      formikProps.submitForm();
      return;
    }
    onComplete();
  };

  return (
    <Modal
      isOpen={isOpen}
      iconName="failure"
      iconVariant="alert"
      title={t('costCenters.deletion.modalTitle')}
      subtitle={
        content === 'deletionDetails'
          ? t('costCenters.deletion.modalSubTitleSubmition', {
              costCenterName: costCenter.name,
            })
          : undefined
      }
      actions={getModalActions({
        content,
        onCancel,
        onSubmit: handleSubmit,
        onNextStep: () => setContent('deletionDetails'),
        translator: t,
      })}
    >
      {content === 'budgetAttachedWarning' && (
        <div>
          {t('costCenters.deletion.modalSubTitle', {
            costCenterName: costCenter.name,
          })}
        </div>
      )}
      {content === 'deletionDetails' &&
        hasCostCenterAttachedEntities(costCenter) && (
          <CostCenterDeletionDetails
            {...formikProps}
            costCenter={costCenter}
            isReplacementRequired={isReplacementRequired}
            substituteCostCenters={substituteCostCenters}
          />
        )}
    </Modal>
  );
};

const getModalActions = ({
  content,
  onCancel,
  onNextStep,
  onSubmit,
  translator,
}: {
  content: Content;
  onNextStep(): void;
  onSubmit(): void;
  onCancel(): void;
  translator(key: string): string;
}) => {
  if (content === 'budgetAttachedWarning') {
    return [
      <Button
        key="1"
        variant="secondary"
        text={translator('costCenters.deletion.cancelButton')}
        onClick={onCancel}
      />,
      <Button
        key="2"
        variant="alert"
        iconName="caret-right"
        iconPosition="right"
        text={translator('costCenters.deletion.continueButton')}
        onClick={onNextStep}
      />,
    ];
  }

  return [
    <Button
      key="1"
      variant="secondary"
      text={translator('costCenters.deletion.cancelButton')}
      onClick={onCancel}
    />,
    <Button
      key="2"
      variant="alert"
      text={translator('costCenters.deletion.delete')}
      onClick={onSubmit}
    />,
  ];
};

const hasCostCenterAttachedEntities = (
  costCenter: Props['costCenter'],
): boolean => {
  const attachedEntities = Object.values(costCenter.replacePreview).reduce(
    (sum, counter) => sum + counter,
    0,
  );
  return attachedEntities !== 0;
};
