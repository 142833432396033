import { Button, Modal } from '@dev-spendesk/grapes';
import { useHistory } from 'react-router-dom';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routes, routeFor } from 'src/core/constants/routes';

type WireTransfersNotEnoughFundsModalProps = {
  count: number;
  isOpen: boolean;
  onContinue: () => void;
};

export const WireTransfersNotEnoughFundsModal = ({
  count,
  isOpen,
  onContinue,
}: WireTransfersNotEnoughFundsModalProps) => {
  const { t } = useTranslation('global');
  const companyId = useCompanyId();
  const history = useHistory();

  const goToWallet = () => {
    history.push(
      routeFor(routes.COMPANY_BANK.path, {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        company: companyId!,
      }),
    );
  };

  return (
    <Modal
      actions={[
        <Button
          key="continue"
          onClick={onContinue}
          text={t('submitMyInvoice.scheduled.wireTransfer.continueAnyway')}
          variant="ghost"
        />,
        <Button
          key="goToWallet"
          text={t('submitMyInvoice.scheduled.wireTransfer.goToWallet')}
          variant="primary"
          onClick={goToWallet}
        />,
      ]}
      iconName="wallet"
      iconVariant="warning"
      title={t(
        'submitMyInvoice.scheduled.wireTransfer.notEnoughFundsTitleSimple',
      )}
      isOpen={isOpen}
    >
      <p>
        {t(
          'submitMyInvoice.scheduled.wireTransfer.notEnoughFundsWarningSimple',
          { count },
        )}
      </p>
    </Modal>
  );
};
