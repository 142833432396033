import { useExpensesCountsQuery } from '@finance-review/apis/expense';
import { useFetchInvoicesCounts } from 'modules/invoices/hooks';
import { useQueryStates } from 'src/core/api/hooks/useQueryStates';
import { type QueryState } from 'src/core/api/queryState';
import { useIsExpenseMigratedToTransferScheduling } from 'src/core/modules/company/general-settings/hooks/useIsExpenseMigratedToTransferScheduling';
import { useNewExpensesCountsQuery } from 'src/core/modules/finance-review/apis/expense/useNewExpensesCountsQuery';

import { useActionsCountsQuery } from './useActionsCountsQuery';

type ActionsCounts = {
  requestsToReview: number;
  invoicesToPay: number;
  invoiceTransfersToConfirm?: number;
  expenseTransfersToConfirm?: number;
  paymentsWithoutReceipts: number;
  paymentsToPrepare: number;
};

export const useAoActionsCountsQuery = (
  shouldFetch: boolean,
): QueryState<ActionsCounts> => {
  const actionsCountsQueryState = useActionsCountsQuery(shouldFetch);
  const invoicesCountsQueryState = useFetchInvoicesCounts({
    isEnabled: shouldFetch,
  });
  const expensesCountsQueryState = useExpensesCountsQuery({
    isEnabled: shouldFetch,
  });
  const newExpensesCountsQueryState = useNewExpensesCountsQuery({
    isEnabled: shouldFetch,
  });

  return useQueryStates({
    states: {
      actionsCounts: actionsCountsQueryState,
      invoicesCounts: invoicesCountsQueryState,
      expensesCounts: useIsExpenseMigratedToTransferScheduling()
        ? newExpensesCountsQueryState
        : expensesCountsQueryState,
    },
    reshapeData: ({ actionsCounts, expensesCounts, invoicesCounts }) => ({
      ...actionsCounts,
      expenseTransfersToConfirm: expensesCounts.paymentsToConfirm,
      invoiceTransfersToConfirm: invoicesCounts.transfersToConfirm,
    }),
  });
};
