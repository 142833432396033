import type { MonetaryValue } from 'ezmoney';

export const earliestAllowedPayableDate = new Date('2010');
export const latestAllowedPayableDate = new Date('2060');

export type PayableId = string;

type CounterPartyUpdate = {
  id: string;
  type: 'employee' | 'supplier';
  name?: string;
  thumbnailUrl?: string;
};

type CustomFieldUpdate = {
  customFieldId: string;
  customFieldListValueId?: string;
  customFieldBooleanValue?: boolean;
};

type ItemLineUpdate = {
  taxAccountId: string | null;
  expenseAccountId: string | null;
  netAmount: MonetaryValue;
  grossAmount: MonetaryValue;
  vatAmount: MonetaryValue;
  vatAdjustment: MonetaryValue;
  vatRate: {
    amount: number;
    precision: number;
  };
  natureId: string;
};

export type PayableUpdate = {
  counterparty?: CounterPartyUpdate;
  description?: string;
  teamId?: string | null;
  costCenterId?: string | null;
  itemLines?: ItemLineUpdate[];
  customFieldAssociations?: CustomFieldUpdate[];
  accountPayableId?: string | null;
  invoiceNumber?: string;
  accountingDate?: string;
  creationDate?: string;
  amortisationDate?: PayableAmortisation['date'] | null;
  amortisationSchemeId?: string | null;
};

export type PayableAmortisation = {
  date: { from: Date; to: Date };
  schemeId?: string;
  schemeName?: string;
};

export type Member =
  | {
      type: 'user';
      givenName: string;
      familyName: string;
      email: string;
      avatar: string | undefined;
    }
  | {
      type: 'suspendedUser';
      givenName: string;
      familyName: string;
      avatar: string | undefined;
    }
  | { type: 'invitee'; email: string };

export type Supplier = {
  name: string;
  thumbnailUrl: string | undefined;
};

type MileageDetails = {
  distance: string;
};

export type PerDiem = {
  duration: number;
};

export type AnalyticalFieldAssociation = {
  fieldKind: 'costCenter' | 'customField';
  fieldEntityId: string;
  fieldEntityValueId: string;
};

type PayableSubType =
  | 'plasticCard'
  | 'subscriptionCard'
  | 'singleUseCard'
  | 'creditNote';

/**
 * Automation
 */
export type MarvinConfidence = 'low' | 'medium' | 'high' | 'sure';

export type ExpenseAccountAutomation =
  | {
      kind: 'default';
      isAppliedOnPayable: boolean;
    }
  | {
      kind: 'supplierRule';
      supplierRule: {
        supplier: {
          id: string;
          name: string;
        };
        expenseAccount: {
          id: string;
          name: string;
        };
      };
      isAppliedOnPayable: boolean;
    }
  | {
      kind: 'prediction';
      expenseAccount: {
        id: string;
        name: string;
      };
      confidence: number;
      isAppliedOnPayable: boolean;
    }
  | {
      kind: 'expenseCategoryRule';
      isAppliedOnPayable: boolean;
    };

export type ExpenseAmountAutomation = {
  kind: 'recommendation';
  confidence?: MarvinConfidence;
  isAppliedOnPayable: boolean;
};

export type TaxAccountAutomation =
  | {
      kind: 'default';
      isAppliedOnPayable: boolean;
    }
  | {
      kind: 'recommendation';
      confidence?: MarvinConfidence;
      isAppliedOnPayable: boolean;
    }
  | {
      kind: 'prediction';
      taxAccount: {
        id: string;
        name: string;
      };
      confidence?: MarvinConfidence;
      isAppliedOnPayable: boolean;
    };

export type AnalyticalFieldAssociationAutomation =
  AnalyticalFieldAssociation & {
    kind: string;
    isAppliedOnPayable: boolean;
  };

export type DocumentaryEvidenceNumberAutomation = {
  kind: 'recommendation';
  isAppliedOnPayable: boolean;
  documentaryEvidenceNumber?: string;
};

export type DocumentaryEvidenceDateAutomation = {
  kind: 'recommendation';
  isAppliedOnPayable: boolean;
  documentaryEvidenceDate?: Date;
};

export type PayableAutomation = {
  expenseAmount?: ExpenseAmountAutomation;
  expenseAccount?: ExpenseAccountAutomation;
  accountPayable?: {
    accountPayableMatch: {
      id: string;
      generalAccountCode: string;
      isDefault: boolean;
      isArchived: boolean;
    };
    isAppliedOnPayable: boolean | null;
    isActive?: boolean;
  };
  tax?: TaxAccountAutomation;
  analyticalFieldAssociations?: AnalyticalFieldAssociationAutomation[];
  documentaryEvidenceNumber?: DocumentaryEvidenceNumberAutomation;
  documentaryEvidenceDate?: DocumentaryEvidenceDateAutomation;
};

/**
 * Payable
 */

export type Payable = {
  id: string;
  version: number;
  kind: 'expense' | 'reversal';
  subtype: PayableSubType | undefined;
  description: string;
  counterparty: Member | (Supplier & { type: 'supplier' }) | undefined;
  member: Member | undefined;
  supplier: Supplier | undefined;
  documentaryEvidence:
    | { type: 'invoice'; invoiceNumber: string | undefined }
    | { type: 'creditNote'; creditNoteNumber: string | undefined }
    | { type: 'declarationOfMissingInvoice' }
    | undefined;
  grossAmount: MonetaryValue;
  functionalAmount: MonetaryValue;
  mileageDetails: MileageDetails | undefined;
  perDiem: PerDiem | undefined;
  creationDate: Date;
};
