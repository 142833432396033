import { useMutation } from 'src/core/api/hooks/useMutation';

type Payload = {
  csv: string;
};

type SuccessResult = {
  outcome: 'success';
  supplier: { id: string };
};

type ErrorResult = {
  outcome: 'error';
  message: string;
  step: string;
};

type BulkImportSuppliersSuccessResponse =
  | {
      outcome: 'success';
      results: SuccessResult[];
    }
  | {
      outcome: 'partialSuccess';
      results: (SuccessResult | ErrorResult)[];
    };

export type BulkImportSuppliersErrorResponse = {
  error:
    | {
        outcome: 'tooManySuppliers';
        maxNumSuppliers: number;
      }
    | {
        outcome: 'validationError' | 'parseError';
        lineErrors: { row: number; type: string }[];
      }
    | {
        outcome: 'failure';
        results: ErrorResult[];
      };
};

export const useBulkImportSuppliers = () => {
  return useMutation<
    Payload,
    BulkImportSuppliersSuccessResponse,
    BulkImportSuppliersSuccessResponse,
    BulkImportSuppliersErrorResponse
  >({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/invoice_suppliers/import`,
      method: 'post',
    },
    reshapeData: (data) => data,
  });
};
