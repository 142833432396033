import type { TGlobalFunctionTyped } from 'common/hooks/useTranslation';
import { type AccountingSoftware } from 'modules/bookkeep/integration/status';

export const getAccountingIntegrationName = (
  accountingIntegration:
    | AccountingSoftware
    | 'noIntegration'
    | 'switchInProgress',
  translator: TGlobalFunctionTyped,
): string => {
  switch (accountingIntegration) {
    case 'SpendeskAccounting':
      return translator(
        'bookkeep.integrations.settings.baseSwitch.doubleEntry',
      );
    case 'SpendeskAccountingSingleEntry':
      return translator(
        'bookkeep.integrations.settings.baseSwitch.singleEntry',
      );
    case 'Sage':
      return translator('bookkeep.integrations.settings.baseSwitch.sage');
    case 'Cegid':
      return translator('bookkeep.integrations.settings.baseSwitch.cegid');
    case 'Datev':
      return translator('bookkeep.integrations.settings.baseSwitch.datev');
    case 'Xero':
      return translator('bookkeep.integrations.settings.baseSwitch.xero');
    case 'Quickbooks':
      return translator('bookkeep.integrations.settings.baseSwitch.quickbooks');
    case 'Netsuite':
      return translator('bookkeep.integrations.settings.baseSwitch.netsuite');
    case 'Sage100':
      return translator('bookkeep.integrations.settings.baseSwitch.sage100');
    case 'ACD':
      return translator('bookkeep.integrations.settings.baseSwitch.acd');
    case 'Odoo':
      return translator('bookkeep.integrations.settings.baseSwitch.odoo');
    case 'ExactOnlineFr':
      return translator(
        'bookkeep.integrations.settings.baseSwitch.exactOnlineFr',
      );
    case 'noIntegration':
      return translator(
        'bookkeep.integrations.settings.baseSwitch.noIntegration',
      );
    default:
      throw new Error(
        `Unhandled i18n key for accounting integration ${accountingIntegration}`,
      );
  }
};

export const getAccountingIntegrationFullName = (
  accountingIntegration: AccountingSoftware,
  translator: TGlobalFunctionTyped,
): string => {
  switch (accountingIntegration) {
    case 'SpendeskAccounting':
      return translator('integrations.fullNames.doubleEntry');
    case 'SpendeskAccountingSingleEntry':
      return translator('integrations.fullNames.singleEntry');
    case 'Sage':
      return translator('integrations.fullNames.sage');
    case 'Cegid':
      return translator('integrations.fullNames.cegid');
    case 'Datev':
      return translator('integrations.fullNames.datev');
    case 'Xero':
      return translator('integrations.fullNames.xero');
    case 'Quickbooks':
      return translator('integrations.fullNames.quickbooks');
    case 'Netsuite':
      return translator('integrations.fullNames.netsuite');
    default:
      throw new Error(
        `Unhandled i18n key for accounting integration ${accountingIntegration}`,
      );
  }
};
