import { ProtectedRoute } from 'src/core/common/components/ProtectedRoute';
import { useFeature } from 'src/core/common/hooks/useFeature';
import FEATURES from 'src/core/constants/features';
import { routeFor, routes } from 'src/core/constants/routes';
import { useCompany } from 'src/core/modules/app/hooks/useCompany';
import { useUser } from 'src/core/modules/app/hooks/useUser';
import { PayablesAllPage } from 'src/core/modules/bookkeep/payables';
import PaymentsAllContainer from 'src/core/modules/payments/PaymentsAllContainer';

import { ExpenseHeader } from './ExpensesHeader';
import { useAccessPaymentsAll } from './useAccessPaymentsAll';

export const ExpensesPage = () => {
  const company = useCompany();
  const user = useUser();
  const hasAccessToPaymentsAll = useAccessPaymentsAll();
  const hasPayableFeature = useFeature(FEATURES.BOOKKEEP_PAYABLES);

  const isAoOrController = user.is_account_owner || user.is_controller;
  return (
    <div className="page__container">
      <ExpenseHeader />
      <ProtectedRoute
        path={routes.PAYMENTS_ALL.path}
        isAccessAllowed={hasAccessToPaymentsAll}
      >
        <PaymentsAllContainer />
      </ProtectedRoute>
      <ProtectedRoute
        path={routes.PAYABLES_ALL.path}
        isAccessAllowed={
          hasPayableFeature && isAoOrController && !company.churning_at
        }
        redirectTo={{
          pathname: routeFor(routes.PAYMENTS_ALL.path, {
            company: company.id,
          }),
        }}
      >
        <PayablesAllPage />
      </ProtectedRoute>
    </div>
  );
};
