import { TextArea } from '@dev-spendesk/grapes';
import { useFormik } from 'formik';

import { PanelTextSection } from 'common/components/Panel';
import { useTranslation } from 'common/hooks/useTranslation';
import { type PayableUpdate } from 'modules/payable';
import {
  useUpdatePayable,
  useUpdatePayableErrorMessage,
} from 'modules/payable/hooks';
import { useNotifications } from 'src/core/modules/app/notifications';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

import { EditAfterExportEditCallout } from './EditAfterExportEditCallout';
import { getEditedFields } from './helpers';
import { type Payable } from '../../containers/PayablePanelContainer';
import { useUpdatePayableQueryCache } from '../../containers/PayablePanelContainer/hooks';

type Props = {
  payable: Payable;
  isEditable: boolean;
};

export const PayableDescriptionSection = ({ payable, isEditable }: Props) => {
  const { t } = useTranslation('global');
  const [updatePayable] = useUpdatePayable(payable.id);
  const updatePayableQueryCache = useUpdatePayableQueryCache();
  const getUpdatePayableErrorMessage = useUpdatePayableErrorMessage();
  const { successNotif, dangerNotif } = useNotifications();

  const { values, isValid, isSubmitting, setFieldValue, submitForm } =
    useFormik<{
      description: string;
    }>({
      initialValues: { description: payable.description },
      onSubmit: async ({ description }) => {
        try {
          const payableUpdate: PayableUpdate = {
            description,
          };
          const { newPayableVersion } = await updatePayable({
            payableVersion: payable.version,
            update: payableUpdate,
          });

          track(AnalyticEventName.BOOKKEEP_PAYABLE_ALL_PAYABLE_SAVED, {
            payableId: payable.id,
            editedFields: getEditedFields({ payable, update: payableUpdate }),
            state: payable.state,
          });

          updatePayableQueryCache(payable.id, {
            version: newPayableVersion,
            description,
          });

          successNotif(
            t('payables.panel.descriptionSection.descriptionUpdatedSuccess'),
          );
        } catch (error) {
          const errorMessage = getUpdatePayableErrorMessage(error);
          dangerNotif(errorMessage);
          throw error;
        }
      },
      validate: ({ description }) =>
        // This should probably come from somewhere in common with Prepare
        description.length === 0
          ? {
              description: 'empty',
            }
          : {},
      enableReinitialize: true,
    });

  return (
    <PanelTextSection
      title={t('payables.panel.description')}
      {...(isEditable
        ? {
            isEditable: true,
            editSection: (
              <>
                <EditAfterExportEditCallout payable={payable} />
                <TextArea
                  value={values.description}
                  isInvalid={!isValid}
                  onChange={(event) => {
                    setFieldValue('description', event.target.value, true);
                  }}
                />
              </>
            ),
            cancelTranslation: t('misc.cancel'),
            saveTranslation: t('misc.saveChanges'),
            onSave: async () => {
              await submitForm();
            },
            onCancel: () => {},
            disableSave: !isValid || isSubmitting,
          }
        : { isEditable: false })}
    >
      {payable.description}
    </PanelTextSection>
  );
};
