import { DATE_FORMAT } from '@dev-spendesk/grapes';

import { useHasAccountingIntegrationCapability } from 'modules/accounting-integration/apis';
import {
  checkIsAutomatedValue as checkIsInvoiceNumberAutomatedValue,
  computeInvoiceNumberLabel,
} from 'modules/payable/components/PayableInvoiceNumberField';
import { useIsEditablePayable } from 'modules/payable/hooks/api/useIsEditablePayable';
import { useIsLockedPayable } from 'modules/payable/hooks/api/useIsLockedPayable';
import { AutomationIcon } from 'src/core/common/components/AutomationIcon';
import { PanelItemsSection } from 'src/core/common/components/Panel';
import { useFeature } from 'src/core/common/hooks/useFeature';
import {
  type TGlobalFunctionTyped,
  useTranslation,
} from 'src/core/common/hooks/useTranslation';
import FEATURES from 'src/core/constants/features';

import {
  PayableDetailsEditSection,
  reshapePayableForField,
} from './PayableDetailsEditSection/PayableDetailsEditSection';
import { usePayableDetailsEditForm } from './hooks/usePayableDetailsEditForm';
import { EditAfterExportEditCallout } from '../../components/PayablePanel/EditAfterExportEditCallout';
import {
  formatMemberName,
  formatSpendingAllocationPeriod,
} from '../../models/member';
import { isSpanishDPRPayable } from '../../models/payable';
import type { Payable } from '../PayablePanelContainer';

type Props = {
  payable: Payable;
};

export const PayableDetailsSectionContainer = ({ payable }: Props) => {
  const { t, localeFormat } = useTranslation('global');
  const hasTeamsFeature = useFeature(FEATURES.TEAMS);
  const hasAllocationPeriodFeature = useFeature(FEATURES.ALLOCATION_PERIOD);
  const hasAccountingDateEnabled = useFeature(FEATURES.TMP_ACCOUNTING_DATE);
  const hasSpanishDPR = isSpanishDPRPayable(payable);
  const hasInvoiceNumberFeature =
    useHasAccountingIntegrationCapability('invoiceNumber');
  const isLocked = useIsLockedPayable(payable);
  const isEditable = useIsEditablePayable(payable);

  const { formik, handleOnSectionCancelEdit, handleOnSectionSaveEdit } =
    usePayableDetailsEditForm({
      payable,
      context: {
        hasInvoiceNumberFeature,
        hasSpanishDPR,
        hasAccountingDateEnabled,
      },
    });

  const formatDate = (date: Date) => localeFormat(date, DATE_FORMAT.SHORT);

  const items = [
    {
      label: t('payables.panel.requester'),
      value: payable.member ? formatMemberName(payable.member, t) : '-',
      type: 'requester',
    },
    ...(payable.type === 'claimedBill' &&
    !hasSpanishDPR &&
    payable.supplier?.name
      ? [
          {
            label: t('misc.supplier'),
            value: payable.supplier?.name,
            type: 'supplier',
          },
        ]
      : []),
    ...getRenderItemsWhenInvoiceNumberForAllPayables(payable, t),
    ...(payable.creationDate
      ? [
          {
            label: computeInvoiceDateLabel(payable, t),
            value: formatDate(payable.creationDate),
            type: 'creationDate',
          },
        ]
      : []),
    ...(hasAccountingDateEnabled
      ? [
          {
            label: t('payables.panel.accountingDate'),
            value: payable.accountingDate
              ? formatDate(payable.accountingDate)
              : '-',
            type: 'accountingDate',
          },
        ]
      : []),

    ...(hasTeamsFeature
      ? [
          {
            label: t('payables.panel.team'),
            value: payable.team?.name ?? '-',
            type: 'team',
          },
        ]
      : []),
    ...(hasAllocationPeriodFeature &&
    payable.type === 'invoicePurchase' &&
    payable.subtype !== 'creditNote'
      ? [
          {
            label: t('payables.panel.allocationPeriod'),
            value: formatSpendingAllocationPeriod(
              payable.spendingAllocationPeriod,
              formatDate,
            ),
            type: 'allocationPeriod',
          },
        ]
      : []),
  ];

  const isEditableProps =
    isEditable && !isLocked
      ? {
          isEditable: true as const,
          cancelTranslation: t('misc.cancel'),
          saveTranslation: t('misc.saveChanges'),
          editSection: (
            <>
              <EditAfterExportEditCallout payable={payable} />
              <PayableDetailsEditSection
                payable={payable}
                {...formik}
                items={[...items]}
                context={{
                  hasAccountingDateEnabled,
                  hasSpanishDPR,
                  hasInvoiceNumberFeature,
                }}
              />
            </>
          ),
          onCancel: handleOnSectionCancelEdit,
          onSave: handleOnSectionSaveEdit,
        }
      : { isEditable: false as const };

  return (
    <PanelItemsSection
      title={t('payables.panel.detailsSection.title')}
      items={items}
      {...isEditableProps}
    />
  );
};

function getRenderItemsWhenInvoiceNumberForAllPayables(
  payable: Payable,
  t: TGlobalFunctionTyped,
) {
  let value;
  if (payable.documentaryEvidence?.type === 'invoice') {
    value = payable.documentaryEvidence?.invoiceNumber;
  } else if (
    payable.documentaryEvidence?.type === 'creditNote' &&
    payable.documentaryEvidence?.creditNoteNumber
  ) {
    value = payable.documentaryEvidence?.creditNoteNumber;
  } else {
    return [];
  }

  const isAutomatedValue = checkIsInvoiceNumberAutomatedValue(
    payable.automation?.documentaryEvidenceNumber,
    value,
  );

  const automation = isAutomatedValue ? (
    <AutomationIcon
      message={t(
        'payables.panel.documentaryEvidenceNumberPrefilledByRecommendation',
      )}
      className="mr-xxs w-s shrink-0"
    />
  ) : null;

  value = (
    <div className="flex flex-row items-center">
      {automation}
      <span className="overflow-hidden text-ellipsis whitespace-nowrap">
        {value || '-'}
      </span>
    </div>
  ) as unknown as string;

  const label = computeInvoiceNumberLabel(reshapePayableForField(payable), t);

  return [
    {
      label,
      value,
      type: 'invoiceNumber',
    },
  ];
}

function computeInvoiceDateLabel(payable: Payable, t: TGlobalFunctionTyped) {
  if (payable.documentaryEvidence?.type === 'creditNote') {
    return t('payables.panel.creditNoteDate');
  }

  switch (payable.type) {
    case 'claimedBill':
    case 'cardPurchase':
    case 'reversal':
      return t('payables.panel.receiptDate');
    default:
      return t('payables.panel.invoiceDate');
  }
}
