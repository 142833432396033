import { CheckboxField } from '@dev-spendesk/grapes';
import { type SetStateAction } from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

type SyncReportingManagersProps = {
  syncReportingManagersSelected: boolean;
  setSyncReportingManagersSelected: (value: SetStateAction<boolean>) => void;
};

export const SyncReportingManagers = ({
  syncReportingManagersSelected,
  setSyncReportingManagersSelected,
}: SyncReportingManagersProps) => {
  const { t } = useTranslation('global');

  return (
    <CheckboxField
      className="ps-[67px]"
      fit="parent"
      label={t('integration.kombo.settings.filters.managerCheckbox')}
      isChecked={syncReportingManagersSelected}
      onChange={(event) => {
        setSyncReportingManagersSelected(event.target.checked);
      }}
    />
  );
};
