import { SwitchField } from '@dev-spendesk/grapes';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useNotifications } from 'modules/app/notifications';
import { apiUrl } from 'src/core/utils/api';

type Props = {
  cfId?: string;
  groups: { id: string; name: string }[];
  isAllScopes?: boolean;
};

export const TeamsSelection = ({
  cfId,
  groups,
  isAllScopes = false,
}: Props) => {
  const { t } = useTranslation('global');
  const { successNotif, dangerNotif } = useNotifications();

  const companyId = useCompanyId();

  const [entities, setEntities] = useState<
    { entity_id: string; scopeId: string }[]
  >([]);
  const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
  const [allDisabled, setAllDisabled] = useState(isAllScopes);

  useEffect(() => {
    fetchGroupsScopes();
  }, []);

  const isGroupChecked = (groupId: string) => {
    return allDisabled || selectedGroups.includes(groupId);
  };

  const toggleAllGroups = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const isNewAllScopes = !!event.target.checked;

    const jsonBody = JSON.stringify({
      customFieldId: cfId,
      isAllScopes: isNewAllScopes ? 'true' : 'false',
    });

    try {
      const response = await fetch(
        apiUrl('/custom-fields/toggle-all-scopes', companyId),
        {
          credentials: 'include',
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: jsonBody,
        },
      );
      const payload = await response.json();
      if (!isEmpty(payload)) {
        successNotif(t('customFields.successTeamUpdated'));
      } else {
        dangerNotif(t('errors:somethingWrong_short'));
      }
    } finally {
      setAllDisabled(isNewAllScopes);
    }
  };

  const toggleGroup = (groupId: string) => {
    const filteredGroups = isGroupChecked(groupId)
      ? selectedGroups.filter((g) => g !== groupId)
      : selectedGroups.concat(groupId);

    if (isGroupChecked(groupId)) {
      removeGroupFromScope(groupId, filteredGroups);
    } else {
      addGroupToScope(groupId, filteredGroups);
    }
  };

  const fetchGroupsScopes = async () => {
    const searchParams = new URLSearchParams(
      cfId ? { customFieldId: cfId } : undefined,
    );

    const response = await fetch(
      apiUrl(`/custom-fields/scopes?${searchParams.toString()}`, companyId),
      {
        credentials: 'include',
      },
    );
    const payload = await response.json();
    if (!isEmpty(payload)) {
      // @ts-expect-error payload isn't typed
      const cfScopes = head(payload)?.scopes;
      // @ts-expect-error payload isn't typed
      const cfScopesIds = cfScopes.map((s) => s.entity_id);
      // @ts-expect-error payload isn't typed
      const cfScopesMatching = cfScopes.map((s) => ({
        scopeId: s.id,
        entity_id: s.entity_id,
      }));

      if (cfScopes.length) {
        setSelectedGroups(cfScopesIds);
        setEntities(cfScopesMatching);
      }
    }
  };

  const addGroupToScope = async (groupId: string, allGroups: string[]) => {
    const jsonBody = JSON.stringify({
      entityId: groupId,
      customFieldId: cfId,
    });

    const response = await fetch(apiUrl('/custom-fields/scopes', companyId), {
      method: 'POST',
      credentials: 'include',
      body: jsonBody,
      headers: { 'Content-Type': 'application/json' },
    });
    const payload = await response.json();
    if (!isEmpty(payload)) {
      successNotif(t('customFields.successTeamAdded'));
      const newEntities = entities.concat([
        { scopeId: payload.scope_id, entity_id: groupId },
      ]);
      setSelectedGroups(allGroups);
      setEntities(newEntities);
    } else {
      dangerNotif(t('errors:somethingWrong_short'));
    }
  };

  const removeGroupFromScope = async (groupId: string, allGroups: string[]) => {
    const entity = entities.find((e) => e.entity_id === groupId);

    if (entity && entity.scopeId) {
      const jsonBody = JSON.stringify({
        customFieldId: cfId,
        scopeId: entity.scopeId,
      });

      try {
        const response = await fetch(
          apiUrl('/custom-fields/scopes', companyId),
          {
            method: 'DELETE',
            credentials: 'include',
            body: jsonBody,
            headers: { 'Content-Type': 'application/json' },
          },
        );
        const payload = await response.json();
        if (payload && payload === 1) {
          successNotif(t('customFields.successTeamRemoved'));
        } else {
          dangerNotif(t('errors:somethingWrong_short'));
        }
      } finally {
        setSelectedGroups(allGroups);
      }
    }
  };

  const getGroupsList = () => {
    return groups?.map((group) => {
      return (
        <div
          key={group.id}
          className="w-full border-0 border-t border-solid border-t-neutral-light px-s py-xs"
        >
          <SwitchField
            fit="parent"
            label={group.name}
            isChecked={isGroupChecked(group.id)}
            onChange={() => toggleGroup(group.id)}
          />
        </div>
      );
    });
  };

  return (
    <div className="box p-0">
      <div className="w-full p-s">
        <SwitchField
          fit="parent"
          label={t('customFields.teamLabel')}
          isChecked={allDisabled}
          onChange={toggleAllGroups}
        />
      </div>
      <AnimateHeight height={allDisabled ? 0 : 'auto'} duration={140}>
        {getGroupsList()}
      </AnimateHeight>
    </div>
  );
};
