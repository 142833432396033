import { PaymentContext } from 'modules/invoices/hooks';
import {
  track,
  AnalyticEventName,
  type AnalyticEventPayloadDefinition,
} from 'src/core/utils/analytics';

import { type Transfer } from '../../models';

type Params = {
  transfers: Transfer[];
};

export const useConfirmTransfersTrackingEvents = () => {
  const { type } = PaymentContext.useContext();

  const getTrackingPayload = ({ transfers }: Params) => ({
    ids: transfers.map((transfer) => transfer.id),
    requestIds: transfers.map((transfer) => transfer.bill.requestId),
    numberOfPayments: transfers.length,
  });

  const onConfirmTransfersConfirmationModalOpened = (params: Params): void => {
    if (type === 'invoice') {
      track(
        AnalyticEventName.INVOICES_CONFIRM_CONFIRMATION_MODAL_OPENED,
        getTrackingPayload(
          params,
        ) as AnalyticEventPayloadDefinition[AnalyticEventName.INVOICES_CONFIRM_CONFIRMATION_MODAL_OPENED],
      );
    }
  };

  const onConfirmTransfersConfirmationModalClosed = (params: Params): void => {
    if (type === 'invoice') {
      track(
        AnalyticEventName.INVOICES_CONFIRM_CONFIRMATION_MODAL_CLOSED,
        getTrackingPayload(
          params,
        ) as AnalyticEventPayloadDefinition[AnalyticEventName.INVOICES_CONFIRM_CONFIRMATION_MODAL_CLOSED],
      );
    }
  };

  const onConfirmTransfersConfirmationModalConfirmed = (
    params: Params,
  ): void => {
    if (type === 'invoice') {
      track(
        AnalyticEventName.INVOICES_CONFIRM_CONFIRMATION_MODAL_CONFIRMED,
        getTrackingPayload(
          params,
        ) as AnalyticEventPayloadDefinition[AnalyticEventName.INVOICES_CONFIRM_CONFIRMATION_MODAL_CONFIRMED],
      );
      track(AnalyticEventName.FINANCE_CONFIRM_TRANSFER_CONFIRMED, {
        category: 'invoice',
        type: 'multiple',
      });
    }
  };

  const onConfirmTransfersRejectionModalOpened = (params: Params): void => {
    if (type === 'invoice') {
      track(
        AnalyticEventName.INVOICES_CONFIRM_REJECTION_MODAL_OPENED,
        getTrackingPayload(
          params,
        ) as AnalyticEventPayloadDefinition[AnalyticEventName.INVOICES_CONFIRM_REJECTION_MODAL_OPENED],
      );
    }
  };

  const onConfirmTransfersRejectionModalClosed = (params: Params): void => {
    if (type === 'invoice') {
      track(
        AnalyticEventName.INVOICES_CONFIRM_REJECTION_MODAL_CLOSED,
        getTrackingPayload(
          params,
        ) as AnalyticEventPayloadDefinition[AnalyticEventName.INVOICES_CONFIRM_REJECTION_MODAL_CLOSED],
      );
    }
  };

  const onConfirmTransfersRejectionModalConfirmed = (params: Params): void => {
    if (type === 'invoice') {
      track(
        AnalyticEventName.INVOICES_CONFIRM_REJECTION_MODAL_CONFIRMED,
        getTrackingPayload(
          params,
        ) as AnalyticEventPayloadDefinition[AnalyticEventName.INVOICES_CONFIRM_REJECTION_MODAL_CONFIRMED],
      );
      track(AnalyticEventName.FINANCE_CONFIRM_TRANSFER_CANCELED, {
        category: 'invoice',
        type: 'multiple',
      });
    }
  };

  const onSendReminderClicked = (): void => {
    if (type === 'invoice') {
      track(AnalyticEventName.INVOICES_CONFIRM_SEND_REMINDER_CLICKED);
    }
  };

  const onAddFundsClicked = (): void => {
    if (type === 'invoice') {
      track(AnalyticEventName.INVOICES_CONFIRM_ADD_FUNDS_CLICKED);
    }
  };

  return {
    onConfirmTransfersConfirmationModalOpened,
    onConfirmTransfersConfirmationModalClosed,
    onConfirmTransfersConfirmationModalConfirmed,
    onConfirmTransfersRejectionModalOpened,
    onConfirmTransfersRejectionModalClosed,
    onConfirmTransfersRejectionModalConfirmed,
    onSendReminderClicked,
    onAddFundsClicked,
  };
};
