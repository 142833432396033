import { Button } from '@dev-spendesk/grapes';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { type EmployeeAccount } from '../../../../../../accounting/employeeAccount';
import '../EmployeeAccountsSection.module.css';

interface Props {
  isLoading: boolean;
  employeeAccounts: EmployeeAccount[];
  fetchNextPage: () => Promise<void>;
}

export const TableFooter = ({
  isLoading,
  employeeAccounts,
  fetchNextPage,
}: Props) => {
  const { t } = useTranslation('global');

  if (isLoading && employeeAccounts.length < 1) {
    return <>{t('misc.loading')}</>;
  }

  if (employeeAccounts.length === 0) {
    return (
      <div>
        <h3 className="title-m">
          {t('bookkeep.integrations.settings.noEmployeeAccountTitle')}
        </h3>
      </div>
    );
  }

  return (
    <Button
      onClick={() => fetchNextPage()}
      text={t('misc.loadMore')}
      variant="secondary"
    />
  );
};

export function hasFooter(
  isLoading: boolean,
  employeeAccounts: EmployeeAccount[],
  hasNextPage: boolean,
) {
  return (
    employeeAccounts.length === 0 ||
    (isLoading && employeeAccounts.length < 1) ||
    hasNextPage
  );
}
