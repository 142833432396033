import { Tag } from '@dev-spendesk/grapes';

import { ApprovalSchemeCreation } from './ApprovalSchemeCreation';
import { ApprovalSchemeEdition } from './ApprovalSchemeEdition';

type Props = {
  onClose: () => void;
  approvalSchemeId: string | undefined;
};

export const StandardApprovalFlowPanelContent = ({
  onClose,
  approvalSchemeId,
}: Props) => {
  let content = null;
  if (approvalSchemeId === undefined) {
    content = <ApprovalSchemeCreation onClose={onClose} />;
  } else {
    content = (
      <ApprovalSchemeEdition
        approvalSchemeId={approvalSchemeId}
        onClose={onClose}
      />
    );
  }

  return (
    <>
      <div>
        <Tag variant="primary">Standard approval workflow</Tag>
        <div className="mb-m mt-s text-neutral-darker body-s">
          This condition will be executed if no other workflow applies.
          <br />
          This ensures that any request has a reviewer.
        </div>
      </div>
      <div className="mb-xs text-complementary title-m">
        Approval required from
      </div>
      {content}
    </>
  );
};
