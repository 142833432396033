import { Modal } from '@dev-spendesk/grapes';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { AccountPayableSuppliersCreateSupplierFormContainer } from './AccountPayableSuppliersCreateSupplierForm';
import { useGetSupplierAccountsQuery } from '../../hooks/useGetSupplierAccountsQuery';

type Props = {
  isSupplierCreationModalOpen: boolean;
  setIsSupplierCreationModalOpen: (isModalOpen: boolean) => void;
};

export const AccountPayableSuppliersCreateSupplier = ({
  isSupplierCreationModalOpen,
  setIsSupplierCreationModalOpen,
}: Props) => {
  const { t } = useTranslation('global');

  const getSupplierAccountsQuery = useGetSupplierAccountsQuery({
    includeArchived: false,
  });

  return (
    <div className="AccountPayableSuppliersCreateSupplier">
      <Modal
        isOpen={isSupplierCreationModalOpen}
        iconName="plus"
        iconVariant="info"
        title={t('bookkeep.accountsPayable.createSupplier.modalTitle')}
        actions={[]}
      >
        {getSupplierAccountsQuery.status === 'success' && (
          <AccountPayableSuppliersCreateSupplierFormContainer
            onClose={() => setIsSupplierCreationModalOpen(false)}
            supplierAccounts={getSupplierAccountsQuery.data}
          />
        )}
      </Modal>
    </div>
  );
};
