import { Button } from '@dev-spendesk/grapes';
import React from 'react';

import { useGetOrganisationEntities } from 'modules/company/billing/components/CompanyPlan/hooks/useGetOrganisationEntities';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';
import { useIsMultiEntityHubPage } from 'src/core/modules/app/layout/components/VerticalNavigation/helpers';
import { useHasAccessToMultiEntityHub } from 'src/core/modules/organisation-reporting/hooks/useHasAccessToMultiEntityHub';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

import { CompanyDropdownWithBreakdown } from './CompanyDropdownWithBreakdown';
import { CompanyDropdownWithSearch } from './CompanyDropdownWithSearch';
import { type CompanyWalletSummary } from '../../../companyWalletSummary';

import './CompanyDropdownContent.css';

type Props = {
  currentCompany: CompanyWalletSummary;
  otherCompanies: CompanyWalletSummary[];
  onCompanyClick(company: CompanyWalletSummary): void;
  onNewCompanyClick?(): void;
  closeDropdown(): void;
};

export const CompanyDropdownContent = ({
  currentCompany,
  otherCompanies,
  onCompanyClick,
  onNewCompanyClick,
  closeDropdown,
}: Props) => {
  const { t } = useTranslation('global');
  const hasAccessToMultiEntityHub = useHasAccessToMultiEntityHub();
  const isMultiEntityHubPage = useIsMultiEntityHubPage();
  const { isAllowedToOpenEntity } = useGetOrganisationEntities();

  const openNewEntityButton = isAllowedToOpenEntity
    ? onNewCompanyClick && {
        label: t('wallet.walletDetails.addAccount'),
        onClick: () => onNewCompanyClick(),
      }
    : undefined;

  if (!hasAccessToMultiEntityHub) {
    return (
      <CompanyDropdownWithBreakdown
        currentCompany={currentCompany}
        otherCompanies={otherCompanies}
        onCompanyClick={onCompanyClick}
        openNewEntityButton={openNewEntityButton}
        closeDropdown={closeDropdown}
      />
    );
  }

  const multiEntityHubLink = !isMultiEntityHubPage ? (
    <Button
      fit="parent"
      component="a"
      href={routeFor(routes.ORGANISATION_REPORTING.path, {
        company: currentCompany.id,
      })}
      onClick={() => {
        closeDropdown();
        track(
          AnalyticEventName.ORGANISATION_REPORTING_COMPANY_DROPDOWN_LINK_CLIKED,
        );
      }}
      variant="primary"
      iconName="dashboard"
      iconPosition="left"
      text={t('organisation.reporting.links.button')}
    />
  ) : null;

  const companies = isMultiEntityHubPage
    ? otherCompanies.concat([currentCompany])
    : otherCompanies;

  return (
    <CompanyDropdownWithSearch
      companies={companies}
      multiEntityHubLink={multiEntityHubLink}
      onCompanyClick={onCompanyClick}
      openNewEntityButton={openNewEntityButton}
      closeDropdown={closeDropdown}
    />
  );
};
