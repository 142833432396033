import { useEffect, useState } from 'react';

import { getPaymentPasswordProcedure } from '../api/getPaymentPasswordProcedure';

export const useGetPaymentPasswordProcedure = (
  procedureId: string | undefined,
  callback?: (paymentPassword?: string) => void,
  hasUsedPhoneFactor?: boolean,
) => {
  const [procedureFailedReason, setProcedureFailedReason] = useState<
    'initializationError' | 'unknown' | 'timeout' | 'rejected' | undefined
  >(undefined);
  const [passwordToShow, setPasswordToShow] = useState<string | undefined>(
    undefined,
  );

  useEffect(() => {
    const handlePaymentPasswordGetting = async (interval?: NodeJS.Timeout) => {
      if (procedureId) {
        try {
          const response = await getPaymentPasswordProcedure({
            procedureId,
            hasUsedPhoneFactor,
          });
          if (response.procedureStatus === 'approved') {
            setPasswordToShow(response.paymentPassword);
            clearInterval(interval);
            callback?.(response.paymentPassword);
          }
          if (response.procedureStatus === 'rejected') {
            setProcedureFailedReason('rejected');
            clearInterval(interval);
            callback?.();
          }
          if (response.procedureStatus === 'timeout') {
            setProcedureFailedReason('timeout');
            clearInterval(interval);
            callback?.();
          }
        } catch {
          setProcedureFailedReason('unknown');
          callback?.();
        }
      }
    };

    if (procedureId) {
      // Using polling isn't ideal for the phone factor flow because we have
      // have the onMfaTokenValidated() callback at which point the related
      // procedure should already be approved.
      if (!hasUsedPhoneFactor) {
        const interval = setInterval(
          () => handlePaymentPasswordGetting(interval),
          2500,
        );
        return () => clearInterval(interval);
      }

      handlePaymentPasswordGetting();
    }
  }, [procedureId]);

  return {
    procedureFailedReason,
    setProcedureFailedReason,
    passwordToShow,
  };
};
