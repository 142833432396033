import { type QueryClient } from 'src/core/api/client';
import { useQuery } from 'src/core/api/hooks/useQuery';

import { type InvoicesCounts } from '../models';

type Params = {
  isEnabled?: boolean;
};

type ExpenseCounts = {
  expensesToValidate: number;
  usersToPay: number;
  paymentsToConfirm: number;
};

export const useFetchInvoicesCounts = ({ isEnabled = true }: Params = {}) => {
  return useQuery<InvoicesCounts, InvoicesCounts>({
    key: ['transfer-scheduling', 'counts', { type: 'invoice' }],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/transfer-scheduling/counts?type=invoice`,
    },
    isEnabled,
  });
};

export const useFetchExpenseCounts = ({ isEnabled = true }: Params = {}) => {
  return useQuery<ExpenseCounts, ExpenseCounts>({
    key: ['transfer-scheduling', 'counts', { type: 'expense' }],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/transfer-scheduling/counts?type=expense`,
    },
    isEnabled,
  });
};

export const invalidateCounts = (queryClient: QueryClient): void => {
  queryClient.invalidateQueries(['transfer-scheduling', 'counts']);
};
