import {
  Avatar,
  colors,
  Icon,
  SkeletonAvatar,
  SkeletonText,
  Tag,
} from '@dev-spendesk/grapes';

import { useQueryStates } from 'src/core/api/hooks/useQueryStates';
import { QueryError } from 'src/core/common/components/QueryError';
import { QuerySuspense } from 'src/core/common/components/QuerySuspense';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import {
  type ApprovalRule,
  type ApprovalRight,
  type Approver,
} from 'src/core/modules/company/structure/approval-flows';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

import { useApprovalSchemeQuery } from '../../hooks/useApprovalScheme';
import { type Member, useMembersQuery } from '../../hooks/useMembersQuery';

type Props = {
  approvalSchemeId: string;
};

export const ApprovalSchemeNodePreview = ({ approvalSchemeId }: Props) => {
  const { t } = useTranslation('global');
  const queryStates = useQueryStates({
    states: {
      approvalScheme: useApprovalSchemeQuery(approvalSchemeId),
      members: useMembersQuery(),
    },
  });
  return (
    <QuerySuspense
      queryState={queryStates}
      loading={
        <div className="box mt-s rounded bg-white p-m">
          <div className="flex items-center gap-xs">
            <SkeletonAvatar size="m" />
            <SkeletonText width="100px" />
          </div>
        </div>
      }
      fallback={(error) => (
        <QueryError
          className="mt-s"
          queryError={error}
          componentType="Callout"
        />
      )}
    >
      {({ approvalScheme, members }) => {
        return (
          <div className="box mt-s rounded bg-white p-m">
            <div className="flex items-center gap-xs">
              <div className="flex h-m w-m items-center justify-center rounded-[50%] border border-solid border-neutral-light bg-neutral-lightest">
                <Icon color={colors.neutralDark} name="magic-wand" size="s" />
              </div>
              <div className="text-neutral-darker title-s">
                Approval is required from
              </div>
            </div>
            <div>
              {approvalScheme.rules.map((rule) => {
                return (
                  <div key={rule.id}>
                    <div className="mb-xxs mt-xs text-neutral-darker title-s">
                      {getThresholdTitle(rule)}
                    </div>
                    <div>
                      {getRuleApprovers(rule.steps, members).map((approver) => {
                        return (
                          <div
                            key={
                              approver.approverType === 'user'
                                ? approver.id
                                : approver.approverType
                            }
                          >
                            <Tag
                              isRounded
                              variant="neutral"
                              className="mb-xxs mr-xxs inline-block"
                            >
                              {approver.approverType === 'reportingManager' && (
                                <>
                                  <div className="mr-xs flex h-s w-s shrink-0 items-center justify-center self-center rounded-[50%] bg-primary-lighter">
                                    <Icon
                                      name="person"
                                      size="s"
                                      color={colors.white}
                                    />
                                  </div>
                                  {t('teams.approvalFlows.reportingManager')}
                                </>
                              )}
                              {approver.approverType === 'costCenterOwner' && (
                                <>
                                  <div className="mr-xs flex h-s w-s shrink-0 items-center justify-center self-center rounded-[50%] bg-primary-lighter">
                                    <Icon
                                      name="person"
                                      size="s"
                                      color={colors.white}
                                    />
                                  </div>
                                  {t('teams.approvalFlows.costCenterOwner')}
                                </>
                              )}
                              {approver.approverType === 'user' && (
                                <>
                                  <Avatar
                                    src={approver.avatar ?? undefined}
                                    size="xs"
                                    className="mr-xxs"
                                    text={
                                      approver.isPending
                                        ? approver.email
                                        : approver.fullname
                                    }
                                  />
                                  {approver.isPending
                                    ? approver.email
                                    : approver.fullname}
                                </>
                              )}
                            </Tag>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      }}
    </QuerySuspense>
  );
};
const getThresholdTitle = (rule: ApprovalRule) => {
  const { from, upTo } = rule;
  // Display only 'up to' value
  if (from?.value === 0 && upTo.value) {
    return `Up to ${formatMonetaryValue({
      amount: upTo.value,
      currency: from.currency,
      precision: 0,
    })}`;
  }
  // Display only 'from' value
  if (from?.value && upTo.value === null) {
    return `From ${formatMonetaryValue({
      amount: from.value,
      currency: from.currency,
      precision: 0,
    })}`;
  }
  // Display From .. to .. value
  if (from?.value && upTo?.value) {
    return `From ${formatMonetaryValue({
      amount: from.value,
      currency: from.currency,
      precision: 0,
    })} to ${formatMonetaryValue({
      amount: upTo.value,
      currency: upTo.currency,
      precision: 0,
    })}`;
  }
  return '';
};
const getRuleApprovers = (
  steps: { rights: ApprovalRight[] }[],
  members: Member[],
): Approver[] => {
  return steps.flatMap(
    (step) =>
      step.rights
        .map((right) => {
          const member = members.find((m) => {
            if (
              right.approverType === 'reportingManager' ||
              right.approverType === 'costCenterOwner'
            ) {
              return true;
            }
            if (right.approverType === 'user') {
              return m.id === right.approverId;
            }
            return false;
          });
          return member
            ? { ...member, approverType: right.approverType }
            : null;
        })
        .filter(Boolean) as Approver[],
  );
};
