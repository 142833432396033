import { Button, Modal } from '@dev-spendesk/grapes';
import React, { type Dispatch, type SetStateAction } from 'react';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useNotifications } from 'modules/app/notifications';
import { type AccountingSoftware } from 'modules/bookkeep/integration/status';
import { type IntegrationVatAccount } from 'modules/bookkeep/settings/accounting/taxAccount';
import { useSetTaxAccountMutation } from 'modules/bookkeep/settings/integrations/hooks/useSetTaxAccountMutation';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';

import { renderVatAddEditForm } from './renderAddEditForm';
import { CodeInUseCallout } from '../../../../components/CodeInUseCallout/CodeInUseCallout';
import { getVatAddEditDescription } from '../../translations';
import { type ModalState } from '../ModalState';
import styles from '../TaxAccountLocalOnlySection.module.css';

interface Props {
  formState: ModalState;
  setFormState: Dispatch<SetStateAction<ModalState>>;
  integration: AccountingSoftware;
  vatAccounts: IntegrationVatAccount[];
  hideErrorLink?: boolean;
}

export const VatAddModal = ({
  formState,
  setFormState,
  integration,
  vatAccounts,
  hideErrorLink = false,
}: Props) => {
  const { t } = useTranslation('global');
  const { dangerNotif } = useNotifications();

  const companyId = useCompanyId();

  const [setTaxAccount, setTaxAccountQueryState, clearErrors] =
    useSetTaxAccountMutation();

  const duplicatedError =
    setTaxAccountQueryState.status === 'success' &&
    setTaxAccountQueryState.data.outcome === 'notSet' &&
    (setTaxAccountQueryState.data.reason === 'nameAndRateAlreadyExists' ||
      setTaxAccountQueryState.data.reason === 'codeAlreadyExists');

  const codeRateDuplicatedCurrentAccount =
    duplicatedError &&
    formState.kind === 'addVat' &&
    !formState.inputChanged &&
    vatAccounts.find(
      (account) =>
        account.name === formState.name &&
        Number.parseInt(account.rate) === Number.parseInt(formState.rate),
    );

  return (
    <Modal
      title={t('bookkeep.integrations.settings.vatAccountsTable.addVatAccount')}
      isOpen={formState.kind === 'addVat'}
      iconName="plus"
      iconVariant="primary"
      actions={[
        <Button
          key="cancel"
          onClick={() => {
            setFormState({ kind: 'closed' });
            clearErrors();
          }}
          text={t('misc.cancel')}
          variant="secondary"
        />,
        <Button
          key="confirmSection"
          onClick={async () => {
            if (formState.kind === 'addVat') {
              setFormState({ ...formState, inputChanged: false });

              if (formState.error) {
                return;
              }

              try {
                const result = await setTaxAccount({
                  name: formState.name,
                  code: formState.code,
                  rate: formState.rate,
                  type: 'vat',
                  isArchived: false,
                });

                if (result.outcome === 'set') {
                  setFormState({ kind: 'closed' });
                }
              } catch {
                dangerNotif(t('misc.errors.unknownError'));
              }
            }
          }}
          text={t(
            'bookkeep.integrations.settings.vatAccountsTable.confirmSelection',
          )}
          variant="primary"
          isDisabled={
            formState.kind === 'addVat' &&
            (!formState.name ||
              !formState.rate ||
              !formState.code ||
              (duplicatedError && !formState.inputChanged))
          }
        />,
      ]}
    >
      <>
        <p className={styles.subtitle}>
          {getVatAddEditDescription(t, integration)}
        </p>

        {renderVatAddEditForm(integration, formState, setFormState)}

        {codeRateDuplicatedCurrentAccount && (
          <CodeInUseCallout
            accountInfo={`${codeRateDuplicatedCurrentAccount.name}, ${codeRateDuplicatedCurrentAccount.code} - ${codeRateDuplicatedCurrentAccount.rate}`}
            linkTo={
              !hideErrorLink
                ? routeFor(routes.COMPANY_ACCOUNTING_TAX_ACCOUNTS.path, {
                    company: companyId,
                  })
                : undefined
            }
          />
        )}
      </>
    </Modal>
  );
};
