import {
  Button,
  EmptyState,
  IconButton,
  SkeletonTable,
  SkeletonText,
  Table,
  Tooltip,
} from '@dev-spendesk/grapes';
import { useState } from 'react';

import { ErrorBoundary } from 'common/components/withErrorBoundary';
import { useModal } from 'common/hooks/useModalGrapes';
import { useQueryStates } from 'src/core/api/hooks/useQueryStates';
import { QueryError } from 'src/core/common/components/QueryError';
import { QuerySuspense } from 'src/core/common/components/QuerySuspense';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { useCompanyCurrency } from 'src/core/modules/app/hooks/useCompanyCurrency';
import { useExpenseCategoriesQuery } from 'src/core/modules/company/expense-categories/containers/EcContainer/hooks/useExpenseCategoriesQuery';
import { formatMoney } from 'src/core/utils/money';

import { useSpendLimitsQuery } from '../../hooks/useSpendLimitsQuery';
import { DeleteSpendLimitModal } from '../DeleteSpendLimitModal';
import { SpendLimitModal } from '../SpendLimitModal';

export const SpendLimitsPage = () => {
  const { t } = useTranslation('global');
  const [isAllExpenseCategoriesUsed, setIsAllExpenseCategoriesUsed] =
    useState(false);
  const companyCurrency = useCompanyCurrency();
  const queryStates = useQueryStates({
    states: {
      spendLimits: useSpendLimitsQuery(),
      expenseCategories: useExpenseCategoriesQuery(),
    },
  });

  const [spendLimitModal, showSpendLimitModal] = useModal<{
    mode: 'create' | 'edit';
    spendLimitId?: string;
  }>(({ onClose, isOpen, mode, spendLimitId }) => {
    return (
      <SpendLimitModal
        onClose={onClose}
        isOpen={isOpen}
        mode={mode}
        spendLimitId={spendLimitId}
      />
    );
  });

  const [deleteSpendLimitModal, showDeleteSpendLimitModal] = useModal<{
    spendLimitId: string;
  }>(({ onClose, isOpen, spendLimitId }) => {
    return (
      <DeleteSpendLimitModal
        onClose={onClose}
        isOpen={isOpen}
        spendLimitId={spendLimitId}
      />
    );
  });

  return (
    <ErrorBoundary context={{ team: 'spend-control', scope: 'spend-limits' }}>
      <>
        {spendLimitModal}
        {deleteSpendLimitModal}
        <div className="page__container items-center">
          <div>
            <div className="mb-s flex items-center justify-between gap-s">
              <div>
                <div className="text-complementary title-xl">
                  {t('spendLimits.page.title')}
                </div>
                <div className="text-neutral-dark body-m">
                  {t('spendLimits.page.subTitle')}
                </div>
              </div>
              <Tooltip
                content={t('spendLimits.page.allCategoriesUsedTooltip')}
                isDisabled={!isAllExpenseCategoriesUsed}
              >
                <Button
                  className="shrink-0"
                  text={t('spendLimits.page.createSpendLimitButton')}
                  variant="primary"
                  isDisabled={isAllExpenseCategoriesUsed}
                  onClick={() => showSpendLimitModal({ mode: 'create' })}
                />
              </Tooltip>
            </div>
            <QuerySuspense
              queryState={queryStates}
              loading={<PageSkeleton />}
              fallback={(error) => (
                <QueryError queryError={error} componentType="Callout" />
              )}
            >
              {({ spendLimits, expenseCategories }) => {
                // check that in spendLimits, all expense categories are used
                setIsAllExpenseCategoriesUsed(
                  spendLimits.length === expenseCategories.length,
                );

                if (spendLimits.length === 0) {
                  return (
                    <EmptyState
                      className="mt-xxl"
                      iconName="search"
                      title={t('spendLimits.page.emptyStateTitle')}
                      subtitle={t('spendLimits.page.emptyStateDescription')}
                      actions={[
                        <Button
                          key="createSpendLimitButton"
                          text={t('spendLimits.page.createSpendLimitButton')}
                          variant="secondary"
                          onClick={() =>
                            showSpendLimitModal({ mode: 'create' })
                          }
                        />,
                      ]}
                    />
                  );
                }
                return (
                  <Table
                    columns={[
                      {
                        id: 'categoryName',
                        header: t('spendLimits.page.categoryNameColumnName'),
                        width: '50%',
                        renderCell: (row) => {
                          const category = expenseCategories.find(
                            (c) => c.id === row.customFieldValueId,
                          );
                          return <div>{category?.name}</div>;
                        },
                      },
                      {
                        id: 'limit',
                        header: t('spendLimits.page.limitColumnName'),
                        width: '50%',
                        renderCell: (row) => (
                          <div className="group flex items-center justify-between">
                            <div>
                              {formatMoney(row.amount, companyCurrency)}{' '}
                              {row.period === 'day'
                                ? t('spendLimits.page.daily')
                                : t('spendLimits.page.monthly')}
                            </div>
                            <div className="hidden gap-s group-hover:flex">
                              <IconButton
                                iconName="pen"
                                variant="borderless"
                                onClick={() => {
                                  showSpendLimitModal({
                                    mode: 'edit',
                                    spendLimitId: row.id,
                                  });
                                }}
                                hasNegativeMargins
                                aria-label={t('misc.edit')}
                              />
                              <IconButton
                                iconName="trash"
                                variant="borderless"
                                onClick={() => {
                                  showDeleteSpendLimitModal({
                                    spendLimitId: row.id,
                                  });
                                }}
                                hasNegativeMargins
                                aria-label={t('misc.delete')}
                              />
                            </div>
                          </div>
                        ),
                      },
                    ]}
                    data={spendLimits}
                    rowHeight="compact"
                    hasColumnSeparator
                    getRowId={(row) => row.id}
                  />
                );
              }}
            </QuerySuspense>
          </div>
        </div>
      </>
    </ErrorBoundary>
  );
};

const PageSkeleton = () => (
  <SkeletonTable
    numberOfRows={5}
    withHeader
    columns={[
      {
        cell: <SkeletonText width="50%" />,
        header: <SkeletonText width="30%" />,
        width: '50%',
      },
      {
        cell: <SkeletonText width="50%" />,
        header: <SkeletonText width="30%" />,
        width: '50%',
      },
    ]}
  />
);
