import { Button } from '@dev-spendesk/grapes';
import { useState } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import {
  useIntegrationStatusQuery,
  type ExpenseAccount,
} from 'modules/bookkeep';
import { CreateExpenseAccountModal } from 'modules/bookkeep/components/CreateExpenseAccountModal';
import { DeleteExpenseAccountModal } from 'modules/bookkeep/components/DeleteExpenseAccountModal';
import { UpdateExpenseAccountModal } from 'modules/bookkeep/components/UpdateExpenseAccountModal';
import { useExpenseAccountsQuery } from 'modules/bookkeep/settings/integrations/hooks/useExpenseAccountsQuery';
import { ExpenseAccountTable } from 'modules/bookkeep/settings/integrations/pages/LegacyIntegrationsAccountingPage/sections/ExpenseAccountsSection/ExpenseAccountTable';
import { unwrapQuery } from 'src/core/api/unwrapQuery';

export const ExpenseAccountTableAndModals = () => {
  const { t } = useTranslation('global');

  const expenseAccountQueryState = useExpenseAccountsQuery('');
  const expenseAccounts =
    expenseAccountQueryState.status === 'success'
      ? expenseAccountQueryState.data
      : [];

  const integrationStatusQueryState = useIntegrationStatusQuery();
  const integrationStatus = unwrapQuery(integrationStatusQueryState);

  const [isCreateExpenseAccountModalOpen, setIsCreateExpenseAccountModalOpen] =
    useState<boolean>(false);
  const [editingExpenseAccount, setEditingExpenseAccount] = useState<
    ExpenseAccount | undefined
  >(undefined);
  const [deletingExpenseAccount, setDeletingExpenseAccount] = useState<
    ExpenseAccount | undefined
  >(undefined);

  return (
    <div>
      <ExpenseAccountTable
        expenseAccounts={expenseAccounts}
        fetchNextPage={expenseAccountQueryState.fetchNextPage}
        hasFooter={expenseAccountQueryState.hasNextPage}
        isLoading={
          expenseAccountQueryState.status === 'loading' &&
          !expenseAccountQueryState.isFetchingNextPage
        }
        isFetchingMore={expenseAccountQueryState.isFetchingNextPage}
        onDelete={
          integrationStatus
            ? (row) => setDeletingExpenseAccount(row)
            : undefined
        }
        onEdit={
          integrationStatus ? (row) => setEditingExpenseAccount(row) : undefined
        }
      />
      {integrationStatus && (
        <Button
          className="mt-s self-start"
          variant="ghost"
          iconName="plus"
          iconPosition="left"
          onClick={() => setIsCreateExpenseAccountModalOpen(true)}
          text={t(
            'bookkeep.integrations.settings.expenseAccountsTable.addExpenseAccount',
          )}
        />
      )}
      {integrationStatus && (
        <>
          <CreateExpenseAccountModal
            integrationStatus={integrationStatus}
            isOpen={isCreateExpenseAccountModalOpen}
            onClose={() => setIsCreateExpenseAccountModalOpen(false)}
          />
          <UpdateExpenseAccountModal
            account={editingExpenseAccount}
            integrationStatus={integrationStatus}
            isOpen={!!editingExpenseAccount}
            onClose={() => setEditingExpenseAccount(undefined)}
          />
          <DeleteExpenseAccountModal
            account={deletingExpenseAccount}
            integrationStatus={integrationStatus}
            isOpen={!!deletingExpenseAccount}
            onClose={() => setDeletingExpenseAccount(undefined)}
          />
        </>
      )}
    </div>
  );
};
