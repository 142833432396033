import { colors, DropdownItem, Icon, Input } from '@dev-spendesk/grapes';

import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { FilteredListBySearchWrapper } from 'src/core/modules/organisation-reporting/components/common/FilteredListBySearchWrapper';

import { type CompanyWalletSummary } from '../../../companyWalletSummary';

type Props = {
  companies: CompanyWalletSummary[];
  multiEntityHubLink: React.ReactNode | null;
  onCompanyClick(company: CompanyWalletSummary): void;
  openNewEntityButton: { label: string; onClick: () => void } | undefined;
  closeDropdown(): void;
};

export const CompanyDropdownWithSearch = ({
  companies,
  multiEntityHubLink,
  onCompanyClick,
  openNewEntityButton,
  closeDropdown,
}: Props) => {
  const { t } = useTranslation('global');

  return (
    <div className="flex w-[338px] flex-col content-stretch">
      <div className="m-s mb-xs flex flex-col gap-xs">{multiEntityHubLink}</div>
      <FilteredListBySearchWrapper
        items={companies.sort((a, b) => a.name.localeCompare(b.name))}
        searchableFields={['name']}
        isSearchEnabled
      >
        {({ filteredItems, search, setSearch }) => (
          <div className="mb-xs flex flex-col">
            <Input
              className="mx-s my-xs min-w-[306px]"
              leftAddon={
                <Icon
                  color={colors.neutral}
                  name="search"
                  className="ml-s mr-xs"
                />
              }
              placeholder={t(
                'organisation.reporting.page.entities.searchPlaceholder',
              )}
              onChange={(event) => setSearch(event.target.value)}
              value={search}
            />
            <div className="flex max-h-[60vh] flex-col content-stretch overflow-auto">
              {filteredItems.map((entity) => (
                <button
                  key={entity.id}
                  type="button"
                  className="border-none bg-white outline-none"
                  onClick={() => {
                    onCompanyClick(entity);
                    closeDropdown();
                  }}
                >
                  <DropdownItem
                    label={entity.name}
                    className="p-s text-complementary title-m"
                  />
                </button>
              ))}
            </div>
          </div>
        )}
      </FilteredListBySearchWrapper>
      {openNewEntityButton && (
        <div
          className="flex flex-col content-stretch gap-s border-neutral-light"
          style={{
            borderTopStyle: 'solid',
            borderTopWidth: '1px',
            borderBottomLeftRadius: '4px',
            borderBottomRightRadius: '4px',
          }}
        >
          <button
            type="button"
            className="flex w-full justify-center border-none bg-neutral-lightest outline-none"
            onClick={openNewEntityButton.onClick}
          >
            <DropdownItem
              label={openNewEntityButton.label}
              className="p-s text-complementary title-m"
            />
          </button>
        </div>
      )}
    </div>
  );
};
