import { useTranslation } from 'common/hooks/useTranslation';
import { NotificationType, useNotifications } from 'modules/app/notifications';
import type { CustomFieldAssociation } from 'modules/budgets/models/customFieldAssociation';
import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';

export type Payload = {
  payments: string[];
  description?: string;
  supplier?: string;
  supplier_id?: string;
  supplier_name?: string;
  group?: string;
  customFieldAssociations?: CustomFieldAssociation[];
};

export const useBulkUpdatePaymentsMutation = (): MutationState<Payload> => {
  const { t } = useTranslation('global');
  const { pushNotif } = useNotifications();

  return useMutation<Payload>({
    request: {
      type: 'rest',
      method: 'put',
      target: 'companyAPI',
      endpoint: `/payments`,
    },
    options: {
      onError: () => {
        pushNotif({
          message: t('errors:somethingWrong'),
          type: NotificationType.Danger,
        });
      },
    },
  });
};
