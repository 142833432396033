import { type I18nKey } from 'src/core/common/hooks/useTranslation';

export type RequestType =
  | 'purchase_order'
  | 'single_purchase'
  | 'subscription'
  | 'subscription_increase'
  | 'card_load'
  | 'expense'
  | 'invoice'
  | 'mileage_allowance'
  | 'per_diem_allowance'
  | 'credit_note';

export const recordRequestTypetoI18NKey: Record<RequestType, I18nKey> = {
  purchase_order: 'requests.types.purchase_order',
  single_purchase: 'requests.types.single_purchase',
  subscription: 'requests.types.subscription',
  subscription_increase: 'requests.types.subscription_increase',
  card_load: 'requests.types.card_load',
  expense: 'requests.types.expense',
  invoice: 'requests.types.invoice',
  mileage_allowance: 'requests.types.mileage_allowance',
  per_diem_allowance: 'requests.types.per_diem_allowance',
  credit_note: 'requests.types.credit_note',
};
