import * as R from '@dev-spendesk/general-type-helpers/Result';
import { useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { useState } from 'react';

import { useCreateOrUpdateAccountPayable } from 'modules/accounting-integration/apis';
import { useCompany } from 'modules/app/hooks/useCompany';
import { NotificationType, useNotifications } from 'modules/app/notifications';
import { useCreateSupplierMutation } from 'modules/bookkeep/accounts-payable/hooks/useCreateSupplierMutation';
import { useGetSupplierLowerCaseNamesQuery } from 'modules/bookkeep/accounts-payable/hooks/useGetSupplierLowerCaseNamesQuery';
import { useSetSupplierAccountToSupplierMutation } from 'modules/bookkeep/accounts-payable/hooks/useSetSupplierAccountToSupplierMutation';
import {
  type BankAccountDetailsFile,
  type CreateSupplierFormValues,
  type SupplierAccount,
} from 'modules/bookkeep/accounts-payable/types';
import {
  useIntegrationStatusQuery,
  useMustDisplayAccountsPayable,
} from 'modules/bookkeep/hooks';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { fileToBase64 } from 'src/core/utils/files';

import { AccountPayableSuppliersCreateSupplierForm } from './AccountPayableSuppliersCreateSupplierForm';
import { validateForm } from './validator';
import {
  getCodeWithAuxiliaryAccounts,
  getGeneralAndAuxiliaryAccountCodes,
} from '../../../../../../utils/accountPayable';
import {
  type AccountPayableCreationModalState,
  AccountPayableCreationModal,
} from '../../../../components/AccountPayableCreationModal';
import { getCreateOrUpdateAccountPayableError } from '../../../../components/AccountPayableCreationModal/AccountPayableCreationModalState';
import { useHasAuxiliaryAccountsEnabled } from '../../../../hooks/useHasAuxiliaryAccountsEnabled';
import { useGetEmployeeAccountCodesQuery } from '../../../../settings/integrations/hooks/useGetEmployeeAccountCodesQuery';
import { useGetSupplierAccountsQuery } from '../../../hooks/useGetSupplierAccountsQuery';

type Props = {
  supplierAccounts: SupplierAccount[];
  onClose: () => void;
};

export const AccountPayableSuppliersCreateSupplierFormContainer = ({
  supplierAccounts,
  onClose,
}: Props) => {
  const { t } = useTranslation('global');
  const company = useCompany();
  const { pushNotif } = useNotifications();

  const [bankAccountDetailsFile, setBankAccountDetailsFile] =
    useState<BankAccountDetailsFile>(null);
  const [failedAccount, setFailedAccount] = useState<string | undefined>();

  const getSupplierAccountsQueryState = useGetSupplierAccountsQuery({
    includeArchived: false,
  });
  const getEmployeeAccountCodesQuery = useGetEmployeeAccountCodesQuery();

  const getSupplierLowerCaseNamesQuery = useGetSupplierLowerCaseNamesQuery();
  const supplierNames = new Set(
    getSupplierLowerCaseNamesQuery.status === 'success'
      ? getSupplierLowerCaseNamesQuery.data.map((supplier) => supplier.name)
      : [],
  );

  const [
    accountPayableCreationModalState,
    setAccountPayableCreationModalState,
  ] = useState<AccountPayableCreationModalState>({ kind: 'closed' });
  const [createSupplierMutation] = useCreateSupplierMutation();
  const [createSupplierAccount] =
    useCreateOrUpdateAccountPayable('supplierAccount');
  const [setSupplierAccountToSupplier] =
    useSetSupplierAccountToSupplierMutation();
  const mustDisplayAccountsPayable = useMustDisplayAccountsPayable();
  const auxiliaryAccountsEnabled = useHasAuxiliaryAccountsEnabled();

  const accountingIntegrationQueryResult = useIntegrationStatusQuery();

  const initialValues = {
    name: '',
    legalName: '',
    accountPayableId: '',
    bankCountry: '',
    iban: '',
    bicSwift: '',
    sortCode: '',
    routingNumber: '',
    accountNumber: '',
    accountCode: '',
    accountHolderName: '',
  };

  function notifyCreationError(
    createSupplierMutationResult: Awaited<
      ReturnType<typeof createSupplierMutation>
    >,
  ) {
    switch (createSupplierMutationResult.createSupplier.reason) {
      case 'invalidBankInfos': {
        pushNotif({
          type: NotificationType.Danger,
          message: t(
            'bookkeep.accountsPayable.createSupplier.invalidBankInfos',
          ),
        });
        break;
      }
      case 'unableToAttachBankDetails': {
        pushNotif({
          type: NotificationType.Danger,
          message: t(
            'bookkeep.accountsPayable.createSupplier.unableToAttachBankDetails',
          ),
        });
        onClose();
        break;
      }
      default: {
        pushNotif({
          type: NotificationType.Danger,
          message: t(
            'bookkeep.accountsPayable.createSupplier.createErrorNotification',
          ),
        });
        onClose();
        break;
      }
    }
  }

  const formikProps = useFormik<CreateSupplierFormValues>({
    initialValues,
    validateOnChange: false,
    validate: (values: CreateSupplierFormValues) => {
      return validateForm(company, values, supplierNames);
    },
    onSubmit: async (values: CreateSupplierFormValues) => {
      let fileToUpload;
      if (
        bankAccountDetailsFile !== null &&
        bankAccountDetailsFile.action === 'upload'
      ) {
        fileToUpload = await fileToBase64(bankAccountDetailsFile.file);
      }

      const payload: Parameters<typeof createSupplierMutation>[0] = {
        supplier: {
          name: values.name,
          accountHolderName: values.accountHolderName,
          bankDetails: {
            bankCountry: values.bankCountry,
            iban: !isEmpty(values.iban) ? values.iban : undefined,
            bicSwift: !isEmpty(values.bicSwift) ? values.bicSwift : undefined,
            sortCode: !isEmpty(values.sortCode) ? values.sortCode : undefined,
            routingNumber: !isEmpty(values.routingNumber)
              ? values.routingNumber
              : undefined,
            accountNumber: !isEmpty(values.accountNumber)
              ? values.accountNumber
              : undefined,
            accountCode: !isEmpty(values.accountCode)
              ? values.accountCode
              : undefined,
          },
          legalDetails: {
            legalName: values.legalName,
          },
        },
      };

      if (bankAccountDetailsFile?.action === 'upload') {
        payload.supplier.bankDetails.bankAccountDetailsFile = fileToUpload;
      }

      const createSupplierMutationResult =
        await createSupplierMutation(payload);
      if (createSupplierMutationResult.createSupplier.reason) {
        notifyCreationError(createSupplierMutationResult);
        return;
      }

      if (
        !isEmpty(values.accountPayableId) &&
        createSupplierMutationResult.createSupplier.id
      ) {
        const setSupplierAccountMutationResult =
          await setSupplierAccountToSupplier({
            supplierId: createSupplierMutationResult.createSupplier.id,
            supplierAccountId: values.accountPayableId,
          });
        if (
          setSupplierAccountMutationResult.setSupplierAccountToSupplier.reason
        ) {
          pushNotif({
            type: NotificationType.Danger,
            message: t(
              'bookkeep.accountsPayable.createSupplier.createErrorNotification',
            ),
          });
          onClose();
          return;
        }
      }

      pushNotif({
        type: NotificationType.Success,
        message: t(
          'bookkeep.accountsPayable.createSupplier.createSuccessNotification',
        ),
      });

      onClose();
    },
  });

  const onCancelCreateAccountPayable = () => {
    formikProps.setFieldValue(
      'accountPayableId',
      initialValues.accountPayableId,
    );
  };

  const createAccountPayable = async (
    generalAccountCode: string,
    auxiliaryAccountCode: string | undefined,
  ) => {
    const accountCode = getCodeWithAuxiliaryAccounts({
      generalAccountCode,
      auxiliaryAccountCode,
    });
    const createSupplierAccountResult = await createSupplierAccount({
      generalAccountCode: auxiliaryAccountsEnabled
        ? generalAccountCode
        : accountCode,
      isArchived: false,
      ...(auxiliaryAccountsEnabled ? { auxiliaryAccountCode } : {}),
    });

    if (R.isFailure(createSupplierAccountResult)) {
      if (accountPayableCreationModalState.kind === 'form') {
        setAccountPayableCreationModalState({
          ...accountPayableCreationModalState,
          ...getCreateOrUpdateAccountPayableError(createSupplierAccountResult),
        });
      }

      if (
        accountingIntegrationQueryResult.status === 'success' &&
        // TODO@integrations understand why we need DATEV-specific behaviour here
        accountingIntegrationQueryResult.data.integration === 'Datev'
      ) {
        formikProps.setFieldError(
          'accountPayableId',
          createSupplierAccountResult.error.reason,
        );
        setFailedAccount(accountCode);
      }

      pushNotif({
        type: NotificationType.Danger,
        message:
          createSupplierAccountResult.error.reason === 'codeAlreadyExists'
            ? t(
                'bookkeep.accountsPayable.panel.accountingSection.addOptionErrorCodeAlreadyExistsNotification',
              )
            : t(
                'bookkeep.accountsPayable.panel.accountingSection.addOptionErrorNotification',
              ),
      });
      throw new Error(`Couldn't create new account payable`);
    }
    formikProps.setFieldTouched('accountPayableId');
    formikProps.setFieldValue(
      'accountPayableId',
      createSupplierAccountResult.value.accountPayableId,
      true,
    );
    return createSupplierAccountResult.value.accountPayableId;
  };

  if (
    accountingIntegrationQueryResult.status !== 'success' ||
    accountingIntegrationQueryResult.data.integration === 'noIntegration' ||
    accountingIntegrationQueryResult.data.integration === 'switchInProgress'
  ) {
    return null;
  }

  const integrationStatus = accountingIntegrationQueryResult.data;

  const onAddOption = async (
    newOptionLabel: string,
  ): Promise<{ key: string; label: string }> => {
    const { generalAccountCode, auxiliaryAccountCode } =
      getGeneralAndAuxiliaryAccountCodes(newOptionLabel);
    if (auxiliaryAccountsEnabled) {
      setAccountPayableCreationModalState({
        kind: 'form',
        error: undefined,
        createdAccount: {
          generalAccountCode,
          auxiliaryAccountCode,
          kind: 'supplierAccount',
          id: '',
          isDefault: false,
          isArchived: false,
        },
      });

      return {
        key: '',
        label: newOptionLabel,
      };
    }
    const accountPayableId = await createAccountPayable(
      generalAccountCode,
      auxiliaryAccountCode,
    );

    return {
      key: accountPayableId ?? '',
      label: newOptionLabel,
    };
  };

  return (
    <>
      <AccountPayableSuppliersCreateSupplierForm
        accountPayables={supplierAccounts}
        onAddOption={onAddOption}
        onCancel={onClose}
        suppliers={supplierNames}
        bankAccountDetailsFile={bankAccountDetailsFile}
        setBankAccountDetailsFile={setBankAccountDetailsFile}
        mustDisplayAccountsPayable={mustDisplayAccountsPayable}
        companyId={company.id}
        isDatev={
          accountingIntegrationQueryResult.status === 'success' &&
          accountingIntegrationQueryResult.data.integration === 'Datev'
        }
        failedAccount={failedAccount}
        integrationStatus={accountingIntegrationQueryResult.data}
        {...formikProps}
      />
      <AccountPayableCreationModal
        integrationStatus={integrationStatus}
        accountPayableCreationModalState={accountPayableCreationModalState}
        setAccountPayableCreationModalState={
          setAccountPayableCreationModalState
        }
        getSupplierAccountsQueryState={getSupplierAccountsQueryState}
        getEmployeeAccountCodesQuery={getEmployeeAccountCodesQuery}
        onClose={onCancelCreateAccountPayable}
        createAccountPayable={createAccountPayable}
      />
    </>
  );
};
