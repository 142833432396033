import { Button } from '@dev-spendesk/grapes';
import React from 'react';

import { useMarkHubTaskAsDoneMutation } from './hooks/useMarkHubTaskAsDoneMutation';
import { type HubTask } from '../../types/hubTask';

type Props = {
  text: string;
  className?: string;
  task: HubTask;
};

export const MarkAsDoneButton = ({ className, task, text }: Props) => {
  const [markTaskAsDoneMutation] = useMarkHubTaskAsDoneMutation();
  const markTaskAsDone = async () => {
    await markTaskAsDoneMutation({ endpointParams: { taskId: task.id } });
  };

  if (!task.canMarkAsCompleted || task.status !== 'ongoing') {
    return null;
  }

  return (
    <Button
      variant="secondary"
      iconName="checked"
      className={className}
      onClick={markTaskAsDone}
      text={text}
    />
  );
};
