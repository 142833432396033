import { IconButton, type AvatarBadgeProps } from '@dev-spendesk/grapes';
import { useEffect, useState } from 'react';

import { fallbackSupplierLogoSrc } from 'common/components/SupplierLogo';
import { useNotifications } from 'modules/app/notifications';
import { Counterparty } from 'modules/bookkeep/components/Counterparty';
import {
  useIsEditablePayable,
  useUpdatePayable,
  useUpdatePayableErrorMessage,
} from 'modules/payable/hooks';
import { SupplierEditForm } from 'modules/suppliers/components/SupplierEditForm/SupplierEditForm';
import { useFetchSupplier } from 'modules/suppliers/hooks';
import { unwrapQuery } from 'src/core/api/unwrapQuery';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { AnalyticEventName, track } from 'src/core/utils/analytics';
import { logger } from 'src/utils/datadog-log-wrapper';

import { EditAfterExportEditCallout } from './EditAfterExportEditCallout';
import {
  getCounterpartyMembersCalloutText,
  getCounterpartyMembersTitle,
} from './translations';
import { SupplierOrMemberSyncStatus } from '../../../components/SupplierOrMemberSyncStatus';
import {
  useGetMemberSyncStatus,
  useGetSupplierSyncWithDefault,
} from '../../../hooks';
import { SupplierSyncStatusForIntegration } from '../../../integration/components/SupplierSyncStatusForIntegration';
import type {
  Member,
  Payable,
  Supplier,
} from '../../containers/PayablePanelContainer';
import { useUpdatePayableQueryCache } from '../../containers/PayablePanelContainer/hooks';
import { formatMemberName } from '../../models/member';
import {
  getIsCardPayable,
  getIsCreditNotePayable,
  getIsInvoicePayable,
  isSpanishDPRPayable,
} from '../../models/payable';

type Props = {
  payable: Payable;
};

export const CounterpartySection = ({ payable }: Props) => {
  const { counterparty } = payable;

  if (!counterparty) {
    return null;
  }

  if (counterparty.type === 'supplier') {
    return <SupplierCounterparty payable={{ ...payable, counterparty }} />;
  }

  return (
    <MemberCounterparty
      counterparty={counterparty}
      badge={getAvatarBadge(payable)}
    />
  );
};

const MemberCounterparty = ({
  counterparty,
  badge,
}: {
  counterparty: Member;
  badge: AvatarBadgeProps | undefined;
}) => {
  const { t } = useTranslation('global');
  const syncStatus = useGetMemberSyncStatus(counterparty.id);

  return (
    <>
      <Counterparty
        type="employee"
        name={formatMemberName(counterparty, t)}
        imageUrl={
          counterparty.type !== 'invitee' ? counterparty.avatar : undefined
        }
        badge={badge}
      />

      {syncStatus && (
        <SupplierOrMemberSyncStatus
          {...syncStatus}
          anchor="simple"
          label={
            syncStatus.synced
              ? getCounterpartyMembersTitle(t, syncStatus.integrationName)
                  .synced
              : getCounterpartyMembersTitle(t, syncStatus.integrationName)
                  .notSynced
          }
          tooltipText={getCounterpartyMembersCalloutText(
            t,
            syncStatus.integrationName,
          )}
        />
      )}
    </>
  );
};

export const SupplierCounterparty = ({
  payable,
}: {
  payable: Omit<Payable, 'counterparty'> & { counterparty: Supplier };
}) => {
  const { counterparty, subtype } = payable;

  const { t } = useTranslation('global');

  const { dangerNotif } = useNotifications();

  const [isEditMode, setIsEditMode] = useState(false);

  const [selectedSupplier, setSelectedSupplier] = useState<{
    id: string;
    name: string;
    thumbnailUrl?: string;
  }>(counterparty);

  useEffect(() => {
    if (counterparty.id !== selectedSupplier.id) {
      setSelectedSupplier(counterparty);
    }
  }, [counterparty.id]);

  const shouldDisplayDPRInformations = isSpanishDPRPayable(payable);

  const syncStatus = useGetSupplierSyncWithDefault(
    selectedSupplier.id,
    subtype,
  );

  const fetchSupplierQuery = useFetchSupplier(selectedSupplier.id);

  const supplierData = unwrapQuery(fetchSupplierQuery);

  const isEditablePayable = useIsEditablePayable(payable);

  const [updatePayable] = useUpdatePayable(payable.id);

  const updatePayableQueryCache = useUpdatePayableQueryCache();

  const getUpdatePayableErrorMessage = useUpdatePayableErrorMessage();

  const isInvoicePayable = getIsInvoicePayable(payable);

  const isCreditNotePayable = getIsCreditNotePayable(payable);

  const isCardPayable = getIsCardPayable(payable);

  const isUnknownSupplier = selectedSupplier.id === 'unknown';

  const isEditable =
    isEditablePayable &&
    (shouldDisplayDPRInformations
      ? isInvoicePayable || isCreditNotePayable || isCardPayable
      : isCardPayable);

  const isSupplierEditable = isEditable && isCardPayable;

  return (
    <>
      {isEditMode === false && (
        <div className="flex flex-row gap-s">
          <Counterparty
            type="supplier"
            name={selectedSupplier?.name}
            imageUrl={
              !isUnknownSupplier ? selectedSupplier.thumbnailUrl : undefined
            }
            countryIsoCode2={
              shouldDisplayDPRInformations
                ? supplierData?.supplierDetails?.address?.country
                : undefined
            }
            vatNumber={
              shouldDisplayDPRInformations
                ? supplierData?.supplierDetails?.legalInfos?.vatNumber
                : undefined
            }
          />

          {isEditable && (
            <IconButton
              data-testid="counterparty-edit-mode-true"
              variant="borderless"
              iconName="pen"
              onClick={() => {
                setIsEditMode(true);
              }}
              aria-label={t('misc.edit')}
            />
          )}
        </div>
      )}

      {isEditMode === true && (
        <>
          <EditAfterExportEditCallout payable={payable} />

          <SupplierEditForm
            supplier={{
              ...selectedSupplier,
              vatNumber: supplierData?.supplierDetails?.legalInfos?.vatNumber,
              country: supplierData?.supplierDetails?.address?.country,
            }}
            onSupplierSelected={(supplier) => {
              setSelectedSupplier(supplier);
            }}
            onConfirm={async () => {
              try {
                setIsEditMode(false);
                if (isSupplierEditable) {
                  const { newPayableVersion } = await updatePayable({
                    payableVersion: payable.version,
                    update: {
                      counterparty: {
                        id: selectedSupplier.id,
                        name: selectedSupplier.name,
                        type: 'supplier',
                      },
                    },
                  });

                  track(AnalyticEventName.BOOKKEEP_PAYABLE_ALL_PAYABLE_SAVED, {
                    payableId: payable.id,
                    editedFields: ['supplier'],
                    state: payable.state,
                  });

                  updatePayableQueryCache(payable.id, {
                    version: newPayableVersion,
                    counterparty: {
                      id: selectedSupplier.id,
                      name: selectedSupplier.name,
                      __typename: 'Supplier',
                    },
                  });
                }
              } catch (error) {
                const errorMessage = getUpdatePayableErrorMessage(error);
                dangerNotif(errorMessage);
                logger.error(errorMessage, {
                  team: 'finance-accountant',
                  scope: 'payables::panel::supplier::update',
                  error,
                });
              }
            }}
            onCancel={() => {
              setIsEditMode(false);
              setSelectedSupplier(counterparty);
            }}
            shouldDisplayDPRInformations={shouldDisplayDPRInformations}
            isSupplierEditable={isSupplierEditable}
          />
        </>
      )}

      {syncStatus && (
        <SupplierSyncStatusForIntegration supplierId={selectedSupplier.id} />
      )}
    </>
  );
};

const getAvatarBadge = (payable: Payable) => {
  if (payable.supplier) {
    return {
      variant: 'square' as const,
      src: payable.supplier.thumbnailUrl,
      text: payable.supplier.name,
      fallbackSrc: fallbackSupplierLogoSrc,
    };
  }
};
