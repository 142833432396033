import axios from 'axios';
import { useState } from 'react';

import {
  useMutation,
  type MutationState,
} from 'src/core/api/hooks/useMutation';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { useNotifications } from 'src/core/modules/app/notifications';
import { handleReceiptUploadError } from 'src/core/modules/requests/expense/creation/BulkExpenseSubmit/utils';

import { useUploadDocumentaryEvidencePolling } from './useUploadDocumentaryEvidencePolling';

type UploadDocumentaryEvidencePayload = {
  files: File[];
};

export const MAX_NUMBER_OF_FILES = 3;

export const useUploadDocumentaryEvidence = (
  payableId: string,
): [
  (payload: UploadDocumentaryEvidencePayload) => Promise<void>,
  { isUploading: boolean },
] => {
  const [isUploading, setIsUploading] = useState(false);

  const { dangerNotif } = useNotifications();
  const { t } = useTranslation('global');

  const [isPolling, setIsPolling] =
    useUploadDocumentaryEvidencePolling(payableId);

  const [uploadDocumentaryEvidenceIntent] =
    useUploadDocumentaryEvidenceIntentMutation();

  return [
    async ({ files }: UploadDocumentaryEvidencePayload): Promise<void> => {
      setIsUploading(true);
      const filesArray = files.slice(0, MAX_NUMBER_OF_FILES);
      await Promise.all(
        filesArray.map(async (file) => {
          try {
            const result = await uploadDocumentaryEvidenceIntent({
              payableId,
              mimeType: file.type,
              contentLength: file.size,
            });

            // Result contains signed URL, this is now used to upload file to S3 with uploadFileAsFormData
            if (result.outcome === 'created') {
              try {
                await uploadFileAsFormData(result.url, result.payload, file);
              } catch {
                // ignore errors
              }
            } else {
              setIsPolling(false);
              setIsUploading(false);
            }
          } catch (error) {
            handleReceiptUploadError({ response: error }, dangerNotif, t);
            setIsPolling(false);
            setIsUploading(false);
          }
        }),
      );

      setIsPolling(true);
      setIsUploading(false);
    },

    { isUploading: isUploading || isPolling },
  ];
};

const uploadFileAsFormData = async (
  url: string,
  meta: { [key: string]: string },
  file: File,
) => {
  const formData = new FormData();

  for (const key in meta) {
    formData.append(key, meta[key]);
  }

  formData.append('file', file);

  return axios.post(url, formData);
};

/**
 * Upload Documentary Evidence intent hook
 */
type UploadDocumentaryEvidenceIntentPayload = {
  payableId: string;
  mimeType: string;
  contentLength: number;
};

type UploadDocumentaryEvidenceIntentResponse =
  | ({
      outcome: 'notCreated';
    } & (
      | {
          reason: 'unsupportedMimeType';
        }
      | {
          reason: 'invalidInsertQuery' | 'invalidSignedUrl' | 'fileTooLarge';
          error: Error;
        }
    ))
  | {
      outcome: 'created';
      url: string;
      payload: { [key: string]: string };
    };

const useUploadDocumentaryEvidenceIntentMutation = (): MutationState<
  UploadDocumentaryEvidenceIntentPayload,
  UploadDocumentaryEvidenceIntentResponse
> => {
  return useMutation<
    UploadDocumentaryEvidenceIntentPayload,
    UploadDocumentaryEvidenceIntentResponse,
    UploadDocumentaryEvidenceIntentResponse
  >({
    request: {
      type: 'rest',
      target: 'companyAPI',
      method: 'post',
      endpoint: '/bookkeeping/documentary-evidence',
    },
    reshapeData: (data: UploadDocumentaryEvidenceIntentResponse) => data,
  });
};
