import { useMutation } from 'src/core/api/hooks/useMutation';
import { logger } from 'src/utils/datadog-log-wrapper';

import { type HubTask, type HubTaskCategory } from '../../types/hubTask';

type Payload = {
  category: HubTaskCategory;
};

export const useCompleteTasksInCategoryMutation = () => {
  return useMutation<Payload>({
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: '/onboarding-hub/tasks/complete-category',
      method: 'post',
    },
    options: {
      throwOnError: true,
      onSuccess: ({ client, payload }) => {
        const { category } = payload;

        const context = category === 'onboarding' ? 'onboarding' : 'setup_hub';
        client.setQueryData<HubTask[]>(
          ['onboarding-hub', 'tasks', context],
          (tasks) => {
            if (!tasks) {
              return [];
            }
            return tasks.map((task) =>
              task.category === category
                ? { ...task, status: 'completed' }
                : task,
            );
          },
        );
      },
      onError: ({ error }) => {
        if (error.type !== 'NetworkError' && error.status !== 401) {
          logger.error(
            'Error while marking all tasks in category as completed',
            {
              scope: 'ready-to-spend',
              team: 'growth',
              stack: error.stack,
            },
          );
        }
      },
    },
  });
};
