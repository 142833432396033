import { Skeleton } from '@dev-spendesk/grapes';
import React from 'react';

import { TextFitter } from '../../../TextFitter';

const BLOCK_WIDTH = '144px';
const BLOCK_HEIGHT = '192px';

export const EntityItemActionsGroup = ({
  title,
  contents,
}: {
  title: string;
  contents: React.ReactNode[];
}) => (
  <div
    className={`flex h-full w-[${BLOCK_WIDTH}] flex-col gap-xs rounded-[8px] bg-page-background px-xs py-s`}
  >
    <h3 className="text-complementary title-m">
      <TextFitter maxLines={2}>{title}</TextFitter>
    </h3>
    <div className="flex flex-col gap-xxs">
      {React.Children.map(contents, (content) => (
        <div key={`entity-item-actions-group-${content?.toString()}`}>
          {content}
        </div>
      ))}
    </div>
  </div>
);

export const EntityStatisticBlockSkeleton = () => (
  <Skeleton
    width={BLOCK_WIDTH}
    height={BLOCK_HEIGHT}
    className="flex rounded-[8px]"
  />
);
