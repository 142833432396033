import { apiUrl } from 'src/core/utils/api';

export const denyRequest = async ({
  companyId,
  requestId,
  denyReason,
}: {
  companyId: string;
  requestId: string;
  denyReason: string;
}) => {
  const response = await fetch(
    apiUrl(`/requests/${requestId}/deny`, companyId),
    {
      method: 'PUT',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ deny_motif: denyReason }),
    },
  );
  if (response.status === 200) {
    return response.json();
  }

  throw new Error('Failed to deny request');
};
