import React from 'react';

import { ExpenseDetailsSection } from './ExpenseDetailsSection';
import { InvoiceDetailsSection } from './InvoiceDetailsSection';
import { isInvoiceBill } from '../../../../models';
import { type TransferDetails } from '../../../models';

type Props = {
  transfer: TransferDetails;
};

export const DetailsSection = ({ transfer }: Props) => {
  return isInvoiceBill(transfer.bill) ? (
    <InvoiceDetailsSection bill={transfer.bill} />
  ) : (
    <ExpenseDetailsSection bill={transfer.bill} />
  );
};
