import { Button } from '@dev-spendesk/grapes';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useUser } from 'modules/app/hooks/useUser';
import {
  NotificationType,
  useNotifications,
} from 'modules/app/notifications/hooks/useNotifications';
import type { AppDispatch } from 'modules/app/redux/store';
import { cancelRequest } from 'modules/requests/api/cancelRequest';
import { denyRequest } from 'modules/requests/api/denyRequest';
import { DenyRequestModal } from 'modules/requests/components/common/DenyRequestModal';
import { canCancelRequest, isRequestApprovable } from 'modules/requests/models';
import {
  approveRequest,
  removeRequestLocally,
  removeDraftRequestLocally,
  updateRequestLocally,
  fetchRequestsStats,
} from 'modules/requests/redux/legacy/actions';
import { isDraftRequest } from 'modules/requests/utils/requestUtils';
import { companyAPI } from 'src/core/api/axios';
import { appQueryClient } from 'src/core/api/client';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { AnalyticEventName, track } from 'src/core/utils/analytics';
import { getFilenameFromContentDispositionHeader } from 'src/core/utils/contentDisposition';
import { downloadFromBlob } from 'src/core/utils/fileDownloader';
import { logger } from 'src/utils/datadog-log-wrapper';

import type { Request } from './RequestsListBox';
import { CancelRequestsModal } from './components/CancelRequestsModal';

type RequestsListBoxHeaderProps = {
  requests: Request[];
  checkedRequests: string[];
  unselectRequests: () => void;
};

export const RequestsListBoxHeader = ({
  requests,
  checkedRequests,
  unselectRequests,
}: RequestsListBoxHeaderProps) => {
  const { t } = useTranslation('global');
  const user = useUser();
  const companyId = useCompanyId();
  const { pushNotif } = useNotifications();
  const dispatch = useDispatch<AppDispatch>();
  const [denyRequestContext, setDenyRequestContext] = useState<{
    open: boolean;
    requests: Request[];
  }>({
    open: false,
    requests: [],
  });
  const [deleteRequestContext, setDeleteRequestContext] = useState<{
    open: boolean;
    requests: Request[];
  }>({
    open: false,
    requests: [],
  });

  const dispatchApproveRequest = (request: Request): Promise<void> => {
    return new Promise((resolve) => {
      dispatch(
        approveRequest(request, {
          updateLocally: true,
          callback: () => {
            resolve();
          },
        }),
      );
    });
  };

  const deleteDraftRequest = async (request: Request) => {
    try {
      await companyAPI.delete(`/drafts/${request.id}`, {
        companyId,
      });
      dispatch(removeDraftRequestLocally({ id: request.id }));
    } catch {
      logger.warn(`[BULK] failed to delete draft request ${request.id}`, {
        scope: 'requests::actions::bulk',
        team: 'capture',
      });
    }
  };

  const bulkAction =
    (function_: (request: Request) => Promise<void>) =>
    async (requestsToBulk: Request[]) => {
      const results = await Promise.allSettled(
        requestsToBulk.map((request) => function_(request)),
      );
      const fulfilledAction = results.filter(
        (result) => result.status === 'fulfilled',
      ).length;

      if (fulfilledAction > 0) {
        unselectRequests();
      }

      pushNotif({
        type: fulfilledAction
          ? NotificationType.Success
          : NotificationType.Danger,
        message: t('requests.actions.successfulWithCount', {
          count: fulfilledAction,
        }),
      });
    };

  const downloadRequests = async (requestsToDownoad: Request[]) => {
    const { data, headers } = await companyAPI.post(
      '/requests/download',
      { requestIds: requestsToDownoad.map((request) => request.id) },
      {
        companyId,
        responseType: 'blob',
      },
    );

    // Due to CORS issue content-disposition is not always passed by the server.
    // So to force the extension to be a csv, hardcode the filename.
    const filename =
      getFilenameFromContentDispositionHeader(headers['content-disposition']) ||
      'requests.csv';

    downloadFromBlob(data, filename);
  };

  const dispatchDenyRequest = (reason: string) => async (request: Request) => {
    try {
      const data = await denyRequest({
        requestId: request.id,
        denyReason: reason,
        companyId,
      });
      dispatch(updateRequestLocally(data));
      appQueryClient.invalidateQueries(['requests', request.id]);
    } catch {
      setDenyRequestContext(() => ({
        open: false,
        requests: [],
      }));
      throw new Error('Failed to deny request');
    }
  };

  const dispatchCancelRequest = async (request: Request) => {
    try {
      await cancelRequest({
        requestId: request.id,
        companyId,
      });
      dispatch(removeRequestLocally(request.id));
    } catch (error) {
      logger.warn(`[BULK] failed to cancel request ${request.id}`, {
        scope: 'requests::actions::bulk',
        team: 'capture',
      });
      throw error;
    }
  };

  const actions = {
    approve: {
      title: (count: number) => t('requests.approveWithCount', { count }),
      run: bulkAction(dispatchApproveRequest),
    },
    cancel: {
      title: (count: number) => t('requests.cancelWithCount', { count }),
      run: (requestsToCancel: Request[]) =>
        setDeleteRequestContext({ open: true, requests: requestsToCancel }),
    },
    delete: {
      title: (count: number) => t('requests.deleteWithCount', { count }),
      run: bulkAction(deleteDraftRequest),
    },
    deny: {
      title: (count: number) => t('requests.denyWithCount', { count }),
      run: (requestsToDeny: Request[]) =>
        setDenyRequestContext({ open: true, requests: requestsToDeny }),
    },
    download: {
      title: (count: number) => t('requests.downloadWithCount', { count }),
      run: downloadRequests,
    },
  };

  const isItemEligibleForAction = (request: Request, action: string) => {
    if (!request) {
      return false;
    }

    if (isDraftRequest(request)) {
      return action === 'delete';
    }

    switch (action) {
      case 'approve':
      case 'deny': {
        return isRequestApprovable(request);
      }
      case 'cancel':
        return canCancelRequest(request, user);
      case 'download':
        return true;
      default:
        return false;
    }
  };

  const eligibleActions = Object.entries(actions).map(
    ([actionName, properties]) => {
      const eligibles = requests.filter((request) => {
        return (
          checkedRequests.includes(request.id) &&
          isItemEligibleForAction(request, actionName)
        );
      });

      return { action: actionName, eligibles, properties };
    },
  );

  if (checkedRequests.length < 1) {
    return <div className="flex h-[52px]" />;
  }

  return (
    <div className="h-min-[52px] flex items-center justify-between gap-xs py-xs">
      <p>
        {t('requests.actions.nRequestsSelected', {
          count: checkedRequests.length,
        })}
      </p>
      <menu className="flex list-none gap-xs">
        {eligibleActions.map(({ action, eligibles, properties }) => {
          if (eligibles.length < 1) {
            return null;
          }
          return (
            <li key={action}>
              <Button
                text={properties.title(eligibles.length)}
                variant="secondary"
                onClick={async () => {
                  track(AnalyticEventName.REQUEST_BULK_ACTION, {
                    type: action,
                    number: eligibles.length,
                  });
                  await properties.run(eligibles);
                }}
              />
            </li>
          );
        })}
      </menu>
      <DenyRequestModal
        isOpen={denyRequestContext.open}
        onCancel={() => {
          setDenyRequestContext((state) => ({
            open: false,
            requests: state.requests,
          }));
        }}
        onProcessed={async (reason: string) => {
          await bulkAction(dispatchDenyRequest(reason))(
            denyRequestContext.requests,
          );
          setDenyRequestContext(() => ({
            open: false,
            requests: [],
          }));
          dispatch(fetchRequestsStats());
        }}
      />
      <CancelRequestsModal
        isOpen={deleteRequestContext.open}
        onClose={() => {
          setDeleteRequestContext((state) => ({
            open: false,
            requests: state.requests,
          }));
        }}
        onConfirm={async () => {
          await bulkAction(dispatchCancelRequest)(
            deleteRequestContext.requests,
          );
          setDeleteRequestContext(() => ({
            open: false,
            requests: [],
          }));
          dispatch(fetchRequestsStats());
        }}
      />
    </div>
  );
};
