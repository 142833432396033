import {
  Callout,
  IconButton,
  Table,
  type TableColumn,
} from '@dev-spendesk/grapes';
import { useMemo, useState } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCreateOrUpdateAccountPayable } from 'modules/accounting-integration/apis';
import { useNotifications } from 'modules/app/notifications';
import { type EmployeeAccount } from 'modules/bookkeep';
import type { IntegrationStatusWithIntegration } from 'modules/bookkeep/integration/status';
import { usePaginatedEmployeeAccountsQuery } from 'modules/bookkeep/settings/integrations/hooks/usePaginatedEmployeeAccountsQuery';
import {
  EmployeeAccountAddEditModal,
  type ModalState,
} from 'modules/bookkeep/settings/integrations/pages/LegacyIntegrationsAccountingPage/sections/EmployeeAccountsSection/EmployeeAccountsLocalOnlySection/modals/EmployeeAccountAddEditModal';
import { EmployeeAccountCreateConfirmationModal } from 'modules/bookkeep/settings/integrations/pages/LegacyIntegrationsAccountingPage/sections/EmployeeAccountsSection/EmployeeAccountsLocalOnlySection/modals/EmployeeAccountCreateConfirmationModal';
import { EmployeeAccountDeleteModal } from 'modules/bookkeep/settings/integrations/pages/LegacyIntegrationsAccountingPage/sections/EmployeeAccountsSection/EmployeeAccountsLocalOnlySection/modals/EmployeeAccountDeleteModal';
import { ExpenseAccountsSkeletonTable } from 'modules/bookkeep/settings/integrations/pages/LegacyIntegrationsAccountingPage/sections/ExpenseAccountsSection/ExpenseAccountsSkeletonTable';
import { useMembersQueryRaw } from 'modules/members/hooks/useMembersQueryRaw';
import { unwrapQuery } from 'src/core/api/unwrapQuery';

type Props = {
  useDefaultValue: boolean;
  integrationStatus: IntegrationStatusWithIntegration;
};

type DataRow = {
  memberId: string;
  fullName: string;
  employeeAccount: Partial<EmployeeAccount>;
};

export const EmployeeAccountsTable = ({
  useDefaultValue,
  integrationStatus,
}: Props) => {
  const { t } = useTranslation('global');
  const { dangerNotif } = useNotifications();

  const membersQueryRawState = useMembersQueryRaw();
  const members = unwrapQuery(membersQueryRawState) ?? [];
  const [modalState, setModalState] = useState<ModalState>({ kind: 'closed' });
  const [updateEmployeeAccount] =
    useCreateOrUpdateAccountPayable('employeeAccount');

  const employeeAccountsQueryState = usePaginatedEmployeeAccountsQuery({
    isMapped: true,
  });

  const data: DataRow[] = useMemo(() => {
    if (employeeAccountsQueryState.status === 'success') {
      return members.flatMap((member) => {
        if (member.pending || member.deleted_at) {
          return [];
        }
        const employeeAccount = employeeAccountsQueryState.data.find(
          (account) => account.user?.id === member.id,
        );
        return {
          memberId: member.id,
          fullName: member.fullname,
          employeeAccount: {
            ...employeeAccount,
            user: {
              id: member.id,
              firstName: member.first_name ?? '',
              lastName: member.last_name ?? '',
              avatar: member.avatar ?? null,
              gender: member.gender,
            },
          },
        };
      });
    }
    return [];
  }, [members, employeeAccountsQueryState]);

  if (
    employeeAccountsQueryState.status === 'loading' ||
    membersQueryRawState.status === 'loading'
  ) {
    return <ExpenseAccountsSkeletonTable />;
  }

  if (
    employeeAccountsQueryState.status === 'error' ||
    membersQueryRawState.status === 'error'
  ) {
    return <Callout title={t('misc.errors.unknownError')} variant="alert" />;
  }

  const handleAddEmployeeAccount = async (employeeAccount: EmployeeAccount) => {
    try {
      await updateEmployeeAccount({
        generalAccountCode: employeeAccount.generalAccountCode,
        auxiliaryAccountCode: employeeAccount.auxiliaryAccountCode,
        isArchived: false,
        name: employeeAccount.user
          ? `${employeeAccount.user.firstName} ${employeeAccount.user.lastName}`
          : t(
              'bookkeep.integrations.settings.employeeAccountsTable.deletedEmployeeAccount',
            ),
        memberIds: employeeAccount.user ? [employeeAccount.user.id] : [],
      });
    } catch {
      return dangerNotif(t('misc.errors.unknownError'));
    }
  };

  const handleDeleteEmployeeAccount = async (
    employeeAccount: EmployeeAccount,
  ) => {
    try {
      await updateEmployeeAccount({
        id: employeeAccount.id,
        generalAccountCode: employeeAccount.generalAccountCode,
        auxiliaryAccountCode: employeeAccount.auxiliaryAccountCode,
        isArchived: true,
        name: employeeAccount.user
          ? `${employeeAccount.user.firstName} ${employeeAccount.user.lastName}`
          : t(
              'bookkeep.integrations.settings.employeeAccountsTable.deletedEmployeeAccount',
            ),
        memberIds: employeeAccount.user ? [employeeAccount.user.id] : [],
      });
    } catch {
      return dangerNotif(t('misc.errors.unknownError'));
    }
  };

  const getAccountCodeToDisplay = (row: DataRow) => {
    if (row.employeeAccount.generalAccountCode) {
      return row.employeeAccount.generalAccountCode;
    }
    return t(
      useDefaultValue
        ? 'setupHub.employeeAccounts.defaultAccount'
        : 'setupHub.employeeAccounts.noAccountYet',
    );
  };

  const columns: TableColumn<DataRow>[] = [
    {
      id: 'employeeName',
      header: t('bookkeep.integrations.settings.employeeAccountsTable.name'),
      renderCell: ({ fullName }) => fullName,
    },
    {
      id: 'accountCode',
      header: t('bookkeep.integrations.settings.employeeAccountsTable.code'),
      renderCell: (row, { isRowHovered }) => (
        <div className="flex items-center justify-between">
          {getAccountCodeToDisplay(row)}
          {isRowHovered && row.employeeAccount.generalAccountCode && (
            <span className="flex gap-xxs">
              <IconButton
                iconName="pen"
                variant="borderless"
                onClick={() => {
                  setModalState({
                    kind: 'edit',
                    employeeAccount: row.employeeAccount,
                    inputChanged: false,
                    error: 'none',
                  });
                }}
                aria-label={t('misc.edit')}
              />
              <IconButton
                iconName="trash"
                variant="borderless"
                onClick={() =>
                  setModalState({
                    kind: 'confirmDelete',
                    employeeAccount: row.employeeAccount as EmployeeAccount,
                  })
                }
                aria-label={t('misc.delete')}
              />
            </span>
          )}
          {isRowHovered && !row.employeeAccount.generalAccountCode && (
            <IconButton
              iconName="pen"
              variant="borderless"
              onClick={() =>
                setModalState({
                  kind: 'add',
                  employeeAccount: row.employeeAccount,
                  inputChanged: false,
                  error: 'none',
                })
              }
              aria-label={t('misc.add')}
            />
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <p className="mb-s mt-s title-m">
        {t('setupHub.employeeAccounts.employeeAccountsTableTitle')}
      </p>
      <Table
        data={data}
        columns={columns}
        getRowId={(row: DataRow) => row.memberId}
        hasColumnSeparator
      />
      <EmployeeAccountAddEditModal
        isFixedUser
        integrationStatus={integrationStatus}
        modalState={modalState}
        setModalState={setModalState}
      />

      <EmployeeAccountCreateConfirmationModal
        integration={integrationStatus.integration}
        modalState={modalState}
        onAdd={handleAddEmployeeAccount}
        setModalState={setModalState}
      />

      <EmployeeAccountDeleteModal
        integration={integrationStatus.integration}
        onDelete={handleDeleteEmployeeAccount}
        modalState={modalState}
        setModalState={setModalState}
      />
    </>
  );
};
