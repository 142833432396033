import { Button, Modal } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import {
  hasIntegrationFileBasedExport,
  type IntegrationStatus,
} from 'modules/bookkeep/integration/status';

import { type ExpenseAccount } from '../../settings/accounting';
import { useSetExpenseAccountMutation } from '../../settings/integrations/hooks/useSetExpenseAccountMutation';

type Props = {
  account?: ExpenseAccount;
  integrationStatus: IntegrationStatus;
  isOpen: boolean;
  onClose: () => void;
};

export const DeleteExpenseAccountModal = ({
  account,
  integrationStatus,
  isOpen,
  onClose,
}: Props) => {
  const { t } = useTranslation('global');

  const [setExpenseAccount] = useSetExpenseAccountMutation();
  const onDelete = async () => {
    if (account) {
      await setExpenseAccount({
        id: account.id,
        name: account.name,
        code: account.code,
        isArchived: true,
      });
    }
    onClose();
  };

  return (
    <Modal
      title={t(
        'bookkeep.integrations.settings.expenseAccountsTable.confirmDeleteExpenseAccountTitle',
        { accountName: account?.name },
      )}
      subtitle={
        hasIntegrationFileBasedExport(integrationStatus.integration)
          ? t('bookkeep.integrations.fileBased.confirmDeleteDescription')
          : t(
              'bookkeep.integrations.settings.expenseAccountsTable.confirmSendBackToPrepareSubtitle',
            )
      }
      isOpen={isOpen}
      iconVariant="alert"
      iconName="trash"
      actions={[
        <Button
          key="cancel"
          onClick={onClose}
          text={t('misc.cancel')}
          variant="secondary"
        />,
        <Button
          key="yes"
          onClick={onDelete}
          text={t(
            'bookkeep.integrations.settings.expenseAccountsTable.confirmDeletion',
          )}
          variant="alert"
        />,
      ]}
    />
  );
};
