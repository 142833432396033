import { Avatar, Button, Callout, Panel, Tooltip } from '@dev-spendesk/grapes';
import React from 'react';
import { useHistory, useLocation, matchPath } from 'react-router-dom';

import { PanelHeader } from 'common/components/Panel';
import { getLogoUrlFromName } from 'common/components/SupplierLogo';
import { useFeature } from 'common/hooks/useFeature';
import { useParams } from 'common/hooks/useParams';
import { useTranslation } from 'common/hooks/useTranslation';
import { type IntegrationStatusWithIntegration } from 'modules/bookkeep/integration/status';
import { useSupplierSyncStatus } from 'modules/suppliers/hooks';
import FEATURES from 'src/core/constants/features';
import { routes, routeFor } from 'src/core/constants/routes';

import { AccountPayableSuppliersPanelAccountingSection } from './AccountPayableSuppliersPanelAccountingSection';
import { AccountPayableSuppliersPanelBankDetailsSection } from './AccountPayableSuppliersPanelBankDetailsSection';
import { AccountPayableSuppliersPanelBankProofSection } from './AccountPayableSuppliersPanelBankProofSection';
import { AccountPayableSuppliersPanelLegalDetailsSection } from './AccountPayableSuppliersPanelLegalDetailsSection';
import { AccountPayableSuppliersPanelVendorSelect } from './AccountPayableSuppliersPanelVendorSelect';
import {
  getAccountPayableSuppliersCalloutContent,
  getAccountPayableSuppliersNotSyncedTitle,
  getAccountPayableSuppliersTitle,
} from './translations';
import { SupplierOrMemberSyncStatus } from '../../../components/SupplierOrMemberSyncStatus';
import { type SupplierDetails } from '../../types';

type Props = {
  integrationStatus: IntegrationStatusWithIntegration;
  supplier: SupplierDetails;
  mustDisplayAccountsPayable: boolean;
  onArchiveSupplier(): void;
  onUnarchiveSupplier(): void;
};

const fallbackSource = '/static/img/avatars/supplier.svg';

export const SuppliersPanelId = 'suppliers-panel';

export const AccountPayableSuppliersPanel = ({
  integrationStatus,
  supplier,
  mustDisplayAccountsPayable,
  onArchiveSupplier,
  onUnarchiveSupplier,
}: Props) => {
  const { t } = useTranslation('global');
  const history = useHistory();
  const location = useLocation();
  const { company } = useParams(
    routes.COMPANY_ACCOUNTS_PAYABLE_SUPPLIER_DETAIL.path,
  );
  const hasAutocatFeature = useFeature(FEATURES.AUTO_CAT);
  const supplierSyncStatus = useSupplierSyncStatus(supplier.id);

  const matchSupplierDetails = matchPath<{ supplierId: string }>(
    location.pathname,
    {
      path: routes.COMPANY_ACCOUNTS_PAYABLE_SUPPLIER_DETAIL.path,
      exact: true,
      strict: false,
    },
  );
  const matchAccountPayableSuppliersDetail = matchPath<{
    accountPayableId: string;
  }>(location.pathname, {
    path: routes.COMPANY_ACCOUNTS_PAYABLE_SUPPLIERS_DETAIL.path,
    exact: true,
    strict: false,
  });

  const handleSuppliersPanelClose = () => {
    if (matchSupplierDetails) {
      history.push({
        pathname: routeFor(routes.COMPANY_ACCOUNTS_PAYABLE.path, {
          company,
        }),
        search: location.search,
      });
    }
    if (matchAccountPayableSuppliersDetail) {
      history.push({
        pathname: routeFor(routes.COMPANY_ACCOUNTS_PAYABLE_SUPPLIERS.path, {
          accountPayableId:
            matchAccountPayableSuppliersDetail?.params.accountPayableId ?? '',
          company,
        }),
        search: location.search,
      });
    }
  };
  const integrationName = integrationStatus.integration;

  return (
    <Panel
      title={t('bookkeep.accountsPayable.panel.headerTitle')}
      data-testid={SuppliersPanelId}
      onClose={() => {
        handleSuppliersPanelClose();
      }}
      header={
        <PanelHeader
          top={
            <>
              <div className="flex h-[36px] items-center justify-between gap-x-xs">
                <div className="flex items-center gap-x-xs">
                  <Avatar
                    className="flex-none"
                    variant="square"
                    src={
                      supplier.thumbnailUrl ?? getLogoUrlFromName(supplier.name)
                    }
                    fallbackSrc={fallbackSource}
                    text={supplier.name}
                  />
                  <div>
                    {supplier.name.length > 27 &&
                    supplierSyncStatus.status === 'success' ? (
                      <Tooltip content={supplier.name}>
                        <div className="max-w-[250px] overflow-hidden text-ellipsis whitespace-nowrap leading-6 text-complementary title-l">
                          {supplier.name}
                        </div>
                      </Tooltip>
                    ) : (
                      <div className="min-w-[250px] leading-6 text-complementary title-l">
                        {supplier.name}
                      </div>
                    )}
                    <div className="text-neutral-dark body-s">
                      <p>
                        {supplier.supplierAccount?.generalAccountCode ||
                          t('bookkeep.accountsPayable.undefined')}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {supplierSyncStatus.status === 'success' && (
                <>
                  <SupplierOrMemberSyncStatus
                    label={
                      supplierSyncStatus.data.synced
                        ? getAccountPayableSuppliersTitle(integrationName, t)
                        : getAccountPayableSuppliersNotSyncedTitle(
                            integrationName,
                            t,
                          )
                    }
                    {...supplierSyncStatus.data}
                  />

                  {!supplierSyncStatus.data.synced && (
                    <Callout
                      className="-mb-m mt-s"
                      title={
                        getAccountPayableSuppliersCalloutContent(
                          integrationName,
                          t,
                        ).title
                      }
                    >
                      <p>
                        {
                          getAccountPayableSuppliersCalloutContent(
                            integrationName,
                            t,
                          ).text
                        }
                      </p>
                    </Callout>
                  )}

                  <AccountPayableSuppliersPanelVendorSelect
                    supplier={supplier}
                    integrationName={integrationName}
                  />
                </>
              )}
            </>
          }
        />
      }
      footer={
        <Button
          fit="parent"
          text={
            supplier.isArchived === false
              ? t('bookkeep.accountsPayable.panel.archive')
              : t('bookkeep.accountsPayable.panel.unarchive')
          }
          variant="secondary"
          iconName={supplier.isArchived === false ? 'archive' : undefined}
          onClick={() => {
            if (supplier.isArchived === false) {
              onArchiveSupplier();
            } else {
              onUnarchiveSupplier();
            }

            history.push(
              routeFor(routes.COMPANY_ACCOUNTS_PAYABLE.path, {
                company,
              }),
            );
          }}
        />
      }
    >
      {(mustDisplayAccountsPayable || hasAutocatFeature) && (
        <AccountPayableSuppliersPanelAccountingSection
          integrationStatus={integrationStatus}
          supplier={supplier}
          mustDisplayAccountsPayable={mustDisplayAccountsPayable}
        />
      )}
      {supplier.bankDetails !== null && (
        <AccountPayableSuppliersPanelBankDetailsSection supplier={supplier} />
      )}
      {supplier.bankDetails !== null && (
        <AccountPayableSuppliersPanelBankProofSection supplier={supplier} />
      )}
      {supplier.legalDetails !== null && (
        <AccountPayableSuppliersPanelLegalDetailsSection supplier={supplier} />
      )}
    </Panel>
  );
};
