import { Icon } from '@dev-spendesk/grapes';

import { useJiraLink } from './useJiraLink';

export const JiraIssueReporter = () => {
  const defaultLink = useJiraLink();

  if (defaultLink === null) {
    return null;
  }

  return (
    <li>
      <button
        onClick={() => {
          window.open(defaultLink, '_blank');
        }}
        className="navigation-item h-xl"
        type="button"
      >
        <Icon
          name="service-bell"
          aria-hidden="true"
          size="m"
          className="shrink-0"
        />
        <span className="navigation-toggle-display grow">Report an issue</span>
      </button>
    </li>
  );
};
