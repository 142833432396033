import {
  Autocomplete,
  AutocompleteNoOptions,
  DropdownItem,
} from '@dev-spendesk/grapes';
import { useState } from 'react';
import { Trans } from 'react-i18next';

import { SupplierLogo } from 'common/components/SupplierLogo';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { useSearchSuppliers } from './hooks/useSuppliersQuery';

type Option = {
  key: string;
  label: string;
  thumbnailUrl?: string;
};

type Props = {
  value: Option | undefined;
  onSelect: (option: Option | undefined) => void;
};

export const RequestSupplierFilter = ({ value, onSelect }: Props) => {
  const { t } = useTranslation('global');
  const searchSuppliers = useSearchSuppliers();
  const [supplierOptions, setSupplierOptions] = useState<
    {
      key: string;
      label: string;
      thumbnailUrl?: string;
    }[]
  >([]);

  const getSupplierNamePrefix = (option: Option | undefined) => {
    return (
      <SupplierLogo src={option?.thumbnailUrl} name={option?.label} size="s" />
    );
  };

  return (
    <Autocomplete
      fit="parent"
      value={value}
      options={supplierOptions}
      onSearch={async (search) => {
        if (search) {
          const suppliers = await searchSuppliers(search);
          setSupplierOptions(suppliers);
        } else {
          setSupplierOptions([]);
        }
      }}
      onSelect={onSelect}
      renderNoOptions={(rawValue) => (
        <AutocompleteNoOptions>
          {rawValue === '' ? (
            t('misc.startTyping')
          ) : (
            <Trans
              i18nKey="misc.noOptions"
              values={{ account: rawValue }}
              // eslint-disable-next-line react/jsx-key
              components={[<span />]}
            />
          )}
        </AutocompleteNoOptions>
      )}
      renderOption={(option, optionState) => (
        <DropdownItem
          label={option.label}
          prefix={getSupplierNamePrefix(option)}
          {...optionState}
        />
      )}
      renderPrefix={(option: { key: string; label: string }) =>
        getSupplierNamePrefix(option)
      }
    />
  );
};
