import { FormField, TextInput } from '@dev-spendesk/grapes';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

type Props = {
  error?: string;
  name: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const CustomFieldNameFormField = ({ error, name, onChange }: Props) => {
  const { t } = useTranslation('global');

  return (
    <FormField
      label={t('customFields.creation.nameLabel')}
      alertMessage={error}
    >
      <TextInput
        name="name"
        onChange={onChange}
        placeholder={t('customFields.creation.namePlaceholder')}
        value={name}
      />
    </FormField>
  );
};
