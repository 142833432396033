import { Avatar, Icon, TimelineItem, colors } from '@dev-spendesk/grapes';

import { type I18nKey, useTranslation } from 'common/hooks/useTranslation';

import { SupplierChangeFieldValue } from './SupplierChangeFieldValue';
import {
  type SupplierDetailsField,
  type SupplierDetailsChange,
} from '../../models';

export type Props = {
  supplierChange: SupplierDetailsChange;
};

export const SupplierChangeTimelineItem = ({ supplierChange }: Props) => {
  const { t } = useTranslation('global');

  const fieldLabel = t(historyModalFieldsToI18nKeys[supplierChange.field]);

  return (
    <div className="text-left">
      <TimelineItem date={new Date(supplierChange.updatedAt)}>
        <section className="flex">
          {supplierChange.updater && (
            <Avatar
              size="l"
              className="ml-xs mr-m"
              src={supplierChange.updater.avatar}
              text={supplierChange.updater.name}
            />
          )}

          {!supplierChange.updater && (
            <div className="w-[88px] pl-xxs">
              <Icon
                name="supplier"
                color={colors.neutralLight}
                size="l"
                className="ml-[20px] mr-m mt-[12px]"
              />
            </div>
          )}

          <div>
            <div>
              {supplierChange.updater &&
                t('suppliers.latestUpdater.historyModal.changeDescription', {
                  field: fieldLabel,
                  username: supplierChange.updater.name,
                })}

              {!supplierChange.updater &&
                t(
                  'suppliers.latestUpdater.historyModal.integrationChangeDescription',
                  {
                    field: fieldLabel,
                  },
                )}
            </div>
            <div className="text-neutral body-s">
              <SupplierChangeFieldValue value={supplierChange.newValue} />
            </div>
            <div className="text-neutral body-s">
              <SupplierChangeFieldValue
                value={supplierChange.oldValue}
                strike
              />
            </div>
          </div>
        </section>
      </TimelineItem>
    </div>
  );
};

const historyModalFieldsToI18nKeys: Record<SupplierDetailsField, I18nKey> = {
  accountHolderName:
    'suppliers.latestUpdater.historyModal.fields.accountHolderName',
  accountCode: 'suppliers.latestUpdater.historyModal.fields.accountCode',
  accountNumber: 'suppliers.latestUpdater.historyModal.fields.accountNumber',
  bankCountry: 'suppliers.latestUpdater.historyModal.fields.bankCountry',
  bic: 'suppliers.latestUpdater.historyModal.fields.bic',
  iban: 'suppliers.latestUpdater.historyModal.fields.iban',
  routingNumber: 'suppliers.latestUpdater.historyModal.fields.routingNumber',
  sortCode: 'suppliers.latestUpdater.historyModal.fields.sortCode',
  legalName: 'suppliers.latestUpdater.historyModal.fields.legalName',
  registrationNumber:
    'suppliers.latestUpdater.historyModal.fields.registrationNumber',
  vatNumber: 'suppliers.latestUpdater.historyModal.fields.vatNumber',
  address: 'suppliers.latestUpdater.historyModal.fields.address',
  city: 'suppliers.latestUpdater.historyModal.fields.city',
  country: 'suppliers.latestUpdater.historyModal.fields.country',
  zipcode: 'suppliers.latestUpdater.historyModal.fields.zipcode',
};
