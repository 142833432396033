import { useTranslation } from 'common/hooks/useTranslation';

export function useGetErrorTranslation() {
  const { t } = useTranslation('global');

  return function getErrorTranslation(error: string): string | undefined {
    if (error === 'invalidBankInfo') {
      return t(
        'wiretransfer.confirmation.errorModal.knownErrors.invalidBankInfo',
      );
    }

    return undefined;
  };
}
