/* eslint-disable @typescript-eslint/no-explicit-any */

import { type ThunkAction, type ThunkDispatch } from '@reduxjs/toolkit';
import { startOfDay } from 'date-fns';
import get from 'lodash/get';

import { addNotification, NotificationType } from 'modules/app/notifications';
import { getSelectedCompanyId, getCompanyCurrency } from 'modules/company';
import { baseAPI, companyAPI } from 'src/core/api/axios';
import { type I18nKey } from 'src/core/common/hooks/useTranslation';
import i18n from 'src/core/config/i18n';

import * as achActions from './actions';
import { getAchSourceToken, getMfaProcedureId } from './selectors';
import { getAchSourceInformations } from '../helpers';
import {
  type AchAction,
  type ParsedCheckBankStatementFormValues,
  type ParsedCreateFundingRequestFormValues,
  type ParsedCreateAutomatedFundingRuleFormValues,
  type ParsedValidatePinFormValues,
  type MarqetaAchSources,
  AutomatedFundingRuleLoadingSteps,
  AutomatedFundingRuleCreateSteps,
} from '../types';

export const getAchFundingSources =
  (): ThunkAction<Promise<void>, any, null, AchAction> =>
  async (
    dispatch: ThunkDispatch<any, null, AchAction>,
    getState,
  ): Promise<void> => {
    const companyId = getSelectedCompanyId(getState());

    dispatch(achActions.getAchFundingSourcesLoading());

    let achSourceToken;
    let achSourceAccountSuffix = null;
    let hasPendingAch = false;
    let hasValidatedAch = false;
    let pendingAchCreatedAt = null;

    try {
      const response = await companyAPI.get(`funding-sources/ach`, {
        companyId,
      });

      const data = getAchSourceInformations(response.data as MarqetaAchSources);
      achSourceToken = data.achSourceToken;
      achSourceAccountSuffix = data.achSourceAccountSuffix;
      hasPendingAch = data.hasPendingAch;
      hasValidatedAch = data.hasValidatedAch;
      pendingAchCreatedAt = data.pendingAchCreatedAt;
    } catch (error) {
      dispatch(achActions.getAchFundingSourcesFailure(error));
      return;
    }

    dispatch(
      achActions.getAchFundingSourcesSuccess({
        hasPendingAch,
        hasValidatedAch,
        pendingAchCreatedAt,
        achSourceToken,
        achSourceAccountSuffix,
      }),
    );
  };

export const checkBankStatement =
  ({
    firstAmount,
    secondAmount,
  }: ParsedCheckBankStatementFormValues): ThunkAction<
    Promise<void>,
    any,
    null,
    AchAction
  > =>
  async (
    dispatch: ThunkDispatch<any, null, AchAction>,
    getState,
  ): Promise<void> => {
    const companyId = getSelectedCompanyId(getState());
    const achSourceToken = getAchSourceToken(getState());
    dispatch(achActions.checkBankStatementLoading());
    try {
      await companyAPI.post(
        `funding-sources/ach/verify`,
        {
          achSourceToken,
          firstAmount,
          secondAmount,
        },
        { companyId },
      );
    } catch (error) {
      dispatch(achActions.checkBankStatementFailure(error));
      return;
    }
    dispatch(achActions.checkBankStatementSuccess());
  };

export const createOneOffFundingRequest =
  ({
    amount,
  }: ParsedCreateFundingRequestFormValues): ThunkAction<
    Promise<void>,
    any,
    null,
    AchAction
  > =>
  async (
    dispatch: ThunkDispatch<any, null, AchAction>,
    getState,
  ): Promise<void> => {
    const state = getState();
    const companyId = getSelectedCompanyId(state);
    const currencyCode = getCompanyCurrency(state);
    const achSourceToken = getAchSourceToken(state);
    dispatch(achActions.createFundingRequestLoading());
    let mfaProcedureId = null;
    let res = null;
    try {
      res = await companyAPI.post(
        `funding-requests`,
        {
          amount,
          currencyCode,
          // this is renamed in the API because it's possible to be not ACH-related
          fundingSourceToken: achSourceToken,
        },
        { companyId },
      );
    } catch (error) {
      dispatch(achActions.createFundingRequestFailure(error));
      dispatch(
        addNotification({
          type: NotificationType.Danger,
          message: i18n.t('ach.fundingRequestError'),
        }),
      );
      return;
    }
    if (res.data.mfaType) {
      dispatch(achActions.setMfaType(res.data.mfaType));
    }
    if (res.data.mfaParams) {
      dispatch(achActions.setMfaParams(res.data.mfaParams));
    }
    if (res.data.mfaProcedureId) {
      mfaProcedureId = res.data.mfaProcedureId;
      dispatch(achActions.createFundingRequestSuccess(mfaProcedureId));
    } else {
      const errorCodeMessage: string = res.data.error
        ? i18n.t(achErrorToI18nKey(res.data.error))
        : '';
      const apiMessage: string = res.data.message || '';
      const error = new Error(errorCodeMessage || apiMessage);
      dispatch(achActions.createFundingRequestFailure(error));
      if (errorCodeMessage) {
        dispatch(
          addNotification({
            type: NotificationType.Danger,
            message: i18n.t('ach.fundingRequestErrorDetails', {
              details: errorCodeMessage,
            }),
          }),
        );
      } else {
        dispatch(
          addNotification({
            type: NotificationType.Danger,
            message: i18n.t('ach.fundingRequestError'),
          }),
        );
      }
    }
  };

export const createAutomatedFundingRule =
  ({
    amount,
    threshold,
  }: ParsedCreateAutomatedFundingRuleFormValues): ThunkAction<
    Promise<void>,
    any,
    null,
    AchAction
  > =>
  async (
    dispatch: ThunkDispatch<any, null, AchAction>,
    getState,
  ): Promise<void> => {
    const state = getState();
    const companyId = getSelectedCompanyId(state);
    const currencyCode = getCompanyCurrency(state);
    const achSourceToken = getAchSourceToken(state);
    dispatch(achActions.createAutomatedFundingRuleLoading());
    let mfaProcedureId = null;
    let res = null;
    try {
      res = await companyAPI.post(
        `automated-funding-rules`,
        {
          amount,
          threshold,
          currencyCode,
          // this is renamed in the API because it's possible to be not ACH-related
          fundingSourceToken: achSourceToken,
        },
        { companyId },
      );
    } catch (error) {
      dispatch(achActions.createAutomatedFundingRuleFailure(error));
      dispatch(
        addNotification({
          type: NotificationType.Danger,
          message: i18n.t('ach.automatedFundingRuleCreateError'),
        }),
      );
      return;
    }
    if (res.data.mfaType) {
      dispatch(achActions.setMfaType(res.data.mfaType));
    }
    if (res.data.mfaParams) {
      dispatch(achActions.setMfaParams(res.data.mfaParams));
    }
    if (res.data.mfaProcedureId) {
      mfaProcedureId = res.data.mfaProcedureId;
      dispatch(
        achActions.createAutomatedFundingRuleSuccess(
          mfaProcedureId,
          amount,
          threshold,
          startOfDay(new Date()).toISOString(),
        ),
      );
      dispatch(
        achActions.setWalletAutomatedFundingCreateRuleStep(
          AutomatedFundingRuleCreateSteps.AutomatedFundingRuleCreateModal,
        ),
      );
    } else {
      const errorCodeMessage: string = res.data.error
        ? i18n.t(achErrorToI18nKey(res.data.error))
        : '';
      const apiMessage: string = res.data.message || '';
      const error = new Error(errorCodeMessage || apiMessage);
      dispatch(achActions.createAutomatedFundingRuleFailure(error));
      if (errorCodeMessage) {
        dispatch(
          addNotification({
            type: NotificationType.Danger,
            message: i18n.t('ach.automatedFundingRuleCreateErrorDetails', {
              details: errorCodeMessage,
            }),
          }),
        );
      } else {
        dispatch(
          addNotification({
            type: NotificationType.Danger,
            message: i18n.t('ach.automatedFundingRuleCreateError'),
          }),
        );
      }
    }
  };

export const deleteAutomatedFundingRule =
  (): ThunkAction<Promise<void>, any, null, AchAction> =>
  async (
    dispatch: ThunkDispatch<any, null, AchAction>,
    getState,
  ): Promise<void> => {
    const state = getState();
    const companyId = getSelectedCompanyId(state);
    const achSourceToken = getAchSourceToken(state);
    dispatch(achActions.deleteAutomatedFundingRuleLoading());
    let res = null;
    try {
      res = await companyAPI.delete(`automated-funding-rules`, {
        companyId,
        achSourceToken,
      });
    } catch (error) {
      dispatch(achActions.deleteAutomatedFundingRuleFailure(error));
      dispatch(
        addNotification({
          type: NotificationType.Danger,
          message: i18n.t('ach.automatedFundingRuleDeleteError'),
        }),
      );
      return;
    }
    if (res.data && res.data.error) {
      const errorCodeMessage: string = res.data.error
        ? i18n.t(achErrorToI18nKey(res.data.error))
        : '';
      const apiMessage: string = res.data.message || '';
      const error = new Error(errorCodeMessage || apiMessage);
      dispatch(achActions.deleteAutomatedFundingRuleFailure(error));
      if (errorCodeMessage) {
        dispatch(
          addNotification({
            type: NotificationType.Danger,
            message: i18n.t('ach.automatedFundingRuleDeleteErrorDetails', {
              details: errorCodeMessage,
            }),
          }),
        );
      } else {
        dispatch(
          addNotification({
            type: NotificationType.Danger,
            message: i18n.t('ach.automatedFundingRuleDeleteError'),
          }),
        );
      }
    } else {
      dispatch(achActions.deleteAutomatedFundingRuleSuccess());
    }
  };

export const validateOneOffFundingRequestPin =
  ({
    pin,
  }: ParsedValidatePinFormValues): ThunkAction<
    Promise<void>,
    any,
    null,
    AchAction
  > =>
  async (
    dispatch: ThunkDispatch<any, null, AchAction>,
    getState,
  ): Promise<void> => {
    const state = getState();
    const mfaProcedureId = getMfaProcedureId(state);
    dispatch(achActions.validateOneOffFundingRequestPinLoading());
    try {
      await baseAPI.post(
        `/multifactor-authenticators/${mfaProcedureId}/validation?token=${pin}`,
      );
    } catch (error) {
      const reason = get(error, 'response.data.reason');
      if (reason === 'badToken' || reason === 'expired') {
        // FIXME: we should handle the case where this fail
        const res = await baseAPI.post(
          `/multifactor-authenticators/${mfaProcedureId}/regeneration`,
        );
        dispatch(achActions.setMfaProcedureId(res.data.id));
      }
      dispatch(achActions.validateOneOffFundingRequestPinFailure(error));
      dispatch(
        addNotification({
          type: NotificationType.Danger,
          message: i18n.t('ach.pinValidationError'),
        }),
      );
      return;
    }

    dispatch(achActions.validateOneOffFundingRequestPinSuccess());
  };

export const retryOneOffFundingRequestPin =
  (): ThunkAction<Promise<void>, any, null, AchAction> =>
  async (
    dispatch: ThunkDispatch<any, null, AchAction>,
    getState,
  ): Promise<void> => {
    const state = getState();
    const mfaProcedureId = getMfaProcedureId(state);
    try {
      const res = await baseAPI.post(
        `/multifactor-authenticators/${mfaProcedureId}/regeneration`,
      );
      dispatch(achActions.setMfaProcedureId(res.data.id));
    } catch {
      dispatch(
        addNotification({
          type: NotificationType.Danger,
          message: i18n.t('ach.pinRegenerationError'),
        }),
      );
    }
  };

export const validateAutomatedFundingRulePin =
  ({
    pin,
  }: ParsedValidatePinFormValues): ThunkAction<
    Promise<void>,
    any,
    null,
    AchAction
  > =>
  async (
    dispatch: ThunkDispatch<any, null, AchAction>,
    getState,
  ): Promise<void> => {
    const state = getState();
    const mfaProcedureId = getMfaProcedureId(state);
    dispatch(achActions.validateAutomatedFundingRulePinLoading());
    try {
      await baseAPI.post(
        `/multifactor-authenticators/${mfaProcedureId}/validation?token=${pin}`,
      );
    } catch (error) {
      const reason = get(error, 'response.data.reason');
      if (reason === 'badToken' || reason === 'expired') {
        // FIXME: we should handle the case where this fail
        const response = await baseAPI.post(
          `/multifactor-authenticators/${mfaProcedureId}/regeneration`,
        );
        dispatch(achActions.setMfaProcedureId(response.data.id));
      }
      dispatch(achActions.validateAutomatedFundingRulePinFailure(error));
      dispatch(
        addNotification({
          type: NotificationType.Danger,
          message: i18n.t('ach.pinValidationError'),
        }),
      );
      return;
    }
    dispatch(achActions.validateAutomatedFundingRulePinSuccess());
  };

export const retryAutomatedFundingRulePin =
  (): ThunkAction<Promise<void>, any, null, AchAction> =>
  async (
    dispatch: ThunkDispatch<any, null, AchAction>,
    getState,
  ): Promise<void> => {
    const state = getState();
    const mfaProcedureId = getMfaProcedureId(state);
    try {
      const response = await baseAPI.post(
        `/multifactor-authenticators/${mfaProcedureId}/regeneration`,
      );
      dispatch(achActions.setMfaProcedureId(response.data.id));
    } catch {
      dispatch(
        addNotification({
          type: NotificationType.Danger,
          message: i18n.t('ach.pinRegenerationError'),
        }),
      );
    }
  };

export const getGpaOrders =
  (): ThunkAction<Promise<void>, any, null, AchAction> =>
  async (
    dispatch: ThunkDispatch<any, null, AchAction>,
    getState,
  ): Promise<void> => {
    const companyId = getSelectedCompanyId(getState());
    dispatch(achActions.getGpaOrdersLoading());
    let gpaOrders;
    try {
      const response = await companyAPI.get(`gpa-orders`, {
        companyId,
      });

      gpaOrders = response.data;
    } catch (error) {
      dispatch(achActions.getGpaOrdersFailure(error));
      dispatch(
        addNotification({
          type: NotificationType.Danger,
          message: i18n.t('ach.gpaOrdersGetError'),
        }),
      );
      return;
    }

    dispatch(achActions.getGpaOrdersSuccess(gpaOrders));
  };

export const getAutomatedFundingRule =
  (): ThunkAction<Promise<void>, any, null, AchAction> =>
  async (
    dispatch: ThunkDispatch<any, null, AchAction>,
    getState,
  ): Promise<void> => {
    const companyId = getSelectedCompanyId(getState());
    const achSourceToken = getAchSourceToken(getState());
    dispatch(achActions.getAutomatedFundingRuleLoading());
    let automatedFundingRule;
    try {
      const response = await companyAPI.get(`automated-funding-rules`, {
        companyId,
        achSourceToken,
      });
      automatedFundingRule = {
        amount: response.data.amount,
        threshold: response.data.threshold,
        createdAt: new Date(response.data.createdAt),
      };
    } catch (error) {
      dispatch(achActions.getAutomatedFundingRuleFailure(error));
      dispatch(
        addNotification({
          type: NotificationType.Danger,
          message: i18n.t('ach.automatedFundingRuleGetError'),
        }),
      );
      return;
    }
    dispatch(achActions.getAutomatedFundingRuleSuccess(automatedFundingRule));
    dispatch(
      achActions.setWalletAutomatedFundingStep(
        AutomatedFundingRuleLoadingSteps.AutomatedFundingRuleLoaded,
      ),
    );
  };

const achErrorToI18nKey = (key: string): I18nKey => {
  switch (key) {
    case 'pinValidationError':
      return 'ach.pinValidationError';
    case 'pinRegenerationError':
      return 'ach.pinValidationError';
    case 'gpaOrdersGetError':
      return 'ach.pinValidationError';
    case 'fundingRequestError':
      return 'ach.pinValidationError';
    case 'fundingRequestErrorDetails':
      return 'ach.pinValidationError';
    case 'automatedFundingRuleGetError':
      return 'ach.pinValidationError';
    case 'automatedFundingRuleCreateError':
      return 'ach.pinValidationError';
    case 'automatedFundingRuleCreateErrorDetails':
      return 'ach.pinValidationError';
    case 'automatedFundingRuleDeleteError':
      return 'ach.pinValidationError';
    case 'automatedFundingRuleDeleteErrorDetails':
      return 'ach.pinValidationError';
    default:
      return 'misc.somethingWrong';
  }
};
