import { SkeletonText } from '@dev-spendesk/grapes';
import classnames from 'classnames';
import { fromNumber } from 'ezmoney';
import React from 'react';
import { Trans } from 'react-i18next';

import { QueryError } from 'common/components/QueryError';
import { QuerySuspense } from 'common/components/QuerySuspense';
import { useTranslation } from 'common/hooks/useTranslation';
import { useWalletTransactionForecastQuery } from 'modules/company';
import { useAreScheduledTransfersIncludedInWallet } from 'modules/company/wallet/hooks/useAreScheduledTransfersIncludedInWallet';
import { useQueryStates } from 'src/core/api/hooks/useQueryStates';
import { useCompanyCurrency } from 'src/core/modules/app/hooks/useCompanyCurrency';
import { formatMonetaryValue } from 'src/core/utils/monetaryValue';

import { useWalletTransactionForecastOnSelectedTransfersQuery } from '../../../../hooks';
import { selectors, useSelectorWithContext } from '../../../../redux';

type Props = {
  canApproveTransfers: boolean;
};

export const WalletBalance = ({ canApproveTransfers }: Props) => {
  const { t } = useTranslation('global');
  const companyCurrency = useCompanyCurrency();
  const transfersSelectedAmountSum = useSelectorWithContext(
    selectors.selectTransfersSelectedAmountSum,
  );

  const walletTransactionForecastOnSelectedTransfersQueryState =
    useWalletTransactionForecastOnSelectedTransfersQuery()();

  const walletTransactionForecastOnAvailableAllocationQueryState =
    useWalletTransactionForecastQuery()(
      fromNumber(0, companyCurrency, 2),
      'availableForNewRequests',
    );

  const walletTransactionForecastOnAvailableAllocationWithoutScheduledTransfersQueryState =
    useWalletTransactionForecastQuery()(
      transfersSelectedAmountSum,
      'availableForNewRequests',
    );

  const areScheduledTransfersIncludedInWalletState =
    useAreScheduledTransfersIncludedInWallet();

  const queryStates = useQueryStates({
    states: {
      walletTransactionForecastOnSelectedTransfers:
        walletTransactionForecastOnSelectedTransfersQueryState,
      walletTransactionForecastOnAvailableAllocation:
        walletTransactionForecastOnAvailableAllocationQueryState,
      walletTransactionForecastOnAvailableAllocationWithoutScheduledTransfers:
        walletTransactionForecastOnAvailableAllocationWithoutScheduledTransfersQueryState,
      areScheduledTransfersIncludedInWallet:
        areScheduledTransfersIncludedInWalletState,
    },
  });

  return (
    <QuerySuspense
      queryState={queryStates}
      loading={<SkeletonText />}
      fallback={(error) => (
        <QueryError
          queryError={error}
          componentType="Notification"
          translations={{
            serverError:
              'invoices.transfer.walletInformation.balanceQueryError',
            requestError: () =>
              'invoices.transfer.walletInformation.balanceQueryError',
          }}
        />
      )}
    >
      {({
        walletTransactionForecastOnSelectedTransfers,
        walletTransactionForecastOnAvailableAllocation,
        walletTransactionForecastOnAvailableAllocationWithoutScheduledTransfers,
        areScheduledTransfersIncludedInWallet,
      }) => {
        const transactionForecastAvailableForNewRequests =
          areScheduledTransfersIncludedInWallet
            ? walletTransactionForecastOnAvailableAllocation
            : walletTransactionForecastOnAvailableAllocationWithoutScheduledTransfers;

        return walletTransactionForecastOnSelectedTransfers.hasEnoughFunds ? (
          <p className="body-s">
            <Trans
              i18nKey="invoices.transfer.walletInformation.balance"
              values={{
                balance: formatMonetaryValue(
                  walletTransactionForecastOnAvailableAllocation.walletAmount,
                ),
              }}
              components={[
                // eslint-disable-next-line react/jsx-key
                <strong
                  className={classnames('body-m', {
                    'text-success':
                      transactionForecastAvailableForNewRequests.hasEnoughFunds,
                    'text-warning':
                      !transactionForecastAvailableForNewRequests.hasEnoughFunds,
                  })}
                />,
              ]}
            />
          </p>
        ) : (
          <p className="text-warning body-m">
            {t('invoices.transfer.walletInformation.insufficientFunds', {
              context: canApproveTransfers ? undefined : 'controller',
            })}
          </p>
        );
      }}
    </QuerySuspense>
  );
};
