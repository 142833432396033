import { Button, Modal } from '@dev-spendesk/grapes';
import { type Dispatch, type SetStateAction } from 'react';

import { type DefaultSupplierAccount } from 'modules/bookkeep/settings/accounting/supplierAccount';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

type ConfirmDeleteSupplierAccountModalProps = {
  setShowModal: Dispatch<SetStateAction<boolean>>;
  showModal: boolean;
  defaultSupplierAccount: DefaultSupplierAccount[];
  handleDisableDefaultSupplierAccount: () => void;
};

export const ConfirmDisableDefaultSupplierAccountModal = ({
  setShowModal,
  showModal,
  defaultSupplierAccount,
  handleDisableDefaultSupplierAccount,
}: ConfirmDeleteSupplierAccountModalProps) => {
  const { t } = useTranslation('global');

  return (
    <Modal
      title={
        defaultSupplierAccount[0] &&
        defaultSupplierAccount[0].defaultFor === 'cardSupplier'
          ? t(
              'bookkeep.integrations.settings.supplierAccountsTable.confirmDefaultCardDisableTitle',
            )
          : t(
              'bookkeep.integrations.settings.supplierAccountsTable.confirmDefaultInvoiceDisableTitle',
            )
      }
      isOpen={showModal}
      iconVariant="alert"
      iconName="trash"
      actions={[
        <Button
          key="cancel"
          onClick={() => setShowModal(false)}
          text={t('misc.cancel')}
          variant="secondary"
        />,
        <Button
          key="yes"
          id="confirm-delete-supplier-account"
          onClick={() => handleDisableDefaultSupplierAccount()}
          text={t(
            'bookkeep.integrations.settings.supplierAccountsTable.confirmDelition',
          )}
          variant="alert"
        />,
      ]}
    />
  );
};
