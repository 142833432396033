import React from 'react';

import { useNotifications, NotificationType } from 'modules/app/notifications';
import {
  useCostCentersQuery,
  useInvalidateCostCentersQuery,
} from 'modules/budgets/apis';
import { toApiFormat as toApiApprovalRules } from 'modules/company/structure/approval-flows/models';
import { unwrapQuery } from 'src/core/api/unwrapQuery';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';
import { AnalyticEventName, track } from 'src/core/utils/analytics';

import { createApprovalScheme } from './hooks/createApprovalScheme';
import { createCostCenter } from './hooks/createCostCenterQuery';
import { CostCenterFormModal } from '../../components/CostCenterFormModal';
import type { Member } from '../../member';

type Props = {
  members: Member[];
  isOpen: boolean;
  showBackButton?: boolean;
  onComplete(): void;
  onCancel(): void;
};

export const CostCenterCreationFormModalContainer = ({
  members,
  isOpen,
  onComplete,
  onCancel,
  showBackButton = false,
}: Props) => {
  const { t } = useTranslation('global');
  const companyId = useCompanyId();
  const { pushNotif } = useNotifications();
  const invalidateCostCentersQuery = useInvalidateCostCentersQuery();

  const costCentersQueryState = useCostCentersQuery();
  const costCenters = unwrapQuery(costCentersQueryState) ?? [];

  if (!isOpen) {
    return null;
  }

  return (
    <CostCenterFormModal
      showBackButton={showBackButton}
      members={members}
      costCenters={costCenters}
      onSubmit={async (costCenter) => {
        track(AnalyticEventName.APPROVALS_WORKFLOW_SETUP_SUBMIT);
        try {
          const response = await createCostCenter(
            {
              name: costCenter.name,
              ownerId: costCenter.ownerId,
              viewerIds: costCenter.viewerIds,
            },
            companyId,
          );

          await createApprovalScheme(
            {
              owner: {
                type: 'costCenter',
                id: response.id,
              },
              rules: toApiApprovalRules(costCenter.approvalRules),
            },
            companyId,
          );
          invalidateCostCentersQuery();
          pushNotif({
            type: NotificationType.Success,
            message: t('costCenters.creation.success', {
              name: costCenter.name,
            }),
          });
          onComplete();
        } catch {
          pushNotif({
            type: NotificationType.Danger,
            message: t('costCenters.creation.error'),
          });
        }
      }}
      onCancel={onCancel}
    />
  );
};
