import React from 'react';
import { useParams } from 'react-router-dom';

import { SiderPageLayout } from 'src/core/common/components/layout';
import { routes, routeFor } from 'src/core/constants/routes';
import history from 'src/core/history';

import styles from './PayablesAllPage.module.css';
import { GraphQLProvider } from '../../components/GraphQLProvider';
import { PayableListContainer } from '../containers/PayableListContainer';
import { PayablePanelContainer } from '../containers/PayablePanelContainer';
import { PayablesFiltersContainer } from '../containers/PayablesFiltersContainer';
import { FiltersContextProvider } from '../containers/PayablesFiltersContainer/hooks';

export const PayablesAllPage = () => {
  const { id: payableId, company: companyId }: { id: string; company: string } =
    useParams();

  const goToPayable = (id: string = '') => {
    history.push(
      `${routeFor(`${routes.PAYABLES_ALL.path}`, { id, company: companyId })}${
        history.location.search
      }`,
    );
  };

  const panel = payableId && (
    <PayablePanelContainer
      payableId={payableId}
      onClose={() => goToPayable()}
    />
  );

  // @TODO: Not sure GraphQL is needed here, check if it can be removed
  return (
    <GraphQLProvider>
      <div className={styles.layout}>
        <FiltersContextProvider>
          <div className={styles.pageContent}>
            <PayablesFiltersContainer />

            <div className="flex flex-1 overflow-hidden">
              <SiderPageLayout panel={panel}>
                <PayableListContainer
                  payableId={payableId}
                  onClick={goToPayable}
                />
              </SiderPageLayout>
            </div>
          </div>
        </FiltersContextProvider>
      </div>
    </GraphQLProvider>
  );
};
