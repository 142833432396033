import { Callout } from '@dev-spendesk/grapes';
import React from 'react';

import { QuerySuspense } from 'common/components/QuerySuspense';
import { useTranslation } from 'common/hooks/useTranslation';
import { PaymentContext } from 'modules/invoices/hooks';
import { useQueryStates } from 'src/core/api/hooks/useQueryStates';
import { useIsCompanyOnSfsUk } from 'src/core/modules/company/hooks';

import { TransferList } from './TransferList';
import { TransferListErrorState } from './TransferListErrorState';
import { TransferListSkeleton } from './TransferListSkeleton';
import { useFetchTransfers, FetchTransfersCountApi } from '../../hooks';
import { type Transfer, type TransferId } from '../../models';
import { MAX_PAYMENTS_PER_BATCH } from '../TransferBatchLimitModal/TransferBatchLimitModal';

type Props = {
  activeTransferId: TransferId;
  canApproveTransfers: boolean;
  onRowClick(transfer: Transfer): void;
};

export const TransferListContainer = (props: Props) => {
  const { type } = PaymentContext.useContext();
  const transfersQueryState = useFetchTransfers({ type });
  const transferCountQueryState = FetchTransfersCountApi.useQuery({ type });
  const isUkCompany = useIsCompanyOnSfsUk();
  const { t } = useTranslation('global');
  const queryStates = useQueryStates({
    states: {
      transferCount: transferCountQueryState,
      transfers: transfersQueryState,
    },
    reshapeData: (data) => ({
      transfersCount: data.transferCount,
      transfers: data.transfers,
    }),
  });

  return (
    <QuerySuspense
      queryState={queryStates}
      loading={<TransferListSkeleton />}
      fallback={() => <TransferListErrorState />}
    >
      {({ transfersCount, transfers }) => (
        <>
          {props.canApproveTransfers && isUkCompany && (
            <Callout
              className="mb-xl mt-xl"
              title={t(
                'expenseClaims.confirm.limitedUkBatchConfirmCallout.title',
                { count: MAX_PAYMENTS_PER_BATCH },
              )}
              variant="warning"
              iconName="clock"
            >
              {t(
                'expenseClaims.confirm.limitedUkBatchConfirmCallout.description',
                { count: MAX_PAYMENTS_PER_BATCH },
              )}
            </Callout>
          )}
          <TransferList
            transfers={transfers}
            totalTransfersCount={transfersCount}
            hasNextPage={transfersQueryState.hasNextPage}
            fetchNextPage={transfersQueryState.fetchNextPage}
            {...props}
          />
        </>
      )}
    </QuerySuspense>
  );
};
