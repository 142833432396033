import { Redirect } from 'react-router';

import { HeaderWithNavigation } from 'src/core/common/components/HeaderWithNavigation/HeaderWithNavigation';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { routes } from 'src/core/constants/routes';
import { AccountPayableSuppliers } from 'src/core/modules/bookkeep/accounts-payable/AccountPayableSuppliers';
import { AccountPayableSuppliersListLoader } from 'src/core/modules/bookkeep/accounts-payable/pages/AccountPayableSuppliersList/AccountPayableSuppliersListLoader';
import { useIntegrationStatusQuery } from 'src/core/modules/bookkeep/hooks';
import { isIntegrationStatusWithIntegration } from 'src/core/modules/bookkeep/integration/status';

export const SettingsAccountsPayable = () => {
  const { t } = useTranslation('global');
  const integrationStatusQueryState = useIntegrationStatusQuery();
  if (
    integrationStatusQueryState.status === 'loading' ||
    integrationStatusQueryState.status === 'error'
  ) {
    // There is already a loading skeleton in the withIntegrationAccounting HOC
    return (
      <div className="page__container">
        <HeaderWithNavigation links={[]}>
          {t('settings.links.accountsPayable')}
        </HeaderWithNavigation>
        <div className="grow bg-page-background p-m">
          <AccountPayableSuppliersListLoader />
        </div>
      </div>
    );
  }

  const status = integrationStatusQueryState.data;

  if (isIntegrationStatusWithIntegration(status)) {
    return (
      <div className="page__container">
        <HeaderWithNavigation links={[]}>
          {t('settings.links.accountsPayable')}
        </HeaderWithNavigation>
        <div className="grow bg-page-background p-xl">
          <AccountPayableSuppliers integrationStatus={status} />
        </div>
      </div>
    );
  }

  return <Redirect to={routes.NOT_FOUND.path} />;
};
