import { Button, Callout, Modal } from '@dev-spendesk/grapes';
import { type FormikErrors, useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { useEffect } from 'react';

import { useTranslation } from 'common/hooks/useTranslation';

import type { CustomFieldCreationForm } from '../../containers/CustomFieldEditionModalContainer';
import { CustomFieldInfoStep } from '../CustomFieldModalInfo/CustomFieldModalInfo';

type Props = {
  isOpen: boolean;
  formValues: CustomFieldCreationForm;
  onClose(): void;
  onSubmit(values: CustomFieldCreationForm): Promise<void>;
};

export const CustomFieldEditionModal = ({
  isOpen,
  formValues,
  onClose,
  onSubmit,
}: Props) => {
  const { t } = useTranslation('global');

  const formikProps = useFormik<CustomFieldCreationForm>({
    enableReinitialize: true,
    initialValues: formValues,
    validate: (values) => {
      const error: FormikErrors<CustomFieldCreationForm> = {};
      if (isEmpty(values.name)) {
        error.name = t('customFields.creation.nameRequired');
      }
      return error;
    },
    onSubmit,
  });

  useEffect(() => {
    // clear input manually as the modal is "always opened" due to the isOpen state
    formikProps.resetForm();
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      title={t('customFields.edition.title')}
      iconName="plus-circle"
      actions={[
        <Button
          key="cancel-button"
          text={t('misc.cancel')}
          variant="secondary"
          onClick={onClose}
        />,
        <Button
          key="submit-button"
          text={t('customFields.edition.submit')}
          onClick={() => formikProps.handleSubmit()}
        />,
      ]}
    >
      <CustomFieldInfoStep formikProps={formikProps} />
      <Callout
        className="mt-s text-left"
        title={t('customFields.edition.callout')}
      />
    </Modal>
  );
};
