import { Button, Callout } from "@dev-spendesk/grapes";
import { useFormik } from "formik";
import { useQueryClient } from "react-query";

import { toApiFormat as toApiApprovalRules } from 'modules/company/structure/approval-flows/models';
import { useQueryStates } from "src/core/api/hooks/useQueryStates";
import { QueryError } from "src/core/common/components/QueryError";
import { QuerySuspense } from "src/core/common/components/QuerySuspense";
import { useTranslation } from "src/core/common/hooks/useTranslation";
import { useCompanyId } from "src/core/modules/app/hooks/useCompanyId";
import { NotificationType, useNotifications } from "src/core/modules/app/notifications";
import { editApprovalScheme } from "src/core/modules/company/cost-centers/containers/CostCenterFormModalContainer/hooks/editApprovalSchemeQuery";
import { ApprovalRulesContainer, type ApprovalRule } from 'src/core/modules/company/structure/approval-flows';

import { Loading } from "./Loading";
import { validateForm } from "./validate";
import { useApprovalSchemeQuery } from "../../hooks/useApprovalScheme";
import { useMembersQuery } from "../../hooks/useMembersQuery";

export type FormProps = {
  approvalRules: ApprovalRule[];
};

export const ApprovalSchemeEdition = ({
  approvalSchemeId,
  onClose,
}: {
  approvalSchemeId: string;
  onClose: () => void;
}) => {
  const companyId = useCompanyId();
  const { t } = useTranslation('global');
  const queryClient = useQueryClient();
  const { pushNotif } = useNotifications();
  const queryStates = useQueryStates({
    states: {
      approvalScheme: useApprovalSchemeQuery(approvalSchemeId),
      members: useMembersQuery(),
    },
  });
  const {
    values: formValues,
    errors,
    setFieldValue,
    handleSubmit,
    isSubmitting,
  } = useFormik<FormProps>({
    initialValues: {
      approvalRules:
        queryStates.status === 'success'
          ? queryStates.data.approvalScheme.rules
          : [],
    },
    validate: (values) => validateForm(values, t),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        await editApprovalScheme({
          companyId,
          payload: {
            rules: toApiApprovalRules(values.approvalRules),
          },
          approvalSchemeId,
        });
      } catch (error) {
        pushNotif({
          type: NotificationType.Danger,
          message: 'Failed to update approval flow',
        });
        throw new Error('Failed to update approval flow', error);
      }
      setSubmitting(false);
      onClose();
      queryClient.invalidateQueries(['approval-scheme', approvalSchemeId]);
      pushNotif({
        type: NotificationType.Success,
        message: 'Approval flow updated',
      });
    },
  });

  return (
    <>
      <div>
        <QuerySuspense
          loading={<Loading />}
          fallback={(error) => (
            <QueryError queryError={error} componentType="Callout" />
          )}
          queryState={queryStates}
        >
          {({ members }) => {
            return (
              <ApprovalRulesContainer
                rules={formValues.approvalRules}
                members={members}
                onChange={(rules) => {
                  setFieldValue('approvalRules', rules);
                }}
                mode="approvalByDimensionsPanel"
              />
            );
          }}
        </QuerySuspense>
      </div>
      {errors.approvalRules && typeof errors.approvalRules === 'string' && (
        <Callout variant="alert" title={errors.approvalRules} />
      )}
      <div className="mt-auto flex justify-between gap-s pt-m">
        <Button
          text="Save"
          className="flex-grow"
          variant="primary"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
          onClick={() => {
            handleSubmit();
          }}
        />
        <Button
          text="Cancel"
          className="flex-grow"
          variant="secondary"
          onClick={() => {
            onClose();
          }}
        />
      </div>
    </>
  );
};
