import { useFormik } from 'formik';

import { useCompany } from 'modules/app/hooks/useCompany';
import { useNotifications, NotificationType } from 'modules/app/notifications';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import { CompanyInformationForm } from './CompanyInformationForm';
import { type CompanyInformationFormValues } from './form';
import { type CompanyInformation } from '../../../../models';
import { useUpdateCompanyInformationMutation } from '../../hooks/useUpdateCompanyInformationMutation';
import { validateAddress } from '../AddressForm';

type Props = {
  companyInformation: CompanyInformation;
};

export const CompanyInformationFormContainer = ({
  companyInformation,
}: Props) => {
  const company = useCompany();
  const { t } = useTranslation('global');
  const { pushNotif } = useNotifications();
  const [updateCompanyInformation] = useUpdateCompanyInformationMutation();

  const formikProps = useFormik<CompanyInformationFormValues>({
    initialValues: {
      address: companyInformation.address ?? '',
      address2: companyInformation.address2 ?? '',
      city: companyInformation.city ?? '',
      country: companyInformation.country ?? '',
      name: companyInformation.name ?? '',
      zipcode: companyInformation.zipcode ?? '',
    },
    enableReinitialize: true,
    validateOnChange: false,
    validate: async (values) => {
      return validateAddress(values);
    },
    onSubmit: async (values) => {
      try {
        updateCompanyInformation({
          ...company,
          address: values.address.trim(),
          address2: values.address2.trim(),
          city: values.city.trim(),
          country: values.country.trim(),
          name: values.name.trim(),
          zipcode: values.zipcode.trim(),
        });
      } catch {
        pushNotif({
          type: NotificationType.Danger,
          message: t(
            'generalSettings.companyInformation.errorSaveCompanyInformation',
          ),
        });
        return;
      }

      pushNotif({
        type: NotificationType.Success,
        message: t(
          'generalSettings.companyInformation.successSaveCompanyInformation',
        ),
      });
    },
  });

  return <CompanyInformationForm {...formikProps} />;
};
