import { Button, Callout, GridContainer, GridItem } from '@dev-spendesk/grapes';
import React from 'react';

import { useTranslation } from 'common/hooks/useTranslation';
import { useUserRoles } from 'modules/app/hooks/useUserRoles';
import { useGoToPaymentMethods } from 'modules/company/general-settings';
import { track, AnalyticEventName } from 'src/core/utils/analytics';

import { Block } from './Block';
import BinocularWoman from './binocular-woman.svg';
import Calculator from './calculator.svg';
import Pot from './pot.svg';

export const EnableTransfersForInvoices = () => {
  const { t } = useTranslation('global');
  const goToPaymentMethods = useGoToPaymentMethods();
  const { isAccountOwner } = useUserRoles();

  return (
    <section className="m-m flex h-full flex-col items-center justify-center gap-xxl">
      <h1 className="max-w-[650px] text-center text-complementary title-xl">
        {t('invoices.transfer.featureDisabled.title')}
      </h1>
      <main>
        <GridContainer>
          <GridItem columnSpan={4}>
            <Block
              imgSrc={BinocularWoman}
              title={t('invoices.transfer.featureDisabled.blockA.title')}
              description={t(
                'invoices.transfer.featureDisabled.blockA.description',
              )}
            />
          </GridItem>
          <GridItem columnSpan={4}>
            <Block
              imgSrc={Pot}
              title={t('invoices.transfer.featureDisabled.blockB.title')}
              description={t(
                'invoices.transfer.featureDisabled.blockB.description',
              )}
            />
          </GridItem>
          <GridItem columnSpan={4}>
            <Block
              imgSrc={Calculator}
              title={t('invoices.transfer.featureDisabled.blockC.title')}
              description={t(
                'invoices.transfer.featureDisabled.blockC.description',
              )}
            />
          </GridItem>
        </GridContainer>
      </main>
      <div className="flex justify-center">
        {isAccountOwner ? (
          <Button
            variant="primary"
            text={t('invoices.transfer.featureDisabled.action')}
            onClick={() => {
              track(
                AnalyticEventName.INVOICES_CONFIRM_TRY_WIRE_TRANSFER_FEATURE_BUTTON_CLICKED,
              );
              goToPaymentMethods();
            }}
          />
        ) : (
          <Callout
            variant="info"
            title={t('invoices.transfer.featureDisabled.actionController')}
          />
        )}
      </div>
    </section>
  );
};
