import { useUser } from 'src/core/modules/app/hooks/useUser';

export function useAccessPaymentsAll() {
  const user = useUser();
  return (
    user.has_plastic_card ||
    user.is_account_owner ||
    user.is_controller ||
    user.is_requester ||
    user.is_group_admin
  );
}
