import { Modal, Button, Callout } from '@dev-spendesk/grapes';
import React, { useMemo } from 'react';
import { Trans } from 'react-i18next';

import {
  type I18nKey,
  useTranslation,
} from 'src/core/common/hooks/useTranslation';

import {
  type ImportBudgetRequestError,
  type InvalidFileErrorDetails,
} from './useImportBudgetMutation';

type Props = {
  onRetry(): void;
  onCancel(): void;
  error: ImportBudgetRequestError;
};

const MAX_RENDERED_CALLOUTS = 5;
const MAX_LISTED_ITEMS = 3;

export const ImportBudgetModalError = ({ onCancel, onRetry, error }: Props) => {
  const { t } = useTranslation('global');

  const modalContent = useMemo(() => {
    if (error.error === 'costCentersNotSet') {
      return {
        title: t(
          'budget.budgetaryExercise.importModal.errors.costCentersNotSet.title',
        ),
        message: t(
          'budget.budgetaryExercise.importModal.errors.costCentersNotSet.message',
        ),
      };
    }
    if (error.error === 'fileInvalid') {
      return {
        title: t(
          'budget.budgetaryExercise.importModal.errors.fileInvalid.title',
        ),
        message: t(
          'budget.budgetaryExercise.importModal.errors.fileInvalid.message',
        ),
      };
    }

    return {
      title: t('budget.budgetaryExercise.importModal.errors.default.title'),
      message: t('budget.budgetaryExercise.importModal.errors.default.message'),
    };
  }, [error]);

  const formatExtraItems = (count: number): string => {
    return t(
      'budget.budgetaryExercise.importModal.errors.fileInvalid.calloutBody.remainingLabel',
      { count },
    );
  };

  const renderExtraNodes = (count: number) => (
    <p className="pt-xs text-alert body-m">{formatExtraItems(count)}</p>
  );

  const renderErrorDetails = (errorDetails: InvalidFileErrorDetails[]) => {
    if (errorDetails[0].reason === 'unreadableFile') {
      return (
        <Callout
          variant="alert"
          title={t(
            'budget.budgetaryExercise.importModal.errors.fileInvalid.calloutTitles.unreadableFile',
          )}
        />
      );
    }

    const fileStructureErrors = errorDetails.filter(
      (x) => x.reason === 'fileStructure',
    );
    if (fileStructureErrors.length > 0) {
      return shortenListOfErrorNodes(
        fileStructureErrors,
        renderFileStructureError,
        MAX_RENDERED_CALLOUTS,
        renderExtraNodes,
      );
    }

    const dataValidationErrors = errorDetails.filter(
      (x) => x.reason === 'dataValidation',
    );
    if (dataValidationErrors.length > 0) {
      const errorDetailsPerWorksheet =
        groupErrorDetailsByWorksheetName(dataValidationErrors);

      return shortenListOfErrorNodes(
        errorDetailsPerWorksheet,
        renderCalloutForDataValidationErrorByWorksheet,
        MAX_RENDERED_CALLOUTS,
        renderExtraNodes,
      );
    }
  };

  const renderFileStructureError = (
    errorDetails: InvalidFileErrorDetails,
  ): React.ReactNode => {
    if (errorDetails.reason !== 'fileStructure') {
      return undefined;
    }

    const { addedValues, removedValues, subreason, worksheetName } =
      errorDetails;

    const liKey = (worksheetName ?? subreason).toLowerCase();
    const groups: ['expected' | 'actual', string[]][] = [
      ['expected', removedValues],
      ['actual', addedValues],
    ];

    return (
      <Callout
        variant="alert"
        title={t(fileStructureToI18nKey[subreason], { name: worksheetName })}
      >
        <ul className="list-disc px-s text-alert-light">
          {groups
            .filter(([_, values]) => values.length > 0)
            .map(([key, values]) => {
              const items = formatListOfItems(
                values,
                MAX_LISTED_ITEMS,
                formatExtraItems,
              );

              return (
                <li key={`${liKey}--${key}`}>
                  <Trans
                    i18nKey={
                      calloutBodySubReasonToI18nKey(subreason, key) as string
                    }
                    values={{ count: values.length, items }}
                  />
                </li>
              );
            })}
        </ul>
      </Callout>
    );
  };

  const renderDataValidationError = (errorDetails: InvalidFileErrorDetails) => {
    if (errorDetails.reason !== 'dataValidation') {
      return null;
    }
    return (
      <li key={`${errorDetails.worksheetName}--${errorDetails.cellPosition}`}>
        <Trans
          i18nKey={calloutBodyToI18nKey[errorDetails.subreason] as string}
          values={{
            cellPosition: errorDetails.cellPosition,
          }}
        />
      </li>
    );
  };

  const renderCalloutForDataValidationErrorByWorksheet = (
    tuple: [name: string, errors: InvalidFileErrorDetails[]],
  ) => {
    const [name, errors] = tuple;

    const listItems = shortenListOfErrorNodes(
      errors,
      renderDataValidationError,
      MAX_LISTED_ITEMS,
      renderExtraNodes,
    );

    return (
      <Callout
        variant="alert"
        title={t(
          `budget.budgetaryExercise.importModal.errors.fileInvalid.calloutTitles.dataValidation`,
          { name },
        )}
      >
        <ul className="list-disc px-s text-alert-light">{listItems}</ul>
      </Callout>
    );
  };

  return (
    <Modal
      isOpen
      iconName="failure"
      iconVariant="alert"
      title={modalContent.title}
      actions={[
        <Button
          key="no"
          onClick={onCancel}
          text={t('misc.cancel')}
          variant="secondary"
        />,
        <Button
          key="yes"
          text={t('misc.tryAgain')}
          variant="primary"
          onClick={onRetry}
        />,
      ]}
    >
      <div className="flex flex-col gap-xs">
        <p>{modalContent.message}</p>
        {error.error === 'fileInvalid' && (error.details ?? []).length > 0 && (
          <div className="flex flex-col gap-xs">
            <h4>
              {t(
                'budget.budgetaryExercise.importModal.errors.fileInvalid.detailsHeading',
              )}
            </h4>
            <div
              className="flex flex-col gap-xs"
              data-testid="BudgetImportDetailedFailures"
            >
              {renderErrorDetails(error.details)}
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

const groupErrorDetailsByWorksheetName = (list: InvalidFileErrorDetails[]) =>
  Array.from(
    list.reduce((map, error) => {
      if ('worksheetName' in error && error.worksheetName) {
        if (map.has(error.worksheetName)) {
          map.get(error.worksheetName)?.push(error);
        } else {
          map.set(error.worksheetName, [error]);
        }
      }

      return map;
    }, new Map<string, InvalidFileErrorDetails[]>()),
  );

export const formatListOfItems = (
  items: unknown[],
  maxItems: number,
  formatPlaceholder: (count: number) => string,
  separator = ', ',
): string => {
  const shorterList = items.slice(0, maxItems);
  const remainingCount = items.length - maxItems;

  const placeholderForHiddenItems =
    remainingCount > 0 ? formatPlaceholder(remainingCount) : undefined;

  return [shorterList.join(separator), placeholderForHiddenItems]
    .filter(Boolean)
    .join(' ');
};

export const shortenListOfErrorNodes = <T,>(
  initialItems: T[],
  errorRenderer: (item: T) => React.ReactNode,
  maxItems: number,
  renderExtraNodes: (count: number) => React.ReactNode,
) => {
  const shortenedNodes = initialItems.slice(0, maxItems).map(errorRenderer);

  const extraCount = initialItems.length - maxItems;
  if (extraCount > 0) {
    shortenedNodes.push(renderExtraNodes(extraCount));
  }

  return shortenedNodes;
};

const fileStructureToI18nKey: Record<
  'worksheetDiscrepancies' | 'rowDiscrepancies' | 'columnDiscrepancies',
  I18nKey
> = {
  worksheetDiscrepancies:
    'budget.budgetaryExercise.importModal.errors.fileInvalid.calloutTitles.fileStructure.worksheetDiscrepancies',
  rowDiscrepancies:
    'budget.budgetaryExercise.importModal.errors.fileInvalid.calloutTitles.fileStructure.rowDiscrepancies',
  columnDiscrepancies:
    'budget.budgetaryExercise.importModal.errors.fileInvalid.calloutTitles.fileStructure.columnDiscrepancies',
};

const calloutBodyToI18nKey: Record<
  'invalidValue' | 'inconsistentValues',
  I18nKey
> = {
  invalidValue:
    'budget.budgetaryExercise.importModal.errors.fileInvalid.calloutBody.invalidValue',
  inconsistentValues:
    'budget.budgetaryExercise.importModal.errors.fileInvalid.calloutBody.inconsistentValues',
};

const calloutBodySubReasonToI18nKey = (
  subreason:
    | 'worksheetDiscrepancies'
    | 'rowDiscrepancies'
    | 'columnDiscrepancies',
  variant: 'expected' | 'actual',
): I18nKey => {
  const record: Record<
    'worksheetDiscrepancies' | 'rowDiscrepancies' | 'columnDiscrepancies',
    I18nKey
  > = {
    worksheetDiscrepancies:
      variant === 'expected'
        ? 'budget.budgetaryExercise.importModal.errors.fileInvalid.calloutBody.worksheetDiscrepancies.expected'
        : 'budget.budgetaryExercise.importModal.errors.fileInvalid.calloutBody.worksheetDiscrepancies.actual',
    rowDiscrepancies:
      variant === 'expected'
        ? 'budget.budgetaryExercise.importModal.errors.fileInvalid.calloutBody.rowDiscrepancies.expected'
        : 'budget.budgetaryExercise.importModal.errors.fileInvalid.calloutBody.rowDiscrepancies.actual',
    columnDiscrepancies:
      variant === 'expected'
        ? 'budget.budgetaryExercise.importModal.errors.fileInvalid.calloutBody.columnDiscrepancies.expected'
        : 'budget.budgetaryExercise.importModal.errors.fileInvalid.calloutBody.columnDiscrepancies.actual',
  };

  return record[subreason];
};
