import { Button, Modal } from '@dev-spendesk/grapes';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

type WelcomeContentProps = {
  isOpen: boolean;
  onContinue: () => void;
  onClose: () => void;
};

export const ModalWireTransferWelcome = ({
  isOpen,
  onContinue,
  onClose,
}: WelcomeContentProps) => {
  const { t } = useTranslation('global');

  return (
    <Modal
      actions={[
        <Button
          key="cancel"
          onClick={onClose}
          text={t('misc.cancel')}
          variant="secondary"
        />,
        <Button
          key="continue"
          text={t('wiretransfer.modal.welcome.button')}
          variant="primary"
          onClick={onContinue}
        />,
      ]}
      iconName="swap"
      iconVariant="primary"
      title={t('wiretransfer.modal.welcome.title')}
      isOpen={isOpen}
    >
      <p>{t('wiretransfer.modal.welcome.desc')}</p>
    </Modal>
  );
};
