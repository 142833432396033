import {
  type AccountPayable,
  type EmployeeAccountCode,
  type TaxAccountUpdate,
} from 'modules/bookkeep';
import { type SupplierAccount } from 'modules/bookkeep/accounts-payable/types';
import { type QueryState } from 'src/core/api/queryState';
import { type I18nKey } from 'src/core/common/hooks/useTranslation';
import { routeFor, routes } from 'src/core/constants/routes';

import {
  type AccountingSoftware,
  hasIntegrationFileBasedExport,
} from '../../../../integration/status';

export function getCodeInUseLinkForAccountsPayable(
  errorState: {
    error:
      | 'codeAlreadyExists'
      | 'invalidPattern'
      | 'required'
      | 'unknown'
      | 'none';
    existingAccount?: AccountPayable;
  },
  companyId: string,
): string {
  if (
    errorState?.error !== 'codeAlreadyExists' ||
    !errorState.existingAccount
  ) {
    // This shouldn't happen: can't delete without confirmation
    throw new Error('Trying to get a link when not error');
  }

  if (errorState.existingAccount.kind === 'employeeAccount') {
    return routeFor(routes.COMPANY_ACCOUNTING_EMPLOYEE_ACCOUNTS.path, {
      company: companyId,
    });
  }

  if (errorState.existingAccount.isDefault) {
    return routeFor(routes.COMPANY_ACCOUNTING_SUPPLIER_ACCOUNTS.path, {
      company: companyId,
    });
  }

  return routeFor(routes.COMPANY_ACCOUNTS_PAYABLE_SUPPLIERS.path, {
    company: companyId,
    accountPayableId: errorState.existingAccount.id,
  });
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export function validateAccountPayable(
  generalAccountCode: string,
  auxiliaryAccountCode: string | undefined,
  accountLength: number,
  supplierAccountsQueryState: QueryState<SupplierAccount[], unknown>,
  employeeAccountCodesQueryState: QueryState<EmployeeAccountCode[], unknown>,
  integration: AccountingSoftware,
  auxiliaryAccountsEnabled: boolean,
):
  | {
      outcome: 'valid';
    }
  | {
      outcome: 'invalid';
      reason: 'invalidPattern';
    }
  | {
      outcome: 'invalid';
      reason: 'required';
    }
  | {
      outcome: 'invalid';
      reason: 'codeAlreadyExists';
      existingAccount: AccountPayable;
    } {
  if (hasIntegrationFileBasedExport(integration)) {
    if (generalAccountCode.trim().length === 0) {
      return {
        outcome: 'invalid',
        reason: 'required',
      };
    }
  } else {
    const accountPayableRegex = new RegExp(`^[1-9]\\d{${accountLength - 1}}$`);

    if (!accountPayableRegex.test(generalAccountCode)) {
      return {
        outcome: 'invalid',
        reason: 'invalidPattern',
      };
    }
  }

  const checkCodeAlreadyExist = (
    accounts: EmployeeAccountCode[] | EmployeeAccountCode[],
    kind: 'supplierAccount' | 'employeeAccount',
  ):
    | undefined
    | {
        outcome: 'invalid';
        reason: 'codeAlreadyExists';
        existingAccount: AccountPayable;
      } => {
    const existingAccountPayable = auxiliaryAccountsEnabled
      ? accounts.find(
          (account) =>
            account.generalAccountCode === generalAccountCode &&
            account.auxiliaryAccountCode === auxiliaryAccountCode,
        )
      : accounts.find(
          (account) => account.generalAccountCode === generalAccountCode,
        );

    if (existingAccountPayable) {
      return {
        outcome: 'invalid',
        reason: 'codeAlreadyExists',
        existingAccount: {
          ...existingAccountPayable,
          kind,
        },
      };
    }
  };

  if (supplierAccountsQueryState.status === 'success') {
    const result = checkCodeAlreadyExist(
      supplierAccountsQueryState.data,
      'supplierAccount',
    );
    if (result) {
      return result;
    }
  }

  if (employeeAccountCodesQueryState.status === 'success') {
    const result = checkCodeAlreadyExist(
      employeeAccountCodesQueryState.data,
      'employeeAccount',
    );
    if (result) {
      return result;
    }
  }

  return {
    outcome: 'valid',
  };
}

export function validateExpenseAccount(code: string):
  | {
      outcome: 'valid';
    }
  | {
      outcome: 'invalid';
      reason: 'required';
    } {
  if (code.trim().length === 0) {
    return {
      outcome: 'invalid',
      reason: 'required',
    };
  }

  return {
    outcome: 'valid',
  };
}

export function validateTaxAccount(taxAccount: TaxAccountUpdate):
  | {
      outcome: 'valid';
    }
  | {
      outcome: 'invalid';
      reason:
        | 'required'
        | 'codeRequired'
        | 'rateRequired'
        | 'negativeTaxRate'
        | 'taxRateExceedsMaximum';
    } {
  const isRateRequired = taxAccount.rate.trim().length === 0;
  const isCodeRequired = taxAccount.code.trim().length === 0;
  if (isRateRequired && isCodeRequired) {
    return {
      outcome: 'invalid',
      reason: 'required',
    };
  }
  if (isRateRequired) {
    return {
      outcome: 'invalid',
      reason: 'rateRequired',
    };
  }
  if (isCodeRequired) {
    return {
      outcome: 'invalid',
      reason: 'codeRequired',
    };
  }

  // This is not really needed since the input attributes enforces the value range
  // but we get those reasons from the backend validation anyway, so better stay aligned
  if (Number.parseFloat(taxAccount.rate) < 0) {
    return {
      outcome: 'invalid',
      reason: 'negativeTaxRate',
    };
  }
  if (Number.parseFloat(taxAccount.rate) > 100) {
    return {
      outcome: 'invalid',
      reason: 'taxRateExceedsMaximum',
    };
  }

  return {
    outcome: 'valid',
  };
}

export function getSupplierHelpCenterLink(
  integration: AccountingSoftware,
): string {
  // FIXME: currently we only show help articles for datev, we don't show the link in integrations
  switch (integration) {
    case 'Datev':
      return 'https://helpcenter.spendesk.com/articles/5556281-set-up-supplier-accounts';
    default:
      return '';
  }
}

export const accountKindToI18nKey: Record<
  'supplierAccount' | 'employeeAccount',
  I18nKey
> = {
  supplierAccount:
    'bookkeep.integrations.settings.supplierAccountsTable.supplierAccount',
  employeeAccount:
    'bookkeep.integrations.settings.supplierAccountsTable.employeeAccount',
};
