import { type TaxAccount } from 'modules/bookkeep/settings/accounting';
import { useDisplayReverseChargeQuery } from 'modules/bookkeep/settings/integrations/hooks/useDisplayReverseChargeQuery';
import { type QueryState } from 'src/core/api/queryState';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { rejectUnexpectedValue } from 'src/core/utils/switchGuard';

import styles from './TaxAccountPullSection.module.css';
import { DefaultTaxAccountPullTable } from './common/CommonTaxAccountPullTable';
import { NetsuiteReverseChargePullTable } from './netsuite/NetsuiteReverseChargePullTable';
import { NetsuiteTaxAccountPullTable } from './netsuite/NetsuiteTaxAccountPullTable';
import { type AccountingSoftware } from '../../../../../../../integration/status';
import {
  getVatAccountsDescriptionI18nKey,
  getReverseChargeAccountsDescriptionI18nKey,
} from '../translations';
import { integrationSupportsReverseCharge } from '../utils';

interface Props {
  taxAccountQueryState: QueryState<TaxAccount[]>;
  integration: AccountingSoftware;
}

export const TaxAccountPullSection = ({
  integration,
  taxAccountQueryState,
}: Props) => {
  const { t } = useTranslation();
  const displayReverseChargeQuery = useDisplayReverseChargeQuery();
  return (
    <>
      <div id="vat-accounts">
        <div className={styles.header}>
          <div className={styles.headerText}>
            <h3 className="IntegrationAccountingPage__section-title title-xl">
              {t('bookkeep.integrations.settings.sectionTaxAccounts')}
            </h3>
            <p className="IntegrationAccountingPage__section-description body-m">
              {t(getVatAccountsDescriptionI18nKey(integration))}
            </p>
            {renderTaxAccountTable(integration, taxAccountQueryState)}
          </div>
        </div>
      </div>

      {displayReverseChargeQuery &&
        integrationSupportsReverseCharge(integration) && (
          <div
            id="reverse-charge-accounts"
            className={styles.reverseChargeSection}
          >
            <div className={styles.header}>
              <div className={styles.headerText}>
                <h3 className="IntegrationAccountingPage__section-title title-xl">
                  {t(
                    'bookkeep.integrations.settings.sectionReverseChargeAccounts',
                  )}
                </h3>
                <p className="IntegrationAccountingPage__section-description body-m">
                  {t(getReverseChargeAccountsDescriptionI18nKey(integration))}
                </p>
                {renderReverseChargeTable(integration, taxAccountQueryState)}
              </div>
            </div>
          </div>
        )}
    </>
  );
};

function renderTaxAccountTable(
  integration: AccountingSoftware,
  taxAccountQueryState: QueryState<TaxAccount[]>,
) {
  switch (integration) {
    case 'Xero':
    case 'Datev':
    case 'SpendeskAccounting':
    case 'Sage':
    case 'Cegid':
    case 'SpendeskAccountingSingleEntry':
    case 'Quickbooks':
    case 'Sage100':
    case 'ACD':
    case 'Odoo':
    case 'ExactOnlineFr':
      return (
        <DefaultTaxAccountPullTable
          taxAccountQueryState={taxAccountQueryState}
        />
      );
    case 'Netsuite':
      return (
        <NetsuiteTaxAccountPullTable
          taxAccountQueryState={taxAccountQueryState}
        />
      );
    default:
      rejectUnexpectedValue('render reverse charge table', integration);
  }
}

function renderReverseChargeTable(
  integration: AccountingSoftware,
  taxAccountQueryState: QueryState<TaxAccount[]>,
) {
  switch (integration) {
    case 'Xero':
    case 'Datev':
    case 'SpendeskAccounting':
    case 'Sage':
    case 'Cegid':
    case 'SpendeskAccountingSingleEntry':
    case 'Quickbooks':
    case 'Sage100':
    case 'ACD':
    case 'Odoo':
    case 'ExactOnlineFr':
      return <></>;
    case 'Netsuite':
      return (
        <NetsuiteReverseChargePullTable
          taxAccountQueryState={taxAccountQueryState}
        />
      );
    default:
      rejectUnexpectedValue('render reverse charge table', integration);
  }
}
