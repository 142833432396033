import { type FormikErrors } from 'formik';

import { type TGlobalFunctionTyped } from 'src/core/common/hooks/useTranslation';

export type FormValues = {
  accountPayableId: string;
  bankCountry: string;
  legalName: string;
  name: string;
};

export const validate = (
  values: FormValues,
  suppliers: { name: string; isArchived: boolean }[],
  t: TGlobalFunctionTyped,
) => {
  const { name, legalName, bankCountry, accountPayableId } = values;

  const errors: FormikErrors<FormValues> = {};

  if (!name.trim()) {
    errors.name = t('bookkeep.accountsPayable.createSupplier.nameError');
  }

  // Only checking non-archived suppliers
  // because we will un-archive the supplier if it already exists
  const isNameAlreadyUsed = suppliers.some(
    (supplier) =>
      !supplier.isArchived && supplier.name === name.trim().toLocaleLowerCase(),
  );
  if (isNameAlreadyUsed) {
    errors.name = t('bookkeep.accountsPayable.createSupplier.nameExistsError');
  }

  if (!legalName.trim()) {
    errors.legalName = t(
      'bookkeep.accountsPayable.createSupplier.legalNameError',
    );
  }

  if (!bankCountry) {
    errors.bankCountry = t(
      'bookkeep.accountsPayable.panel.bankDetailsSection.bankCountryError',
    );
  }

  if (!accountPayableId) {
    errors.accountPayableId = t(
      'bookkeep.accountsPayable.createAccountPayable.errors.required',
    );
  }

  return errors;
};
