import { Button, Modal, Callout } from '@dev-spendesk/grapes';
import cx from 'classnames';
import { useReducer, type Reducer } from 'react';

import { useTranslation } from 'src/core/common/hooks/useTranslation';

import IllustrationImport from './illustration-import.svg';
import IllustrationManual from './illustration-manual.svg';
import './CostCenterCreationMethodSelectionModal.css';

type CostCenterCreationType = 'import' | 'manual';

type Props = {
  onSelect(type: CostCenterCreationType): void;
  onCancel(): void;
  isOpen: boolean;
};

type Action = 'selectImport' | 'selectManual' | 'showError';

type State = {
  method: CostCenterCreationType | undefined;
  error: boolean;
};

const reducer: Reducer<State, Action> = (state, action) => {
  switch (action) {
    case 'selectImport':
      return { method: 'import', error: false };
    case 'selectManual':
      return { method: 'manual', error: false };
    case 'showError':
      return { method: undefined, error: true };
    default:
      return state;
  }
};

export const CostCenterCreationMethodSelectionModal = ({
  onSelect,
  onCancel,
  isOpen,
}: Props) => {
  const { t } = useTranslation('global');

  const [{ method, error }, dispatch] = useReducer(reducer, {
    method: undefined,
    error: false,
  });

  return (
    <Modal
      isOpen={isOpen}
      iconName="plus-circle"
      iconVariant="primary"
      title={t('costCenters.creationMethodSelection.title')}
      subtitle={t('costCenters.creationMethodSelection.subtitle')}
      actions={[
        <Button
          key="cancel-button"
          onClick={() => onCancel()}
          text={t('misc.cancel')}
          variant="secondary"
        />,
        <Button
          key="continue-button"
          text={t(`misc.continue`)}
          iconName="caret-right"
          iconPosition="right"
          variant="primary"
          onClick={() => {
            if (!method) {
              return dispatch('showError');
            }

            onSelect(method);
          }}
        />,
      ]}
    >
      <div className="flex justify-between">
        <button
          type="button"
          onClick={() => dispatch('selectManual')}
          className={cx('CostCenterCreationMethodSelectionModal__selection', {
            selected: method === 'manual',
          })}
        >
          <img
            width="119"
            height="95"
            alt=""
            src={IllustrationManual}
            className="my-m"
          />
          <h1 className="text-complementary title-m">
            {t('costCenters.creationMethodSelection.manual.title')}
          </h1>
          <p className="mx-s mb-m body-m">
            {t('costCenters.creationMethodSelection.manual.subtitle')}
          </p>
        </button>
        <button
          type="button"
          onClick={() => dispatch('selectImport')}
          className={cx('CostCenterCreationMethodSelectionModal__selection', {
            selected: method === 'import',
          })}
        >
          <img
            width="128"
            height="95"
            alt=""
            src={IllustrationImport}
            className="my-m"
          />
          <h1 className="text-complementary title-m">
            {t('costCenters.creationMethodSelection.import.title')}
          </h1>
          <p className="mx-s mb-m body-m">
            {t('costCenters.creationMethodSelection.import.subtitle')}
          </p>
        </button>
      </div>
      {error && (
        <Callout
          className="mt-s"
          variant="alert"
          title={t('costCenters.creationMethodSelection.error')}
        />
      )}
    </Modal>
  );
};
