import { Button } from '@dev-spendesk/grapes';
import { useState } from 'react';

import { type IntegrationStatus } from 'modules/bookkeep/integration/status';
import { useTranslation } from 'src/core/common/hooks/useTranslation';

import integrationAccountingPath from './assets/integration-accounting.svg';
import { AccountingBaseInput } from './components/AccountingBaseInput';
import { AccountingBaseSelect } from './components/AccountingBaseSelect';
import { AccountingBaseSwitchOptions } from './components/AccountingBaseSwitchOptions';
import { getAccountingIntegrationDetails } from '../../../integrations/helper';
import './AccountingBasePage.css';

export function AccountingBasePage({
  isDefaultOpen = false,
  integrationStatus,
}: {
  isDefaultOpen?: boolean;
  integrationStatus: IntegrationStatus;
}) {
  const { t } = useTranslation('global');
  const [isOpen, setIsOpen] = useState(isDefaultOpen);
  const { logoPath: spendeskDoubleEntryPath } =
    getAccountingIntegrationDetails('SpendeskAccounting');
  const { logoPath: sagePath } = getAccountingIntegrationDetails('Sage');
  const { logoPath: cegidPath } = getAccountingIntegrationDetails('Cegid');

  const renderSection = () => {
    if (integrationStatus.integration === 'noIntegration') {
      return (
        <>
          <div className="AccountingBasePage__feature-description">
            <h3 className="AccountingBasePage__feature-title title-l">
              {t('bookkeep.integrations.settings.featureTitle')}
            </h3>
            <p className="AccountingBasePage__feature-content body-m">
              {t('bookkeep.integrations.settings.featureContent')}
            </p>
          </div>
          <div className="AccountingBasePage__feature-actions">
            <Button
              variant="primary"
              text={t('bookkeep.integrations.settings.featureCta')}
              onClick={() => setIsOpen(true)}
            />
          </div>
        </>
      );
    }
    if (integrationStatus.integration === 'SpendeskAccounting') {
      return (
        <>
          <div className="AccountingBasePage__feature-description">
            <h3 className="AccountingBasePage__feature-title title-l">
              {t('bookkeep.integrations.settings.doubleEntryTitle')}
            </h3>
            <p className="AccountingBasePage__feature-content body-m">
              {t('bookkeep.integrations.settings.doubleEntryContent')}
            </p>
          </div>
          <div className="AccountingBasePage__feature-actions">
            <AccountingBaseInput
              value={integrationStatus.integration}
              onClick={() => setIsOpen(true)}
            />
          </div>
          <div className="AccountingBasePage__feature-switch body-s">
            <AccountingBaseSwitchOptions
              firstIcon={sagePath}
              secondIcon={cegidPath}
              firstOption="SpendeskAccountingSingleEntry"
              secondOption="Sage"
              thirdOption="Cegid"
            />
          </div>
        </>
      );
    }
    if (integrationStatus.integration === 'SpendeskAccountingSingleEntry') {
      return (
        <>
          <h3 className="AccountingBasePage__feature-title title-l">
            {t('bookkeep.integrations.settings.singleEntryTitle')}
          </h3>
          <p className="AccountingBasePage__feature-content body-m">
            {t('bookkeep.integrations.settings.singleEntryContent')}
          </p>
          <div className="AccountingBasePage__feature-actions">
            <AccountingBaseInput
              value={integrationStatus.integration}
              onClick={() => setIsOpen(true)}
            />
          </div>
          <div className="AccountingBasePage__feature-switch body-s">
            <AccountingBaseSwitchOptions
              firstIcon={sagePath}
              secondIcon={cegidPath}
              firstOption="SpendeskAccounting"
              secondOption="Sage"
              thirdOption="Cegid"
            />
          </div>
        </>
      );
    }
    if (integrationStatus.integration === 'Sage') {
      return (
        <>
          <h3 className="AccountingBasePage__feature-title title-l">
            {t('bookkeep.integrations.settings.sageTitle')}
          </h3>
          <p className="AccountingBasePage__feature-content body-m">
            {t('bookkeep.integrations.settings.sageContent')}
          </p>
          <div className="AccountingBasePage__feature-actions">
            <AccountingBaseInput
              value={integrationStatus.integration}
              onClick={() => setIsOpen(true)}
            />
          </div>
          <div className="AccountingBasePage__feature-switch body-s">
            <AccountingBaseSwitchOptions
              firstIcon={spendeskDoubleEntryPath}
              secondIcon={cegidPath}
              firstOption="SpendeskAccounting"
              secondOption="SpendeskAccountingSingleEntry"
              thirdOption="Cegid"
            />
          </div>
        </>
      );
    }
    if (integrationStatus.integration === 'Cegid') {
      return (
        <>
          <h3 className="AccountingBasePage__feature-title title-l">
            {t('bookkeep.integrations.settings.cegidTitle')}
          </h3>
          <p className="AccountingBasePage__feature-content body-m">
            {t('bookkeep.integrations.settings.cegidContent')}
          </p>
          <div className="AccountingBasePage__feature-actions">
            <AccountingBaseInput
              value={integrationStatus.integration}
              onClick={() => setIsOpen(true)}
            />
          </div>
          <div className="AccountingBasePage__feature-switch body-s">
            <AccountingBaseSwitchOptions
              firstIcon={spendeskDoubleEntryPath}
              secondIcon={sagePath}
              firstOption="SpendeskAccounting"
              secondOption="SpendeskAccountingSingleEntry"
              thirdOption="Sage"
            />
          </div>
        </>
      );
    }

    return null;
  };

  return (
    <div className="AccountingBasePage" data-testid="accounting-base-page">
      <div
        className="AccountingBasePage__feature shadow-10"
        data-testid="accounting-base-page-feature"
      >
        <div>
          <img src={integrationAccountingPath} alt="integration accounting" />
        </div>
        <div data-testid="accounting-base-switch-status-select" className="p-m">
          {integrationStatus.integration !== 'switchInProgress'
            ? renderSection()
            : null}
          <AccountingBaseSelect
            initialIntegrationStatus={integrationStatus}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            onClose={() => setIsOpen(false)}
          />
        </div>
      </div>
    </div>
  );
}
