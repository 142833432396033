import { Callout, FormField, TextInput } from '@dev-spendesk/grapes';

import { type SelectableBankAccount } from 'modules/bookkeep';
import { BankAccountAdvice } from 'modules/bookkeep/components/AccountAdvice';
import { type IntegrationStatusWithIntegration } from 'modules/bookkeep/integration/status';
import { AutocompleteNoOptions } from 'src/core/common/components/AutocompleteNoOptions';
import { AutocompleteSearch } from 'src/core/common/components/AutocompleteSearch';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { rejectUnexpectedValue } from 'src/core/utils/switchGuard';

import styles from './BankAccountsSection.module.css';
import { type ListAccount } from './listAccount';

type EditBankAccountFormProps = {
  integrationStatus: IntegrationStatusWithIntegration;
  editedAccount: ListAccount;
  allAccounts: SelectableBankAccount[];
  setEditedAccount: (account: ListAccount) => void;
  showError: boolean;
  isError: boolean;
  warning?: string;
  accountsToFilter?: SelectableBankAccount[];
};

export const EditBankAccountForm = (props: EditBankAccountFormProps) => {
  const { t } = useTranslation('global');
  const {
    integrationStatus,
    editedAccount,
    setEditedAccount,
    accountsToFilter,
    showError,
    isError,
    warning,
  } = props;
  switch (editedAccount.capability) {
    case 'pullAndSelect': {
      const reshapedAccounts = props.allAccounts
        .filter((account) =>
          accountsToFilter && accountsToFilter.length
            ? !accountsToFilter.some((v) => v.id === account.id)
            : true,
        )
        .map((account) => ({
          key: account.id,
          label: computeAccountLabel({ integrationStatus, account }),
        }));
      const selectedAccount = reshapedAccounts.find(
        (account) => account.key === editedAccount.accountId,
      );

      // TODO handle the "no option" case
      return (
        <FormField
          label={editedAccount.name}
          htmlFor="set-account-autocomplete-input"
        >
          <AutocompleteSearch
            name="set-account-autocomplete-input"
            placeholder={t(
              'bookkeep.integrations.settings.sectionBankAccounts',
            )}
            fit="parent"
            value={selectedAccount}
            options={reshapedAccounts}
            renderNoOptions={(rawValue) => (
              <AutocompleteNoOptions value={rawValue} />
            )}
            onSelect={(account) =>
              setEditedAccount({
                ...editedAccount,
                accountId: account ? account.key : null,
              })
            }
          />
        </FormField>
      );
    }
    case 'localOnly':
      return (
        <>
          <FormField
            label={t('bookkeep.integrations.settings.editBankCodeLabel', {
              bankAccountName: editedAccount.name,
            })}
            htmlFor="set-account-input"
            alertMessage={
              isError
                ? t('bookkeep.integrations.settings.bankAccountRequiredError')
                : undefined
            }
          >
            <TextInput
              value={editedAccount.code}
              onChange={(e) =>
                setEditedAccount({ ...editedAccount, code: e.target.value })
              }
              id="set-account-input"
              isInvalid={isError}
            />
          </FormField>
          {warning && (
            <Callout
              className={styles.editBankAccountCodeWarning}
              variant="warning"
              title={warning}
            />
          )}
          <BankAccountAdvice
            className={styles.formCallout}
            showError={showError}
            integrationStatus={integrationStatus}
          />
        </>
      );
    default:
      rejectUnexpectedValue('editedAccount', editedAccount);
  }
};

function computeAccountLabel({
  integrationStatus,
  account,
}: {
  integrationStatus: IntegrationStatusWithIntegration;
  account: SelectableBankAccount;
}) {
  switch (integrationStatus.integration) {
    case 'Sage100':
    case 'ACD':
    case 'Odoo':
    case 'ExactOnlineFr':
      return `${account.code} - ${account.name}`;
    default:
      return account.name;
  }
}
