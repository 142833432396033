import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { rejectUnexpectedValue } from 'src/core/utils/switchGuard';

import './TaxAccountLocalOnlySection.module.css';

export const TableFooter = ({ type }: { type: 'vat' | 'reverseCharge' }) => {
  const { t } = useTranslation('global');

  switch (type) {
    case 'vat': {
      return (
        <div>
          <h3 className="title-m">
            {t('bookkeep.integrations.settings.noVatAccountTitle')}
          </h3>
        </div>
      );
    }
    case 'reverseCharge':
      return (
        <div>
          <h3 className="title-m">
            {t('bookkeep.integrations.settings.noReverseChargeAccountTitle')}
          </h3>
        </div>
      );
    default:
      rejectUnexpectedValue('taxAccountTableFooter', type);
  }

  return null;
};
