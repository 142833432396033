import { createAction } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { ErrorBoundary } from 'common/components/withErrorBoundary';
import { useModal } from 'common/hooks/useModalGrapes';
import { useTranslation } from 'common/hooks/useTranslation';
import { UPDATE_FEATURE_SET } from 'modules/app/layout/redux/actionTypes';
import { useNotifications } from 'modules/app/notifications';
import FEATURES from 'src/core/constants/features';

import { useMigrateToCostCenters } from './hooks/useMigrateToCostCenters';
import { useMigrationStatus } from './hooks/useMigrationStatus';
import {
  CostCenterActivationConfirmationModal,
  CostCenterActivationInitialModal,
  CostCenterActivationProcessingModal,
  CostCenterActivationErrorModal,
} from '../../components/CostCenterActivationModals';
import { CostCentersActivationPage } from '../../components/CostCentersActivationPage';

export const CostCentersActivationPageContainer = () => {
  const { t } = useTranslation('global');
  const dispatch = useDispatch();
  const { pushNotif } = useNotifications();
  const migrationStatusQueryState = useMigrationStatus();
  const [migrateToCostCenters] = useMigrateToCostCenters();

  const handleStartMigration = async () => {
    hideConfirmationModal();
    showProcessingModal();
    try {
      await migrateToCostCenters();
    } catch {
      hideProcessingModal();
      showErrorModal();
    }
  };

  const handleCompleteMigration = () => {
    dispatch(
      createAction<Record<string, boolean>>(UPDATE_FEATURE_SET)({
        [FEATURES.COST_CENTERS_ACTIVATED]: true,
        [FEATURES.TEAMS]: false,
      }),
    );
  };

  const [setupGuideModal, showSetupGuideModal, hideSetupGuideModal] = useModal(
    ({ isOpen }: { isOpen: boolean }) => (
      <CostCenterActivationInitialModal
        isOpen={isOpen}
        onCloseModal={() => hideSetupGuideModal()}
        onDone={() => {
          hideSetupGuideModal();
          showConfirmationModal();
        }}
      />
    ),
  );

  const [confirmationModal, showConfirmationModal, hideConfirmationModal] =
    useModal(({ isOpen }: { isOpen: boolean }) => (
      <CostCenterActivationConfirmationModal
        isOpen={isOpen}
        onConfirm={handleStartMigration}
        onCloseModal={() => hideConfirmationModal()}
      />
    ));

  const [processingModal, showProcessingModal, hideProcessingModal] = useModal(
    ({ isOpen }: { isOpen: boolean }) => (
      <CostCenterActivationProcessingModal isOpen={isOpen} />
    ),
  );

  const [errorModal, showErrorModal, hideErrorModal] = useModal(
    ({ isOpen }: { isOpen: boolean }) => (
      <CostCenterActivationErrorModal
        isOpen={isOpen}
        onCloseModal={() => hideErrorModal()}
      />
    ),
  );

  const showModalDependingOnStatus = () => {
    if (migrationStatusQueryState.status !== 'success') {
      return;
    }
    switch (migrationStatusQueryState.data.status) {
      case 'migratable':
        showSetupGuideModal();
        return;
      case 'pending':
      case 'inProgress':
        showProcessingModal();
        return;
      case 'migratedByUser':
      case 'migratedByStaff': {
        handleCompleteMigration();
        pushNotif({ message: t('costCenters.activation.success') });
        return;
      }
      default:
        hideProcessingModal();
        showErrorModal();
    }
  };

  useEffect(() => {
    const isModalDisplayed =
      setupGuideModal.props.isOpen ||
      confirmationModal.props.isOpen ||
      processingModal.props.isOpen ||
      errorModal.props.isOpen;
    // only update the migration flow content (i.e. the opened modal) depending
    // on the migration status change if there is already one modal opened
    if (isModalDisplayed) {
      showModalDependingOnStatus();
    }
  }, [
    migrationStatusQueryState.status === 'success'
      ? migrationStatusQueryState.data?.status
      : undefined,
  ]);

  return (
    <ErrorBoundary
      context={{ team: 'finance-controller', scope: 'cost-centers-activation' }}
    >
      {setupGuideModal}
      {confirmationModal}
      {processingModal}
      {errorModal}
      <CostCentersActivationPage
        isDisabled={migrationStatusQueryState.status !== 'success'}
        onCreateClick={showModalDependingOnStatus}
      />
    </ErrorBoundary>
  );
};
