import { requestMfaProcedureSuccess } from 'common/components/ModalMFA/actions';
import type { AppDispatch } from 'modules/app/redux/store';
import { apiV2Url } from 'src/core/utils/api';

type Response = {
  procedureId: string;
};

export const postShowPaymentPassword = async (
  // Dispatching is only used for the SMS fallback flow.
  dispatch?: AppDispatch,
  useSmsFallback?: boolean,
): Promise<Response> => {
  const response = await fetch(apiV2Url('/cards/payment-password/show'), {
    method: 'POST',
    credentials: 'include',
    headers: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ useSmsFallback: !!useSmsFallback }),
  });
  if (response.status === 200) {
    const responsePayload = await response.json();

    if (useSmsFallback) {
      // Dispatching so that it would be used by ModalMFAToken
      dispatch(
        requestMfaProcedureSuccess({
          id: responsePayload.procedureId,
          factorId: responsePayload.factorId,
        }),
      );
    }

    return responsePayload;
  }
  throw new Error('Show Payment password failed');
};
