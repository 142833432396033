import { useQuery, type UseQueryResult } from 'react-query';

import { type QueryError } from 'src/core/api/queryError';
import { type QueryState } from 'src/core/api/queryState';
import { apiV2Url } from 'src/core/utils/api';

import { useCompany } from '../../app/hooks/useCompany';
import { type SpendLimitStatus } from '../models/spendLimit';

export const useSpendLimitStatusByRequestQuery = ({
  requestId,
  enabled,
}: {
  requestId: string;
  enabled: boolean;
}): QueryState<SpendLimitStatus> => {
  const company = useCompany();
  const result = useQuery(
    ['spend-limits', 'status', 'by-request', requestId],
    async (): Promise<SpendLimitStatus> => {
      const response = await fetch(
        apiV2Url(`/spend-limits/status/by-request/${requestId}`, company.id),
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Content-Type': 'application/json',
          },
        },
      );
      if (!response.ok) {
        throw new Error('Failed to fetch spend limit status');
      }
      return response.json();
    },
    {
      enabled,
      staleTime: 60 * 1000,
      cacheTime: 5 * 60 * 1000,
      retry: false,
      refetchOnWindowFocus: false,
    },
  );
  return mapQueryResultToQueryState(result);
};

const mapQueryResultToQueryState = <T>(
  result: UseQueryResult<T>,
): QueryState<T> => {
  if (result.status === 'loading') {
    return { status: 'loading' };
  }
  if (result.status === 'error') {
    return { status: 'error', error: result.error as QueryError<unknown> };
  }
  return { status: 'success', data: result.data as T };
};
