import {
  ReactFlow,
  Controls,
  Background,
  BackgroundVariant,
} from '@xyflow/react';
import { useEffect } from 'react';
import '@xyflow/react/dist/style.css';

import { useWorkflowContext } from '../../WorkflowContext';
import { WorkflowProvider } from '../../WorkflowProvider';
import { edgeTypes } from '../../edge-types';
import { useApprovalWorkflowQuery } from '../../hooks/useApprovalWorkflowQuery';
import { nodeTypes } from '../../node-types';
import { NodeSettingsPanel } from '../NodeSettingsPanel';

const ApprovalByDimensionsPageInternal = () => {
  const { nodes, edges, setNodes } = useWorkflowContext();
  const approvalWorkflowQueryState = useApprovalWorkflowQuery();

  useEffect(() => {
    if (approvalWorkflowQueryState.status === 'success') {
      const schemeId =
        approvalWorkflowQueryState.data.definition.approvalSchemeId;
      const updatedNodes = nodes.map((node) => {
        return {
          ...node,
          data: {
            ...node.data,
            schemeId,
          },
        };
      });
      setNodes(updatedNodes);
    }
  }, [
    approvalWorkflowQueryState.status,
    approvalWorkflowQueryState.data,
    setNodes,
  ]);

  return (
    <div className="page__container">
      <ReactFlow
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        nodes={nodes}
        edges={edges}
        fitView
        fitViewOptions={{ padding: 1.2 }}
      >
        <Controls />
        <Background gap={12} size={1} variant={BackgroundVariant.Dots} />
      </ReactFlow>
      <NodeSettingsPanel />
    </div>
  );
};

export const ApprovalByDimensionsPage = () => {
  return (
    <WorkflowProvider>
      <ApprovalByDimensionsPageInternal />
    </WorkflowProvider>
  );
};
