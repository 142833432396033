import { Button, Callout } from '@dev-spendesk/grapes';
import { useFormik } from 'formik';
import { useQueryClient } from 'react-query';

import { toApiFormat as toApiApprovalRules } from 'modules/company/structure/approval-flows/models';
import { QueryError } from 'src/core/common/components/QueryError';
import { QuerySuspense } from 'src/core/common/components/QuerySuspense';
import { useTranslation } from 'src/core/common/hooks/useTranslation';
import { useCompanyId } from 'src/core/modules/app/hooks/useCompanyId';
import {
  NotificationType,
  useNotifications,
} from 'src/core/modules/app/notifications';
import { createApprovalScheme } from 'src/core/modules/company/cost-centers/containers/CostCenterFormModalContainer/hooks/createApprovalScheme';
import { createApprovalWorkflow } from 'src/core/modules/company/cost-centers/containers/CostCenterFormModalContainer/hooks/createApprovalWorkflow';
import {
  ApprovalRulesContainer,
  type ApprovalRule,
} from 'src/core/modules/company/structure/approval-flows';

import { Loading } from './Loading';
import { validateForm } from './validate';
import { useMembersQuery } from '../../hooks/useMembersQuery';

type FormProps = {
  approvalRules: ApprovalRule[];
};

export const ApprovalSchemeCreation = ({
  onClose,
}: {
  onClose: () => void;
}) => {
  const companyId = useCompanyId();
  const { t } = useTranslation('global');
  const queryClient = useQueryClient();
  const { pushNotif } = useNotifications();
  const membersQueryState = useMembersQuery();
  const {
    values: formValues,
    errors,
    setFieldValue,
    handleSubmit,
    isSubmitting,
  } = useFormik<FormProps>({
    initialValues: {
      approvalRules: defaultApprovalRules,
    },
    validate: (values) => validateForm(values, t),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        const approvalScheme = await createApprovalScheme(
          {
            owner: null,
            rules: toApiApprovalRules(values.approvalRules),
          },
          companyId,
        );
        await createApprovalWorkflow(
          { approvalSchemeId: approvalScheme.id },
          companyId,
        );
      } catch (error) {
        pushNotif({
          type: NotificationType.Danger,
          message: 'Failed to update approval flow',
        });
        throw new Error('Failed to update approval flow', error);
      }
      setSubmitting(false);
      onClose();
      queryClient.invalidateQueries(['approval-workflows', 'active']);
      pushNotif({
        type: NotificationType.Success,
        message: 'Approval flow updated',
      });
    },
  });

  return (
    <>
      <div>
        <QuerySuspense
          loading={<Loading />}
          fallback={(error) => (
            <QueryError queryError={error} componentType="Callout" />
          )}
          queryState={membersQueryState}
        >
          {(members) => {
            return (
              <ApprovalRulesContainer
                rules={formValues.approvalRules}
                members={members}
                onChange={(rules) => {
                  setFieldValue('approvalRules', rules);
                }}
                mode="approvalByDimensionsPanel"
              />
            );
          }}
        </QuerySuspense>
      </div>
      {errors.approvalRules && typeof errors.approvalRules === 'string' && (
        <Callout variant="alert" title={errors.approvalRules} />
      )}
      <div className="mt-auto flex justify-between gap-s pt-m">
        <Button
          text="Save"
          className="flex-grow"
          variant="primary"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
          onClick={() => {
            handleSubmit();
          }}
        />
        <Button
          text="Cancel"
          className="flex-grow"
          variant="secondary"
          onClick={() => {
            onClose();
          }}
        />
      </div>
    </>
  );
};

const defaultApprovalRules: ApprovalRule[] = [
  {
    id: '1',
    from: {
      value: null,
      currency: 'EUR',
    },
    upTo: {
      value: null,
      currency: 'EUR',
    },
    steps: [
      {
        rights: [
          {
            approverType: 'costCenterOwner',
          },
        ],
      },
    ],
  },
];
