import FEATURES from 'src/core/constants/features';
import { routes } from 'src/core/constants/routes';

import { type QuestionsList } from './getQuestionsList';
import { type TGlobalFunctionTyped as TFunctionTyped } from '../../hooks/useTranslation';

type User = {
  is_account_owner: boolean;
  is_admin: boolean;
  is_controller: boolean;
};

type GetQuestionsRouterParams = {
  questionsList: QuestionsList;
  featureFlags: {
    [FEATURES.PAYMENT_INBOX]: boolean;
  };
  t: TFunctionTyped;
};

export const getQuestionsRouter = ({
  questionsList,
  featureFlags,
  t,
}: GetQuestionsRouterParams) => {
  const questionsListByUrl = {
    requests: (user: User) => {
      const defaultQuestions = [
        questionsList.HC_LINK_1,
        questionsList.HC_LINK_2,
      ];

      if (user.is_account_owner) {
        return [
          questionsList.HC_LINK_6,
          questionsList.HC_LINK_7,
          ...defaultQuestions,
        ];
      }

      if (user.is_admin) {
        return [
          ...defaultQuestions,
          questionsList.HC_LINK_3,
          questionsList.HC_LINK_4,
        ];
      }

      if (user.is_controller) {
        return [questionsList.HC_LINK_6, ...defaultQuestions];
      }

      return [
        ...defaultQuestions,
        questionsList.HC_LINK_3,
        questionsList.HC_LINK_4,
      ];
    },

    payments: (user: User) => {
      const defaultQuestions = [questionsList.HC_LINK_10];
      if (user.is_account_owner) {
        return [
          ...defaultQuestions,
          featureFlags[FEATURES.PAYMENT_INBOX]
            ? questionsList.HC_LINK_16
            : questionsList.HC_LINK_47,
          questionsList.HC_LINK_13,
          questionsList.HC_LINK_11,
        ];
      }
      if (user.is_admin) {
        return [
          ...defaultQuestions,
          questionsList.HC_LINK_8,
          questionsList.HC_LINK_9,
          questionsList.HC_LINK_11,
        ];
      }
      if (user.is_controller) {
        return [
          ...defaultQuestions,
          featureFlags[FEATURES.PAYMENT_INBOX]
            ? questionsList.HC_LINK_16
            : questionsList.HC_LINK_47,
          questionsList.HC_LINK_13,
        ];
      }
      return [
        ...defaultQuestions,
        questionsList.HC_LINK_8,
        questionsList.HC_LINK_9,
        questionsList.HC_LINK_11,
      ];
    },

    toPrepare: () => {
      return [questionsList.HC_LINK_14, questionsList.HC_LINK_13];
    },

    subscriptions: () => {
      return [
        questionsList.HC_LINK_17,
        questionsList.HC_LINK_18,
        questionsList.HC_LINK_19,
        questionsList.HC_LINK_20,
      ];
    },

    cards: () => {
      return [
        questionsList.HC_LINK_22,
        questionsList.HC_LINK_23,
        questionsList.HC_LINK_24,
        questionsList.HC_LINK_25,
      ];
    },

    card: () => {
      return [
        questionsList.HC_LINK_22,
        questionsList.HC_LINK_23,
        questionsList.HC_LINK_24,
        questionsList.HC_LINK_25,
      ];
    },

    invoices: () => {
      return [questionsList.HC_LINK_60, questionsList.HC_LINK_61];
    },

    billing: () => {
      return [questionsList.HC_LINK_28, questionsList.HC_LINK_42];
    },

    members: () => {
      return [
        questionsList.HC_LINK_29,
        questionsList.HC_LINK_30,
        questionsList.HC_LINK_31,
        questionsList.HC_LINK_32,
        questionsList.HC_LINK_33,
      ];
    },

    teams: () => {
      return [
        questionsList.HC_LINK_34,
        questionsList.HC_LINK_35,
        questionsList.HC_LINK_36,
      ];
    },

    policies: () => [questionsList.HC_LINK_37, questionsList.HC_LINK_38],

    bank: (user: User) => {
      if (!user.is_controller && !user.is_account_owner) {
        return null;
      }
      return [
        questionsList.HC_LINK_6,
        questionsList.HC_LINK_39,
        questionsList.HC_LINK_40,
        questionsList.HC_LINK_41,
      ];
    },

    companyNotifications: () => {
      return [questionsList.HC_LINK_13];
    },

    accounting: () => {
      return [questionsList.HC_LINK_44, questionsList.HC_LINK_45];
    },

    categories: () => {
      return [questionsList.HC_LINK_47];
    },

    accountMe: () => {
      return [questionsList.HC_LINK_49, questionsList.HC_LINK_50];
    },

    accountNotifications: () => {
      return [questionsList.HC_LINK_51];
    },

    exports: () => {
      return [
        questionsList.HC_LINK_52,
        questionsList.HC_LINK_53,
        questionsList.HC_LINK_54,
      ];
    },

    homepage: () => {
      return [questionsList.HC_LINK_39, questionsList.HC_LINK_13];
    },

    expenseClaims: () => {
      return [questionsList.HC_LINK_62, questionsList.HC_LINK_63];
    },

    purchaseOrders: () => {
      return [questionsList.HC_LINK_64];
    },
  };

  return [
    {
      path: routes.REQUESTS.path,
      questions: questionsListByUrl.requests,
      helpCenterIndex: questionsList.HC_INDEX,
      title: t('helpCenter.titles.requests'),
    },
    {
      path: routes.PAYMENTS_ALL.path,
      questions: questionsListByUrl.payments,
      helpCenterIndex: questionsList.HC_INDEX_2,
      title: t('helpCenter.titles.payments'),
    },
    {
      path: routes.EXPENSE_INBOX_PREPARE.path,
      questions: questionsListByUrl.toPrepare,
      helpCenterIndex: questionsList.HC_INDEX_2,
      title: t('helpCenter.titles.payments'),
    },
    {
      path: routes.EXPENSE_INBOX_EXPORT.path,
      questions: questionsListByUrl.payments,
      helpCenterIndex: questionsList.HC_INDEX_2,
      title: t('helpCenter.titles.payments'),
    },
    {
      path: routes.SUBSCRIPTIONS.path,
      questions: questionsListByUrl.subscriptions,
      helpCenterIndex: questionsList.HC_INDEX_1,
      title: t('helpCenter.titles.subscriptions'),
    },
    {
      path: routes.CARDS.path,
      questions: questionsListByUrl.cards,
      helpCenterIndex: questionsList.HC_INDEX_3,
      title: t('helpCenter.titles.cards'),
    },
    {
      path: routes.CARD.path,
      questions: questionsListByUrl.card,
      helpCenterIndex: questionsList.HC_INDEX_3,
      title: t('helpCenter.titles.card'),
    },
    {
      path: routes.INVOICES.path,
      questions: questionsListByUrl.invoices,
      helpCenterIndex: questionsList.HC_INDEX_7,
      title: t('helpCenter.titles.invoices'),
    },
    {
      path: routes.COMPANY_ACCOUNTING.path,
      questions: questionsListByUrl.accounting,
      helpCenterIndex: questionsList.HC_INDEX_4,
      title: t('helpCenter.titles.accountingSettings'),
    },
    {
      path: routes.COMPANY_EXPORTS.path,
      questions: questionsListByUrl.exports,
      helpCenterIndex: questionsList.HC_INDEX_8,
      title: t('helpCenter.titles.companyExports'),
    },
    {
      path: routes.COMPANY_BILLING_PLAN.path,
      questions: questionsListByUrl.billing,
      helpCenterIndex: questionsList.HC_INDEX_5,
      title: t('helpCenter.titles.companySettings'),
    },
    {
      path: routes.COMPANY_MEMBERS.path,
      questions: questionsListByUrl.members,
      helpCenterIndex: questionsList.HC_INDEX_6,
      title: t('helpCenter.titles.membersSettings'),
    },
    {
      path: routes.COMPANY_TEAMS.path,
      questions: questionsListByUrl.teams,
      helpCenterIndex: questionsList.HC_INDEX_6,
      title: t('helpCenter.titles.teamsSettings'),
    },
    {
      path: routes.COMPANY_POLICIES.path,
      questions: questionsListByUrl.policies,
      helpCenterIndex: questionsList.HC_INDEX_6,
      title: t('helpCenter.titles.policiesSettings'),
    },
    {
      path: routes.COMPANY_CONTROL_RULES.path,
      questions: questionsListByUrl.policies,
      helpCenterIndex: questionsList.HC_INDEX_6,
      title: t('helpCenter.titles.controlRulesSettings'),
    },
    {
      path: routes.COMPANY_BANK.path,
      questions: questionsListByUrl.bank,
      helpCenterIndex: questionsList.HC_INDEX_5,
      title: t('helpCenter.titles.walletSettings'),
    },
    {
      path: routes.COMPANY_GENERAL_SETTINGS_NOTIFICATIONS.path,
      questions: questionsListByUrl.companyNotifications,
      helpCenterIndex: questionsList.HC_INDEX_5,
      title: t('helpCenter.titles.notificationsSettings'),
    },
    {
      path: routes.COMPANY_CATEGORIES.path,
      questions: questionsListByUrl.categories,
      helpCenterIndex: questionsList.HC_INDEX_4,
      title: t('helpCenter.titles.categoriesSettings'),
    },
    {
      path: routes.ACCOUNT_ME.path,
      questions: questionsListByUrl.accountMe,
      helpCenterIndex: questionsList.HC_INDEX,
      title: t('helpCenter.titles.profileDetails'),
    },
    {
      path: routes.ACCOUNT_NOTIFICATIONS.path,
      questions: questionsListByUrl.accountNotifications,
      helpCenterIndex: questionsList.HC_INDEX,
      title: t('helpCenter.titles.profileNotification'),
    },
    {
      path: routes.ACCOUNT_NOTIFICATIONS_GENERAL.path,
      questions: questionsListByUrl.accountNotifications,
      helpCenterIndex: questionsList.HC_INDEX,
      title: t('helpCenter.titles.profileNotification'),
    },
    {
      path: routes.ACCOUNT_NOTIFICATIONS_CONTROL.path,
      questions: questionsListByUrl.accountNotifications,
      helpCenterIndex: questionsList.HC_INDEX,
      title: t('helpCenter.titles.profileNotification'),
    },
    {
      path: routes.ACCOUNT_NOTIFICATIONS_USAGE.path,
      questions: questionsListByUrl.accountNotifications,
      helpCenterIndex: questionsList.HC_INDEX,
      title: t('helpCenter.titles.profileNotification'),
    },
    {
      path: routes.HOMEPAGE.path,
      questions: questionsListByUrl.homepage,
      helpCenterIndex: questionsList.HC_INDEX_5,
      title: t('helpCenter.titles.companySettings'),
    },
    {
      path: routes.EXPENSE_CLAIMS.path,
      questions: questionsListByUrl.expenseClaims,
      helpCenterIndex: questionsList.HC_INDEX,
      title: t('helpCenter.titles.expenseClaims'),
    },
    {
      path: routes.PURCHASE_ORDERS.path,
      questions: questionsListByUrl.purchaseOrders,
      helpCenterIndex: questionsList.HC_LINK_64,
      title: t('helpCenter.titles.purchaseOrders'),
    },
  ];
};
