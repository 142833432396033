import {
  FormField,
  AmountInput,
  type AmountInputProps,
} from '@dev-spendesk/grapes';
import React from 'react';

import {
  type CurrencyOption,
  SORTED_CURRENCY_OPTIONS,
} from 'src/core/config/money';

import type { BasicInputProps, FieldName, Form } from './types';
import { useErrorMessage } from './utils/useErrorMessage';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Props<TForm extends Form<any>> = BasicInputProps<TForm, number> &
  Omit<
    AmountInputProps,
    'currency' | 'onChange' | 'value' | 'onSelectCurrency' | 'currencies'
  > & {
    currencyFieldName: FieldName<TForm, 'EUR' | 'USD' | 'GBP'>;
    canChangeCurrency?: boolean;
    overridenCurrencyOptions?: CurrencyOption[];
    hint?: React.ReactNode;
  };

export function FormAmountInput<TForm>({
  name,
  currencyFieldName,
  form,
  label,
  hint,
  canChangeCurrency = true,
  overridenCurrencyOptions,
  ...props
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
Props<TForm extends Form<any> ? TForm : never>) {
  const error = useErrorMessage({
    form,
    key: name,
  });

  const currencies = overridenCurrencyOptions ?? SORTED_CURRENCY_OPTIONS;

  const baseProps: AmountInputProps = {
    currency:
      currencies.find(
        (currency) => currency.key === form.values[currencyFieldName],
      ) ?? currencies[0],
    onChange: (_, value) => {
      if (Number.isNaN(value)) {
        form.setFieldValue(name, undefined);
      } else if (value < 10_000_000_000_000) {
        // prevent safe integer error
        form.setFieldValue(name, value);
      }
    },
    value: form.values[name],
    fit: 'parent',
    ...props,
  };

  return (
    <FormField label={label} hint={hint} alertMessage={error}>
      {canChangeCurrency ? (
        <AmountInput
          {...baseProps}
          currencies={currencies}
          onSelectCurrency={(currency) =>
            form.setFieldValue(currencyFieldName, currency.key)
          }
        />
      ) : (
        <AmountInput {...baseProps} />
      )}
    </FormField>
  );
}
