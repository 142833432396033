import { useQueryStates } from 'src/core/api/hooks/useQueryStates';
import { type QueryState } from 'src/core/api/queryState';

import { useOrganisationEntityExpenseClaimsQuery } from './useOrganisationEntityExpenseClaimsQuery';
import { useOrganisationEntityInboxInvoicesQuery } from './useOrganisationEntityInboxInvoicesQuery';
import { useOrganisationEntityPayablesQuery } from './useOrganisationEntityPayablesQuery';
import { useOrganisationEntityReceiptsQuery } from './useOrganisationEntityReceiptsQuery';
import { useOrganisationEntityRequestStatsQuery } from './useOrganisationEntityRequestStatsQuery';
import { useOrganisationEntityTransferSchedulingQuery } from './useOrganisationEntityTransferSchedulingQuery';
import { type EntityActions, type EntityBasicInformation } from '../../types';
import { type OrganisationFeatures } from '../useOrganisationFeatures';

export const useGetEntityActions = (
  entityBasicData: EntityBasicInformation,
  features: OrganisationFeatures,
): QueryState<EntityActions> => {
  const { id: companyId } = entityBasicData;

  return useQueryStates({
    states: {
      inboxInvoicesStats: useOrganisationEntityInboxInvoicesQuery(companyId),
      requestStats: useOrganisationEntityRequestStatsQuery(companyId),
      transferScheduling:
        useOrganisationEntityTransferSchedulingQuery(companyId),
      expenseClaimsStats: useOrganisationEntityExpenseClaimsQuery(companyId),
      receiptsStats: useOrganisationEntityReceiptsQuery(companyId),
      payablesStats: useOrganisationEntityPayablesQuery(companyId),
    },
    reshapeData: ({
      inboxInvoicesStats,
      requestStats,
      transferScheduling,
      expenseClaimsStats,
      receiptsStats,
      payablesStats,
    }) =>
      ({
        inboxInvoices: features.invoicesInbox
          ? inboxInvoicesStats
          : { toAssign: 0, awaiting: 0 },
        requests: features.requests
          ? requestStats
          : { all: undefined, toApprove: undefined },
        invoices: features.invoices
          ? transferScheduling
          : {
              toReview: undefined,
              toSchedule: undefined,
              toConfirm: undefined,
            },
        expenseClaims: features.expenseClaims
          ? expenseClaimsStats
          : {
              toReview: undefined,
              toReimburse: undefined,
              toConfirm: undefined,
            },
        receipts: {
          late: features.receipts.lateReceipts ? receiptsStats.late : undefined,
          missing: features.receipts.missingReceipts
            ? receiptsStats.missing
            : undefined,
        },
        payables: features.payables
          ? payablesStats
          : {
              toPrepare: undefined,
              toExport: undefined,
            },
      }) satisfies EntityActions,
  });
};
