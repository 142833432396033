import React, { useId } from 'react';

import { type PartnerIntegration } from '../../../types/partnerIntegration';
import { PartnerIntegrationBox } from '../IntegrationBox';

type Props = {
  title: string;
  integrations: PartnerIntegration[];
};
export const PartnerIntegrationsSection = (props: Props) => {
  const { title, integrations } = props;
  const id = useId();

  return integrations.length !== 0 ? (
    <div className="mt-m flex flex-col gap-m">
      <section className="grid grid-cols-2 gap-m" aria-labelledby={id}>
        <h2 className="col-span-2 text-complementary title-xl" id={id}>
          {title}
        </h2>
        {integrations.map((integration) => (
          <PartnerIntegrationBox
            key={`partner_integration_${integration.id}`}
            integration={integration}
          />
        ))}
      </section>
    </div>
  ) : null;
};
