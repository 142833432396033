import { useGoToPayment } from 'modules/payments';
import { rejectUnexpectedValue } from 'src/core/utils/switchGuard';

import { useGoToPaymentHistory } from './useGoToPaymentHistory';
import { useGoToSchedulePayment } from './useGoToSchedulePayment';
import type {
  Bill,
  SchedulingProcessEntry,
  SchedulingProcessEntryExecuting,
  SchedulingProcessEntryPaid,
  SchedulingProcessEntryScheduled,
  SchedulingProcessEntryToSchedule,
  SchedulingProcessEntryFailed,
} from '../../models';
import { useGoToTransfer } from '../../transfer/hooks/route';

export const useGoToSchedulingProcessEntry = () => {
  const goToPayment = useGoToPayment();
  const goToTransfer = useGoToTransfer();
  const goToSchedulePayment = useGoToSchedulePayment();
  const goToPaymentHistory = useGoToPaymentHistory();

  const onSchedulingProcessEntryPaidClick = (
    schedulingProcessEntryPaid: SchedulingProcessEntryPaid,
  ): void => {
    goToPayment({ paymentId: schedulingProcessEntryPaid.paymentId });
  };

  const onSchedulingProcessEntryScheduledClick = (
    schedulingProcessEntryScheduled: SchedulingProcessEntryScheduled,
    bill: Bill,
  ): void => {
    goToTransfer({
      paymentId: schedulingProcessEntryScheduled.id,
      type: bill.type,
    });
  };

  const onSchedulingProcessEntryToScheduleClick = (
    schedulingProcessEntryToSchedule: SchedulingProcessEntryToSchedule,
    bill: Bill,
  ): void => {
    goToSchedulePayment({
      paymentId: schedulingProcessEntryToSchedule.id,
      type: bill.type,
    });
  };

  const onSchedulingProcessEntryExecutingClick = (
    schedulingProcessEntryExecuting: SchedulingProcessEntryExecuting,
    bill: Bill,
  ): void => {
    goToPaymentHistory({
      paymentId: schedulingProcessEntryExecuting.id,
      type: bill.type,
    });
  };

  const onSchedulingProcessEntryFailedClick = (
    schedulingProcessEntryFailed: SchedulingProcessEntryFailed,
    bill: Bill,
  ): void => {
    goToPaymentHistory({
      paymentId: schedulingProcessEntryFailed.id,
      type: bill.type,
    });
  };

  return (
    schedulingProcessEntry: SchedulingProcessEntry,
    bill: Bill,
  ): (() => void) => {
    switch (schedulingProcessEntry.status) {
      case 'toSchedule':
        return () =>
          onSchedulingProcessEntryToScheduleClick(schedulingProcessEntry, bill);

      case 'scheduled':
        return () =>
          onSchedulingProcessEntryScheduledClick(schedulingProcessEntry, bill);

      case 'success':
        return () => onSchedulingProcessEntryPaidClick(schedulingProcessEntry);

      case 'executing':
        return () =>
          onSchedulingProcessEntryExecutingClick(schedulingProcessEntry, bill);

      case 'failed':
        return () =>
          onSchedulingProcessEntryFailedClick(schedulingProcessEntry, bill);

      default:
        rejectUnexpectedValue(
          'Unexpected status on SchedulingProcessEntry: ',
          schedulingProcessEntry,
        );
    }
  };
};
