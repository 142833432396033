import { useHistory } from 'react-router-dom';

import { routeBuilder } from 'common/hooks/useGoTo';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { useExpensesClaimsRouter } from 'modules/reimbursements/hooks/useExpensesClaimsRouter';

type Params = {
  paymentId?: string;
  userId?: string;
  type: 'invoice' | 'expense';
};

export const useGoToSchedulePayment = () => {
  const expenseRouter = useExpensesClaimsRouter();
  const getRoute = useSchedulePaymentRoute();
  const history = useHistory();

  return (params: Params) => {
    if (params.type === 'expense') {
      expenseRouter.goToPay(params.userId);
    } else {
      const route = getRoute(params);
      history.push(route);
    }
  };
};

export const useSchedulePaymentRoute = () => {
  const companyId = useCompanyId();
  return (params: Params): string => getSchedulePaymentRoute(companyId, params);
};

const getSchedulePaymentRoute = (
  companyId: string,
  { paymentId }: Params,
): string => {
  if (paymentId) {
    return routeBuilder('INVOICES_PAY', {
      company: companyId,
      paymentToScheduleId: paymentId,
    });
  }

  return routeBuilder('INVOICES_PAY', {
    company: companyId,
  });
};
