import { type Dispatch, createAction } from '@reduxjs/toolkit';
import get from 'lodash/get';
import isEqual from 'react-fast-compare';

import { fetchUnreadExports } from 'modules/bookkeep/export';
import { fetchUserApprovalDataSuccess } from 'modules/profile/redux/actions';
import { automaticSessionExpire } from 'src/auth/modules/Login/redux/actions';
import { fetchMeCostCenter, updateMeLocally } from 'src/core/actions/users';
import { baseAPI, companyAPI } from 'src/core/api/axios';
import Config from 'src/core/config';
import { updateCurrentLanguage } from 'src/core/config/i18n';
import FEATURES from 'src/core/constants/features';
import { routeFor, routes } from 'src/core/constants/routes';
import { LocalStorageKey } from 'src/core/constants/storage';
import { fetchCompanyTotalSpend } from 'src/core/modules/company/redux/thunks';
import isEmailAtSpendesk from 'src/core/modules/jira-issue-reporter/utils/isEmailAtSpendesk';
import { type AppState } from 'src/core/reducers';
import { getIsFeatureEnabled } from 'src/core/selectors/globalSelectors';
import { getCompanyId } from 'src/core/selectors/globalSelectorsTyped';
import {
  getSelf,
  getUserApprovalData,
  isAccountOwner,
  isController,
} from 'src/core/selectors/users';
import { apiUrl } from 'src/core/utils/api';
import { enrichUser, spxEnrichUser } from 'src/core/utils/entities/user';
import { getOnboardingUrl } from 'src/core/utils/onboarding';
import {
  init as initLocalStorage,
  setItem,
  getItem,
  removeItem,
} from 'src/core/utils/storage';
import { addSpendeskContextToDatadogLogs } from 'src/utils/datadog-log-init';
import { addSpendeskContextToDatadogRum } from 'src/utils/datadog-rum-init';

import * as types from './actionTypes';
import { detectSupervisionAccountSwitch } from '../components/SwitchedSupervisedUserModal/redux/actions';

// App's bootstraping
const fetchMeLoading = createAction(types.FETCH_ME_LOADING);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fetchMeFailure = createAction<any>(types.FETCH_ME_FAILURE);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchMeSuccess = createAction<any>(types.FETCH_ME_SUCCESS);

export const fetchMe =
  (selectCompanyId: string | null = null) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, sonarjs/cognitive-complexity
  async (dispatch: Dispatch<any>, getState: () => AppState) => {
    dispatch(fetchMeLoading());

    // eslint-disable-next-line sonarjs/cognitive-complexity
    const fetchData = async () => {
      let response;
      try {
        const userResponse = await baseAPI.get('/user', {
          params: {
            activeCompanyId: selectCompanyId,
          },
        });
        const companyId = userResponse.data.active_company_id;

        const [companyResponse, featuresResponse, infoResponse] =
          await Promise.all([
            baseAPI.get(`/${companyId}/company`),
            baseAPI.get(`/${companyId}/features`),
            baseAPI.get(`/info`, {
              params: {
                activeCompanyId: companyId,
              },
            }),
          ]);

        response = {
          data: {
            company: companyResponse.data,
            featureSet: featuresResponse.data,
            user: userResponse.data,
            ...infoResponse.data,
          },
        };

        if (process.env.NODE_ENV === 'development' && selectCompanyId) {
          setItem(
            LocalStorageKey.__DEV_ONLY_me,
            JSON.stringify({
              responseData: response.data,
              activeCompanyId: selectCompanyId,
            }),
            localStorage,
            true,
          );
        }

        return response.data;
      } catch (error) {
        removeItem(LocalStorageKey.__DEV_ONLY_me, localStorage, true);

        if (
          error.isAxiosError &&
          (error.response?.status === 401 || error.response?.status === 0)
        ) {
          // Not logged in -> redirect to login
          const url = new URL(window.location.href);
          const backendEnvironment =
            Config.stage === 'production' || !url.searchParams.has('backendEnv')
              ? ''
              : `&backendEnv=${url.searchParams.get('backendEnv')}`;
          window.location.href = `/auth/login?targetUrl=${encodeURIComponent(
            window.location.href,
          )}${backendEnvironment}`;
          return;
        }

        if (
          error.isAxiosError &&
          error.response?.status === 403 &&
          error.response?.data.code === 'ACCESS_BLOCKED'
        ) {
          // Access blocked -> redirect to login
          // @ts-expect-error: Not an helpful comment
          const url = new URL(window.location);
          url.pathname = '/auth/login';
          url.searchParams.append('access-blocked', '1');
          window.location.href = url.toString();
        }

        dispatch(fetchMeFailure(error));
        throw error;
      }
    };

    const handleResponseData = ({
      company,
      user,
      supervisor,
      impersonator,
      hasImpersonationTargets,
      featureSet,
      ui,
    }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any) => {
      user.bankInfo = user?.data_by_company[company.id]?.bank_info;

      if (ui?.targetApp === 'onboarding') {
        // company has not fulfilled its kyb
        window.location.href = getOnboardingUrl({
          bankingProvider: company.banking_provider,
          pathname: company.id,
        });
        return;
      }
      if (
        ui?.targetApp === 'onboarding-hub' &&
        !window.location.pathname.includes('onboarding-hub')
      ) {
        window.location.href = routeFor(routes.ONBOARDING_HUB.path, {
          company: company.id,
        });
        return;
      }

      // spxEnrichUser is the old backbone equivalent of enrichUser. The two *enrichUser could be merged
      const spxUser = spxEnrichUser(user, company);
      const enrichedUser = enrichUser(spxUser, company);
      updateCurrentLanguage(enrichedUser.lang);

      const config = {
        company,
        user,
        features: featureSet,
        supervisor,
        impersonator,
        ui,
      };

      // Augment global variable
      if (!window.Spx) {
        window.Spx = {};
      }
      window.Spx.features = new Proxy(config.features, {
        set(_, property, value) {
          dispatch(updateFeatureSet({ [property]: value }));
          return true;
        },
      });
      window.Spx.userId = user.id;

      const email = supervisor?.email ?? user?.email ?? '';
      if (isEmailAtSpendesk(email)) {
        window.Spx.ssdInfo = {
          userEmail: email,
          companyId: company.id,
          companyName: company.name,
        };
      }

      initLocalStorage(company.id);

      const me = {
        supervisor,
        impersonator,
        hasImpersonationTargets,
        user: enrichedUser,
        company,
        config,
      };

      dispatch(fetchMeSuccess(me));

      const state = getState();

      dispatch(automaticSessionExpire());

      dispatch(detectSupervisionAccountSwitch());
      const canExport = isAccountOwner(state) || isController(state);

      if (canExport) {
        dispatch(fetchUnreadExports());
      }

      if (getIsFeatureEnabled(state, FEATURES.COST_CENTERS_ACTIVATED)) {
        dispatch(fetchMeCostCenter());
      }

      if (user.is_controller) {
        dispatch(fetchCompanyTotalSpend());
      }

      // todo : check for consent before loading datadog
      addSpendeskContextToDatadogRum({
        userId: user.id,
        companyId: company.id,
      });
      addSpendeskContextToDatadogLogs({
        userId: user.id,
        companyId: company.id,
      });
    };

    let cachedResponseData;
    if (process.env.NODE_ENV === 'development') {
      try {
        cachedResponseData = getItem(
          LocalStorageKey.__DEV_ONLY_me,
          localStorage,
          true,
        );
        if (cachedResponseData && selectCompanyId) {
          const { responseData, activeCompanyId } =
            JSON.parse(cachedResponseData);
          cachedResponseData = responseData;
          if (activeCompanyId === selectCompanyId) {
            handleResponseData(cachedResponseData);
          }
        }
      } catch {
        removeItem(LocalStorageKey.__DEV_ONLY_me, localStorage, true);
      }
    }

    const responseData = await fetchData();

    if (
      process.env.NODE_ENV !== 'development' ||
      !isEqual(responseData, cachedResponseData)
    ) {
      handleResponseData(responseData);
    }
  };
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fetchMonthlyBudgetSuccess = createAction<any>(
  types.FETCH_MONTHLY_BUDGET_SUCCESS,
);
export const fetchMonthlyBudget =
  () => async (dispatch: Dispatch, getState: () => AppState) => {
    const companyId = getCompanyId(getState());

    const response = await fetch(apiUrl('/get-monthly-budget', companyId), {
      method: 'GET',
      credentials: 'include',
    });

    if (response.status === 200) {
      const { budget } = await response.json();
      dispatch(fetchMonthlyBudgetSuccess({ budget }));
    } else {
      dispatch(fetchMonthlyBudgetSuccess({ budget: null }));
    }
  };
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setMeCompanyPlasticCard = createAction<any>(
  types.SET_ME_COMPANY_PLASTIC_CARD,
);

// Delegation
export const revokeApprovalRights =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  () => async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const state = getState();
    const user = getSelf(state);
    const companyId = getCompanyId(state);
    const delegateId = get(user, 'user_delegate');

    if (!delegateId) {
      return;
    }

    try {
      await companyAPI.delete(`/delegation?delegateId=${delegateId}`, {
        companyId,
      });
    } catch (error) {
      reportError(error);
      return;
    }

    const delegateInfo = {
      is_delegating: false,
      user_delegate: null,
    };
    const dataByCompany = get(user, 'data_by_company');

    dispatch(
      updateMeLocally({
        dataByCompany: {
          ...dataByCompany,
          [companyId]: {
            ...dataByCompany[companyId],
            ...delegateInfo,
          },
        },
        ...delegateInfo,
      }),
    );

    const userApprovalData = getUserApprovalData(state);

    dispatch(
      fetchUserApprovalDataSuccess({
        ...userApprovalData,
        isDelegatingTo: undefined,
      }),
    );
  };
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateFeatureSet = createAction<any>(types.UPDATE_FEATURE_SET);
