import { useWalletTransactionForecastQuery } from 'modules/company';

import { walletAmountAllocationToCheckForMissingFunds } from '../models';
import { selectors, useSelectorWithContext } from '../redux';

export const useWalletTransactionForecastOnSelectedTransfersQuery = () => {
  const transfersSelectedAmountSum = useSelectorWithContext(
    selectors.selectTransfersSelectedAmountSum,
  );

  const getWalletTransactionForecastQueryState =
    useWalletTransactionForecastQuery();

  return (): ReturnType<typeof getWalletTransactionForecastQueryState> =>
    getWalletTransactionForecastQueryState(
      transfersSelectedAmountSum,
      walletAmountAllocationToCheckForMissingFunds,
    );
};
