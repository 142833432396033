import { useQuery } from 'src/core/api/hooks/useQuery';
import { type QueryState } from 'src/core/api/queryState';

type CompanyBillingStatus = {
  status: {
    companyId: string;
    billingInvoicePaymentStatus: 'good' | 'atRisk' | 'delinquent';
  };
  organisationPlanOwner:
    | {
        exists: true;
        companyId: string;
        billingInvoicePaymentStatus: 'good' | 'atRisk' | 'delinquent';
      }
    | {
        exists: false;
      };
};

export const useFetchCompanyBillingStatus = (
  companyId: string,
): QueryState<CompanyBillingStatus | undefined> => {
  return useQuery({
    key: ['billingStatus', companyId],
    request: {
      type: 'rest',
      target: 'companyAPI',
      endpoint: `/billing/status`,
    },
    reshapeData: (data) => data,
  });
};
