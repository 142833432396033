import {
  Button,
  IconButton,
  SwitchInput,
  Table,
  type TableColumn,
} from '@dev-spendesk/grapes';

import { useTranslation } from 'common/hooks/useTranslation';
import { type ExpenseAccount } from 'modules/bookkeep/settings/accounting';
import { EllipsisTooltip } from 'src/core/common/components/EllipsisTooltip';

import { ExpenseAccountsSkeletonTable } from './ExpenseAccountsSkeletonTable';
import { CellWithButton } from '../../components/CellWithIconButton/CellWithIconButton';

type Props = {
  expenseAccounts: ExpenseAccount[];
  expenseAccountsErrorsList?: string[];
  fetchNextPage: () => Promise<void>;
  hasFooter: boolean;
  hasHideAccountsFeatureOrAvailableIntegration?: boolean;
  isFetchingMore: boolean;
  isLoading: boolean;
  onDelete?: (row: ExpenseAccount) => void;
  onEdit?: (row: ExpenseAccount) => void;
  onSwitch?: (row: ExpenseAccount) => void;
};

export const ExpenseAccountTable = ({
  expenseAccounts,
  expenseAccountsErrorsList,
  fetchNextPage,
  hasFooter,
  hasHideAccountsFeatureOrAvailableIntegration = false,
  isLoading,
  isFetchingMore,
  onDelete,
  onEdit,
  onSwitch,
}: Props) => {
  const { t } = useTranslation('global');

  if (isLoading) {
    return <ExpenseAccountsSkeletonTable />;
  }

  const columns: TableColumn<ExpenseAccount>[] = [
    {
      id: 'expense-account-name',
      header: t(
        'bookkeep.integrations.settings.expenseAccountsTable.accountName',
      ),
      renderCell: (row) => {
        if (hasHideAccountsFeatureOrAvailableIntegration && onSwitch) {
          return (
            <div className="relative flex min-h-[36px] w-full items-center">
              <SwitchInput
                className="mr-s"
                isChecked={!!row.isAvailable}
                onChange={() => onSwitch(row)}
              />
              <EllipsisTooltip text={row.name} />
            </div>
          );
        }
        return row.name;
      },
      width: '50%',
    },
    {
      getCellVariant: (row) =>
        expenseAccountsErrorsList?.includes(row.id) ? 'alert' : undefined,
      id: 'expense-account-code',
      header: t(
        'bookkeep.integrations.settings.expenseAccountsTable.accountCode',
      ),
      width: '50%',
      renderCell: (row, { isRowHovered }) => {
        if (onEdit && onDelete) {
          return (
            <CellWithButton
              isButtonVisible={isRowHovered}
              button={
                <span className="CellWithButton__button flex gap-xxs">
                  <>
                    <IconButton
                      iconName="pen"
                      variant="borderless"
                      onClick={() => onEdit(row)}
                      aria-label={t('misc.edit')}
                    />
                    <IconButton
                      iconName="trash"
                      variant="borderless"
                      onClick={() => onDelete(row)}
                      aria-label={t('misc.delete')}
                    />
                  </>
                </span>
              }
            >
              {row.code}
            </CellWithButton>
          );
        }
        return row.code;
      },
    },
  ];

  return (
    <Table
      data={expenseAccounts}
      columns={columns}
      getRowId={(row) => row.id}
      getIsRowDisabled={(row) => {
        if (hasHideAccountsFeatureOrAvailableIntegration) {
          return !row.isAvailable;
        }
        return false;
      }}
      footer={
        hasFooter ? (
          <Button
            text={t('misc.loadMore')}
            variant="secondary"
            isDisabled={isLoading || isFetchingMore}
            onClick={fetchNextPage}
          />
        ) : undefined
      }
      emptyState={{
        title: t('bookkeep.integrations.settings.noExpenseAccountTitle'),
      }}
      hasColumnSeparator
      rowHeight="compact"
    />
  );
};
