import { IconButton } from '@dev-spendesk/grapes';
import { type ReactNode } from 'react';

type Props = {
  isOpen: boolean;
  title: string;
  children: ReactNode;
  onClose: () => void;
};
export const FloatingPanel = ({ children, isOpen, title, onClose }: Props) => {
  return isOpen ? (
    <div className="box absolute right-s top-s z-50 m-0 flex h-[calc(100%_-_32px)] w-[480px] flex-col overflow-y-auto bg-white p-0 shadow-40">
      <div className="flex items-center justify-between border-0 border-b border-solid border-b-neutral-lightest px-s py-xs">
        <div className="text-complementary title-l">{title}</div>
        <IconButton aria-label="cross" onClick={onClose} iconName="cross" />
      </div>
      <div className="flex grow flex-col p-s">{children}</div>
    </div>
  ) : null;
};
