import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { routes, routeFor } from 'src/core/constants/routes';

import { type TransferId } from '../../models';

type Params = {
  type: 'invoice' | 'expense';
  paymentId?: TransferId;
};

export const useTransferRoute = () => {
  const companyId = useCompanyId();

  return ({ paymentId, type }: Params) =>
    routeFor(
      type === 'invoice'
        ? routes.INVOICES_CONFIRM.path
        : routes.EXPENSE_CLAIMS_CONFIRM.path,
      {
        company: companyId,
        ...(paymentId ? { paymentId } : {}),
      },
    );
};
