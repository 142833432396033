import {
  useFetchInvoicesCounts,
  useFetchExpenseCounts,
} from 'modules/invoices/hooks';
import { type QueryState } from 'src/core/api/queryState';

export type Params = {
  type: 'invoice' | 'expense';
};

export type Response = number;

export const useQuery = (params: Params): QueryState<Response> => {
  const invoicesCountQueryState = useFetchInvoicesCounts({
    isEnabled: params.type === 'invoice',
  });
  const expenseCountQueryState = useFetchExpenseCounts({
    isEnabled: params.type === 'expense',
  });

  if (params.type === 'invoice') {
    return invoicesCountQueryState.status === 'success'
      ? {
          ...invoicesCountQueryState,
          data: invoicesCountQueryState.data.transfersToConfirm ?? 0,
        }
      : invoicesCountQueryState;
  }

  return expenseCountQueryState.status === 'success'
    ? {
        ...expenseCountQueryState,
        data: expenseCountQueryState.data.paymentsToConfirm ?? 0,
      }
    : expenseCountQueryState;
};
