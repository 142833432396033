import { z } from 'zod';

const partnerIntegrationSchema = z.object({
  description: z.string(),
  helpLink: z.string().url(),
  id: z.string(),
  logo: z.string(),
  name: z.string(),
  website: z.string().url(),
});

export const partnerIntegrationsSchema = z.array(partnerIntegrationSchema);
export type PartnerIntegration = z.infer<typeof partnerIntegrationSchema>;
