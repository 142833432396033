import {
  Button,
  Callout,
  IconButton,
  SkeletonTable,
  SkeletonText,
  Table,
} from '@dev-spendesk/grapes';
import { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'common/hooks/useTranslation';
import { useCompanyId } from 'modules/app/hooks/useCompanyId';
import { TaskStepLayout } from 'modules/onboarding/components/TaskStepLayout';
import { unwrapQuery } from 'src/core/api/unwrapQuery';
import { routeFor } from 'src/core/constants/routes';
import { useAccountPayableSuppliersListQuery } from 'src/core/modules/bookkeep/accounts-payable/hooks/useAccountPayableSuppliersListQuery';

import { ArchiveSupplierModal } from './components/ArchiveSupplierModal';
import { CreateSupplierAccountModal } from './components/CreateSupplierAccountModal';
import { UpdateSupplierAccountModal } from './components/UpdateSupplierAccountModal';
import { routes } from '../../routes';

export type SupplierAndAccountPayable = {
  supplierId: string;
  accountPayableId: string;
  supplierName: string;
  code: string;
};

export const AccountingSupplierAccountsList = () => {
  const history = useHistory();
  const failedLineToImportIndexes =
    history.location.state?.failedLineIndexes ?? [];

  const { t } = useTranslation('global');

  const companyId = useCompanyId();

  const [isCreateAccountPayableModalOpen, setIsCreateAccountPayableModalOpen] =
    useState<boolean>(false);
  const [
    editingSupplierAndAccountPayable,
    setEditingSupplierAndAccountPayable,
  ] = useState<SupplierAndAccountPayable | null>(null);
  const [
    archivingSupplierAndAccountPayable,
    setArchivingSupplierAndAccountPayable,
  ] = useState<SupplierAndAccountPayable | null>(null);

  const accountPayableSuppliersQuery = useAccountPayableSuppliersListQuery(
    '',
    'current',
  );
  const accountPayableSuppliers = unwrapQuery(accountPayableSuppliersQuery);

  const suppliersAndAccountPayables = useMemo(() => {
    if (!accountPayableSuppliers) {
      return [];
    }
    return accountPayableSuppliers.reduce(
      (accumulator, accountPayableSupplier) => {
        if (accountPayableSupplier.accountPayable.isArchived) {
          return accumulator;
        }
        return [
          ...accumulator,
          ...accountPayableSupplier.suppliers.map((supplier) => ({
            supplierId: supplier.id,
            accountPayableId: accountPayableSupplier.accountPayable.id,
            supplierName: supplier.name,
            code: accountPayableSupplier.accountPayable.generalAccountCode,
          })),
        ];
      },
      [] as SupplierAndAccountPayable[],
    );
  }, [accountPayableSuppliers]);

  const onBack = () => {
    const previousPath = routeFor(routes.ACCOUNTING_SUPPLIER_ACCOUNTS.path, {
      company: companyId,
    });
    history.push(previousPath);
  };

  const onNext = () => {
    const nextPath = routeFor(
      routes.ACCOUNTING_SUPPLIER_ACCOUNTS_DEFAULT.path,
      {
        company: companyId,
      },
    );
    history.push(nextPath);
  };

  return (
    <div className="page__container">
      <TaskStepLayout
        description={t('setupHub.supplierAccounts.description')}
        onBack={onBack}
        onNext={onNext}
        title={t('setupHub.supplierAccounts.title')}
        video={{
          // TODO(GROW-1484): Replace with localized video URL
          url: '',
          title: t('setupHub.supplierAccounts.helpTitle'),
        }}
      >
        <p className="mb-s title-m">
          {t('setupHub.supplierAccounts.listTitle')}
        </p>
        {failedLineToImportIndexes.length > 0 && (
          <Callout
            className="mb-s"
            variant="warning"
            title={t('setupHub.supplierAccounts.import.partialSuccessError', {
              lines: failedLineToImportIndexes.join(', '),
              count: failedLineToImportIndexes.length,
            })}
          />
        )}
        {accountPayableSuppliersQuery.status === 'loading' ? (
          <SkeletonTable
            numberOfRows={2}
            columns={[
              {
                cell: <SkeletonText width="80%" />,
                header: <SkeletonText width="50%" />,
                width: '50%',
              },
              {
                cell: <SkeletonText width="80%" />,
                header: <SkeletonText width="50%" />,
                width: '50%',
              },
            ]}
            withColumnSeparator
            withHeader
          />
        ) : (
          <>
            <Table
              data={suppliersAndAccountPayables}
              columns={[
                {
                  id: 'supplier-name',
                  header: t(
                    'bookkeep.accountsPayable.list.headers.supplierName',
                  ),
                  renderCell: (row) => (
                    <p className="body-m">{row.supplierName}</p>
                  ),
                  width: '50%',
                },
                {
                  id: 'account-payable-code',
                  header: t(
                    'bookkeep.accountsPayable.list.headers.accountPayable',
                  ),
                  renderCell: (row, { isRowHovered }) => (
                    <div className="relative flex items-center">
                      <p className="body-m">{row.code}</p>
                      {isRowHovered && (
                        <div className="absolute right-0 flex gap-xxs">
                          <IconButton
                            iconName="pen"
                            variant="borderless"
                            onClick={() =>
                              setEditingSupplierAndAccountPayable(row)
                            }
                            aria-label={t('misc.edit')}
                          />
                          <IconButton
                            iconName="trash"
                            variant="borderless"
                            onClick={() =>
                              setArchivingSupplierAndAccountPayable(row)
                            }
                            aria-label={t('misc.edit')}
                          />
                        </div>
                      )}
                    </div>
                  ),
                  width: '50%',
                },
              ]}
              getRowId={(row) => row.supplierId}
              hasColumnSeparator
            />
            <Button
              className="mt-s self-start"
              variant="ghost"
              iconName="plus"
              iconPosition="left"
              onClick={() => setIsCreateAccountPayableModalOpen(true)}
              text={t('setupHub.supplierAccounts.createSupplier.cta')}
            />
          </>
        )}
      </TaskStepLayout>
      <CreateSupplierAccountModal
        isOpen={isCreateAccountPayableModalOpen}
        onClose={() => setIsCreateAccountPayableModalOpen(false)}
      />
      <UpdateSupplierAccountModal
        isOpen={!!editingSupplierAndAccountPayable}
        onClose={() => setEditingSupplierAndAccountPayable(null)}
        supplierAndAccountPayable={editingSupplierAndAccountPayable}
      />
      <ArchiveSupplierModal
        isOpen={!!archivingSupplierAndAccountPayable}
        onClose={() => setArchivingSupplierAndAccountPayable(null)}
        supplierAndAccountPayable={archivingSupplierAndAccountPayable}
      />
    </div>
  );
};
