import { type TGlobalFunctionTyped } from 'src/core/common/hooks/useTranslation';
import {
  MAX_NUMBER_OF_LATE_RECEIPTS,
  MIN_NUMBER_OF_LATE_RECEIPTS,
} from 'src/core/modules/members/constants/controlRules';

type FormValues = {
  name: string;
  limit: number;
  delay: number;
};

type FormErrors = { [K in keyof FormValues]?: string };

export const validateForm = (
  {
    values,
  }: {
    values: FormValues;
  },
  translator: TGlobalFunctionTyped,
): FormErrors => {
  const errors: FormErrors = {};

  if (values.name?.trim() === '') {
    errors.name = translator('controlRulesModal.errors.emptyName');
  }

  if (!values.limit) {
    errors.limit = translator('controlRulesModal.errors.emptyLimit');
  }

  if (values.limit > MAX_NUMBER_OF_LATE_RECEIPTS) {
    errors.limit = translator('controlRulesModal.errors.maxLimit');
  }

  if (values.limit < MIN_NUMBER_OF_LATE_RECEIPTS) {
    errors.limit = translator('controlRulesModal.errors.minLimit');
  }

  return errors;
};

export const setErrors = (
  setFieldError: (field: string, message: string) => void,
  error: { error: string; message: string },
  t: TGlobalFunctionTyped,
): void => {
  switch (error?.error) {
    case 'name_already_used':
      setFieldError('name', t('controlRulesModal.errors.nameExists'));
      break;
    case 'invalid_parameters':
      setFieldError('limit', t('controlRulesModal.errors.invalidParameters'));
      break;
    default:
      break;
  }
};
