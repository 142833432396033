import { useTranslation } from 'common/hooks/useTranslation';
import { useNotifications } from 'modules/app/notifications';
import {
  type CustomFieldAssociation,
  type RequestType,
} from 'modules/requests/models';
import {
  type MutationState,
  useMutation,
} from 'src/core/api/hooks/useMutation';
import { type CurrenciesKey } from 'src/core/config/money';

type Payload = {
  type: RequestType;
  currency_declared: CurrenciesKey;
  amount_declared: number;
  description: string;
  user_message: string;
  custom_fields_associations: CustomFieldAssociation[];
  get_card_allowed: boolean;
  is_approved: boolean;
  is_single_purchase: boolean;
  is_subscription: boolean;
  is_card_load: boolean;
};

type Response = {
  auto_approved: boolean;
};

export const useRequestCardTopupMutation = (): MutationState<
  Payload,
  Response
> => {
  const { t } = useTranslation('global');
  const { successNotif, dangerNotif } = useNotifications();

  return useMutation<Payload, Response, Response>({
    request: {
      type: 'rest',
      method: 'post',
      target: 'companyAPI',
      endpoint: `/requests`,
    },
    reshapeData: (data) => data,
    options: {
      onSuccess: ({ data }) => {
        successNotif(
          data.auto_approved
            ? t('cards.actions.successTopupAutoApproved')
            : t('cards.actions.successSubmitTopupRequest'),
        );
      },
      onError: () => {
        dangerNotif(t('errors:somethingWrong'));
      },
    },
  });
};
