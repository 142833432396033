import { type I18nKey } from 'src/core/common/hooks/useTranslation';
import { rejectUnexpectedValue } from 'src/core/utils/switchGuard';

import { type AccountingSoftware } from '../../../../../../integration/status';

export function getEmployeeAccountsDescriptionI18nKey(
  integration: AccountingSoftware,
): string {
  switch (integration) {
    case 'Xero':
      return 'bookkeep.integrations.xero.employeeAccountsDescription';
    case 'Datev':
      return 'bookkeep.integrations.datev.employeeAccountsDescription';
    case 'SpendeskAccounting':
    case 'SpendeskAccountingSingleEntry':
    case 'Sage':
    case 'Cegid':
      return 'bookkeep.integrations.fileBased.employeeAccountsDescription';
    case 'Netsuite':
      return 'netsuite';
    case 'Quickbooks':
      return 'quickbooks';
    case 'Sage100':
      return 'sage100';
    case 'ACD':
      return 'acd';
    case 'Odoo':
      return 'odoo';
    case 'ExactOnlineFr':
      return 'exactOnlineFr';
    default:
      rejectUnexpectedValue('integration type', integration);
  }
}

export function getEmployeeAccountsHelpCenterLink(
  integration: AccountingSoftware,
): string {
  switch (integration) {
    case 'Datev':
      return 'https://helpcenter.spendesk.com/articles/5560134-how-to-set-up-employee-accounts-datev-bookkeep-2-0';
    default:
      return 'https://helpcenter.spendesk.com/articles/4136555-set-up-your-accounting-codes';
  }
}

export function getSpecificEmployeeAccountsDescriptionI18nKey(
  integration: AccountingSoftware,
): I18nKey {
  switch (integration) {
    case 'Xero':
      return 'bookkeep.integrations.xero';
    case 'Datev':
      return 'bookkeep.integrations.datev.specificEmployeeAccountsDescription';
    case 'SpendeskAccounting':
    case 'SpendeskAccountingSingleEntry':
    case 'Sage':
    case 'Cegid':
      return 'bookkeep.integrations.fileBased.specificEmployeeAccountsDescription';
    case 'Netsuite':
      return 'bookkeep.integrations.netsuite.specificEmployeeAccountsDescription';
    case 'Quickbooks':
      return 'bookkeep.integrations.quickbooks';
    case 'Sage100':
    case 'ACD':
    case 'Odoo':
    case 'ExactOnlineFr':
      return 'bookkeep.integrations.chift.specificEmployeeAccountsDescription';
    default:
      rejectUnexpectedValue('integration type', integration);
  }
}

export function getSpecificEmployeeAccountsHelpCenterLink(
  integration: AccountingSoftware,
): string {
  switch (integration) {
    case 'Datev':
      return 'https://helpcenter.spendesk.com/articles/5560134-how-to-set-up-employee-accounts-datev-bookkeep-2-0';
    default:
      return 'https://helpcenter.spendesk.com/articles/4136555-set-up-your-accounting-codes';
  }
}

export function getDefaultEmployeeAccountPlaceholderI18nKey(
  integration: AccountingSoftware,
): I18nKey {
  switch (integration) {
    case 'Netsuite':
      return 'bookkeep.integrations.netsuite.defaultEmployeeAccountPlaceholder';
    case 'Quickbooks':
      return 'bookkeep.integrations.quickbooks';
    case 'Xero':
      return 'bookkeep.integrations.xero';
    case 'Datev':
      return 'bookkeep.integrations.datev';
    case 'SpendeskAccounting':
      return 'bookkeep.integrations';
    case 'SpendeskAccountingSingleEntry':
      return 'bookkeep.integrations';
    case 'Sage':
      return 'bookkeep.integrations.sage';
    case 'Cegid':
      return 'bookkeep.integrations.cegid';
    case 'Sage100':
    case 'ACD':
    case 'Odoo':
    case 'ExactOnlineFr':
      return 'bookkeep.integrations.chift.defaultEmployeeAccountPlaceholder';
    default:
      rejectUnexpectedValue('integration type', integration);
  }
}
